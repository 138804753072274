import classnames from 'classnames';
import styles from './ButtonRect.module.scss';
import { ButtonRectProps, ButtonType } from './ButtonRect.types';

const ButtonRect: React.FC<ButtonRectProps> = ({
  className,
  onClick,
  text,
  isVisible,
  type,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classnames(
      styles.button,
      className,
      styles[type ?? ButtonType.DEFAULT],
      { [styles.hidden]: isVisible === false },
    )}
  >
    { text }
  </button>
);

export default ButtonRect;
