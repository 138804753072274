import { useEffect } from 'react';
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import { useInView } from 'react-intersection-observer';
import { TIME_SPENT_INCREMENT, TIME_SPENT_INTERVAL } from './constants';

const useEntrepreneurshipTracker = () => {
  const { addTimeSpent } = useAssessmentStateActions();
  const [entrepreneurshipRef, entrepreneurshipInView] = useInView({ threshold: 0.1 });

  useEffect(() => {
    const learningCornerInterval = setInterval(() => {
      if (entrepreneurshipInView) {
        addTimeSpent('entrepreneurshipTimeSpent', TIME_SPENT_INCREMENT);
      }
    }, TIME_SPENT_INTERVAL);

    if (!entrepreneurshipInView) {
      clearInterval(learningCornerInterval);
    }
    return () => clearInterval(learningCornerInterval);
  }, [entrepreneurshipInView, addTimeSpent]);

  return {
    entrepreneurshipRef,
  };
};

export default useEntrepreneurshipTracker;
