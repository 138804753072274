/* eslint-disable no-alert */
import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import s from './InstallPrompt.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';

const InstallPrompt: React.FC = () => {
  const lang = useTranslation(TranslationPage.INSTALL_PROMPT);
  const [promptIfPossible, setPromptIfPossible] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      if (isMobile) {
        e.preventDefault();
        setDeferredPrompt(e);
      }
    });
  }, []);

  if (!deferredPrompt || !promptIfPossible) return null;

  const triggerInstall = async () => {
    try {
      (deferredPrompt as any).prompt();
      await (deferredPrompt as any).userChoice;
      setPromptIfPossible(false);
    } catch (e) {
      setPromptIfPossible(false);
    }
  };

  const cancelInstall = () => {
    setPromptIfPossible(false);
  };

  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>
        {lang.title}
      </h1>
      <p className={s.description}>
        {lang.description}
      </p>
      <div className={s.actions}>
        <button
          type="button"
          className={s.buttonCancel}
          onClick={cancelInstall}
        >
          {lang.cancel}
        </button>
        <button
          type="button"
          className={s.buttonInstall}
          onClick={triggerInstall}
        >
          {lang.install}
        </button>
      </div>
    </div>
  );
};

export default InstallPrompt;
