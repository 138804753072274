import { Formik, Form } from 'formik';
import { useMemo } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

import { objectEntries } from 'utils';
import {
  Radio,
  QuestionnaireWrapper,
  ErrorMessage,
} from 'components/questionnaire';
import {
  FivePointScale,
} from 'types/enums';
import { PostAssessmentQuestions } from 'types';

import { FormValues } from './FormValues';
import makeValidator from './makeValidator';
import { stripNulls } from '../helpers';
import { useTranslation } from '../../../hooks';
import { TranslationPage } from '../../../types/enums/TranslationPage';

type Props = {
  onSubmit: (values: Partial<PostAssessmentQuestions>) => Promise<void>;
  onQuit: () => void;
};

const PostAssessmentQuestionnaire: React.FC<Props> = ({
  onSubmit,
}) => {
  const lang = useTranslation(TranslationPage.Questionnaire);
  const validator = useMemo(() => makeValidator(lang.errors), [lang.errors]);

  return (
    <QuestionnaireWrapper>
      <Formik
        initialValues={{
          postAssessmentQuestionsAnswered: true,
          reportUsefullness: '',
          jobStrengthsAndWeaknesses: '',
          helpJobSearch: '',
          helpGettingJob: '',
          jobOpportunitiesKnowWaysToFindJob: '',
          jobOpportunitiesHaveGoodIdeaOfJobMarket: '',
          jobOpportunitiesKnowWhatKindOFJobWant: '',
          jobOpportunitiesIsGoodAtSearchingJobs: '',
          planToIncreaseJobSearch: '',
          targetSector: '',
          otherTargetSector: '',
          howManyHoursWillSearchPerWeek: '',
          howManyApplicationsNextMonth: '',
          willSkillsAttachHelpGetInterview: '',
          plansToAttachSkillsReport: '',
          expectsToFindJobInSixMonths: '',
          wageOfSimilarEmployeesAfter: '',
          lowestAcceptablePayAfter: '',
          accessedLearningLinks: '',
          accessedLearningLinksWhyNot: '',
          howManyHoursToUpskillPerWeek: '',
          helpGetInterview: '',
          helpGetJobInDesiredSector: '',
          inspiredToDevelopFurther: '',
          likelyToRecommendSkillcraft: '',
        } as FormValues}
        validate={validator}
        onSubmit={(form) => {
          const values = stripNulls({
            postAssessmentQuestionsAnswered: true,
            reportUsefullness:
              form.reportUsefullness !== ''
                ? form.reportUsefullness : null,
            jobStrengthsAndWeaknesses:
              form.jobStrengthsAndWeaknesses !== ''
                ? form.jobStrengthsAndWeaknesses : null,
            helpJobSearch:
              form.helpJobSearch !== ''
                ? form.helpJobSearch : null,
            helpGettingJob:
              form.helpGettingJob !== ''
                ? form.helpGettingJob : null,
            jobOpportunitiesKnowWaysToFindJob:
              form.jobOpportunitiesKnowWaysToFindJob !== ''
                ? form.jobOpportunitiesKnowWaysToFindJob : null,
            jobOpportunitiesHaveGoodIdeaOfJobMarket:
              form.jobOpportunitiesHaveGoodIdeaOfJobMarket !== ''
                ? form.jobOpportunitiesHaveGoodIdeaOfJobMarket : null,
            jobOpportunitiesKnowWhatKindOFJobWant:
              form.jobOpportunitiesKnowWhatKindOFJobWant !== ''
                ? form.jobOpportunitiesKnowWhatKindOFJobWant : null,
            jobOpportunitiesIsGoodAtSearchingJobs:
              form.jobOpportunitiesIsGoodAtSearchingJobs !== ''
                ? form.jobOpportunitiesIsGoodAtSearchingJobs : null,
            planToIncreaseJobSearch:
              form.planToIncreaseJobSearch !== ''
                ? form.planToIncreaseJobSearch : null,
            accessedLearningLinks:
              form.accessedLearningLinks !== ''
                ? form.accessedLearningLinks : null,
            accessedLearningLinksWhyNot:
              form.accessedLearningLinksWhyNot !== ''
                ? form.accessedLearningLinksWhyNot : null,
            howManyHoursToUpskillPerWeek:
              form.howManyHoursToUpskillPerWeek !== ''
                ? form.howManyHoursToUpskillPerWeek : null,
            howManyHoursWillSearchPerWeek:
              form.howManyHoursWillSearchPerWeek !== ''
                ? form.howManyHoursWillSearchPerWeek : null,
            howManyApplicationsNextMonth:
              form.howManyApplicationsNextMonth !== ''
                ? form.howManyApplicationsNextMonth : null,
            willSkillsAttachHelpGetInterview:
              form.willSkillsAttachHelpGetInterview !== ''
                ? form.willSkillsAttachHelpGetInterview : null,
            plansToAttachSkillsReport:
              form.plansToAttachSkillsReport !== ''
                ? form.plansToAttachSkillsReport : null,
            expectsToFindJobInSixMonths:
              form.expectsToFindJobInSixMonths !== ''
                ? form.expectsToFindJobInSixMonths : null,
            targetSector: form.targetSector !== ''
              ? form.targetSector : null,
            otherPreferredSector: form.otherTargetSector !== ''
              ? form.otherTargetSector : null,
            wageOfSimilarEmployeesAfter: form.wageOfSimilarEmployeesAfter !== ''
              ? form.wageOfSimilarEmployeesAfter : null,
            lowestAcceptablePayAfter: form.lowestAcceptablePayAfter !== ''
              ? form.lowestAcceptablePayAfter : null,
            helpGetInterview: form.helpGetInterview !== ''
              ? form.helpGetInterview : null,
            helpGetJobInDesiredSector: form.helpGetJobInDesiredSector !== ''
              ? form.helpGetJobInDesiredSector : null,
            inspiredToDevelopFurther: form.inspiredToDevelopFurther !== ''
              ? form.inspiredToDevelopFurther : null,
            likelyToRecommendSkillcraft: form.likelyToRecommendSkillcraft !== ''
              ? form.likelyToRecommendSkillcraft : null,
          });
          onSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          submitForm,
          isSubmitting,
        }) => (
          <Form>

            {/* 19. REPORT USEFULLNESS */}

            {/* 20. JOB RELATED STRENGTHS AND WEAKNESSES */}
            <Radio
              name="jobStrengthsAndWeaknesses"
              question={lang.questions.postAssessment.jobStrengthsAndWeaknesses}
              isTouched={touched.jobStrengthsAndWeaknesses}
              error={errors.jobStrengthsAndWeaknesses}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.jobStrengthsAndWeaknessesFivePointScale[
                  value as keyof typeof lang.jobStrengthsAndWeaknessesFivePointScale
                ],
              }))}
            />

            <Radio
              name="reportUsefullness"
              question={lang.questions.postAssessment.reportUsefullness}
              isTouched={touched.reportUsefullness}
              error={errors.reportUsefullness}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.fivePointScale[value as keyof typeof lang.fivePointScale],
              }))}
            />

            {/* 21. HELP JOB SEARCH */}
            {/*<Radio*/}
            {/*  name="helpJobSearch"*/}
            {/*  question={lang.questions.postAssessment.helpJobSearch}*/}
            {/*  isTouched={touched.helpJobSearch}*/}
            {/*  error={errors.helpJobSearch}*/}
            {/*  items={objectEntries(FivePointScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.fivePointScale[value as keyof typeof lang.fivePointScale],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 22. HELP GETTING A JOB */}
            {/*<Radio*/}
            {/*  name="helpGettingJob"*/}
            {/*  question={lang.questions.postAssessment.helpGettingJob}*/}
            {/*  isTouched={touched.helpGettingJob}*/}
            {/*  error={errors.helpGettingJob}*/}
            {/*  items={objectEntries(FivePointScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.fivePointScale[value as keyof typeof lang.fivePointScale],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 23. jobOpportunity */}
            {/*<div>*/}
            {/*  <InputLabel>{lang.questions.preAssessment.jobOpportunity}</InputLabel>*/}

            {/* 23.1. jobOpportunity */}
            {/*<Radio*/}
            {/*  name="jobOpportunitiesKnowWaysToFindJob"*/}
            {/*  question={lang.questions.preAssessment.jobOpportunitiesKnowWaysToFindJob}*/}
            {/*  isTouched={touched.jobOpportunitiesKnowWaysToFindJob}*/}
            {/*  error={errors.jobOpportunitiesKnowWaysToFindJob}*/}
            {/*  items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.jobOpportunitiesAgreeDisagreeScale[*/}
            {/*      value as keyof typeof lang.jobOpportunitiesAgreeDisagreeScale*/}
            {/*    ],*/}
            {/*  }))}*/}
            {/*/>*/}
            {/* <QuizeeScale
              name="jobOpportunitiesKnowWaysToFindJob"
              question={lang.questions.preAssessment.jobOpportunitiesKnowWaysToFindJob}
              isTouched={touched.jobOpportunitiesKnowWaysToFindJob}
              error={errors.jobOpportunitiesKnowWaysToFindJob}
              items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({
                value,
                label: lang.jobOpportunitiesAgreeDisagreeScale[value],
              }))}
            /> */}

            {/* 23.2. jobOpportunity */}
            {/*<Radio*/}
            {/*  name="jobOpportunitiesHaveGoodIdeaOfJobMarket"*/}
            {/*  question={lang.questions.preAssessment.jobOpportunitiesHaveGoodIdeaOfJobMarket}*/}
            {/*  isTouched={touched.jobOpportunitiesHaveGoodIdeaOfJobMarket}*/}
            {/*  error={errors.jobOpportunitiesHaveGoodIdeaOfJobMarket}*/}
            {/*  items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.jobOpportunitiesAgreeDisagreeScale[*/}
            {/*      value as keyof typeof lang.jobOpportunitiesAgreeDisagreeScale*/}
            {/*    ],*/}
            {/*  }))}*/}
            {/*/>*/}
            {/* <QuizeeScale
              name="jobOpportunitiesHaveGoodIdeaOfJobMarket"
              question={lang.questions.preAssessment.jobOpportunitiesHaveGoodIdeaOfJobMarket}
              isTouched={touched.jobOpportunitiesHaveGoodIdeaOfJobMarket}
              error={errors.jobOpportunitiesHaveGoodIdeaOfJobMarket}
              items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({
                value,
                label: lang.jobOpportunitiesAgreeDisagreeScale[value],
              }))}
            /> */}

            {/* 23.3. jobOpportunity */}
            {/*<Radio*/}
            {/*  name="jobOpportunitiesKnowWhatKindOFJobWant"*/}
            {/*  question={lang.questions.preAssessment.jobOpportunitiesKnowWhatKindOFJobWant}*/}
            {/*  isTouched={touched.jobOpportunitiesKnowWhatKindOFJobWant}*/}
            {/*  error={errors.jobOpportunitiesKnowWhatKindOFJobWant}*/}
            {/*  items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.jobOpportunitiesAgreeDisagreeScale[*/}
            {/*      value as keyof typeof lang.jobOpportunitiesAgreeDisagreeScale*/}
            {/*    ],*/}
            {/*  }))}*/}
            {/*/>*/}
            {/* <QuizeeScale
              name="jobOpportunitiesKnowWhatKindOFJobWant"
              question={lang.questions.preAssessment.jobOpportunitiesKnowWhatKindOFJobWant}
              isTouched={touched.jobOpportunitiesKnowWhatKindOFJobWant}
              error={errors.jobOpportunitiesKnowWhatKindOFJobWant}
              items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({
                value,
                label: lang.jobOpportunitiesAgreeDisagreeScale[value],
              }))}
            /> */}

            {/* 23.4. jobOpportunity */}
            {/*<Radio*/}
            {/*  name="jobOpportunitiesIsGoodAtSearchingJobs"*/}
            {/*  question={lang.questions.preAssessment.jobOpportunitiesIsGoodAtSearchingJobs}*/}
            {/*  isTouched={touched.jobOpportunitiesIsGoodAtSearchingJobs}*/}
            {/*  error={errors.jobOpportunitiesIsGoodAtSearchingJobs}*/}
            {/*  items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.jobOpportunitiesAgreeDisagreeScale[*/}
            {/*      value as keyof typeof lang.jobOpportunitiesAgreeDisagreeScale*/}
            {/*    ],*/}
            {/*  }))}*/}
            {/*/>*/}
            {/* <QuizeeScale
              name="jobOpportunitiesIsGoodAtSearchingJobs"
              question={lang.questions.preAssessment.jobOpportunitiesIsGoodAtSearchingJobs}
              isTouched={touched.jobOpportunitiesIsGoodAtSearchingJobs}
              error={errors.jobOpportunitiesIsGoodAtSearchingJobs}
              items={objectEntries(AgreeDisagreeScale).map(([, value]) => ({
                value,
                label: lang.jobOpportunitiesAgreeDisagreeScale[value],
              }))}
            /> */}
            {/*</div>*/}

            {/* 24. WILL INCREASE JOB SEARCH */}
            {/*<Radio*/}
            {/*  name="planToIncreaseJobSearch"*/}
            {/*  question={lang.questions.postAssessment.planToIncreaseJobSearch}*/}
            {/*  isTouched={touched.planToIncreaseJobSearch}*/}
            {/*  error={errors.planToIncreaseJobSearch}*/}
            {/*  items={objectEntries(PlanToIncreaseJobSearch).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.planToIncreaseJobSearch[*/}
            {/*      value as keyof typeof lang.planToIncreaseJobSearch*/}
            {/*    ],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 25. TARGET SECTOR */}
            {/*<Select*/}
            {/*  name="targetSector"*/}
            {/*  question={lang.questions.postAssessment.targetSector}*/}
            {/*  error={errors.targetSector}*/}
            {/*  items={objectEntries(JobSector).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.jobSector[value as keyof typeof lang.jobSector],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 25.5 OTHER SECTOR */}
            {/*<Text*/}
            {/*  showIf={values.targetSector === JobSector.Other}*/}
            {/*  name="otherTargetSector"*/}
            {/*  question={lang.questions.postAssessment.otherTargetSector}*/}
            {/*  error={errors.otherTargetSector}*/}
            {/*/>*/}

            {/* 26. HOW MANY HOURS JOB SEARCHING PER WEEK */}
            {/*<Select*/}
            {/*  name="howManyHoursWillSearchPerWeek"*/}
            {/*  question={lang.questions.postAssessment.howManyHoursWillSearchPerWeek}*/}
            {/*  error={errors.howManyHoursWillSearchPerWeek}*/}
            {/*  items={objectEntries(HowMany).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.howMany[value as keyof typeof lang.howMany],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 27. HOW MANY APPLICATIONS IN NEXT MONTH */}
            {/*<Select*/}
            {/*  name="howManyApplicationsNextMonth"*/}
            {/*  question={lang.questions.postAssessment.howManyApplicationsNextMonth}*/}
            {/*  error={errors.howManyApplicationsNextMonth}*/}
            {/*  items={objectEntries(HowMany).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.howMany[value as keyof typeof lang.howMany],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 28. WILL ATTACH SKILLS REPORT TO CV */}
            {/*<Radio*/}
            {/*  name="plansToAttachSkillsReport"*/}
            {/*  question={lang.questions.postAssessment.plansToAttachSkillsReport}*/}
            {/*  isTouched={touched.plansToAttachSkillsReport}*/}
            {/*  error={errors.plansToAttachSkillsReport}*/}
            {/*  items={objectEntries(YesOrNo).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.yesOrNo[value as keyof typeof lang.yesOrNo],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 29. ATTACHING WILL HELP GETTING A JOB */}
            {/*<Radio*/}
            {/*  name="willSkillsAttachHelpGetInterview"*/}
            {/*  question={lang.questions.postAssessment.willSkillsAttachHelpGetInterview}*/}
            {/*  isTouched={touched.willSkillsAttachHelpGetInterview}*/}
            {/*  error={errors.willSkillsAttachHelpGetInterview}*/}
            {/*  items={objectEntries(FivePointScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.fivePointScale[value as keyof typeof lang.fivePointScale],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 30. EXECTS TO FIND JOB IN 6 MONTHS */}
            {/*<Radio*/}
            {/*  name="expectsToFindJobInSixMonths"*/}
            {/*  question={lang.questions.postAssessment.expectsToFindJobInSixMonths}*/}
            {/*  isTouched={touched.expectsToFindJobInSixMonths}*/}
            {/*  error={errors.expectsToFindJobInSixMonths}*/}
            {/*  items={objectEntries(YesOrNo).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.yesOrNo[value as keyof typeof lang.yesOrNo],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 31. TYPICAL MONTHLY WAGE FOR PEOPLE LIKE YOU */}
            {/*<Text*/}
            {/*  name="wageOfSimilarEmployeesAfter"*/}
            {/*  question={lang.questions.postAssessment.wageOfSimilarEmployeesAfter}*/}
            {/*  error={errors.wageOfSimilarEmployeesAfter}*/}
            {/*/>*/}

            {/* 32. LOWEST ACCEPTABLE WAGE */}
            {/*<Text*/}
            {/*  name="lowestAcceptablePayAfter"*/}
            {/*  question={lang.questions.postAssessment.lowestAcceptablePayAfter}*/}
            {/*  error={errors.lowestAcceptablePayAfter}*/}
            {/*/>*/}

            {/* 33. ACCESSED UPSKILLING LINKS */}
            {/*<Radio*/}
            {/*  name="accessedLearningLinks"*/}
            {/*  question={lang.questions.postAssessment.accessedLearningLinks}*/}
            {/*  isTouched={touched.accessedLearningLinks}*/}
            {/*  error={errors.accessedLearningLinks}*/}
            {/*  items={objectEntries(YesNoPlanTo).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.yesNoPlanTo[value as keyof typeof lang.yesNoPlanTo],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 34. HOW MANY HOURS UPSKILLING PER WEEK */}
            {/*<Select*/}
            {/*  showIf={values.accessedLearningLinks !== YesNoPlanTo.No}*/}
            {/*  name="howManyHoursToUpskillPerWeek"*/}
            {/*  question={lang.questions.postAssessment.howManyHoursToUpskillPerWeek}*/}
            {/*  error={errors.howManyHoursToUpskillPerWeek}*/}
            {/*  items={objectEntries(HowMany).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.howMany[value as keyof typeof lang.howMany],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 35. WHY NOT UPSKILL */}
            {/*<Text*/}
            {/*  showIf={*/}
            {/*    values.accessedLearningLinks !== YesNoPlanTo.Yes*/}
            {/*    && values.accessedLearningLinks !== YesNoPlanTo.PlanTo*/}
            {/*  }*/}
            {/*  name="accessedLearningLinksWhyNot"*/}
            {/*  question={lang.questions.postAssessment.accessedLearningLinksWhyNot}*/}
            {/*  error={errors.accessedLearningLinksWhyNot}*/}
            {/*/>*/}

            {/* 36. HOW HELPFUL GETTING INTERVIEW */}
            {/*<Radio*/}
            {/*  name="helpGetInterview"*/}
            {/*  question={lang.questions.postAssessment.helpGetInterview}*/}
            {/*  isTouched={touched.helpGetInterview}*/}
            {/*  error={errors.helpGetInterview}*/}
            {/*  items={objectEntries(FivePointScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.helpfulFivePointScale[*/}
            {/*  value as keyof typeof lang.helpfulFivePointScale*/}
            {/*  ],**/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 37. HOW HELPFUL GETTING DESIRED SECTOR */}
            {/*<Radio*/}
            {/*  name="helpGetJobInDesiredSector"*/}
            {/*  question={lang.questions.postAssessment.helpGetJobInDesiredSector}*/}
            {/*  isTouched={touched.helpGetJobInDesiredSector}*/}
            {/*  error={errors.helpGetJobInDesiredSector}*/}
            {/*  items={objectEntries(FivePointScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.helpfulFivePointScale[*/}
            {/*      value as keyof typeof lang.helpfulFivePointScale*/}
            {/*    ],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 39. INSPIRED TO DEVELOP FURTHER */}
            <Radio
              name="inspiredToDevelopFurther"
              question={lang.questions.postAssessment.inspiredToDevelopFurther}
              isTouched={touched.inspiredToDevelopFurther}
              error={errors.inspiredToDevelopFurther}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.likelinessFivePointScale[
                  value as keyof typeof lang.likelinessFivePointScale
                ],
              }))}
            />

            {/* 38. RECOMMEND SKILLCRAFT */}
            <Radio
              name="likelyToRecommendSkillcraft"
              question={lang.questions.postAssessment.likelyToRecommendSkillcraft}
              isTouched={touched.likelyToRecommendSkillcraft}
              error={errors.likelyToRecommendSkillcraft}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.likelinessFivePointScale[
                  value as keyof typeof lang.likelinessFivePointScale
                ],
              }))}
            />

            {/* SUBMIT */}
            <Box margin={1}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {lang.submit}
              </Button>
            </Box>

            {(errors as any).submissionError && (
              <ErrorMessage>{lang.submissionError}</ErrorMessage>
            )}
            {!!Object.entries(errors).length && (
              <ErrorMessage>{lang.missingEntriesError}</ErrorMessage>
            )}
          </Form>
        )}
      </Formik>
    </QuestionnaireWrapper>
  );
};

export default PostAssessmentQuestionnaire;
