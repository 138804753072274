import { create } from 'zustand';

type SkillStrengthCardState = {
  isSkillsStrengthCardOpen: boolean;
  skillsStrengthCardOpenName: string;
  toggleSkillStrengthCard: (isOpen: boolean, name?: string) => void;
};

const createSkillStrengthCardState = () => create<SkillStrengthCardState>(
  (set) => ({
    isSkillsStrengthCardOpen: false,
    skillsStrengthCardOpenName: '',
    toggleSkillStrengthCard: (isOpen: boolean, name?: string) => {
      set({
        isSkillsStrengthCardOpen: isOpen,
        skillsStrengthCardOpenName: name || '',
      });
    },
  }),
);

const useSkillStrengthCardState = createSkillStrengthCardState();
export {
  createSkillStrengthCardState,
};
export default useSkillStrengthCardState;
