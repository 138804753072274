import { Box, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';

const ErrorMessage: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Box>
    <FormHelperText error={true} variant="outlined">
      {children}
    </FormHelperText>
  </Box>
);

export default ErrorMessage;
