/* eslint-disable max-len */
import {
  useEffect,
  useRef,
  useState,
  createRef,
} from 'react';
import classnames from 'classnames';

import s from '../MTPT.module.scss';
import { Point } from '../types';

type ShapeProps = {
  cursor: Point;
  isFinished: boolean;
  hasError: boolean;
  isTracking: boolean;
  onError: () => void;
  onProgress: (progress: number) => void;
  setStartingPointRef: React.Dispatch<SVGEllipseElement | null>;
};

const Shape2: React.FC<ShapeProps> = ({
  cursor,
  isFinished,
  hasError,
  onError,
  onProgress,
  isTracking,
  setStartingPointRef,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const shapeRef = useRef<SVGPathElement | null>(null);
  const [progressShapes] = useState([
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
  ]);

  const [activatedProgressShapes] = useState(new Set<SVGEllipseElement>());
  if (!isTracking) {
    activatedProgressShapes.clear();
  }

  useEffect(() => {
    if (!svgRef.current || !shapeRef.current) {
      return;
    }
    const { left, top } = svgRef.current!.getBoundingClientRect();
    const cursorAbsolute = [
      cursor.x + left,
      cursor.y + top,
    ] as const;
    const elements = document.elementsFromPoint(...cursorAbsolute);

    const isInsideShape = elements.includes(shapeRef.current!);
    if (!isInsideShape) {
      onError();
    }

    // There are 9 progress shapes, each representing 10% of the trace progress. This means that
    // completing all of them makes up for 100% of the total progress.
    const progressShape = elements.find(e => progressShapes.map(s => s.current).includes(e as SVGEllipseElement));
    if (progressShape) {
      activatedProgressShapes.add(progressShape as SVGEllipseElement);
      onProgress((activatedProgressShapes.size) / (progressShapes.length));
    }
  }, [cursor, onError, onProgress, progressShapes, activatedProgressShapes]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1778 1000"
      xmlSpace="preserve"
      id="svg821"
      width="1778"
      height="1000"
      ref={svgRef}
      className={classnames(
        s.svg,
        isFinished && s.isFinished,
        hasError && s.hasError,
      )}
    >
      <path
        ref={shapeRef}
        className={s.mainShape}
        d="m 971.31845,858.50333 c 0,0 446.29805,0.16131 452.12135,0.24255 5.8233,0.0812 10.3617,-0.11697 13.9265,3.14857 3.5648,3.26554 3.2058,7.97543 3.3158,14.48041 0.11,6.50498 0.1732,61.41981 0.2253,68.39056 0.052,6.97076 -0.5071,8.82129 -3.3385,11.00032 -3.1032,2.38829 -8.4795,2.14839 -13.331,2.2439 -4.8515,0.0955 -523.71877,-0.16901 -523.71877,-0.16901 -18.31873,0.28657 -30.90304,-0.23009 -33.87434,-8.12794 -2.97131,-7.89785 -2.50386,-16.28767 -2.61163,-30.67423 L 863.88128,45.283933 c 0,0 -0.0631,-8.115619 4.39083,-14.422224 4.45394,-6.306605 10.61567,-6.490731 17.7883,-6.680163 18.84847,-0.06847 56.3712,-0.231112 64.10614,0.02117 7.73494,0.252283 12.22772,0.164444 15.64418,3.539541 3.41647,3.375098 4.10041,6.399279 4.02714,30.53579 -0.0733,24.136512 1.48058,800.225283 1.48058,800.225283 z"
      />
      <ellipse
        ref={setStartingPointRef}
        className={s.progressShape}
        cx="920"
        cy="65"
        rx="20"
        ry="20"
      />
      <ellipse
        ref={progressShapes[0]}
        className={s.progressShape}
        cx="916.95911"
        cy="165.32234"
        rx="68.196297"
        ry="29.651653"
      />
      <ellipse
        ref={progressShapes[1]}
        className={s.progressShape}
        cx="914.78845"
        cy="302.78281"
        rx="70.647369"
        ry="29.651653"
      /><ellipse
        ref={progressShapes[2]}
        className={s.progressShape}
        cx="913.96442"
        cy="748.63922"
        rx="69.869911"
        ry="29.651653"
      />
      <ellipse
        ref={progressShapes[3]}
        className={s.progressShape}
        cx="978.31024"
        cy="-1373.1404"
        rx="70.605049"
        ry="29.651653"
        transform="rotate(87.104469)"
      />
      <ellipse
        ref={progressShapes[4]}
        className={s.progressShape}
        cx="917.38159"
        cy="446.18207"
        rx="70.908813"
        ry="29.651653"
      />
      <ellipse
        ref={progressShapes[5]}
        className={s.progressShape}
        cx="918.90918"
        cy="587.10901"
        rx="71.926498"
        ry="29.651653"
      />
      <ellipse
        ref={progressShapes[6]}
        className={s.progressShape}
        cx="963.85132"
        cy="-1133.5107"
        rx="72.897865"
        ry="29.651653"
        transform="rotate(87.298036)"
      />
      <ellipse
        ref={progressShapes[7]}
        className={s.progressShape}
        cx="51.749973"
        cy="1289.0372"
        rx="88.810303"
        ry="31.476311"
        transform="matrix(0.75326,-0.65772286,0.68454213,0.7289733,0,0)"
      />
      <ellipse
        ref={progressShapes[8]}
        className={s.progressShape}
        cx="915.91937"
        cy="-1042.1301"
        rx="71.015114"
        ry="29.651653"
        transform="rotate(89.82005)"
      />
      <ellipse
        ref={progressShapes[9]}
        className={s.progressShape}
        cx="978.53497"
        cy="-1256.5352"
        rx="73.253899"
        ry="29.651653"
        transform="rotate(87.089281)"
      />
    </svg>
  );
};

export default Shape2;
