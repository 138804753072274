import { Field } from 'formik';
import {
  InputLabel,
  FormControlLabel,
  Radio as MuiRadio,
  FormHelperText,
} from '@mui/material';
import { RadioGroup } from 'formik-mui';
import Box from '@mui/material/Box';

type Props = {
  name: string;
  question: string;
  isTouched?: boolean;
  error?: string;
  items: { value: string; label: string }[];
  showIf?: boolean;
};

const Radio: React.FC<Props> = ({
  name,
  question,
  isTouched,
  error,
  items,
  showIf: isVisible,
}) => (isVisible === false ? null : (
  <Box>
    <InputLabel htmlFor={name}>{question}</InputLabel>
    <Field
      component={RadioGroup}
      variant="outlined"
      name={name}
      id={name}
    >
      {items.map(({ value, label }) => (
        <FormControlLabel
          value={value}
          key={value}
          label={label}
          control={<MuiRadio />}
        />
      ))}
    </Field>
    {isTouched && error && <FormHelperText error={true}>{error}</FormHelperText>}
  </Box>
));

export default Radio;
