import { useEffect, useState } from 'react';

export const useIsHorizontalOrientation = () => {
  const [isHorizontal, setIsHorizontal] = useState(window.innerHeight < window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsHorizontal(window.innerHeight < window.innerWidth);
    }, false);
    return () => {
      window.removeEventListener("resize", () => {
        setIsHorizontal(window.innerHeight < window.innerWidth);
      }, false);
    }
  }, []);

  return isHorizontal;
};
