import { useCurrentGameId, useIsGameFlowCompleted } from 'appState';
import games from 'games';
import { useRef } from 'react';

const Game: React.FC = () => {
  const gameId = useCurrentGameId();
  const gameFlowCompleted = useIsGameFlowCompleted();
  if (gameId === null && !gameFlowCompleted) {
    throw new Error('Missing game ID');
  }
  const cachedComponent = useRef(games[gameId!]);
  const GameComponent = cachedComponent.current;

  return <GameComponent />;
};

export default Game;
