import {
  useAppStateActions,
  usePostAssessmentQuestionsAnswered,
  // usePreAssessmentQuestionsAnswered,
  useShowFeedback,
} from 'appState';

// import PreAssessment from './PreAssessment';
import PostAssessment from './PostAssessment';
import PostAssessmentNoFeedback from './PostAssessmentNoFeedback';

const Questionnaire: React.FunctionComponent = () => {
  // const preCompleted = usePreAssessmentQuestionsAnswered();
  const postCompleted = usePostAssessmentQuestionsAnswered();
  const showFeedback = useShowFeedback();
  const {
    // submitPreAssessmentQuestionnaire,
    submitPostAssessmentQuestionnaire,
    appFlowForward,
  } = useAppStateActions();

  // TODO: Temporarily disabled, restore once options are added in admin panel for this instead
  // if (!preCompleted && false) {
  //   return (
  //     <PreAssessment
  //       onSubmit={values => submitPreAssessmentQuestionnaire({
  //         ...values,
  //         preAssessmentQuestionsAnswered: true,
  //       })}
  //     />
  //   );
  // } else {
  //   setTimeout(() => {
  //     appFlowForward();
  //   }, 0);
  //   return null;
  // }
  // TODO: Temporarily changed, restore once options are added in admin panel for this instead
  // if (preCompleted && !postCompleted) {
  if (!postCompleted) {
    return showFeedback
      ? (
        <PostAssessment
          onSubmit={values => submitPostAssessmentQuestionnaire({
            ...values,
            postAssessmentQuestionsAnswered: true,
          })}
          onQuit={appFlowForward}
        />
      )
      : (
        <PostAssessmentNoFeedback
          onSubmit={values => submitPostAssessmentQuestionnaire({
            ...values,
            postAssessmentQuestionsAnswered: true,
          })}
          onQuit={appFlowForward}
        />

      );
  }
  return null;
};

export default Questionnaire;
