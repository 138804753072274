import { FastLayer } from 'react-konva';

import useGameState from './useGameState';
import Connector from './Connector';

const Connectors: React.FC = () => {
  const isDrawingMode = useGameState(s => s.isDrawingMode);
  const activeConnector = useGameState(s => s.activeConnector);
  const wrongConnector = useGameState(s => s.wrongConnector);
  const committedConnectors = useGameState(s => s.committedConnectors);

  const activeConnectorSegments = partitionArrayWithOverlap(activeConnector, 50, 1);

  return (
    <>
      <FastLayer listening={false}>
        <Connector
          points={committedConnectors}
        />
      </FastLayer>
      <FastLayer listening={false}>
        {
          wrongConnector.length > 2
            ? (
              <Connector
                wrong
                points={wrongConnector}
              />
            ) : null
        }
      </FastLayer>
      <FastLayer listening={false}>
        {
          // Render by segments, so that previous segments don't get rendered
          // (thanks to <Connector>'s memoization):
          isDrawingMode
            ? (
              activeConnectorSegments
                .slice(0, activeConnectorSegments.length - 1)
                .map((segment, index) => (
                  <Connector
                    key={index}
                    active
                    points={segment}
                  />
                ))
            ) : null
        }
      </FastLayer>
      <FastLayer listening={false}>
        {
          isDrawingMode
            ? (
              // Render the final segment separately, so that the others don't cause canvas redraw:
              activeConnectorSegments.slice(-1).map((segment, index) => (
                <Connector
                  key={index}
                  active
                  points={segment}
                />
              ))
            ) : null
        }
      </FastLayer>
    </>
  );
};

export default Connectors;

function partitionArrayWithOverlap<T>(arr: T[], chunkSize: number, overlap: number): T[][] {
  if (chunkSize <= 0 || overlap < 0) {
    throw new Error("Invalid chunk size or overlap value.");
  }

  const result = [];
  let i = 0;

  while (i < arr.length) {
    const chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
    i += chunkSize - overlap;
  }

  return result;
}