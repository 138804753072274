import { AspectRatioView, ButtonRect } from 'components';
import { useAppStateActions } from 'appState';
import s from './Feedback.module.scss';
import { TranslationPage } from '../../../types/enums/TranslationPage';
import { useTranslation } from '../../../hooks';

type FeedbackProps = {
  isSliderVersion?: boolean;
  sliderOffset?: number;
  sliderLabels?: string[];
  skillDescription?: string,
  title?: string,
  subTitle: string;
  description?: string;
};

const Feedback: React.FC<FeedbackProps> = ({
  title,
  isSliderVersion,
  sliderLabels,
  sliderOffset,
  skillDescription,
  subTitle,
  description,
}) => {
  const { appFlowForward } = useAppStateActions();
  const lang = useTranslation(TranslationPage.CHILDREN);

  if (isSliderVersion) {
    return (
      <>
        <AspectRatioView>
          <div className={s.container}>
            <h3 className={s.title}>{title}</h3>
            <p className={s.subTitleSmall}>{subTitle}</p>
            <div className={s.labelsContainer}>
              {sliderLabels?.map((label, index) => (
                <p key={`${label}-${index}`} className={s.skillDescription}>{label}</p>
              ))}
            </div>
            <div className={s.skillBarContainer}>
              <div className={s.skillBarStrength} style={{ left: `${sliderOffset}%` }} />
            </div>
            <p className={s.skillDescriptionExtra}>{skillDescription}</p>
            {description && <p className={s.descriptionSmall}>{description}</p>}
            <ButtonRect onClick={appFlowForward} text={lang.next} />
          </div>
        </AspectRatioView>
      </>
    );
  }

  return (
    <>
      <AspectRatioView>
        <div className={s.container}>
          <h3 className={s.title}>{title}</h3>
          <h3 className={s.subTitle}>{subTitle}</h3>
          {description && <p className={s.description}>{description}</p>}
          <ButtonRect onClick={appFlowForward} text={lang.next} />
        </div>
      </AspectRatioView>
    </>
  );
};

export default Feedback;
