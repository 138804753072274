import img1 from './01.png';
import img2 from './02.png';
import img3 from './03.png';
import img4 from './04.png';
import img5 from './05.png';
import img6 from './06.png';
import img7 from './07.png';
import img8 from './08.png';
import img9 from './09.png';
import img10 from './10.png';
import img11 from './11.png';
import img12 from './12.png';
import img13 from './13.png';
import img14 from './14.png';
import img15 from './15.png';
import img16 from './16.png';
import img17 from './17.png';
import img18 from './18.png';
import img19 from './19.png';
import img20 from './20.png';
import img21 from './21.png';
import img22 from './22.png';
import img23 from './23.png';
import img24 from './24.png';
import img25 from './25.png';
import img26 from './26.png';
import img27 from './27.png';
import img28 from './28.png';
import img29 from './29.png';
import img30 from './30.png';

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
];

export default images;
