import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import s from './GameModal.module.scss';
import {
  Success,
  Incorrect,
  GameOver,
  Go,
  SkipGame,
} from './Children';

type GameModalProps = {
  isVisible: boolean,
  delay?: number;
  children?: ReactNode;
};

type GameModalComposition = {
  Success: typeof Success,
  Incorrect: typeof Incorrect,
  Go: typeof Go,
  GameOver: typeof GameOver,
  SkipGame: typeof SkipGame,
};

const GameModal: React.FC<GameModalProps> & GameModalComposition = ({
  isVisible,
  delay,
  children,
}) => {
  const [isCurrentlyVisible, setIsCurrentlyVisible] = useState(false);
  useEffect(() => {
    if (!isVisible) {
      setIsCurrentlyVisible(false);
      return;
    }
    const timeout = setTimeout(
      () => setIsCurrentlyVisible(true),
      delay ?? 0,
    );
    return () => clearTimeout(timeout);
  }, [isVisible, delay]);

  return (
    <div className={classNames(s.modalContainer, { [s.visible]: isCurrentlyVisible })}>
      {children}
    </div>
  );
};

GameModal.Success = Success;
GameModal.Incorrect = Incorrect;
GameModal.Go = Go;
GameModal.GameOver = GameOver;
GameModal.SkipGame = SkipGame;

export default GameModal;
