import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useCorsiGameData, useCorsiGameActions } from './state';
import s from './CorsiGame.module.scss';
import {
  GO_BOX_DISPLAY_START_DELAY,
  FISH_ANIMATION_START_DELAY,
  FISH_ANIMATION_TIME,
} from './CorsiTimings';
import { CorsiRoundStage } from './types';

type FishProps = {
  id: number,
  order: number,
};

const Fish: React.FC<FishProps> = ({ id, order }) => {
  const [highlighting, setHighlighting] = useState(false);
  const { setAnswer, roundStageForward, startTrialTimer } = useCorsiGameActions();
  const { numberBlocksToHighlight: numberBlocks, stage } = useCorsiGameData();
  const isAnimatingStage = stage === CorsiRoundStage.ANIMATING;

  const highLight = (): void => {
    setHighlighting(true);
    setTimeout(() => {
      setHighlighting(false);
    }, FISH_ANIMATION_TIME);
  };

  useEffect(() => {
    let time: number;
    if (order <= numberBlocks && isAnimatingStage) {
      time = window.setTimeout(highLight, FISH_ANIMATION_START_DELAY * order);
    }
    return () => clearTimeout(time);
  }, [order, isAnimatingStage, numberBlocks]);

  useEffect(() => {
    let time: number;
    if (order === 1 && isAnimatingStage) {
      startTrialTimer();
    }
    if (order === numberBlocks && isAnimatingStage) {
      time = window.setTimeout(roundStageForward,
        FISH_ANIMATION_START_DELAY * order + GO_BOX_DISPLAY_START_DELAY);
    }
    return () => clearTimeout(time);
  }, [order, isAnimatingStage, roundStageForward, numberBlocks, startTrialTimer]);

  const handleClick = (): void => {
    highLight();
    setAnswer(id);
  };

  return (
    <div
      id={`${id}`}
      className={classNames(s.fish, { [s.highlighted]: highlighting })}
      onClick={handleClick}
    />
  );
};

export default Fish;
