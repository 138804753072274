import s from './Layout.module.scss';
import { ReactNode } from 'react';

const Footer: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <footer className={s.footer}>
    {children}
  </footer>
);

export default Footer;
