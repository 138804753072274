import { Carousel } from 'react-responsive-carousel';

import { useIsLargeScreen } from 'hooks';
import s from './CardCarousel.module.scss';

type Props = {
  children?: any; // TODO: type this correctly
  chosenItem?: number;
  onChosenItemChange?: (itemNumber: number) => void;
};

const CardCarousel: React.FC<Props> = ({
  children,
  chosenItem,
  onChosenItemChange,
}) => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <div className={s.wrapper}>
      <Carousel
        centerMode
        centerSlidePercentage={isLargeScreen ? 36 : 80}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        selectedItem={chosenItem}
        onChange={onChosenItemChange}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CardCarousel;
