export enum FormError {
  InvalidPhone = 'invalid phone',
  MustBeNumber = 'must be number',
  CannotBeEmpty = 'cannot be empty',
}

type ObjectValOrEmptyString<T> = {
  [K in keyof T]: T[K] | '';
};

export type MaybeEmptyString<T> = ObjectValOrEmptyString<T>;
