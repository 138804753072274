import React, { ReactNode, useEffect } from 'react';

// import { AspectRatioView } from 'components';

import Content from './Content';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

import s from './Layout.module.scss';

type ComponentComposition = {
  Content: typeof Content;
  Topbar: typeof Topbar;
  Sidebar: typeof Sidebar;
  Footer: typeof Footer
};

type Props = {
  scrollPosition?: number | undefined;
  children?: ReactNode;
};

const Layout: React.FC<Props> & ComponentComposition = ({ children, scrollPosition }) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const wrapperParent = wrapperRef.current?.parentElement;
    wrapperParent?.scroll?.({
      behavior: 'smooth',
      top: scrollPosition,
    });
  }, [scrollPosition, wrapperRef]);

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      {/*<AspectRatioView />*/}
      <div className={s.layout}>
        {children}
      </div>
    </div>
  );
};

Layout.Content = Content;
Layout.Topbar = Topbar;
Layout.Sidebar = Sidebar;
Layout.Footer = Footer;

export default Layout;
