import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Item, Point } from './types';
import { LazyBrush } from './lazyBrush';
import { lazyBrushRadius } from './stageConfig';

type GameState = {
  /** Clear the whole game state */
  purge: () => void;
  /** Checks if the game has been completed */
  gameDidFinish: () => boolean;
  /** Connectable items: their position and state */
  items: Item[];
  /** Set connectable items (once per game) */
  setItems: (partialItems: Pick<Item, 'label' | 'pos'>[]) => void;
  /** Item that can be drawn from */
  getLastCompletedItem: () => Item;
  /** Index of the next correct item */
  getNextItem: () => Item | null;
  /** Mark current item as "completed" and next item as "next" */
  moveToNextItem: () => void;
  /** Mark item as wrong */
  markItemWrong: (itemIndex: number) => void;
  /** Clear "wrong" status from all items */
  clearWrongMarks: () => void;
  /** Indicates if the connector is being drawn */
  isDrawingMode: boolean;
  /** Indicates if the user is using a tochscreen */
  isTouchMode: boolean;
  /** Turn connector drawing on/off */
  toggleDrawingMode: (toggle: boolean) => void;
  /** Cursor/finger position */
  cursorPos: Point | null;
  /** Set connector head after mouse move / touch move */
  updateCursorPos: (cursorPos: Point) => void;
  /** Start plotting a new active connector */
  startDrawing: (options: { isTouchMode: boolean }) => void;
  /** Discard the active connector */
  cancelDrawing: () => void;
  /** Connector has touched an item */
  didConnect: (itemIndex: number) => void;
  /** Connector that is currently being plotted */
  activeConnector: Point[];
  /** Wrong connector that should fade out */
  wrongConnector: Point[];
  /** All previous connectors (merged into a single curve) */
  committedConnectors: Point[];
  /** Connector head position */
  getActiveConnectorHead: () => Point | null;
  /** Helper for more touch-friendly drawing */
  lazyBrush: LazyBrush | null;
  /** Save & clear the current connector */
  commitActiveConnector: () => void;
  /** Total number of wrong hits */
  errors: number;
  /** Total number of finger lifts */
  fingerLifts: number;
  /** Selection history: `[expected item, selected item]` */
  selections: [string, string][];
};

const useGameState = create<GameState>()(devtools((set, get) => ({
  purge: () => set({
    items: [],
    isDrawingMode: false,
    cursorPos: null,
    activeConnector: [],
    wrongConnector: [],
    committedConnectors: [],
    errors: 0,
    fingerLifts: 0,
    selections: [],
  }),

  gameDidFinish: () => get().items.length > 0 && get().items.every(i => i.isCompleted),

  items: [],
  setItems: (partialItems) => {
    const items = partialItems.map((item, index) => ({
      ...item,
      index,
      isCompleted: index === 0,
      // isNext: index === 1,
      isMarkedWrong: false,
    }));
    set({ items });
  },

  moveToNextItem: () => {
    const next = get().getLastCompletedItem().index + 1;
    const items = get().items
      .map(item => (
        item.index === next
          ? { ...item, isCompleted: true }
          : item
      ));
    set({ items });
  },

  markItemWrong: (index) => set({
    items: get().items.map(item => (
      item.index === index
        ? { ...item, isMarkedWrong: true }
        : item
    )),
  }),

  clearWrongMarks: () => set({
    items: get().items.map(item => (
      item.isMarkedWrong
        ? { ...item, isMarkedWrong: false }
        : item
    )),
  }),

  getLastCompletedItem: () => {
    const items = [...get().items].reverse();
    return items.find(i => i.isCompleted) ?? items[0];
  },

  getNextItem: () => get().items.find(
    i => i.index === get().getLastCompletedItem().index + 1,
  ) ?? null,

  isDrawingMode: false,
  toggleDrawingMode: (isDrawingMode) => {
    if (isDrawingMode && get().gameDidFinish()) {
      return;
    }
    set({ isDrawingMode });
  },

  isTouchMode: false,

  cursorPos: null,
  updateCursorPos: (cursorPos) => {
    set({ cursorPos });
    const brush = get().lazyBrush;
    const connector = get().activeConnector;

    const pointsMatch = (p1?: Point, p2?: Point) => p1?.x === p2?.x && p1?.y === p2?.y;

    if (brush) {
      brush.update(cursorPos);
      const brushPoint = brush.getBrushCoordinates();
      const latestSavedPoint = connector[connector.length - 1];

      if (!pointsMatch(brushPoint, latestSavedPoint)) {
        set({ activeConnector: [...connector, brushPoint] });
      }
    }
  },

  didConnect: (itemIndex) => {
    const nextItem = get().getNextItem();
    const selectedItem = get().items.find(i => i.index === itemIndex);

    if (!nextItem || !selectedItem) {
      set({ isDrawingMode: false });
      return;
    }

    const isError = itemIndex !== nextItem.index;
    const didHitSelf = itemIndex === get().getLastCompletedItem().index;
    const didHitCompletedItem = get().items
      .filter(i => i.isCompleted)
      .map(i => i.index)
      .includes(itemIndex);

    if (didHitSelf || didHitCompletedItem) return;

    set({
      selections: [
        ...get().selections,
        [
          nextItem.label,
          selectedItem.label,
        ],
      ],
    });

    if (isError) {
      get().markItemWrong(itemIndex);
      get().toggleDrawingMode(false);
      set({
        errors: get().errors + 1,
        wrongConnector: get().activeConnector,
      });
    } else {
      get().commitActiveConnector();
      get().clearWrongMarks();
      get().moveToNextItem();
      if (get().gameDidFinish()) {
        set({ isDrawingMode: false });
      }
    }
  },

  committedConnectors: [],
  activeConnector: [],
  wrongConnector: [],
  lazyBrush: null,

  getActiveConnectorHead: () => get().activeConnector[get().activeConnector.length - 1] ?? null,

  startDrawing: ({ isTouchMode }) => {
    const committedConnectorHead = get().committedConnectors[get().committedConnectors.length - 1];
    const lastCompletedItem = get().getLastCompletedItem();
    const initialPoint = lastCompletedItem.pos;

    const lazyBrush = new LazyBrush({
      initialPoint,
      radius: isTouchMode
        ? lazyBrushRadius.touch
        : lazyBrushRadius.nonTouch,
    });

    set({
      lazyBrush,
      isTouchMode,
      isDrawingMode: true,
      activeConnector: committedConnectorHead
        ? [committedConnectorHead]
        : [],
    });
  },

  cancelDrawing: () => {
    set({
      isDrawingMode: false,
      lazyBrush: null,
      activeConnector: [],
      fingerLifts: get().fingerLifts + 1,
    });
  },

  commitActiveConnector: () => set({
    committedConnectors: get().committedConnectors.concat(get().activeConnector),
    activeConnector: [get().activeConnector[get().activeConnector.length - 1]],
  }),

  errors: 0,
  fingerLifts: 0,
  selections: [],
})));

export default useGameState;
