import { Occupation } from 'types/enums';

const iconTypes = {
  [Occupation['General and Operations Managers']]: 'E',
  [Occupation['Marketing Managers']]: 'E',
  [Occupation['Sales Managers']]: 'E',
  [Occupation['Industrial Production Managers']]: 'E',
  [Occupation['Human Resources Managers']]: 'E',
  [Occupation['Construction Managers']]: 'E',
  [Occupation['Education and Childcare Administrators, Preschool and Daycare']]: 'S',
  [Occupation['Food Service Managers']]: 'E',
  [Occupation['Water Resource Specialists']]: 'I',
  [Occupation['Postmasters and Mail Superintendents']]: 'E',
  [Occupation['Property, Real Estate, and Community Association Managers']]: 'E',
  [Occupation['Social and Community Service Managers']]: 'E',
  [Occupation['Buyers and Purchasing Agents']]: 'E',
  [Occupation['Environmental Compliance Inspectors']]: 'C',
  [Occupation['Human Resources Specialists']]: 'I',
  [Occupation['Farm Labor Contractors']]: 'E',
  [Occupation['Meeting, Convention, and Event Planners']]: 'E',
  [Occupation['Sustainability Specialists']]: 'C',
  [Occupation['Online Merchants']]: 'E',
  [Occupation['Personal Financial Advisors']]: 'E',
  [Occupation['Loan Officers']]: 'E',
  [Occupation['Actuaries']]: 'C',
  [Occupation['Operations Research Analysts']]: 'I',
  [Occupation['Statisticians']]: 'C',
  [Occupation['Architects']]: 'A',
  [Occupation['Surveyors']]: 'R',
  [Occupation['Agricultural Engineers']]: 'I',
  [Occupation['Chemical Engineers']]: 'I',
  [Occupation['Civil Engineers']]: 'R',
  [Occupation['Transportation Engineers']]: 'R',
  [Occupation['Electrical Engineers']]: 'I',
  [Occupation['Mechanical Engineers']]: 'I',
  [Occupation['Automotive Engineers']]: 'R',
  [Occupation['Wind Energy Engineers']]: 'R',
  [Occupation['Solar Energy Systems Engineers']]: 'R',
  [Occupation['Civil Engineering Technicians']]: 'R',
  [Occupation['Environmental Engineering Technicians']]: 'R',
  [Occupation['Industrial Engineering Technicians']]: 'I',
  [Occupation['Automotive Engineering Technicians']]: 'R',
  [Occupation['Animal Scientists']]: 'I',
  [Occupation['Food Scientists and Technologists']]: 'I',
  [Occupation['Soil and Plant Scientists']]: 'I',
  [Occupation['Range Managers']]: 'R',
  [Occupation['Park Naturalists']]: 'S',
  [Occupation['Foresters']]: 'R',
  [Occupation['Epidemiologists']]: 'I',
  [Occupation['Medical Scientists']]: 'I',
  [Occupation['Environmental Scientists and Specialists']]: 'I',
  [Occupation['Geographers']]: 'I',
  [Occupation['Transportation Planners']]: 'I',
  [Occupation['Chemical Technicians']]: 'I',
  [Occupation['Social Science Research Assistants']]: 'C',
  [Occupation['Quality Control Analysts']]: 'C',
  [Occupation['Educational, Guidance, and Career Counselors']]: 'S',
  [Occupation['Child, Family, and School Social Workers']]: 'S',
  [Occupation['Healthcare Social Workers']]: 'S',
  [Occupation['Health Education Specialists']]: 'S',
  [Occupation['Social and Human Service Assistants']]: 'C',
  [Occupation['Community Health Workers']]: 'S',
  [Occupation['Lawyers']]: 'E',
  [Occupation['Paralegals and Legal Assistants']]: 'C',
  [Occupation['Elementary School Teachers']]: 'S',
  [Occupation['Special Education Teachers']]: 'S',
  [Occupation['Adapted Physical Education Specialists']]: 'S',
  [Occupation['Adult Educators']]: 'S',
  [Occupation['Archivists']]: 'C',
  [Occupation['Museum Technicians and Conservators']]: 'R',
  [Occupation['Library Technicians']]: 'C',
  [Occupation['Craft Artists']]: 'A',
  [Occupation['Commercial and Industrial Designers']]: 'A',
  [Occupation['Fashion Designers']]: 'A',
  [Occupation['Floral Designers']]: 'A',
  [Occupation['Graphic Designers']]: 'A',
  [Occupation['Merchandise Displayers']]: 'A',
  [Occupation['Set and Exhibit Designers']]: 'A',
  [Occupation['Actors']]: 'A',
  [Occupation['Poets, Lyricists and Creative Writers']]: 'A',
  [Occupation['Interpreters and Translators']]: 'A',
  [Occupation['Audio and Video Technicians']]: 'R',
  [Occupation['Photographers']]: 'A',
  [Occupation['Camera Operators, Television, Video, and Film']]: 'R',
  [Occupation['Film and Video Editors']]: 'A',
  [Occupation['Dentists']]: 'I',
  [Occupation['Physician Assistants']]: 'S',
  [Occupation['Occupational Therapists']]: 'S',
  [Occupation['Physical Therapists']]: 'S',
  [Occupation['Veterinarians']]: 'I',
  [Occupation['Registered Nurses']]: 'S',
  [Occupation['Nurse Practitioners']]: 'S',
  [Occupation['Medical and Clinical Laboratory Technicians']]: 'R',
  [Occupation['Dietetic Technicians']]: 'S',
  [Occupation['Pharmacy Technicians']]: 'C',
  [Occupation['Psychiatric Technicians']]: 'S',
  [Occupation['Surgical Technologists']]: 'R',
  [Occupation['Veterinary Technicians']]: 'R',
  [Occupation['Licensed Practical and Licensed Vocational Nurses']]: 'S',
  [Occupation['Midwives']]: 'S',
  [Occupation['Occupational Therapy Assistants']]: 'S',
  [Occupation['Physical Therapist Aides']]: 'S',
  [Occupation['Massage Therapists']]: 'S',
  [Occupation['Dental Assistants']]: 'C',
  [Occupation['Medical Assistants']]: 'S',
  [Occupation['Medical Equipment Preparers']]: 'R',
  [Occupation['Pharmacy Aides']]: 'C',
  [Occupation['Veterinary Assistants and Laboratory Animal Caretakers']]: 'R',
  [Occupation['Speech-Language Pathology Assistants']]: 'S',
  [Occupation['Forest Fire Inspectors and Prevention Specialists']]: 'R',
  [Occupation['Fish and Game Wardens']]: 'R',
  [Occupation['Transit and Railroad Police']]: 'R',
  [Occupation['Animal Control Workers']]: 'R',
  [Occupation['Chefs and Head Cooks']]: 'E',
  [Occupation['Cooks, Private Household']]: 'A',
  [Occupation['Food Preparation Workers']]: 'R',
  [Occupation['Waiters and Waitresses']]: 'S',
  [Occupation['Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop']]: 'E',
  [Occupation['Landscaping and Groundskeeping Workers']]: 'R',
  [Occupation['Animal Caretakers']]: 'R',
  [Occupation['Barbers']]: 'R',
  [Occupation['Hairdressers, Hairstylists, and Cosmetologists']]: 'A',
  [Occupation['Makeup Artists, Theatrical and Performance']]: 'A',
  [Occupation['Manicurists and Pedicurists']]: 'R',
  [Occupation['Skincare Specialists']]: 'E',
  [Occupation['Tour Guides and Escorts']]: 'S',
  [Occupation['Childcare Workers']]: 'S',
  [Occupation['Residential Advisors']]: 'S',
  [Occupation['Cashiers']]: 'C',
  [Occupation['Retail Salespersons']]: 'E',
  [Occupation['Travel Agents']]: 'E',
  [Occupation['Sales Representatives']]: 'C',
  [Occupation['Demonstrators and Product Promoters']]: 'E',
  [Occupation['Real Estate Sales Agents']]: 'E',
  [Occupation['Payroll and Timekeeping Clerks']]: 'C',
  [Occupation['Procurement Clerks']]: 'C',
  [Occupation['Tellers']]: 'C',
  [Occupation['Customer Service Representatives']]: 'E',
  [Occupation['Library Assistants, Clerical']]: 'C',
  [Occupation['Human Resources Assistants']]: 'C',
  [Occupation['Receptionists and Information Clerks']]: 'C',
  [Occupation['Cargo and Freight Agents']]: 'C',
  [Occupation['Postal Service Mail Carriers']]: 'C',
  [Occupation['Secretaries and Administrative Assistants']]: 'C',
  [Occupation['Data Entry Keyers']]: 'C',
  [Occupation['Word Processors and Typists']]: 'C',
  [Occupation['Statistical Assistants']]: 'C',
  [Occupation['Animal Breeders']]: 'R',
  [Occupation['Agricultural Equipment Operators']]: 'R',
  [Occupation['Forest and Conservation Workers']]: 'R',
  [Occupation['Logging Equipment Operators']]: 'R',
  [Occupation['First-Line Supervisors of Construction Trades and Extraction Workers']]: 'E',
  [Occupation['Boilermakers']]: 'R',
  [Occupation['Stonemasons']]: 'R',
  [Occupation['Tile and Stone Setters']]: 'R',
  [Occupation['Cement Masons and Concrete Finishers']]: 'R',
  [Occupation['Operating Engineers and Other Construction Equipment Operators']]: 'R',
  [Occupation['Drywall and Ceiling Tile Installers']]: 'R',
  [Occupation['Electricians']]: 'R',
  [Occupation['Glaziers']]: 'R',
  [Occupation['Painters, Construction and Maintenance']]: 'R',
  [Occupation['Roofers']]: 'R',
  [Occupation['Rail-Track Laying and Maintenance Equipment Operators']]: 'R',
  [Occupation['Computer, Automated Teller, and Office Machine Repairers']]: 'R',
  [Occupation['Electric Motor, Power Tool, and Related Repairers']]: 'R',
  [Occupation['Security and Fire Alarm Systems Installers']]: 'R',
  [Occupation['Automotive Body and Related Repairers']]: 'R',
  [Occupation['Maintenance and Repair Workers, General']]: 'R',
  [Occupation['Commercial Divers']]: 'R',
  [Occupation['Aircraft Structure, Surfaces, Rigging, and Systems Assemblers']]: 'R',
  [Occupation['Engine and Other Machine Assemblers']]: 'R',
  [Occupation['Bakers']]: 'R',
  [Occupation['Butchers and Meat Cutters']]: 'R',
  [Occupation['Machinists']]: 'R',
  [Occupation['Tool and Die Makers']]: 'R',
  [Occupation['Printing Press Operators']]: 'R',
  [Occupation['Shoe and Leather Workers and Repairers']]: 'R',
  [Occupation['Tailors, Dressmakers, and Custom Sewers']]: 'R',
  [Occupation['Patternmakers, Wood']]: 'R',
  [Occupation['Water and Wastewater Treatment Plant and System Operators']]: 'R',
  [Occupation['Chemical Plant and System Operators']]: 'R',
  [Occupation['Petroleum Pump System Operators, Refinery Operators, and Gaugers']]: 'R',
  [Occupation['Chemical Equipment Operators and Tenders']]: 'R',
  [Occupation['Medical Appliance Technicians']]: 'R',
  [Occupation['Painting, Coating, and Decorating Workers']]: 'R',
  [Occupation['Potters, Manufacturing']]: 'R',
  [Occupation['Flight Attendants']]: 'E',
  [Occupation['Ambulance Drivers and Attendants']]: 'R',
  [Occupation['Heavy and Tractor-Trailer Truck Drivers']]: 'R',
  [Occupation['Rail Yard Engineers, Dinkey Operators, and Hostlers']]: 'R',
  [Occupation['Sailors and Marine Oilers']]: 'R',
  [Occupation['Motorboat Operators']]: 'R',
  [Occupation['Ship Engineers']]: 'R',
} as Record<Occupation, 'R' | 'I' | 'A' | 'S' | 'E' | 'C'>;

export default iconTypes;
