import { useEffect, useState } from 'react';
import classnames from 'classnames';

import s from './BRET.module.scss';
import { useBretData } from './state';
import { BretRoundStage } from './types';

type SquareProps = {
  id: number,
};

const Square: React.FC<SquareProps> = ({ id }) => {
  const {
    currentStage,
    parcelWithBomb,
    didBombExplode,
    isPracticeMode,
  } = useBretData();
  const [isHidden, setHidden] = useState(false);
  const bombClass = parcelWithBomb - 1 === id && !isPracticeMode
    && (currentStage === BretRoundStage.BOMB_INFO || currentStage === BretRoundStage.FEEDBACK);
  const shouldResetParcel = (currentStage === BretRoundStage.DEFAULT);

  useEffect(() => {
    let time: number;
    if (shouldResetParcel) {
      setHidden(false);
    }
    if (currentStage === BretRoundStage.COLLECTING) {
      time = window.setTimeout(() => {
        setHidden(true);
      }, id * 1000);
    }
    return () => clearTimeout(time);
  }, [currentStage, id, shouldResetParcel]);

  return (
    <div
      className={classnames(s.square, {
        [s.hidden]: isHidden,
        [s.bombExplode]: bombClass && didBombExplode,
        [s.bombNotExplode]: bombClass && !didBombExplode,
      })}
    />
  );
};

export default Square;
