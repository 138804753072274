import image1 from './slide1.png';
import image2 from './slide2.png';
import image3 from './slide3.png';

const images = [
  image1,
  image2,
  image3,
];

export default images;
