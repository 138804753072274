import { useAssessmentResults, useLanguage, useUsername } from 'appState';
import { useTranslation } from 'hooks';
import { fractionToPercent, objectEntries } from 'utils';
import { PopupContent } from 'components/assessment';
import { TranslationPage } from '../../../types/enums/TranslationPage';

const useWebAssessmentData = () => {
  const lang = useTranslation(TranslationPage.ASSESMENT);
  const results = useAssessmentResults();
  const username = useUsername();
  const language = useLanguage();
  if (!results) return null;

  // Emotional intelligence type: the strongest one and any other(s) within 2 points.
  const EIScores = objectEntries(results.emotionalIntelligence)
    // @ts-ignore
    .filter((t): t is [keyof typeof results.emotionalIntelligence, number] => t[1] !== null)
    .sort(([, a], [, b]) => b - a);

  const emotionalIntelligenceTypes = EIScores
    .map(([id, value]) => ({
      id,
      type: 'emotionalIntelligence' as const,
      percentage: fractionToPercent(value),
      category: lang.cards.emotionalIntelligence[
        id as keyof typeof lang.cards.emotionalIntelligence
      ].category,
      name: lang.cards.emotionalIntelligence[
        id as keyof typeof lang.cards.emotionalIntelligence
      ].name,
      description: lang.cards.emotionalIntelligence[
        id as keyof typeof lang.cards.emotionalIntelligence
      ].description,
      popupContent: (
        <PopupContent
          barColor="#05A660"
          title={lang.cards.emotionalIntelligence[
            id as keyof typeof lang.cards.emotionalIntelligence
          ].category}
          items={EIScores.map(([id, value]) => ({
            name: lang.cards.emotionalIntelligence[
              id as keyof typeof lang.cards.emotionalIntelligence
            ].name,
            description: lang.cards.emotionalIntelligence[
              id as keyof typeof lang.cards.emotionalIntelligence
            ].description,
            value,
          }))}
          type="emotional intelligence"
        />
      ),
    }));

  // Cognitive type in Careers mode: only the strongest one
  const relevantCognitiveStrengths = objectEntries(results.cognitive)
    // @ts-ignore
    .filter((t): t is [keyof typeof results.cognitive, number] => t[1] !== null)
    .sort(([, a], [, b]) => b - a);

  const cognitiveTypeCareer = relevantCognitiveStrengths
    .map(([id, value]) => ({
      id,
      type: 'cognitive' as const,
      percentage: fractionToPercent(+value),
      category: lang.cards.cognitive[
        id as keyof typeof lang.cards.cognitive
      ].category,
      name: lang.cards.cognitive[
        id as keyof typeof lang.cards.cognitive
      ].name,
      description: lang.cards.cognitive[
        id as keyof typeof lang.cards.cognitive
      ].description,
      popupContent: <PopupContent
        barColor="#E57A00"
        title={lang.cards.cognitive[
          id as keyof typeof lang.cards.cognitive
        ].name}
        text={lang.cards.cognitive[
          id as keyof typeof lang.cards.cognitive
        ].details}
        items={relevantCognitiveStrengths.map(([id, value]) => ({
          name: lang.cards.cognitive[
            id as keyof typeof lang.cards.cognitive
          ].name,
          description: lang.cards.cognitive[
            id as keyof typeof lang.cards.cognitive
          ].description,
          value: +value,
        }))}
        type="cognitive"
      />,
    }))
    .slice(0, 1);

  // Cognitive type in Entrepreneursip mode: only "problem solving" if >40%
  const cognitiveTypeEntrepreurship = (results.cognitive.problemSolving ?? 0) > 0.4 ? [{
    id: 'problemSolving',
    type: 'cognitive' as const,
    percentage: fractionToPercent(results.cognitive.problemSolving ?? 0),
    category: lang.cards.cognitive.problemSolving.category,
    name: lang.cards.cognitive.problemSolving.name,
    description: lang.cards.cognitive.problemSolving.description,
    popupContent: (
      <PopupContent
        title={lang.cards.cognitive.problemSolving.name}
        text={lang.cards.cognitive.problemSolving.description}
      />
    ),
  }] : [];

  const perseverance = results.cognitive.persistence ? [{
    id: 'persistence',
    type: 'cognitive' as const,
    category: lang.cards.cognitive.persistence.category,
    name: lang.cards.cognitive.persistence.name,
    description: lang.cards.cognitive.persistence.description,
    popupContent: (
      <PopupContent
        title={lang.cards.cognitive.persistence.name}
        text={lang.cards.cognitive.persistence.description}
      />
    ),
  }] : [];

  // Personality type: 2 strongest ones
  const personalityResults = {
    agreeableness: results.personality?.agreeableness || 0,
    conscientiousness: results.personality?.conscientiousness || 0,
    extraversion: results.personality?.extraversion || 0,
    emotionalStability: results.personality?.emotionalStability || 0,
    openness: results.personality?.openness || 0,
  };
  const relevantCareerPersonalityTypes = objectEntries(personalityResults)
    .filter(([, value]) => value !== 0)
    .sort(([, a], [, b]) => b - a);
  const careerPersonalityTypes = relevantCareerPersonalityTypes
    .slice(0, 1)
    .map(([id, value]) => ({
      id,
      type: 'personality' as const,
      percentage: fractionToPercent(value),
      category: lang.cards.personality[
        id as keyof typeof lang.cards.personality
      ].category,
      name: lang.cards.personality[
        id as keyof typeof lang.cards.personality
      ].name,
      description: lang.cards.personality[
        id as keyof typeof lang.cards.personality
      ].description,
      popupContent: <PopupContent
        barColor="#4C0099"
        title={lang.cards.personality[
          id as keyof typeof lang.cards.personality
        ].name}
        text={lang.cards.personality[
          id as keyof typeof lang.cards.personality
        ].details}
        items={relevantCareerPersonalityTypes.map(([id, value]) => ({
          name: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].name,
          description: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].description,
          labels: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].labels,
          descriptionLeft: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].descriptionLeft,
          descriptionRight: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].descriptionRight,
          value,
        }))}
        type="personality"
      />,
    }));

  // Personality type: strongest one
  const relevantEntrepreneurPersonalityTypes = objectEntries(personalityResults)
    .filter(([, value]) => value !== 0)
    .sort(([, a], [, b]) => b - a);
  const entrepreneurPersonalityTypes = relevantEntrepreneurPersonalityTypes
    .slice(0, 1)
    .map(([id, value]) => ({
      id,
      type: 'personality' as const,
      percentage: fractionToPercent(value),
      category: lang.cards.personality[
        id as keyof typeof lang.cards.personality
      ].category,
      name: lang.cards.personality[
        id as keyof typeof lang.cards.personality
      ].name,
      description: lang.cards.personality[
        id as keyof typeof lang.cards.personality
      ].description,
      popupContent: <PopupContent
        barColor="#4C0099"
        title={lang.cards.personality[
          id as keyof typeof lang.cards.personality
        ].name}
        text={lang.cards.personality[
          id as keyof typeof lang.cards.personality
        ].details}
        items={relevantEntrepreneurPersonalityTypes.map(([id, value]) => ({
          name: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].name,
          description: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].description,
          labels: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].labels,
          descriptionLeft: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].descriptionLeft,
          descriptionRight: lang.cards.personality[
            id as keyof typeof lang.cards.personality
          ].descriptionRight,
          value,
        }))}
        type="personality"
      />,
    }));

  // Growth mindset: only if 50% or more:
  const growthMindset = (results.behavior.growthMindset ?? 0) >= 0.5 ? [{
    id: 'growthMindset',
    type: 'behavior' as const,
    percentage: fractionToPercent(results.behavior.growthMindset ?? 0),
    category: lang.cards.behavior.growthMindset.category,
    name: lang.cards.behavior.growthMindset.name,
    description: lang.cards.behavior.growthMindset.description,
  }] : [];

  const proactivenessType = (results.behavior.proactivity ?? 0) > 0.5 ? [{
    id: 'proactivity',
    type: 'behavior' as const,
    percentage: fractionToPercent(results.behavior.proactivity ?? 0),
    category: lang.cards.behavior.proactivity.category,
    name: lang.cards.behavior.proactivity.name,
    description: lang.cards.behavior.proactivity.description,
    popupContent: (
      <PopupContent
        title={lang.cards.behavior.proactivity.name}
        text={lang.cards.behavior.proactivity.description}
      />
    ),
  }] : [];

  const riskTakingType = (results.behavior.riskBehavior !== null)
    ? [{
      id: 'riskTaking',
      type: 'behavior' as const,
      category: lang.cards.behavior.riskTaking.category,
      name: lang.cards.behavior.riskTaking.items[
        results.behavior.riskBehavior
      ].name,
      description: lang.cards.behavior.riskTaking.items[
        results.behavior.riskBehavior
      ].description,
      popupContent: (
        <PopupContent
          title={lang.cards.behavior.riskTaking.items[
            results.behavior.riskBehavior
          ].name}
          text={lang.cards.behavior.riskTaking.items[
            results.behavior.riskBehavior
          ].details}
        />
      ),
    }] : [];

  const skillStrengths = {
    career: [
      ...cognitiveTypeCareer,
      ...emotionalIntelligenceTypes,
      ...careerPersonalityTypes,
    ],
    entrepreneurship: [
      ...proactivenessType,
      ...riskTakingType,
      ...growthMindset,
      ...perseverance,
      ...cognitiveTypeEntrepreurship,
      ...emotionalIntelligenceTypes,
      ...entrepreneurPersonalityTypes,
    ],
  };

  // Occupational interests: all, but sorted from strongest
  const occupationalInterests = objectEntries(results.occupationalInterests)
    .sort(([, a], [, b]) => b - a)
    .map(([id, value]) => ({
      id,
      percentage: fractionToPercent(value),
      name: lang.cards.occupationInterests[
        id as keyof typeof lang.cards.occupationInterests
      ].name,
      description: lang.cards.occupationInterests[
        id as keyof typeof lang.cards.occupationInterests
      ].description,
      examples: lang.cards.occupationInterests[
        id as keyof typeof lang.cards.occupationInterests
      ].examples,
    }));

  return {
    skillStrengths,
    occupationalInterests,
    occupations: results.occupations,
    username,
    date: (new Date(results.date)).toLocaleDateString(language),
  };
};

export default useWebAssessmentData;
