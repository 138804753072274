import { Point, RandomConstraints } from './types';

const getDistance = (p1: Point, p2: Point) => Math.hypot(p1.x - p2.x, p1.y - p2.y);

const getRandomInRange = (min: number, max: number) => Math.random() * (max - min) + min;

const getNextPosition = (existingPositions: Point[], constraints: RandomConstraints) => {
  const MAX_ATTEMPTS = 1000;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= MAX_ATTEMPTS; i++) {
    const candidate = {
      x: getRandomInRange(constraints.xMin, constraints.xMax),
      y: getRandomInRange(constraints.yMin, constraints.yMax),
    };
    if (existingPositions.every(
      position => getDistance(position, candidate) >= constraints.minDistance,
    )) {
      return candidate;
    }
  }
  throw new Error('Could not randomize item position');
};

const getRandomPositions = (itemCount: number, constraints: RandomConstraints) => Array(itemCount)
  .fill(null)
  .reduce<Point[]>(positions => [...positions, getNextPosition(positions, constraints)], []);

export default getRandomPositions;
