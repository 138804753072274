import { create } from 'zustand';
import {
  FAKE_TARGETS_PER_BLOCK_EXTENDED, LETTER_REST_TIME,
  LETTERS_CHANGE_INTERVAL,
  LETTERS_PER_BLOCK, MAX_BLOCKS, MAX_BLOCKS_PRACTICE,
  TARGET_LETTER, TARGET_LETTER_EXTENSION,
  TARGETS_PER_BLOCK, TARGETS_PER_BLOCK_EXTENDED,
} from './CPTState.const';

export enum CPTStage {
  BEGINNING,
  SHOW_LETTERS,
  PAUSE,
  LETTER_REST,
  BLOCK_END,
  FINISHED,
}

export enum CPTGameModal {
  TARGET_CORRECT,
  TARGET_INCORRECT,
  TARGET_MISSED,
}

type CptRoundData = {
  practice: boolean;
  responses: Array<number>;
};

type CptRoundDataForSubmission = {
  practice: boolean;
  responses: string;
};

type CPTGameResult = {
  cptGameScores: Array<CptRoundData>
  cptGameScoresExtended: Array<CptRoundData>;
  cptMetadata: {
    startDate: string;
    startTime: string;
    countCorrect: number;
    percentCorrect: number;
    meanRT: number;
    probHits: number;
    countCorrect_A: number;
    percentCorrect_A: number;
    meanRT_A: number;
    probHits_A: number;
    countCorrect_AX: number;
    percentCorrect_AX: number;
    meanRT_AX: number;
    probHits_AX: number;
    timeSpentInIntro: number;
    timeSpentInIntroExtended: number;
    wrongHitsTotal: number;
    wrongHitsTotal_A: number;
    wrongHitsTotal_AX: number;
  },
};

export type CPTGameResultForSubmission = {
  cptGameScores: Array<CptRoundDataForSubmission>;
  cptGameScoresExtended: Array<CptRoundDataForSubmission>;
  cptMetadata: CPTGameResult['cptMetadata'];
};

type CPTGameState = {
  gameStage: CPTStage;
  blockNumber: number;
  targetNumber: number;
  isCurrentLetterTarget: boolean;
  blockTargetHits: Array<number>;
  gameFinished: boolean;
  finalScore: number;

  gameResult: CPTGameResult;

  displayGameModal: boolean;
  gameModalToDisplay: CPTGameModal;

  isPracticeMode: boolean;
  togglePracticeMode: (isPracticeMode: boolean) => void;
  isExtendedMode: boolean;
  toggleExtendedMode: (isExtendedMode: boolean) => void;
  startNewGame: () => void;
  resetGameStage: () => void;

  nonTargetLetters: Array<string>;
  lettersBlock: Array<string>;
  letterToDisplay: number;
  letterDisplayInterval: number;
  startShowLetters: () => void;
  generateLettersBlock: () => void;
  continueCurrentBlock: () => void;
  nextGameBlock: () => void;
  onBlockEnd: () => void;

  onLetterClick: () => void;
  startLetterShowInterval: () => void;
  isTargetLetter: (index: number) => boolean;
  nextLetter: () => void;
  recordBlockHit: (isCorrect: boolean) => void;

  introStartTime: number;
  startIntroTimer: () => void;
  endIntroTimer: (extendedMode: boolean) => void;
  saveGameResult: () => void;
  progress: number;
  updateProgress: () => void;
};

const useCPTGameState = create<CPTGameState>((set, get) => ({
  gameStage: CPTStage.BEGINNING,
  blockNumber: 1,
  targetNumber: 0,
  isCurrentLetterTarget: false,
  blockTargetHits: [],
  gameFinished: false,
  finalScore: 9001,
  progress: 0,

  updateProgress: () => {
    const currentBlockProgress = get().letterToDisplay / LETTERS_PER_BLOCK;
    const currentBlock = get().letterToDisplay === LETTERS_PER_BLOCK
      ? get().blockNumber - 1
      : get().blockNumber - 1;
    const totalBlocks = get().isPracticeMode ? MAX_BLOCKS_PRACTICE : MAX_BLOCKS;
    const progress = (currentBlock + currentBlockProgress) / totalBlocks;
    set({ progress });
  },

  gameResult: {
    cptGameScores: [],
    cptGameScoresExtended: [],
    cptMetadata: {
      startDate: '',
      startTime: '',
      countCorrect: 0,
      percentCorrect: 0,
      meanRT: 0,
      probHits: 0,
      countCorrect_A: 0,
      percentCorrect_A: 0,
      meanRT_A: 0,
      probHits_A: 0,
      countCorrect_AX: 0,
      percentCorrect_AX: 0,
      meanRT_AX: 0,
      probHits_AX: 0,
      timeSpentInIntro: 0,
      timeSpentInIntroExtended: 0,
      wrongHitsTotal: 0,
      wrongHitsTotal_A: 0,
      wrongHitsTotal_AX: 0,
    },
  },

  displayGameModal: false,
  gameModalToDisplay: CPTGameModal.TARGET_CORRECT,

  countDownText: 'Ready...',
  countDownInterval: 0,

  isPracticeMode: false,
  togglePracticeMode: (isPracticeMode: boolean) => {
    set({
      isPracticeMode,
    });
  },
  isExtendedMode: false,
  toggleExtendedMode: (isExtendedMode: boolean) => {
    set({
      isExtendedMode,
    });
  },
  startNewGame: () => {
    set({
      gameResult: {
        ...get().gameResult,
        cptMetadata: {
          ...get().gameResult.cptMetadata,
          startDate: get().gameResult.cptMetadata.startDate.length
            ? get().gameResult.cptMetadata.startDate
            : new Date().toISOString().slice(0, 10),
          startTime: get().gameResult.cptMetadata.startTime.length
            ? get().gameResult.cptMetadata.startTime
            : new Date().toISOString().slice(10, 20),
        },
      },
      blockNumber: 1,
      progress: 0,
    });
    get().startShowLetters();
  },
  resetGameStage: () => {
    set({
      gameStage: CPTStage.BEGINNING,
      progress: 0,
    });
  },

  nonTargetLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
    'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'Y', 'Z'],
  lettersBlock: [],
  letterToDisplay: 0,
  letterDisplayInterval: 0,
  startShowLetters: () => {
    get().generateLettersBlock();
    set({
      letterToDisplay: 0,
      targetNumber: 0,
      gameStage: CPTStage.SHOW_LETTERS,
    });
    get().startLetterShowInterval();
  },
  generateLettersBlock: () => {
    const {
      nonTargetLetters,
      isExtendedMode,
    } = get();
    const lettersToInsert = isExtendedMode ? 2 : 1;
    const targetsToCreate = isExtendedMode
      ? TARGETS_PER_BLOCK_EXTENDED + FAKE_TARGETS_PER_BLOCK_EXTENDED
      : TARGETS_PER_BLOCK;
    const blockLetters = [...Array(LETTERS_PER_BLOCK)]
      .map(() => nonTargetLetters[Math.floor(Math.random() * nonTargetLetters.length)]);
    const availableIndexes = [...Array(LETTERS_PER_BLOCK - lettersToInsert)]
      .map((_, index) => index + lettersToInsert);
    const indexesToInsertTargets = [...Array(targetsToCreate)]
      .map(() => {
        const tempIndex = Math.floor(Math.random() * availableIndexes.length);
        const indexForInsertion = availableIndexes[tempIndex];
        for (let i = indexForInsertion - lettersToInsert; i <= indexForInsertion + 1; i += 1) {
          const index = availableIndexes.indexOf(i);
          if (index > -1) {
            availableIndexes.splice(index, 1);
          }
        }
        return indexForInsertion;
      });
    indexesToInsertTargets.forEach(index => {
      blockLetters.splice(index, 1, TARGET_LETTER);
      if (isExtendedMode) {
        blockLetters.splice(index - 1, 1, TARGET_LETTER_EXTENSION);
      }
    });
    if (isExtendedMode) {
      const targetLetterIndexes = blockLetters
        .reduce(
          (indexes, letter, index) => indexes.concat(letter === TARGET_LETTER ? index - 1 : []),
          [] as number[],
        );
      const indexesToReplace = targetLetterIndexes
        .filter(i => i >= 1)
        .sort(() => 0.5 - Math.random())
        .slice(0, 2);

      indexesToReplace.forEach(index => {
        const prevLetter = blockLetters[index - 1];
        const fakeLetter = nonTargetLetters
          .filter(letter => letter !== prevLetter)
          .filter(letter => letter !== TARGET_LETTER_EXTENSION)
          .sort(() => 0.5 - Math.random())[0];
        blockLetters[index] = fakeLetter;
      });
    }
    const possibleHits = isExtendedMode
      ? TARGETS_PER_BLOCK_EXTENDED
      : TARGETS_PER_BLOCK;
    set({
      lettersBlock: blockLetters,
      blockTargetHits: [...Array(possibleHits)].map(_ => 0),
    });
  },
  continueCurrentBlock: () => {
    const {
      letterDisplayInterval,
      letterToDisplay,
      isTargetLetter,
      targetNumber,
    } = get();
    set({
      displayGameModal: false,
      gameStage: CPTStage.PAUSE,
    });
    const isNextLetterTarget = isTargetLetter(letterToDisplay + 1);
    clearInterval(letterDisplayInterval);
    set({
      gameStage: CPTStage.SHOW_LETTERS,
      letterToDisplay: letterToDisplay + 1,
      isCurrentLetterTarget: isNextLetterTarget,
      targetNumber: isNextLetterTarget
        ? targetNumber + 1
        : targetNumber,
    });
    get().updateProgress();
    get().startLetterShowInterval();
  },
  nextGameBlock: () => {
    const {
      blockNumber,
      isPracticeMode,
      startShowLetters,
    } = get();

    set({
      displayGameModal: false,
    });
    if (isPracticeMode && blockNumber >= MAX_BLOCKS_PRACTICE) {
      set({
        gameStage: CPTStage.FINISHED,
      });
    } else if (blockNumber >= MAX_BLOCKS) {
      set({
        gameStage: CPTStage.FINISHED,
      });
    } else {
      set({
        gameStage: CPTStage.BLOCK_END,
        blockNumber: blockNumber + 1,
      });
      setTimeout(() => startShowLetters(), 500);
    }
  },

  onLetterClick: () => {
    const {
      isPracticeMode,
      letterToDisplay,
      letterDisplayInterval,
      gameStage,
    } = get();
    if (gameStage !== CPTStage.SHOW_LETTERS && gameStage !== CPTStage.LETTER_REST) {
      return;
    }
    clearInterval(letterDisplayInterval);
    const isCorrect = get().isTargetLetter(letterToDisplay);
    if (isPracticeMode) {
      const gameModalToDisplay = isCorrect
        ? CPTGameModal.TARGET_CORRECT
        : CPTGameModal.TARGET_INCORRECT;
      set({
        displayGameModal: true,
        gameModalToDisplay,
        gameStage: CPTStage.PAUSE,
      });
      get().recordBlockHit(isCorrect);
    } else {
      get().recordBlockHit(isCorrect);
      if (get().letterToDisplay >= LETTERS_PER_BLOCK) {
        clearInterval(get().letterDisplayInterval);
        get().onBlockEnd();
      } else {
        get().continueCurrentBlock();
      }
    }
  },
  startLetterShowInterval: () => {
    clearInterval(get().letterDisplayInterval);
    const intervalId = window.setInterval(() => {
      if (get().letterToDisplay >= LETTERS_PER_BLOCK) {
        clearInterval(get().letterDisplayInterval);
        get().onBlockEnd();
      } else {
        get().nextLetter();
        get().updateProgress();
      }
    }, LETTERS_CHANGE_INTERVAL + LETTER_REST_TIME);
    set({
      letterDisplayInterval: intervalId,
    });
  },
  isTargetLetter: () => {
    let isTarget;
    if (get().isExtendedMode) {
      isTarget = get().lettersBlock[get().letterToDisplay] === TARGET_LETTER
        && get().lettersBlock[get().letterToDisplay - 1] === TARGET_LETTER_EXTENSION;
    } else {
      isTarget = get().lettersBlock[get().letterToDisplay] === TARGET_LETTER;
    }
    return isTarget;
  },
  nextLetter: () => {
    const {
      letterToDisplay,
      isTargetLetter,
      targetNumber,
      isPracticeMode,
      letterDisplayInterval,
    } = get();
    const isCurrentLetterTarget = isTargetLetter(letterToDisplay);
    const isNextLetterTarget = isTargetLetter(letterToDisplay + 1);

    if (isPracticeMode && isCurrentLetterTarget) {
      clearInterval(letterDisplayInterval);
      set({
        gameStage: CPTStage.LETTER_REST,
      });
      setTimeout(() => {
        if (get().displayGameModal) {
          return;
        }
        set({
          displayGameModal: true,
          gameModalToDisplay: CPTGameModal.TARGET_MISSED,
          gameStage: CPTStage.PAUSE,
        });

        get().updateProgress();
      }, LETTER_REST_TIME);
    } else {
      set({
        gameStage: CPTStage.LETTER_REST,
      });
      setTimeout(() => {
        set({
          gameStage: CPTStage.SHOW_LETTERS,
          letterToDisplay: letterToDisplay + 1,
          isCurrentLetterTarget: isNextLetterTarget,
          targetNumber: isNextLetterTarget
            ? targetNumber + 1
            : targetNumber,
        });

        get().updateProgress();
      }, LETTER_REST_TIME);
    }
  },
  recordBlockHit: (isCorrect: boolean) => {
    const {
      gameResult,
      isExtendedMode,
      isPracticeMode,
    } = get();
    const newHitsArray = get().blockTargetHits;
    if (isCorrect) {
      newHitsArray[get().targetNumber] = 1;
    }
    const isCorrectNonPractice = isCorrect && !isPracticeMode;
    const isNotCorrectNonPractice = !isCorrect && !isPracticeMode;
    set({
      blockTargetHits: newHitsArray,
      gameResult: {
        ...gameResult,
        cptMetadata: {
          ...gameResult.cptMetadata,
          countCorrect: isCorrectNonPractice
            ? gameResult.cptMetadata.countCorrect + 1
            : gameResult.cptMetadata.countCorrect,
          countCorrect_A: isCorrectNonPractice && !isExtendedMode
            ? gameResult.cptMetadata.countCorrect_A + 1
            : gameResult.cptMetadata.countCorrect_A,
          countCorrect_AX: isCorrectNonPractice && isExtendedMode
            ? gameResult.cptMetadata.countCorrect_AX + 1
            : gameResult.cptMetadata.countCorrect_AX,
          wrongHitsTotal: isNotCorrectNonPractice
            ? gameResult.cptMetadata.wrongHitsTotal + 1
            : gameResult.cptMetadata.wrongHitsTotal,
          wrongHitsTotal_A: isNotCorrectNonPractice && !isExtendedMode
            ? gameResult.cptMetadata.wrongHitsTotal_A + 1
            : gameResult.cptMetadata.wrongHitsTotal_A,
          wrongHitsTotal_AX: isNotCorrectNonPractice && isExtendedMode
            ? gameResult.cptMetadata.wrongHitsTotal_AX + 1
            : gameResult.cptMetadata.wrongHitsTotal_AX,
        },
      },
    });
  },

  onBlockEnd: () => {
    const {
      isPracticeMode,
      letterDisplayInterval,
      gameResult,
      isExtendedMode,
      blockTargetHits,
    } = get();
    clearInterval(letterDisplayInterval);
    const roundResult = {
      practice: isPracticeMode,
      responses: blockTargetHits,
    };
    set({
      gameResult: {
        ...gameResult,
        cptGameScores: isExtendedMode
          ? gameResult.cptGameScores
          : [...gameResult.cptGameScores, roundResult],
        cptGameScoresExtended: isExtendedMode
          ? [...gameResult.cptGameScoresExtended, roundResult]
          : gameResult.cptGameScoresExtended,
      },
    });
    get().nextGameBlock();
  },

  introStartTime: 0,
  startIntroTimer: () => {
    set({
      introStartTime: new Date().getTime(),
    });
  },
  endIntroTimer: (extendedMode: boolean) => {
    const { gameResult, introStartTime } = get();
    const currentTime = new Date().getTime();
    set({
      gameResult: {
        ...gameResult,
        cptMetadata: {
          ...gameResult.cptMetadata,
          timeSpentInIntro: extendedMode
            ? gameResult.cptMetadata.timeSpentInIntro
            : currentTime - introStartTime,
          timeSpentInIntroExtended: extendedMode
            ? currentTime - introStartTime
            : gameResult.cptMetadata.timeSpentInIntroExtended,
        },
      },
    });
  },
  saveGameResult: () => {
    const {
      gameResult,
      isPracticeMode,
      isExtendedMode,
    } = get();
    const totalHits = gameResult.cptMetadata.countCorrect
      + gameResult.cptMetadata.wrongHitsTotal;
    const totalHitsA = gameResult.cptMetadata.countCorrect_A
      + gameResult.cptMetadata.wrongHitsTotal_A;
    const totalHitsAX = gameResult.cptMetadata.countCorrect_AX
      + gameResult.cptMetadata.wrongHitsTotal_AX;
    const correctWhenHitPercentage = Math.floor((
      gameResult.cptMetadata.countCorrect / totalHits
    ) * 1000) / 10;
    const correctWhenHitPercentageA = Math.floor((
      gameResult.cptMetadata.countCorrect_A / totalHitsA
    ) * 1000) / 10;
    const correctWhenHitPercentageAX = Math.floor((
      gameResult.cptMetadata.countCorrect_AX / totalHitsAX
    ) * 1000) / 10;
    const totalTargets = MAX_BLOCKS * TARGETS_PER_BLOCK + MAX_BLOCKS * TARGETS_PER_BLOCK_EXTENDED;
    const correctHitPercentage = Math.floor((
      gameResult.cptMetadata.countCorrect / totalTargets
    ) * 1000) / 10;
    const totalTargetsA = MAX_BLOCKS * TARGETS_PER_BLOCK;
    const correctHitPercentageA = Math.floor((
      gameResult.cptMetadata.countCorrect_A / totalTargetsA
    ) * 1000) / 10;
    const totalTargetsAX = MAX_BLOCKS * TARGETS_PER_BLOCK_EXTENDED;
    const correctHitPercentageAX = Math.floor((
      gameResult.cptMetadata.countCorrect_AX / totalTargetsAX
    ) * 1000) / 10;
    set({
      gameResult: {
        ...gameResult,
        cptMetadata: {
          ...gameResult.cptMetadata,
          percentCorrect: correctHitPercentage,
          meanRT: correctWhenHitPercentage || 0,
          probHits: totalHits,
          percentCorrect_A: correctHitPercentageA,
          meanRT_A: correctWhenHitPercentageA || 0,
          probHits_A: totalHitsA,
          percentCorrect_AX: correctHitPercentageAX,
          meanRT_AX: correctWhenHitPercentageAX || 0,
          probHits_AX: totalHitsAX,
        },
      },
      gameFinished: !isPracticeMode && isExtendedMode,
    });
  },
}));

export default useCPTGameState;
