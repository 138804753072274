import { useMemo } from 'react';
import { Section, Tab } from 'AppFlow/Summary/Assessment/enums';
import useSectionSelection from 'AppFlow/Summary/Assessment/useSectionSelection';
import { useAppStateActions, useAssessmentVisibility } from 'appState';
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import { getLearningCenterSectionName } from 'utils';
import { useTranslation } from 'hooks';
import { useSkillStrengthCardStateActions } from '../SkillStrengthCard/state/selectors';
import s from './ChartItem.module.scss';
import { TranslationPage } from '../../../types/enums/TranslationPage';

type Props = {
  name: string;
  description?: string;
  value: number;
  order?: number;
  barColor?: string;
  type?: string;
};

const ChartItem: React.FC<Props> = ({
  name,
  description,
  value,
  order = 0,
  barColor,
  type,
}) => {
  const lang = useTranslation(TranslationPage.CHART_ITEM);
  const { onSectionSelected, activeTab } = useSectionSelection();
  const { setSelectedAssessmentLearningSection } = useAppStateActions();
  const { incrementInteraction } = useAssessmentStateActions();
  const { toggleSkillStrengthCard } = useSkillStrengthCardStateActions();
  const { showFeedback, feedbackVisibilityOptions } = useAssessmentVisibility();

  const sectionName = useMemo<string>(() => {
    if (
      type === 'personality'
      || type === 'emotional intelligence'
    ) {
      return getLearningCenterSectionName(type);
    }
    return getLearningCenterSectionName(name);
  }, [name, type]);

  return (
    <div className={s.item}>
      <h3 className={s.name}>{name}</h3>
      <div className={s.barWrapper}>
        <div
          className={s.barValue}
          style={{
            width: `${value * 100}%`,
            animationDelay: `calc(200ms + ${order * 200}ms)`,
            ...barColor ? { backgroundColor: barColor } : {},
            opacity: 0.2 + (value * 0.8),
          }}
        />
      </div>
      {description && <p className={s.description}>{description}</p>}
      {
        showFeedback
        && feedbackVisibilityOptions.careerLearningCenter
        && activeTab !== Tab.Entrepreneurship
        && (
          <div
            className={s.link}
            onClick={() => {
              toggleSkillStrengthCard(false);
              onSectionSelected(Section.CareerLearningCenter);
              setSelectedAssessmentLearningSection(sectionName);
              const event = `learnMoreRedirection${sectionName}`;
              incrementInteraction('mainFeedbackInteractions', event);
            }}
          >
            {lang.freeCourses}
          </div>
        )
      }
    </div>
  );
};

export default ChartItem;
