import classnames from 'classnames';
import styles from './DigitSpanGame.module.scss';
import { DigitDisplayProps } from './DigitSpan.types';

const DigitDisplay:React.FC<DigitDisplayProps> = ({
  correctSolution,
  digitIndex,
}) => (
  <div className={classnames(styles.Digit, styles.Rollout)} key={digitIndex}>
    {/* shows one empty square before sequence */}
    {digitIndex ? correctSolution[digitIndex - 1] : ''}
  </div>
);

export default DigitDisplay;
