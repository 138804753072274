import { objectEntries } from 'utils';
import { FormError } from './types';

export const validatePhone = (value: string) => (
  /^[\d+-\s]{10,}$/.test(value)
    ? null
    : FormError.InvalidPhone
);

export const validateNonEmpty = (value: string) => (value === '' ? FormError.CannotBeEmpty : null);

export const validateNumber = (value: string) => (
  /^\d+$/.test(value)
    ? null
    : FormError.MustBeNumber
);

export const stripNulls = <T extends {}>(obj: T) => Object.fromEntries(
  objectEntries(obj)
    .filter(([_key, value]) => value !== null),
) as { [K in keyof T]?: NonNullable<T[K]> };
