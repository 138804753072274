import { ReactNode, useEffect, useState } from 'react';

import { useAppStateActions } from 'appState';

import s from './Viewport.module.scss';

const ViewportSetup: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [visibleAreaDiv, setVisibleAreaDiv] = useState<HTMLDivElement | null>(null);
  const [visibleArea, setVisibleArea] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const onResize = () => {
      const { width, height } = visibleAreaDiv?.getBoundingClientRect() ?? { width: 0, height: 0 };
      setVisibleArea({ width, height });
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [visibleAreaDiv, setVisibleArea]);

  const { setViewportSize } = useAppStateActions();
  useEffect(() => {
    setViewportSize(visibleArea)
  }, [setViewportSize, visibleArea]);

  return (
    <div
      className={s.screenArea}
      ref={setVisibleAreaDiv}
    >
      {/*<div className={s.screenArea} />*/}
      {/*<div*/}
      {/*  className={s.visibleArea}*/}
      {/*  ref={setVisibleAreaDiv}*/}
      {/*/>*/}
      {/*<div className={s.content}>*/}
      {children}
      {/*</div>*/}
    </div>
  );
};

export default ViewportSetup;
