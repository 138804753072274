import { useEffect, useMemo, useState } from 'react';

import { useAppStateActions } from 'appState';
import { GameModal } from 'components';
import { useTranslation } from 'hooks';

import { GameStage, StageResults } from './types';
import { constraints, itemLabels } from './stageConfig';
import getRandomPositions from './getRandomPositions';
import useGameState from './useGameState';
import GameArea from './GameArea';
import { TranslationPage } from '../../types/enums/TranslationPage';

type TMTGameProps = {
  stage: GameStage;
  maxDuration: number;
  onComplete: (results: StageResults) => void;
};

const TMTGame: React.FC<TMTGameProps> = ({ stage, onComplete, maxDuration }) => {
  const setItems = useGameState(s => s.setItems);
  useEffect(() => {
    const labels = itemLabels[stage];
    const items = getRandomPositions(labels.length, constraints[stage])
      .map((pos, i) => ({ pos, label: labels[i] }));
    setItems(items);
  }, [stage, setItems]);

  const purgeState = useGameState(s => s.purge);
  useEffect(() => purgeState, [purgeState]);

  const gameDidFinish = useGameState(s => s.gameDidFinish());
  const { gameFlowForward } = useAppStateActions();

  const startTime = useMemo(() => Date.now(), []);
  const errors = useGameState(s => s.errors);
  const fingerLifts = useGameState(s => s.fingerLifts);
  const selections = useGameState(s => s.selections);

  const [isFinished, setIsFinished] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  useEffect(() => {
    const ref = setTimeout(() => {
      setHasTimedOut(true);
    }, maxDuration);
    return () => clearTimeout(ref);
  }, [maxDuration, setIsFinished, setHasTimedOut]);
  useEffect(() => {
    if ((gameDidFinish || hasTimedOut) && !isFinished) {
      const time = Math.round((Date.now() - startTime));
      onComplete({
        time,
        fingerLifts,
        errors,
        selections,
      });
      setIsFinished(true);
    }
  }, [
    gameDidFinish,
    isFinished,
    startTime,
    fingerLifts,
    errors,
    onComplete,
    selections,
    hasTimedOut,
  ]);

  const lang = useTranslation(TranslationPage.TMT);
  const modalContent = lang.endOfRound[stage];
  const header = lang.gameHeader[stage];

  return (
    <>
      <GameArea header={header} />
      <GameModal isVisible={isFinished}>
        {hasTimedOut ? (
          <GameModal.Incorrect
            header={lang.timeoutHeader}
            text={modalContent.text}
            onClick={gameFlowForward}
          />
        ) : (
          <GameModal.Success
            header={modalContent.header}
            text={modalContent.text}
            onClick={gameFlowForward}
          />
        )}
      </GameModal>
    </>
  );
};

export default TMTGame;
