import Logo from "assets/images/logo.jpg";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAppStateActions } from "appState";
import { useTranslation } from "hooks";

import { TranslationPage } from "../../types/enums/TranslationPage";
import Animation from "./Animation";
import s from "./Welcome.module.scss";

const Welcome: React.FC = () => {
  const lang = useTranslation(TranslationPage.WELCOME);
  const { appFlowForward } = useAppStateActions();
  // const vp = useViewportSize();

  const [animationFired, setAnimationFired] = useState(false);
  const [animationStage, setAnimationStage] = useState(0);
  const [isConsent, setIsConset] = useState("false");
  useEffect(() => {
    if (!animationFired) return;
    const ref = setInterval(() => setAnimationStage((s) => s + 1), 300);
    return () => clearInterval(ref);
  }, [animationFired, setAnimationStage]);

  useEffect(() => {
    const ref = setTimeout(() => setAnimationFired(true), 1000);
    return () => clearTimeout(ref);
  }, [setAnimationFired]);

  const readyToGo = animationStage >= 8;

  const handleConsent = () =>
    isConsent === "true" ? setIsConset("false") : setIsConset("true");

  return (
    <div className={s.wrapper} style={{ height: "100%" }}>
      <div className={s.logo}>{/* ... */}</div>
      <div className={s.text}>
        <div className={s.logo}>
          <img src={Logo} alt="Logo" />
        </div>
        <p className={s["line-1"]}>{lang.line1}</p>
        <h1 className={s["line-2"]}>{lang.line2}</h1>
        <p className={s["line-3"]}>{lang.line3}</p>
      </div>
      <div className={s.animation}>
        <Animation stage={animationStage} />
      </div>
      <div className={classnames(s.buttons, readyToGo && s.readyToGo)}>
        <Link to="/about" className={s.about}>
          {lang.about}
        </Link>
        <p className={s.dataUsage_text}>{lang.dataUsage}</p>
        <button
          type="button"
          className={s.startButton}
          onClick={() => appFlowForward()}
          disabled={isConsent === "false"}
        >
          {lang.start}
        </button>
        <div className={classnames(s.consent, readyToGo && s.readyToGo)}>
          <div className={s.consent_wrapper}>
            <input name="consent" type="checkbox" onChange={handleConsent} />
            <div>
              <p className={s.consent_text}>
                {lang.consentCheckboxFirstPart}
                <Link to="/privacy-policy">
                  {lang.consentCheckboxSecondPart}
                </Link>
              </p>
              <p className={s.consent_text_two}>
                {lang.consentCheckboxThirdPart}
              </p>
            </div>
          </div>
        </div>
        <Link to="/privacy-policy" className={s.privacyPolicy}>
          {lang.privacyPolicy}
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
