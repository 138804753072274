import s from './Layout.module.scss';
import { ReactNode } from 'react';

const Sidebar: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <aside className={s.sidebar}>
    {children}
  </aside>
);

export default Sidebar;
