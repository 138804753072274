import React from 'react';
import classNames from 'classnames';
import gameOverImg from 'assets/images/trophe.svg';
import { ButtonRect } from 'components';

import { GameOverModalProps } from './types';
import s from '../GameModal.module.scss';

const GameOver: React.FC<GameOverModalProps> = ({ onClick, superPower }) => (
  <div className={classNames(s.modal, s.gameOver)}>
    <div>
      <h6>Game over</h6>
      <p>Achievement unlocked</p>
    </div>
    <img className={s.modalImg} src={gameOverImg} alt="Game over illustration" />
    <div style={{ flexDirection: 'column' }}>
      <h6>{superPower}</h6>
      <ButtonRect
        text="Show results"
        onClick={onClick}
        className={s.gameOverBtn}
        isVisible={true}
      />
    </div>

  </div>
);

export default GameOver;
