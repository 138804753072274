import { useEffect } from 'react';
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import { useInView } from 'react-intersection-observer';
import { TIME_SPENT_INCREMENT, TIME_SPENT_INTERVAL } from './constants';

const useCareerTracker = () => {
  const { addTimeSpent } = useAssessmentStateActions();
  const [mainFeedbackRef, mainFeedbackInView] = useInView({ threshold: 0.1 });
  const [learningCornerRef, learningCornerInView] = useInView({ threshold: 0.6 });

  useEffect(() => {
    const learningCornerInterval = setInterval(() => {
      if (learningCornerInView) {
        addTimeSpent('learningCornerTimeSpent', TIME_SPENT_INCREMENT);
      }
    }, TIME_SPENT_INTERVAL);

    if (!learningCornerInView) {
      clearInterval(learningCornerInterval);
    }
    return () => clearInterval(learningCornerInterval);
  }, [learningCornerInView, addTimeSpent]);

  useEffect(() => {
    const mainFeedbackInterval = setInterval(() => {
      if (mainFeedbackInView) {
        addTimeSpent('mainFeedbackTimeSpent', TIME_SPENT_INCREMENT);
      }
    }, TIME_SPENT_INTERVAL);
    if (!mainFeedbackInView) {
      clearInterval(mainFeedbackInterval);
    }
    return () => clearInterval(mainFeedbackInterval);
  }, [mainFeedbackInView, addTimeSpent]);

  return {
    mainFeedbackRef,
    learningCornerRef,
  };
};

export default useCareerTracker;
