import classNames from 'classnames';
import { GameMessageType } from 'types/enums';
import { useTranslation } from 'hooks';

import s from 'components/GameModal/GameModal.module.scss';
import { TranslationPage } from '../../../types/enums/TranslationPage';

const Message: React.FC<{ messageType: GameMessageType }> = ({
  messageType,
}) => {
  const lang = useTranslation(TranslationPage.MESSAGE);

  return (
    <div className={s.feedbackToast}>
      <div className={classNames(s.feedbackIcon, s[messageType])} />
      <h6>{lang[messageType]}</h6>
    </div>
  );
};

export default Message;
