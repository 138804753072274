import { Portal } from 'components';
import ButtonRect from 'components/ButtonRect/ButtonRect';
import { ButtonType } from 'components/ButtonRect/ButtonRect.types';

import s from './Popup.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactElement[] | React.ReactElement;
};

const Popup: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
}) => (isOpen ? (
  <Portal>
    <div className={s.popupBackdrop}>
      <div className={s.popupWrapper}>
        <div className={s.popup}>
          <ButtonRect
            type={ButtonType.CLOSE}
            onClick={onClose}
            text=""
            className={s.closeButton}
          />
          <div className={s.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  </Portal>
) : null);

export default Popup;
