import Point from './Point';

class LazyPoint extends Point {
  /**
   * Update the x and y values
   */
  update(point: Point) {
    this.x = point.x;
    this.y = point.y;
  }

  /**
   * Move the point to another position using an angle and distance
   *
   * @param angle The angle in radians
   * @param distance How much the point should be moved
   */
  moveByAngle(angle: number, distance: number) {
    // Rotate the angle based on the browser coordinate system ([0,0] in the top left)
    const angleRotated = angle + (Math.PI / 2);

    this.x = this.x + (Math.sin(angleRotated) * distance);
    this.y = this.y - (Math.cos(angleRotated) * distance);
  }

  /**
   * Check if this point is the same as another point
   */
  equalsTo(point: Point) {
    return this.x === point.x && this.y === point.y;
  }

  /**
   * Get the difference for x and y axis to another point
   */
  getDifferenceTo(point: Point) {
    return new Point(this.x - point.x, this.y - point.y);
  }

  /**
   * Calculate distance to another point
   */
  getDistanceTo(point: Point) {
    const diff = this.getDifferenceTo(point);
    return Math.sqrt(diff.x ** 2 + diff.y ** 2);
  }

  /**
   * Calculate the angle to another point
   */
  getAngleTo(point: Point) {
    const diff = this.getDifferenceTo(point);
    return Math.atan2(diff.y, diff.x);
  }

  /**
   * Return a simple object with x and y properties
   */
  toObject() {
    return {
      x: this.x,
      y: this.y,
    };
  }
}

export default LazyPoint;
