/* eslint-disable max-len */
import {
  useEffect,
  useRef,
  useState,
  createRef,
} from 'react';
import classnames from 'classnames';

import s from '../MTPT.module.scss';
import { Point } from '../types';

type ShapeProps = {
  cursor: Point;
  isFinished: boolean;
  hasError: boolean;
  isTracking: boolean;
  onError: () => void;
  onProgress: (progress: number) => void;
  setStartingPointRef: React.Dispatch<SVGEllipseElement | null>;
};

const Shape1: React.FC<ShapeProps> = ({
  cursor,
  isFinished,
  hasError,
  onError,
  onProgress,
  isTracking,
  setStartingPointRef,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const shapeRef = useRef<SVGPathElement | null>(null);
  const [progressShapes] = useState([
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
  ]);

  const [activatedProgressShapes] = useState(new Set<SVGEllipseElement>());
  if (!isTracking) {
    activatedProgressShapes.clear();
  }

  useEffect(() => {
    if (!svgRef.current || !shapeRef.current) {
      return;
    }
    const { left, top } = svgRef.current!.getBoundingClientRect();
    const cursorAbsolute = [
      cursor.x + left,
      cursor.y + top,
    ] as const;
    const elements = document.elementsFromPoint(...cursorAbsolute);

    const isInsideShape = elements.includes(shapeRef.current!);
    if (!isInsideShape) {
      onError();
    }

    // There are 9 progress shapes, each representing 10% of the trace progress. This means that
    // completing all of them makes up for 100% of the total progress.
    const progressShape = elements.find(e => progressShapes.map(s => s.current).includes(e as SVGEllipseElement));
    if (progressShape) {
      activatedProgressShapes.add(progressShape as SVGEllipseElement);
      onProgress((activatedProgressShapes.size) / (progressShapes.length));
    }
  }, [cursor, onError, onProgress, progressShapes, activatedProgressShapes]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1778 1000"
      xmlSpace="preserve"
      id="svg821"
      width="1778"
      height="1000"
      ref={svgRef}
      className={classnames(
        s.svg,
        isFinished && s.isFinished,
        hasError && s.hasError,
      )}
    >
      <path
        ref={shapeRef}
        className={s.mainShape}
        d="m 839.91885,950.84624 c -1.2789,3.94857 -3.1608,8.27054 -7.8436,9.19511 -5.1323,1.01335 -12.8186,-1.43932 -19.8403,-3.35262 -7.022,-1.91332 -82.90077,-23.60092 -82.90077,-23.60092 -7.75675,-2.07678 -13.38663,-4.94868 -16.61008,-9.08719 -3.22325,-4.13847 -3.38732,-6.07719 -1.67363,-12.08581 L 956.73445,54.07023 c 0,0 2.1447,-7.827691 10.3033,-12.049727 8.1593,-4.221869 17.0946,-1.852284 27.4895,0.930138 27.19705,7.724284 58.27615,16.463315 69.35985,19.902656 11.0838,3.439398 17.5864,5.211861 21.5832,9.868467 3.9968,4.656608 1.3105,17.329049 -2.6029,31.019296 -3.9103,13.69118 -241.66935,843.1567 -242.94825,847.10527 z"
      />
      <ellipse
        ref={setStartingPointRef}
        className={s.progressShape}
        cx="995"
        cy="-200"
        rx="20"
        ry="20"
        transform="matrix(0.95840248,0.28542021,-0.29760383,0.95468946,0,0)"
      />
      <ellipse
        ref={progressShapes[0]}
        className={s.progressShape}
        cx="1002.6809"
        cy="-118.76048"
        rx="92.551422"
        ry="29.655983"
        transform="matrix(0.95840248,0.28542021,-0.29760383,0.95468946,0,0)"
      />
      <ellipse
        ref={progressShapes[1]}
        className={s.progressShape}
        cx="1003.4827"
        cy="-1.0807781"
        rx="92.538734"
        ry="29.665129"
        transform="matrix(0.96606306,0.25830634,-0.23654304,0.97162101,0,0)"
      />
      <ellipse
        ref={progressShapes[2]}
        className={s.progressShape}
        cx="989.8009"
        cy="111.61123"
        rx="92.44883"
        ry="29.729788"
        transform="matrix(0.97231101,0.23369062,-0.18089186,0.98350299,0,0)"
      />
      <ellipse
        ref={progressShapes[3]}
        className={s.progressShape}
        cx="995.37933"
        cy="185.56793"
        rx="92.518524"
        ry="29.67968"
        transform="matrix(0.96808897,0.25060677,-0.21913528,0.97569449,0,0)"
      />
      <ellipse
        ref={progressShapes[4]}
        className={s.progressShape}
        cx="1002.5562"
        cy="239.15028"
        rx="92.557335"
        ry="29.651722"
        transform="matrix(0.96161568,0.27439986,-0.27285236,0.96205592,0,0)"
      />
      <ellipse
        ref={progressShapes[5]}
        className={s.progressShape}
        cx="1013.8295"
        cy="314.67252"
        rx="92.546333"
        ry="29.659653"
        transform="matrix(0.95735087,0.28892786,-0.30545492,0.95220654,0,0)"
      />
      <ellipse
        ref={progressShapes[6]}
        className={s.progressShape}
        cx="992.22729"
        cy="429.02277"
        rx="92.541992"
        ry="29.662779"
        transform="matrix(0.96563705,0.25989437,-0.24013154,0.97074036,0,0)"
      />
      <ellipse
        ref={progressShapes[7]}
        className={s.progressShape}
        cx="971.93762"
        cy="526.2171"
        rx="92.501259"
        ry="29.692106"
        transform="matrix(0.96938786,0.24553448,-0.20766392,0.97820023,0,0)"
      />
      <ellipse
        ref={progressShapes[8]}
        className={s.progressShape}
        cx="995.81689"
        cy="581.66156"
        rx="92.556412"
        ry="29.65239"
        transform="matrix(0.96241212,0.27159329,-0.2665317,0.96382615,0,0)"
      />
      <ellipse
        ref={progressShapes[9]}
        className={s.progressShape}
        cx="989.95624"
        cy="675.62372"
        rx="92.553284"
        ry="29.654642"
        transform="matrix(0.96356122,0.26748791,-0.25727605,0.96633795,0,0)"
      />
    </svg>
  );
};

export default Shape1;
