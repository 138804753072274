import classnames from 'classnames';

import s from './Heading.module.scss';
import { ReactNode } from 'react';

type Props = {
  secondary?: boolean;
  children?: ReactNode;
};

const Heading: React.FC<Props> = ({ children, secondary }) => (
  <h2 className={classnames(s.heading, secondary && s.secondary)}>
    {children}
  </h2>
);

export default Heading;
