import { useAppStateActions } from 'appState';
import { AspectRatioView, ButtonRect, HorizontalOrientationPrompt } from 'components';

import s from './GamesCompleted.module.scss';
import { useTranslation } from '../../hooks';
import { TranslationPage } from '../../types/enums/TranslationPage';

const GamesCompleted: React.FC = () => {
  const { appFlowForward } = useAppStateActions();
  const lang = useTranslation(TranslationPage.GAMES_COMPLETED);

  return (
    <>
      <HorizontalOrientationPrompt />
      <AspectRatioView>
        <div className={s.container}>
          <h3 className={s.title}>{lang.title}</h3>
          <p className={s.message}>{lang.message}
          </p>
          <ButtonRect onClick={appFlowForward} text={lang.buttonLabel} />
        </div>
      </AspectRatioView>
    </>
  );
};

export default GamesCompleted;
