import { useState } from 'react';
import classNames from 'classnames';

import ButtonRect from 'components/ButtonRect/ButtonRect';
import { useTranslation } from 'hooks';
import { TranslationPage } from 'types/enums/TranslationPage';
import { useAppStateActions } from 'appState';
import { ALTERNATIVE_GAME_FLOW } from 'config';
import s from './GameSkipper.module.scss';
import GameModal from 'components/GameModal/GameModal';

type GameSkipperProps = {
  className?: string;
};

const GameSkipper: React.FC<GameSkipperProps> = ({
  className,
}) => {
  const [confirmationPrompt, setConfirmationPrompt] = useState(false);
  const lang = useTranslation(TranslationPage.GAME_SKIPPER);
  const { skipCurrentGame } = useAppStateActions();

  if (!ALTERNATIVE_GAME_FLOW) {
    return null;
  }

  return (
    <>
      <GameModal isVisible={confirmationPrompt}>
        <GameModal.SkipGame
          onConfirm={skipCurrentGame}
          onCancel={() => setConfirmationPrompt(false)}
        />
      </GameModal>
      {!confirmationPrompt && (
        <ButtonRect
          className={classNames(s.button, className)}
          text={lang.skipButton}
          onClick={() => setConfirmationPrompt(true)}
        />
      )}
    </>
  );
};

export default GameSkipper;
