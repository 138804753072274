import { GameId } from 'types/enums';

import Corsi, { Results as CORSIResults } from './Corsi';
import TMT, { Results as TMTResults } from './TMT';
import DigitSpan, { Results as DigitSpanResults } from './DigitSpan';
import CPTGame, { Results as CPTResults } from './CPT';
import MTPT, { Results as MTPTResults } from './MTPT';
import BRET, { Results as BRETResults } from './BRET';
import RMET, { Results as RMETResults } from './RMET';
import HMT, { Results as HMTResults } from './HMT';
import EI, { Results as EIResults } from './EI';
import BigFive, { Results as BigFiveResults } from './BigFive';
import RIASEC, { Results as RIASECPResults } from './RIASEC';
import PP, { Results as PPResults } from './PP';
import GM, { Results as GMResults } from './GM';

const gameIds = {
  [GameId.CORSI]: Corsi,
  [GameId.TMT]: TMT,
  [GameId.DigitSpan]: DigitSpan,
  [GameId.MTPT]: MTPT,
  [GameId.BRET]: BRET,
  [GameId.RMET]: RMET,
  [GameId.HMT]: HMT,
  [GameId.CPT]: CPTGame,
  [GameId.EI]: EI,
  [GameId.BigFive]: BigFive,
  [GameId.PP]: PP,
  [GameId.RIASEC]: RIASEC,
  [GameId.GM]: GM,
};

export default gameIds;

export type GameResults = CORSIResults
| TMTResults
| DigitSpanResults
| MTPTResults
| BRETResults
| RMETResults
| HMTResults
| CPTResults
| EIResults
| BigFiveResults
| PPResults
| RIASECPResults
| GMResults;
