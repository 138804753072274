import {
  AspectRatioView,
  ButtonRect,
  GameModal,
  Toast,
} from 'components';
import { ProgressBar } from 'components/game';
import { useEffect } from 'react';
import cls from 'classnames';
import { useAppStateActions } from 'appState';
import { fillTranslationTextWithVariables, useTranslation } from 'hooks';
import { GameId, GameMessageType } from 'types/enums';
import { useDigitSpanGameActions, useDigitSpanGameData } from './state';
import styles from './DigitSpanGame.module.scss';
import {
  DigitSpanGameModal,
  DigitSpanResultForSubmission,
  DigitSpanStage,
} from './state/digitSpanState';
import { DigitSpanGameProps } from './DigitSpan.types';
import DigitDisplay from './DigitDisplay';
import { TranslationPage } from '../../types/enums/TranslationPage';
import GameSkipper from 'components/game/GameSkipper/GameSkipper';

const DigitSpanGame: React.FC<DigitSpanGameProps> = ({
  practiceMode,
  reversedMode,
}) => {
  const lang = useTranslation(TranslationPage.DIGIT_SPAN);
  const {
    roundAnswer,
    digitsSet,
    roundFlowStage,
    sequence,
    correctSequence,
    digitToDisplay,
    displayGameModal,
    gameModalToDisplay,
    gameResult,
    progres,
  } = useDigitSpanGameData();
  const {
    setAnswer,
    togglePracticeMode,
    startNewGame,
    submitAnswer,
    removeLastFromAnswer,
    setDirectionReversed,
    resetDigitSpanFlow,
    nextRound,
  } = useDigitSpanGameActions();
  const { gameFlowForward, submitGameResults } = useAppStateActions();

  useEffect(
    () => {
      togglePracticeMode(!!practiceMode);
      setDirectionReversed(!!reversedMode);
    },
    [practiceMode, reversedMode, togglePracticeMode, setDirectionReversed],
  );

  useEffect(startNewGame, [startNewGame]);

  useEffect(() => resetDigitSpanFlow, [resetDigitSpanFlow]);

  useEffect(() => {
    if (roundFlowStage === DigitSpanStage.FINISHED) {
      gameFlowForward();
    }
  }, [roundFlowStage, gameFlowForward]);

  useEffect(() => {
    if (
      roundFlowStage === DigitSpanStage.FINISHED
      && !practiceMode
      && reversedMode
    ) {
      const resultForSubmission: DigitSpanResultForSubmission = {
        ...gameResult,
        digitSpanScores: gameResult.digitSpanScores.map(score => ({
          ...score,
          sequence: score.sequence.join(' '),
          correctSolution: score.correctSolution.join(' '),
          proposedSolution: score.proposedSolution.join(' '),
        })),
      };
      submitGameResults(GameId.DigitSpan, resultForSubmission);
    }
  }, [roundFlowStage, submitGameResults, practiceMode, reversedMode, gameResult]);

  const renderRoundStage = () => {
    switch (roundFlowStage) {
      case (DigitSpanStage.SHOW_DIGITS):
        return (
          <div className={cls(
            styles.DigitDisplay,
            reversedMode && styles.DigitDisplayReversed,
          )}
          >
            <DigitDisplay correctSolution={sequence} digitIndex={digitToDisplay} />
          </div>
        );
      case (DigitSpanStage.REPEAT_DIGITS):
        return (
          <div className={styles.GuessBoard}>
            <div className={styles.LeftContainer}>
              <div className={styles.AmountOfDigitsContainer}>
                <p className={styles.AmountOfDigits}>{
                  correctSequence.length
                } {lang.amountOfLetterText}
                </p>
              </div>

              <p className={cls(
                styles.DirectionTip,
                reversedMode && styles.DirectionTipHighlight,
              )}
              >
                {!reversedMode ? lang.directionTipForward : lang.directionTipReverse}
              </p>
              <div className={styles.GuessContainer}>
                <p className={styles.ResultGuess}>{roundAnswer.join('')}</p>
              </div>
            </div>
            <div className={styles.RightContainer}>
              {
                digitsSet.map(digit => (
                  <ButtonRect
                    key={digit}
                    text={digit.toString()}
                    className={styles.DigitButton}
                    onClick={() => {
                      setAnswer(digit);
                    }}
                  />
                ))
              }
              <button
                type="button"
                onClick={removeLastFromAnswer}
                className={styles.ReduceAnswerButton}
              >
                { lang.deleteButtonText }
              </button>
            </div>
            <div className={styles.BottomContainer}>
              <ButtonRect
                className={styles.SubmitButton}
                onClick={submitAnswer}
                text={lang.submitButtonText}
                isVisible={!!roundAnswer.length}
              />
            </div>
          </div>
        );
      case (DigitSpanStage.PAUSE):
        return (
          <Toast isVisible>
            <Toast.Message messageType={GameMessageType.GO} />
          </Toast>
        );
      default:
        return null;
    }
  };

  const renderGameModal = () => {
    switch (gameModalToDisplay) {
      case (DigitSpanGameModal.ANSWER_RIGHT):
        return (
          <GameModal.Success
            onClick={nextRound}
            text={lang.correctAnswerMsg}
            header={lang.success}
          />
        );
      case (DigitSpanGameModal.ANSWER_WRONG):
        return (
          <GameModal.Incorrect
            onClick={nextRound}
            header={lang.incorrect}
            text={fillTranslationTextWithVariables(
              lang.incorrectAnswerMsg,
              { correctAnswer: correctSequence.join('') },
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <AspectRatioView>
      <GameSkipper className={styles.skipButton} />
      <div className={styles.Container}>
        {
          renderRoundStage()
        }
        <GameModal isVisible={displayGameModal}>
          {
            renderGameModal()
          }
        </GameModal>
        <ProgressBar progress={progres} />
      </div>
    </AspectRatioView>
  );
};

export default DigitSpanGame;
