export enum CorsiTrialsType {
  PRACTICE = 'practice',
  MAIN = 'main',
}

export enum CorsiRoundStage {
  DEFAULT = 'default',
  ANIMATING = 'animating',
  GO = 'go',
  USER_CHOICES = 'user_choices',
  FEEDBACK = 'feedback',
}

export enum CorsiTrialStatus {
  FAIL = 0,
  SUCCESS = 1,
}

export type CorsiRoundData = {
  highestCorsiSpan: number,
  numberOfItemsToRemember: number,
  statusOfTrial: CorsiTrialStatus,
  tableRow: number,
  fTime: number,
  lTime: number,
  clicks: number,
  correct: number,
  practice: boolean
};

export type CorsiResult = {
  corsiGameScores: CorsiRoundData[],
  corsiMetadata: {
    firstPracticeFails: number,
    timeSpentOnPracticeTrials: number,
    corsiInstructionTime: number
  },
};
