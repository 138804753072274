import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import { SlideImageProps } from './SlidImage.types';
import styles from './SlideImage.module.scss';

const SlideText: React.FC<SlideImageProps> = ({
  className,
  content: text,
}) => (
  <>
    <div className={classnames(styles.container, className)}>
      <ReactMarkdown className={styles.text}>
        {text}
      </ReactMarkdown>
    </div>
  </>
);

export default SlideText;
