import classnames from 'classnames';
import { useAppStateActions } from 'appState';
import { GameId } from 'types/enums';
import { useTranslation } from 'hooks';

import s from './ProgressMap.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';

type ProgressMapItemProps = {
  active: boolean,
  gameId: GameId,
  finished: boolean,
  selectionMode: 'onlyNextGame' | 'allUpcomingGames'
};

const ProgressMapItem: React.FC<ProgressMapItemProps> = ({
  active,
  gameId,
  finished,
  selectionMode,
}) => {
  const lang = useTranslation(TranslationPage.PROGRESS_MAP);
  const gamesInfo = useTranslation(TranslationPage.GAMES_INFO);
  const { appFlowForward } = useAppStateActions();
  const onClick = {
    'onlyNextGame': () => appFlowForward(),
    'allUpcomingGames': () => appFlowForward({ explicitGameId: gameId }),
  }[selectionMode];

  return (
    <div className={classnames(s.itemWrapper, s[selectionMode])}>
      {!finished && (
        <span className={classnames(s.tag, { [s.upcoming]: !finished && !active })}>
          {active ? lang.gameStatus.active : lang.gameStatus.upcoming}
        </span>
      )}
      <div
        className={classnames(s.item, {
          [s.active]: active,
          [s.upcoming]: !active && !finished,
        })}
        onClick={onClick}
      >
        <div className={classnames(s.gameImg, s[gameId])} />
        <div className={s.aboutGame}>
          <div className={s.aboutGameRow}>
            <h6 className={s.gameName}>{gamesInfo[gameId].gameName}</h6>
            <div className={s.superPower}>
              <span className={s.star} />
              <p className={s.superPowerText}>{gamesInfo[gameId].superPower}</p>
            </div>
          </div>
          <p className={s.description}>{gamesInfo[gameId].description}</p>
        </div>
        <div className={classnames(s.finished, { [s.visible]: finished })} />
      </div>
    </div>
  );
};

export default ProgressMapItem;
