export enum Tab {
  Career = 'career',
  Entrepreneurship = 'entrepreneurship',
}

export enum Section {
  CareerOverview = 'career-overview',
  CareerSkillStrenghts = 'career-skill-strenghts',
  CareerInterests = 'career-interests',
  CareerOptions = 'career-options',
  CareerLearningCenter = 'career-learning-center',

  EntrepreneurshipOverview = 'entrepreneurship-overview',
  EntrepreneurshipSkillStrenghts = 'entrepreneurship-skill-strengths',
  EntrepreneurshipCourses = 'entrepreneurship-courses',
  EntrepreneurshipNetworks = 'entrepreneurship-networks',
}
