import { useEffect } from 'react';

import { useAppState, useAppStateActions, useAssessmentVisibility } from 'appState';
import { Layout, TabToggle } from 'components/assessment';
import { useAssessmentStateActions } from './state';

import Career from './Career';
import Entrepreneurship from './Entrepreneurship';
import useWebAssessmentData from './useWebAssessmentData';
import usePdfAssessmentData from './usePdfAssessmentData';
import { Tab, Section } from './enums';
import useSectionSelection from './useSectionSelection';
import { useTranslation } from '../../../hooks';
import { TranslationPage } from '../../../types/enums/TranslationPage';
import { OccupationalInterest, SkillStrength } from './types';

const Assessment: React.FC = () => {
  const lang = useTranslation(TranslationPage.ASSESMENT);
  const {
    emailAssessmentPdf,
    generateAssessmentPdf,
  } = useAppStateActions();

  const { onSectionSelected, activeTab } = useSectionSelection();
  const { assessmentScrollPosition: scrollPosition } = useAppState();
  const currentUser = useAppState(s => s.currentUser);
  const {
    createNewVisitation,
    setPdfSentToEmail,
    setPdfDownloaded,
    getExistingVisitation,
  } = useAssessmentStateActions();

  useEffect(() => {
    const root = sessionStorage.getItem('assessmentEvents');
    let currentVisitation;
    if (root) {
      currentVisitation = JSON.parse(root);
    }
    if (!currentVisitation) {
      createNewVisitation();
    } else {
      getExistingVisitation();
    }
  }, [createNewVisitation, getExistingVisitation]);

  const data = {
    pdf: usePdfAssessmentData(),
    web: useWebAssessmentData(),
  };
  const visibility = useAssessmentVisibility();
  if (!data.pdf || !data.web) {
    return null;
  }

  const {
    skillStrengths,
    occupationalInterests,
    occupations,
    username,
    date,
  } = data.web;

  const showCareerOptions = visibility.feedbackVisibilityOptions.careerOptionsStrengths
    || visibility.feedbackVisibilityOptions.careerOptionsInterests;
  const occupationsNotEmpty = occupations.byInterests.length > 0
    || occupations.bySkills.length > 0;
  const mobileMenuItems = [
    {
      id: Section.CareerOverview,
      name: lang.menu.career,
      primary: true,
      visible: visibility.feedbackVisibilityOptions.careerOptionsStrengths
        || visibility.feedbackVisibilityOptions.careerInterests
        || visibility.feedbackVisibilityOptions.careerOptionsStrengths
        || visibility.feedbackVisibilityOptions.careerOptionsInterests
        || visibility.feedbackVisibilityOptions.careerLearningCenter,
    },
    {
      id: Section.CareerSkillStrenghts,
      name: lang.menu.skillStrenghts,
      visible: visibility.feedbackVisibilityOptions.careerStrengths
        && skillStrengths.career.length > 0,
    },
    {
      id: Section.CareerInterests,
      name: lang.menu.careerInterests,
      visible: visibility.feedbackVisibilityOptions.careerInterests
        && occupationalInterests.length > 0,
    },
    {
      id: Section.CareerOptions,
      name: lang.menu.careerOptions,
      visible: showCareerOptions
        && occupationsNotEmpty,
    },
    {
      id: Section.CareerLearningCenter,
      name: lang.menu.learningCenter,
      visible: visibility.feedbackVisibilityOptions.careerLearningCenter,
    },
    {
      id: Section.EntrepreneurshipOverview,
      name: lang.menu.entrepreneurship,
      primary: true,
      visible: visibility.feedbackVisibilityOptions.entrepreneurshipStrengths
      || visibility.feedbackVisibilityOptions.entrepreneurshipCourses,
    },
    {
      id: Section.EntrepreneurshipSkillStrenghts,
      name: lang.menu.skillStrenghts,
      visible: visibility.feedbackVisibilityOptions.entrepreneurshipStrengths,
    },
    {
      id: Section.EntrepreneurshipCourses,
      name: lang.menu.courses,
      visible: visibility.feedbackVisibilityOptions.entrepreneurshipCourses,
    },
  ];

  const onEmailPdf = () => {
    if (currentUser) {
      setPdfSentToEmail(currentUser);
    }
    return emailAssessmentPdf();
  };

  const onDownloadPdf = () => {
    setPdfDownloaded();
    return generateAssessmentPdf();
  };

  return (
    <Layout scrollPosition={scrollPosition}>
      <Layout.Topbar>
        <TabToggle
          items={mobileMenuItems}
          onSelected={onSectionSelected}
          activeTab={activeTab}
        />
      </Layout.Topbar>
      <Layout.Content>
        {
          activeTab === Tab.Career && (
            <Career
              username={username}
              testDate={date}
              skillStrengths={skillStrengths.career as SkillStrength[]}
              occupationalInterests={occupationalInterests as OccupationalInterest[]}
              occupations={occupations}
              onDownloadPdf={onDownloadPdf}
              onEmailPdf={onEmailPdf}
              onSectionSelected={onSectionSelected}
            />
          )
        }
        {
          activeTab === Tab.Entrepreneurship && (
            <Entrepreneurship
              username={username}
              testDate={date}
              skillStrengths={skillStrengths.entrepreneurship as SkillStrength[]}
            />
          )
        }
      </Layout.Content>
      <Layout.Sidebar />
    </Layout>
  );
};

export default Assessment;
