import s from './Layout.module.scss';
import { ReactNode } from 'react';

const Topbar: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <div className={s.topbar}>
    {children}
  </div>
);

export default Topbar;
