/* eslint-disable react/no-array-index-key */
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import { getLearningCenterSectionName } from 'utils';
import s from './LearningCenterGroupCard.module.scss';

const parseTitle = (rawTitle: string) => {
  const title = rawTitle.replace(/([^[]+)(.*)$/, '$1');
  const website = rawTitle.replace(/([^[]+)(\[(.+)\])?/, '$3');
  return { title, website };
};

type ResourceGroup = {
  heading?: string;
  items: Array<{
    url: string;
    title: string;
  }>;
};

type Props = {
  resource: ResourceGroup;
};

const LearningCenterGroupCard: React.FC<Props> = ({ resource: { heading, items } }) => {
  const { incrementInteraction } = useAssessmentStateActions();
  return (
    <section
      className={s.card}
      data-section={heading ? getLearningCenterSectionName(heading) : undefined}
    >
      {heading && <h3 className={s.name}>{heading}</h3>}
      <ul className={s.items}>
        {items.map(({ title, url }, i) => (
          <li className={s.item} key={`${url}-${i}`}>
            <a
              href={url}
              className={s.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => incrementInteraction('learningCornerInteractions', title)}
            >
              {parseTitle(title).title}
            </a>
            {parseTitle(title).website && (
              <span className={s.linkWebsite}> [{parseTitle(title).website}]</span>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default LearningCenterGroupCard;
