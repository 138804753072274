import { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { Stage, Layer, FastLayer } from 'react-konva';

import { useVirtualUnits, useVirtualViewportSize } from 'appState';
import { AspectRatioView } from 'components';

import useGameState from './useGameState';
import GameHeader from './GameHeader';
import Connectors from './Connectors';
import ItemShape from './ItemShape';
import GameSkipper from 'components/game/GameSkipper/GameSkipper';
import s from './TMT.module.scss';

type GameAreaProps = {
  header: string;
};

const GameArea: React.FC<GameAreaProps> = ({ header }) => {
  const stageRef = useRef<Konva.Stage | null>(null);

  const updateCursorPos = useGameState(s => s.updateCursorPos);
  const stage = useVirtualViewportSize();
  const [height, setHeight] = useState<number>(stage.height);
  const v = useVirtualUnits();

  useEffect(() => {
    setHeight(window.innerHeight);
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setHeight(window.innerHeight);
      })
    }
  }, []);

  const cancelDrawing = useGameState(s => s.cancelDrawing);

  const rafRef = useRef(-1);
  const onMove = () => {
    const pos = stageRef.current?.getPointerPosition();
    window.cancelAnimationFrame(rafRef.current);
    rafRef.current = window.requestAnimationFrame(() => {
      if (pos) {
        updateCursorPos({
          x: pos.x / v.w,
          y: pos.y / v.w,
        });
      }
    });
  };

  const items = useGameState(s => s.items);
  const lastCompletedItem = useGameState(s => s.getLastCompletedItem());
  const otherItems = items.filter(i => i.index !== lastCompletedItem.index);

  return (
    <AspectRatioView background="black">
      <GameSkipper className={s.skipButton} />
      <Stage
        width={stage.width}
        height={height}
        style={{ backgroundColor: '#ADD8E6' }}
        ref={stageRef}
        onMouseUp={cancelDrawing}
        onTouchEnd={cancelDrawing}
        onMouseMove={onMove}
        onTouchMove={onMove}
      >
        <FastLayer>
          {otherItems.map(item => <ItemShape {...item} key={item.index} />)}
        </FastLayer>
        <Layer>
          {/* Render on separate stage because it can animate */}
          {lastCompletedItem && (
            <ItemShape {...lastCompletedItem} />
          )}
        </Layer>
        <Connectors />
      </Stage>
      <GameHeader>{header}</GameHeader>
    </AspectRatioView>
  );
};

export default GameArea;
