// import { useViewportSize } from 'appState';
// import { useIsLargeScreen } from 'hooks';

import ObservableSection from './ObservableSection';
import Section from './Section';
import s from './Layout.module.scss';
import { ReactNode } from 'react';

type ComponentComposition = {
  Section: typeof Section;
  ObservableSection: typeof ObservableSection;
};
const Content: React.FC<{ children?: ReactNode }> & ComponentComposition = ({ children }) => {
  // const { width } = useViewportSize();
  // const isSmallScreen = !useIsLargeScreen();

  return (
    <main
      className={s.content}
      style={{
        // false ? { width: `${width}px` } : {},
      }}
    >
      {children}
    </main>
  );
};

Content.Section = Section;
Content.ObservableSection = ObservableSection;

export default Content;
