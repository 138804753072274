import { useEffect } from 'react';

import { useAppFlowStage, useAppStateActions } from 'appState';
import { AppFlowStage } from 'types/enums';

import {
  Game,
  LoggingIn,
  LogIn,
  LogInByReferral,
  AfterLandingPage,
  PostLoginInstructions,
  CollectExtraData,
  UpdateExtraData,
  ProgressMap,
  Welcome,
  Questionnaire,
  GamesCompleted,
  Summary,
} from './stages';
// import {
//   useTransition,
//   animated,
//   useSpringRef,
// } from '@react-spring/web';

const componentMap = {
  [AppFlowStage.Launch]: null,
  [AppFlowStage.AfterLandingPage]: AfterLandingPage,
  [AppFlowStage.LogIn]: LogIn,
  [AppFlowStage.LogInByReferral]: LogInByReferral,
  [AppFlowStage.LoggingIn]: LoggingIn,
  [AppFlowStage.PostLoginInstructions]: PostLoginInstructions,
  [AppFlowStage.CollectExtraData]: CollectExtraData,
  [AppFlowStage.UpdateExtraData]: UpdateExtraData,
  [AppFlowStage.Welcome]: Welcome,
  [AppFlowStage.ProgressMap]: ProgressMap,
  [AppFlowStage.Game]: Game,
  [AppFlowStage.GamesCompleted]: GamesCompleted,
  // [AppFlowStage.PreAssessmentQuestionnaire]: Questionnaire,
  [AppFlowStage.Summary]: Summary,
  [AppFlowStage.PostAssessmentQuestionnaire]: Questionnaire,
} as Record<AppFlowStage, null | React.FC>;

const renderStage = (stage: AppFlowStage) => {
  const Component = componentMap[stage];
  return Component
    ? <Component />
    : null;
};

const resolvePilotGroup = () =>
  new URL(window.location.href).pathname.split('/').filter(Boolean)[0];

const AppFlow: React.FC = () => {
  const stage = useAppFlowStage();
  const {
    onAppFlowReady,
    appFlowForward,
    tryShowingSampleReport,
    setFetchPilotGroupsInterval,
    setSubmitPendingResultsInterval,
    resolveDefaultLanguage,
    resolvePrivacyPolicy,
    resolveLogInMode,
  } = useAppStateActions();

  useEffect(() => {setFetchPilotGroupsInterval()}, [setFetchPilotGroupsInterval]);
  useEffect(() => {setSubmitPendingResultsInterval()}, [setSubmitPendingResultsInterval]);

  useEffect(() => {onAppFlowReady()}, [onAppFlowReady]);

  useEffect(() => {tryShowingSampleReport()}, [tryShowingSampleReport]);
  useEffect(() => {appFlowForward()}, [appFlowForward]);

  useEffect(() => {resolveDefaultLanguage(resolvePilotGroup())}, [resolveDefaultLanguage]);
  useEffect(() => {resolvePrivacyPolicy(resolvePilotGroup())}, [resolvePrivacyPolicy]);
  useEffect(() => {resolveLogInMode(resolvePilotGroup())}, [resolveLogInMode]);


  return (
    <div style={{
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden'
    }}>
      {renderStage(stage)}
    </div>
  );
};

export default AppFlow;
