import ButtonRect from '../ButtonRect/ButtonRect';
import { ButtonType } from '../ButtonRect/ButtonRect.types';
import { useMemo } from 'react';
import { isIOS } from '../../utils/isIOS';

type FullscreenButtonProps = {
  text: string;
};


const FullscreenButton: React.FC<FullscreenButtonProps> = ({ text }) => {
  const isAppleDevice = useMemo(() => {
    return isIOS();
  }, [])
  const handleFullscreen = (): void => {
    const doc = document as any;
    const docEl = document.documentElement as any;
    if (doc.fullscreenElement) {
      doc.exitFullscreen();
    } else if (docEl.requestFullscreen) {
      docEl.requestFullscreen();
    }
  };

  if (isAppleDevice) {
    return null;
  }

  return (
    <ButtonRect onClick={handleFullscreen} text={text} type={ButtonType.FULLSCREEN} />
  );
};

export default FullscreenButton;
