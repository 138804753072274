import { useAppStateActions } from 'appState';
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import ButtonRect from '../ButtonRect/ButtonRect';
import { ButtonType } from '../ButtonRect/ButtonRect.types';
import { LogoutButtonProps } from './LogoutButton.types';

const LogoutButton: React.FC<LogoutButtonProps> = ({ text }) => {
  const { logout } = useAppStateActions();
  const {
    clearSessionStorageVisitations,
  } = useAssessmentStateActions();

  const handleLogout = (): void => {
    logout();
    clearSessionStorageVisitations();
    window.location.reload();
  };

  return (
    <ButtonRect onClick={handleLogout} text={text} type={ButtonType.LOGOUT} />
  );
};

export default LogoutButton;
