import { useEffect } from 'react';

import { AspectRatioView } from 'components';

import { useCorsiGameActions } from './state';
import CorsiGameArea from './CorsiGameArea';

type CorsiGameProps = {
  practiceMode?: boolean;
};

const CorsiGame: React.FC<CorsiGameProps> = ({ practiceMode }) => {
  const { startGame } = useCorsiGameActions();

  useEffect(
    () => {
      startGame(!!practiceMode);
    },
    [practiceMode, startGame]
  );

  return (
    <AspectRatioView background="#2a2929">
      <CorsiGameArea />
    </AspectRatioView>
  );
};

export default CorsiGame;
