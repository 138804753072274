import { create } from 'zustand';
import { getRandomNumWithoutRepeat } from 'utils';
import {
  CorsiResult,
  CorsiRoundStage,
  CorsiTrialStatus,
  CorsiTrialsType,
} from '../types';

type CoriGameState = {
  corsiBlocks: { id: number }[],
  usedSequences: Array<number>,
  tableRow: number,
  getCurrentSequence: () => Array<number>,
  getLongestSequence: () => Array<number>,
  currentRoundStage: CorsiRoundStage,
  isFinishedGameTrials: boolean,
  isGameOver: boolean,
  errors: number,
  highestRememberedSequence: number,
  gameResult: CorsiResult,
  currentRound: number,
  numberBlocksToHighLight: number,
  roundAnswers: Array<number>,
  isSuccessRound: boolean,

  practiceTrialStartTime: number,
  instructionsStartTime: number,
  trialTime: { start: number, firstClick: number, lastClick: number },

  isPracticeMode: boolean;
  startGame: (isPracticeMode: boolean) => void;

  roundStageForward: () => void,
  testTrialsForward: () => void,
  mainTrialsForward: () => void,

  checkAndSaveRoundData: (stage: CorsiTrialsType) => void,
  setAnswer: (answer: number) => void,

  finishGame: (stage: CorsiTrialsType) => void,
  setNextRound: () => void,
  repeatRound: () => void,

  startPracticeTrialsTimer: () => void,
  stopPracticeTrialsTimer: () => void,
  startInstructionsTimer: () => void,
  stopInstructionsTimer: () => void,
  startTrialTimer: () => void,
};

const blocks = [
  { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 },
  { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 },
];

const sequences = [
  [1, 8, 2, 7, 6, 3, 5, 9, 4], [7, 1, 9, 2, 5, 3, 8, 4, 6], [3, 6, 1, 9, 4, 7, 5, 2, 8],
  [7, 2, 5, 4, 6, 1, 3, 8, 9], [7, 9, 1, 5, 3, 2, 8, 4, 6], [5, 7, 1, 4, 2, 8, 9, 3, 6],
  [5, 9, 2, 7, 8, 1, 6, 3, 4], [6, 4, 9, 5, 1, 8, 3, 2, 7], [5, 8, 1, 4, 7, 3, 9, 6, 2],
  [5, 8, 6, 7, 2, 4, 9, 1, 3], [8, 7, 5, 2, 4, 1, 3, 9, 6], [7, 2, 4, 3, 1, 8, 9, 6, 5],
  [7, 6, 4, 9, 5, 3, 2, 1, 8], [6, 8, 7, 1, 3, 5, 4, 2, 9], [5, 1, 6, 8, 9, 7, 4, 2, 3],
  [4, 5, 3, 1, 9, 6, 7, 8, 2], [2, 3, 7, 6, 8, 5, 4, 9, 1], [4, 1, 5, 7, 9, 2, 3, 6, 8],
  [1, 3, 5, 7, 4, 9, 8, 6, 2], [2, 6, 8, 3, 5, 7, 9, 4, 1], [7, 1, 5, 8, 3, 4, 6, 9, 2],
  [8, 9, 5, 7, 3, 6, 1, 2, 4], [2, 9, 4, 5, 3, 8, 1, 6, 7], [3, 2, 9, 4, 8, 1, 5, 7, 6],
  [9, 6, 5, 7, 8, 3, 2, 4, 1], [3, 4, 8, 7, 9, 1, 2, 6, 5], [1, 7, 3, 6, 2, 5, 9, 4, 8],
  [6, 1, 2, 9, 4, 8, 7, 3, 5], [3, 5, 8, 4, 9, 1, 2, 6, 7], [4, 9, 6, 2, 1, 3, 5, 7, 8],
  [1, 6, 7, 8, 2, 4, 9, 3, 5], [9, 2, 1, 6, 8, 3, 5, 4, 7], [9, 3, 5, 8, 4, 7, 1, 6, 2],
  [8, 5, 1, 7, 6, 3, 4, 9, 2], [6, 7, 1, 9, 2, 3, 4, 8, 5], [3, 2, 1, 8, 9, 5, 7, 4, 6],
  [7, 6, 9, 5, 4, 1, 2, 3, 8], [6, 9, 7, 1, 5, 8, 2, 3, 4], [9, 3, 5, 1, 7, 8, 4, 6, 2],
  [2, 6, 8, 5, 9, 1, 4, 7, 3], [4, 8, 3, 5, 7, 2, 9, 1, 6], [6, 4, 8, 1, 3, 2, 7, 5, 9],
  [7, 2, 9, 4, 8, 6, 3, 1, 5], [1, 4, 5, 2, 9, 6, 7, 8, 3], [3, 1, 8, 2, 7, 5, 9, 4, 6],
  [5, 9, 4, 2, 1, 7, 8, 6, 3], [5, 7, 9, 8, 2, 4, 1, 6, 3], [9, 8, 3, 7, 4, 6, 1, 2, 5],
  [6, 2, 5, 1, 7, 8, 3, 9, 4], [5, 7, 2, 4, 6, 1, 9, 3, 8], [8, 9, 7, 4, 1, 6, 5, 3, 2],
  [2, 4, 8, 5, 6, 7, 1, 9, 3], [3, 2, 6, 1, 5, 8, 9, 7, 4], [4, 8, 5, 7, 1, 2, 6, 9, 3],
  [7, 5, 8, 3, 9, 6, 2, 4, 1], [9, 5, 8, 7, 1, 6, 3, 4, 2], [4, 1, 5, 7, 3, 8, 6, 2, 9],
  [2, 1, 3, 7, 8, 6, 5, 9, 4], [8, 6, 2, 4, 7, 9, 1, 5, 3], [9, 1, 2, 3, 8, 7, 6, 5, 4],
  [4, 1, 6, 9, 3, 2, 7, 5, 8], [1, 4, 6, 9, 3, 7, 2, 5, 8], [2, 6, 3, 8, 4, 5, 7, 9, 1],
  [9, 3, 4, 1, 6, 5, 7, 2, 8], [1, 9, 4, 7, 6, 3, 5, 8, 2], [5, 8, 2, 4, 6, 3, 1, 7, 9],
  [1, 6, 9, 5, 7, 3, 8, 2, 4], [3, 7, 4, 1, 5, 6, 9, 8, 2], [1, 9, 2, 6, 4, 5, 3, 7, 8],
  [2, 8, 1, 4, 5, 6, 3, 9, 7], [6, 9, 2, 7, 5, 4, 8, 3, 1], [5, 4, 1, 3, 7, 9, 8, 2, 6],
  [9, 6, 1, 3, 5, 2, 4, 8, 7], [1, 2, 4, 5, 8, 7, 6, 9, 3], [7, 4, 5, 9, 2, 6, 8, 1, 3],
  [5, 1, 6, 2, 3, 9, 7, 8, 4], [4, 9, 3, 7, 8, 5, 2, 6, 1], [6, 1, 8, 3, 9, 7, 5, 4, 2],
  [8, 5, 9, 6, 7, 3, 1, 4, 2], [9, 2, 5, 1, 7, 3, 6, 4, 8], [5, 3, 4, 6, 2, 8, 9, 7, 1],
  [8, 1, 6, 4, 9, 2, 5, 7, 3], [1, 4, 6, 7, 9, 5, 2, 3, 8], [7, 1, 8, 4, 3, 5, 2, 9, 6],
  [2, 3, 8, 5, 1, 9, 7, 4, 6], [7, 6, 2, 5, 8, 3, 1, 4, 9], [5, 2, 4, 8, 7, 6, 1, 3, 9],
  [4, 2, 5, 3, 8, 7, 6, 9, 1], [3, 4, 6, 9, 5, 2, 7, 1, 8], [9, 3, 6, 5, 1, 4, 7, 8, 2],
  [1, 2, 6, 4, 9, 3, 7, 8, 5], [1, 4, 7, 3, 2, 8, 5, 9, 6], [7, 6, 4, 8, 9, 5, 2, 3, 1],
  [3, 6, 9, 7, 8, 1, 4, 2, 5], [6, 7, 3, 4, 8, 1, 5, 9, 2], [8, 4, 2, 9, 5, 3, 1, 6, 7],
  [7, 9, 6, 1, 3, 5, 2, 8, 4], [5, 8, 3, 2, 9, 1, 7, 6, 4], [9, 4, 5, 2, 6, 3, 8, 1, 7],
  [1, 9, 6, 3, 8, 7, 2, 5, 4], [9, 3, 7, 6, 4, 2, 8, 5, 1], [6, 3, 1, 8, 4, 5, 7, 2, 9],
  [5, 7, 2, 6, 4, 8, 3, 9, 1], [7, 6, 1, 3, 4, 9, 2, 8, 5], [3, 4, 5, 9, 1, 7, 8, 6, 2],
  [8, 3, 6, 7, 5, 9, 2, 4, 1], [6, 3, 4, 1, 2, 7, 5, 9, 8], [9, 3, 2, 4, 5, 8, 6, 1, 7],
  [4, 1, 3, 7, 8, 5, 9, 6, 2], [8, 6, 9, 1, 7, 2, 3, 4, 5], [2, 6, 1, 9, 4, 8, 3, 7, 5],
  [1, 8, 9, 3, 7, 6, 5, 4, 2], [4, 7, 3, 6, 1, 9, 8, 2, 5], [9, 3, 4, 6, 8, 1, 5, 7, 2],
  [6, 7, 4, 2, 9, 8, 3, 5, 1], [6, 2, 3, 9, 8, 7, 1, 5, 4], [8, 4, 1, 2, 7, 6, 9, 5, 3],
  [5, 2, 9, 7, 1, 3, 6, 8, 4], [2, 1, 9, 6, 8, 3, 5, 7, 4], [8, 5, 1, 7, 3, 2, 6, 9, 4],
  [3, 8, 5, 2, 4, 1, 6, 9, 7], [7, 6, 1, 3, 5, 9, 2, 4, 8], [5, 7, 2, 3, 6, 4, 8, 1, 9],
  [1, 8, 3, 7, 5, 2, 6, 9, 4], [7, 4, 9, 2, 3, 5, 6, 8, 1], [5, 9, 2, 3, 7, 4, 6, 1, 8],
  [6, 7, 3, 1, 8, 9, 2, 4, 5], [5, 3, 7, 1, 2, 4, 9, 6, 8], [8, 3, 2, 6, 5, 7, 4, 1, 9],
  [8, 5, 4, 2, 1, 6, 3, 9, 7], [2, 5, 3, 9, 6, 8, 4, 7, 1], [4, 5, 6, 8, 1, 2, 3, 9, 7],
  [2, 9, 3, 1, 4, 7, 6, 8, 5], [9, 3, 4, 1, 6, 5, 8, 7, 2], [1, 3, 8, 2, 4, 6, 7, 5, 9],
  [2, 8, 3, 7, 9, 6, 1, 4, 5], [2, 8, 4, 6, 7, 9, 5, 1, 3], [2, 4, 7, 9, 3, 1, 8, 5, 6],
  [4, 5, 3, 9, 2, 1, 7, 6, 8], [1, 7, 2, 8, 9, 4, 6, 5, 3], [5, 9, 1, 8, 7, 3, 6, 4, 2],
  [1, 7, 8, 3, 4, 5, 6, 9, 2], [5, 1, 2, 6, 7, 8, 4, 3, 9], [7, 9, 6, 5, 8, 4, 2, 1, 3],
  [6, 8, 9, 2, 1, 7, 4, 5, 3], [6, 5, 3, 8, 2, 4, 1, 7, 9], [7, 5, 1, 6, 9, 2, 8, 3, 4],
  [6, 4, 3, 8, 2, 5, 9, 1, 7], [2, 4, 8, 5, 3, 9, 6, 1, 7], [4, 9, 2, 6, 5, 7, 8, 3, 1],
  [8, 7, 9, 2, 3, 4, 1, 5, 6], [9, 4, 6, 5, 8, 1, 3, 2, 7], [8, 7, 1, 2, 4, 6, 9, 5, 3],
  [7, 1, 2, 3, 6, 9, 4, 5, 8], [4, 8, 9, 2, 7, 5, 3, 1, 6], [8, 6, 5, 2, 4, 7, 1, 3, 9],
  [6, 8, 1, 3, 7, 4, 5, 9, 2], [3, 8, 2, 6, 4, 9, 1, 7, 5], [2, 3, 7, 9, 5, 8, 1, 4, 6],
  [5, 8, 1, 3, 9, 4, 2, 6, 7], [5, 4, 1, 3, 2, 7, 9, 8, 6], [3, 1, 5, 4, 6, 7, 9, 8, 2],
  [2, 8, 7, 6, 3, 1, 4, 5, 9], [4, 2, 1, 8, 7, 9, 6, 3, 5], [9, 2, 6, 7, 1, 5, 4, 3, 8],
  [1, 3, 8, 6, 2, 9, 5, 4, 7], [9, 8, 6, 2, 3, 4, 7, 1, 5], [9, 7, 6, 3, 2, 4, 1, 8, 5],
  [9, 6, 5, 8, 7, 4, 2, 3, 1], [3, 6, 9, 7, 5, 2, 4, 8, 1], [5, 6, 2, 9, 8, 3, 4, 1, 7],
  [9, 8, 2, 4, 6, 5, 3, 7, 1], [7, 2, 5, 4, 3, 8, 6, 1, 9], [6, 2, 9, 8, 4, 1, 5, 3, 7],
  [3, 1, 7, 5, 2, 8, 6, 9, 4], [7, 2, 6, 1, 8, 9, 4, 5, 3], [4, 8, 1, 7, 3, 9, 2, 6, 5],
  [7, 3, 4, 8, 5, 6, 9, 2, 1], [7, 8, 9, 4, 6, 5, 1, 3, 2], [6, 2, 1, 8, 4, 3, 5, 7, 9],
  [3, 8, 6, 5, 1, 7, 9, 4, 2], [2, 3, 4, 9, 6, 8, 5, 7, 1], [9, 4, 6, 1, 2, 3, 7, 8, 5],
  [5, 8, 4, 3, 2, 9, 6, 7, 1], [5, 2, 6, 3, 8, 4, 1, 9, 7], [8, 5, 7, 9, 2, 6, 1, 3, 4],
  [5, 1, 6, 7, 8, 3, 4, 2, 9], [2, 6, 8, 4, 7, 9, 5, 1, 3], [3, 5, 7, 6, 8, 2, 9, 1, 4],
  [8, 9, 1, 3, 4, 2, 5, 7, 6], [7, 3, 1, 8, 5, 9, 2, 4, 6], [7, 2, 6, 1, 5, 8, 4, 9, 3],
  [3, 7, 8, 1, 2, 5, 6, 4, 9], [4, 6, 3, 2, 8, 1, 5, 9, 7], [5, 3, 4, 2, 6, 8, 1, 7, 9],
  [4, 6, 1, 8, 2, 9, 7, 3, 5], [6, 5, 4, 3, 1, 7, 9, 8, 2], [8, 2, 3, 7, 5, 6, 1, 4, 9],
  [9, 4, 3, 1, 6, 8, 7, 5, 2], [4, 3, 5, 9, 8, 6, 7, 2, 1], [8, 3, 1, 6, 9, 2, 5, 7, 4],
  [5, 4, 7, 9, 3, 2, 1, 8, 6], [4, 7, 2, 1, 6, 9, 3, 8, 5], [1, 7, 4, 3, 8, 9, 6, 5, 2],
  [8, 1, 2, 7, 6, 3, 4, 9, 5], [6, 2, 4, 9, 8, 1, 5, 7, 3], [5, 4, 7, 1, 3, 2, 9, 8, 6],
  [4, 1, 7, 3, 2, 9, 5, 8, 6], [1, 8, 6, 9, 3, 5, 4, 7, 2], [9, 4, 3, 7, 2, 8, 6, 5, 1],
  [6, 9, 8, 4, 5, 1, 3, 7, 2], [9, 8, 3, 5, 1, 4, 7, 2, 6], [7, 4, 2, 9, 3, 8, 5, 6, 1],
  [6, 9, 4, 8, 3, 5, 2, 1, 7], [9, 2, 6, 4, 7, 1, 3, 5, 8], [9, 4, 5, 8, 6, 1, 7, 3, 2],
  [9, 8, 5, 6, 1, 7, 4, 2, 3], [3, 9, 4, 6, 8, 2, 1, 5, 7], [4, 3, 7, 5, 2, 8, 1, 6, 9],
  [6, 3, 8, 2, 9, 1, 7, 5, 4], [1, 6, 5, 3, 2, 4, 8, 9, 7], [2, 8, 5, 3, 6, 4, 7, 9, 1],
  [2, 5, 6, 4, 8, 1, 3, 9, 7], [1, 9, 5, 4, 3, 6, 7, 8, 2], [9, 7, 3, 6, 1, 2, 8, 5, 4],
  [3, 5, 1, 6, 2, 4, 8, 9, 7], [9, 1, 7, 6, 3, 2, 5, 8, 4], [5, 1, 6, 3, 9, 4, 2, 7, 8],
  [3, 2, 5, 7, 1, 9, 6, 8, 4], [8, 7, 1, 5, 4, 6, 2, 9, 3], [2, 8, 4, 3, 1, 6, 5, 7, 9],
  [5, 1, 3, 2, 8, 9, 7, 6, 4], [6, 5, 2, 3, 1, 8, 4, 7, 9], [6, 3, 1, 4, 8, 9, 5, 2, 7],
  [6, 3, 1, 8, 5, 4, 9, 2, 7], [6, 3, 2, 5, 8, 4, 1, 7, 9], [3, 9, 5, 8, 7, 6, 1, 2, 4],
  [8, 3, 6, 1, 2, 9, 5, 4, 7], [3, 2, 4, 9, 1, 7, 5, 6, 8], [2, 1, 8, 9, 4, 6, 7, 3, 5],
  [6, 8, 3, 2, 4, 7, 1, 5, 9], [6, 4, 8, 9, 2, 1, 3, 7, 5], [1, 6, 4, 9, 7, 8, 3, 2, 5],
  [9, 3, 8, 4, 1, 6, 7, 5, 2], [4, 3, 8, 9, 2, 6, 5, 7, 1], [6, 4, 8, 2, 1, 7, 5, 9, 3],
  [5, 1, 3, 9, 2, 7, 6, 4, 8], [6, 4, 1, 9, 8, 5, 2, 7, 3], [5, 3, 8, 1, 2, 9, 4, 7, 6],
  [6, 4, 2, 3, 9, 5, 8, 7, 1], [7, 4, 3, 2, 1, 8, 9, 5, 6], [8, 7, 3, 2, 9, 4, 1, 5, 6],
  [2, 9, 8, 3, 6, 4, 7, 5, 1], [4, 3, 5, 1, 9, 2, 6, 7, 8], [1, 5, 7, 2, 4, 3, 9, 8, 6],
  [7, 9, 3, 4, 1, 6, 2, 8, 5], [1, 6, 3, 8, 7, 5, 2, 4, 9], [7, 1, 9, 8, 4, 6, 2, 5, 3],
  [3, 2, 6, 7, 1, 5, 4, 8, 9], [6, 5, 9, 8, 3, 4, 7, 1, 2], [6, 7, 8, 3, 4, 9, 5, 2, 1],
  [1, 3, 7, 4, 2, 9, 5, 6, 8], [3, 6, 5, 2, 9, 4, 8, 1, 7], [2, 6, 3, 5, 1, 8, 7, 4, 9],
  [5, 7, 4, 8, 3, 2, 6, 1, 9], [4, 9, 1, 5, 7, 6, 3, 2, 8], [4, 8, 5, 9, 1, 7, 2, 6, 3],
  [6, 5, 8, 3, 9, 2, 7, 1, 4], [1, 5, 2, 9, 7, 6, 3, 8, 4], [2, 3, 5, 4, 8, 9, 7, 6, 1],
  [3, 1, 7, 8, 9, 4, 5, 6, 2], [9, 7, 6, 2, 1, 3, 8, 5, 4], [5, 1, 7, 2, 4, 3, 8, 6, 9],
  [2, 9, 5, 1, 4, 7, 3, 6, 8], [1, 3, 8, 7, 5, 2, 6, 9, 4], [2, 1, 4, 8, 7, 3, 9, 6, 5],
  [8, 9, 4, 3, 2, 5, 7, 1, 6], [8, 7, 9, 5, 2, 6, 4, 3, 1], [4, 2, 1, 7, 3, 9, 6, 8, 5],
  [4, 9, 5, 8, 3, 7, 2, 1, 6], [3, 9, 8, 5, 6, 1, 7, 4, 2], [1, 2, 5, 6, 4, 7, 9, 8, 3],
  [9, 3, 5, 8, 2, 1, 4, 7, 6], [6, 2, 5, 3, 9, 1, 7, 4, 8], [9, 1, 6, 7, 2, 8, 5, 4, 3],
  [4, 9, 3, 8, 7, 1, 2, 5, 6], [7, 3, 2, 1, 4, 6, 8, 5, 9], [9, 6, 4, 2, 1, 5, 7, 8, 3],
  [4, 5, 1, 8, 2, 3, 7, 9, 6], [5, 9, 1, 8, 2, 4, 6, 7, 3], [7, 2, 6, 5, 1, 3, 4, 9, 8],
  [8, 3, 9, 7, 6, 2, 1, 5, 4], [5, 4, 2, 3, 8, 7, 9, 1, 6], [8, 9, 1, 2, 3, 6, 4, 5, 7],
  [7, 1, 5, 2, 8, 4, 9, 3, 6], [7, 8, 9, 1, 4, 3, 6, 5, 2], [7, 1, 8, 4, 5, 3, 9, 6, 2],
  [3, 6, 2, 7, 9, 8, 4, 5, 1], [7, 6, 9, 4, 8, 5, 3, 1, 2], [9, 5, 2, 3, 8, 7, 6, 1, 4],
  [1, 9, 7, 2, 8, 5, 3, 4, 6], [2, 1, 5, 3, 7, 8, 6, 4, 9], [3, 9, 5, 2, 1, 7, 8, 6, 4],
  [7, 8, 3, 1, 5, 2, 4, 9, 6], [1, 3, 6, 2, 9, 7, 8, 4, 5], [2, 3, 7, 6, 9, 8, 4, 5, 1],
  [7, 5, 1, 4, 9, 6, 8, 3, 2], [8, 9, 7, 1, 2, 3, 4, 5, 6], [8, 2, 4, 3, 6, 7, 1, 9, 5],
  [3, 9, 6, 4, 1, 2, 8, 7, 5], [8, 3, 9, 6, 4, 7, 5, 1, 2], [3, 7, 5, 6, 9, 4, 1, 2, 8],
  [3, 6, 2, 8, 9, 7, 5, 4, 1], [1, 2, 6, 7, 3, 4, 8, 9, 5], [8, 5, 2, 9, 4, 7, 1, 6, 3],
  [2, 4, 7, 3, 9, 5, 8, 6, 1], [9, 1, 8, 2, 3, 7, 4, 6, 5], [8, 3, 1, 5, 7, 2, 4, 6, 9],
  [1, 4, 5, 7, 6, 9, 8, 2, 3], [6, 8, 7, 9, 2, 5, 4, 1, 3], [4, 9, 5, 6, 2, 8, 7, 3, 1],
  [5, 2, 7, 1, 8, 9, 6, 4, 3], [9, 4, 5, 7, 3, 8, 6, 2, 1], [5, 3, 6, 2, 8, 7, 1, 4, 9],
  [5, 2, 9, 4, 8, 1, 6, 3, 7], [5, 1, 6, 8, 7, 3, 4, 9, 2], [9, 5, 6, 3, 7, 8, 4, 2, 1],
  [3, 7, 4, 9, 8, 1, 5, 2, 6], [9, 8, 5, 2, 4, 3, 1, 6, 7], [3, 8, 2, 7, 9, 1, 4, 6, 5],
  [5, 2, 4, 6, 7, 3, 9, 8, 1], [6, 3, 7, 9, 4, 5, 2, 8, 1], [6, 1, 8, 7, 2, 5, 9, 4, 3],
  [8, 9, 7, 6, 4, 3, 5, 2, 1], [3, 6, 9, 2, 4, 5, 8, 7, 1], [6, 8, 4, 2, 7, 9, 5, 1, 3],
  [7, 2, 4, 5, 9, 1, 6, 3, 8], [8, 5, 4, 7, 9, 3, 6, 1, 2], [4, 2, 5, 6, 8, 9, 7, 1, 3],
  [1, 4, 6, 2, 7, 5, 8, 9, 3], [2, 5, 6, 8, 1, 4, 3, 9, 7], [8, 5, 1, 7, 3, 4, 2, 9, 6],
  [2, 6, 7, 1, 3, 8, 5, 9, 4], [6, 8, 7, 4, 9, 3, 2, 5, 1], [7, 9, 6, 8, 4, 1, 3, 5, 2],
  [5, 4, 7, 8, 9, 6, 3, 1, 2], [7, 9, 1, 2, 3, 8, 6, 5, 4], [2, 7, 1, 3, 8, 5, 6, 4, 9],
  [1, 7, 6, 9, 5, 3, 2, 4, 8], [5, 2, 1, 7, 8, 4, 6, 9, 3], [8, 3, 6, 5, 1, 9, 7, 2, 4],
  [2, 1, 7, 5, 3, 6, 9, 4, 8], [7, 1, 5, 8, 6, 4, 3, 9, 2], [7, 4, 6, 2, 1, 3, 9, 5, 8],
  [9, 7, 6, 1, 3, 4, 5, 8, 2], [9, 3, 8, 6, 4, 1, 2, 7, 5], [4, 5, 9, 2, 3, 7, 6, 8, 1],
  [3, 1, 6, 7, 5, 8, 2, 9, 4], [4, 1, 9, 6, 5, 3, 7, 2, 8], [2, 3, 7, 8, 1, 9, 6, 4, 5],
  [7, 8, 6, 1, 9, 2, 3, 4, 5], [3, 7, 4, 8, 1, 2, 5, 9, 6], [4, 7, 2, 9, 3, 5, 1, 6, 8],
  [3, 7, 5, 1, 4, 6, 9, 2, 8], [6, 1, 8, 2, 9, 3, 7, 5, 4], [7, 4, 8, 5, 1, 6, 2, 3, 9],
  [9, 5, 4, 7, 2, 3, 8, 6, 1], [4, 8, 3, 9, 5, 7, 6, 2, 1], [4, 7, 6, 5, 2, 3, 8, 9, 1],
  [2, 4, 7, 9, 6, 3, 8, 5, 1], [5, 3, 6, 2, 7, 9, 1, 8, 4], [5, 3, 2, 7, 4, 9, 6, 1, 8],
  [7, 4, 2, 8, 1, 9, 6, 5, 3], [8, 1, 3, 5, 6, 4, 9, 7, 2], [9, 4, 8, 3, 1, 2, 5, 6, 7],
  [9, 6, 5, 8, 3, 4, 1, 2, 7], [5, 1, 9, 8, 2, 6, 7, 3, 4], [1, 4, 7, 2, 8, 6, 5, 3, 9],
  [8, 3, 7, 5, 9, 2, 4, 1, 6], [8, 1, 4, 6, 3, 5, 9, 2, 7], [7, 6, 5, 9, 1, 3, 8, 2, 4],
  [7, 1, 3, 5, 8, 4, 9, 6, 2], [6, 9, 2, 8, 3, 5, 4, 1, 7], [5, 1, 7, 3, 9, 8, 2, 4, 6],
  [8, 7, 9, 6, 4, 3, 5, 2, 1], [2, 5, 3, 6, 1, 9, 8, 4, 7], [4, 7, 8, 5, 9, 2, 1, 3, 6],
  [2, 6, 5, 9, 8, 1, 4, 3, 7], [8, 6, 4, 9, 7, 2, 5, 1, 3], [1, 3, 7, 9, 5, 8, 6, 4, 2],
  [7, 2, 5, 1, 3, 6, 4, 9, 8], [2, 3, 9, 8, 5, 1, 6, 4, 7], [1, 8, 6, 2, 4, 5, 9, 7, 3],
  [9, 5, 3, 4, 6, 8, 1, 2, 7], [5, 7, 6, 9, 8, 3, 1, 4, 2], [1, 7, 6, 3, 2, 5, 9, 8, 4],
  [5, 6, 7, 4, 9, 8, 2, 3, 1], [9, 8, 3, 4, 5, 7, 2, 6, 1], [5, 3, 4, 2, 9, 7, 6, 1, 8],
  [6, 4, 3, 2, 1, 7, 8, 5, 9], [5, 3, 6, 1, 4, 9, 7, 8, 2], [4, 7, 8, 3, 6, 2, 9, 5, 1],
  [9, 3, 2, 5, 4, 6, 8, 7, 1], [3, 8, 7, 5, 4, 6, 9, 1, 2], [3, 1, 9, 8, 2, 4, 6, 7, 5],
  [7, 1, 4, 6, 3, 9, 8, 5, 2], [9, 6, 1, 4, 2, 7, 5, 8, 3], [9, 1, 3, 5, 7, 8, 2, 4, 6],
  [9, 6, 8, 7, 5, 2, 1, 4, 3], [1, 7, 8, 6, 4, 2, 3, 5, 9], [4, 2, 3, 6, 7, 5, 1, 8, 9],
  [9, 1, 8, 3, 4, 2, 6, 7, 5], [5, 1, 2, 8, 4, 9, 3, 6, 7], [6, 8, 9, 2, 5, 7, 3, 1, 4],
  [3, 7, 9, 4, 6, 8, 2, 5, 1], [3, 5, 2, 4, 6, 8, 7, 9, 1], [6, 8, 3, 4, 7, 5, 2, 1, 9],
  [1, 3, 5, 4, 8, 7, 9, 6, 2], [8, 7, 6, 2, 1, 9, 3, 4, 5], [1, 2, 8, 3, 7, 9, 4, 5, 6],
  [9, 4, 5, 8, 2, 1, 3, 6, 7], [9, 2, 8, 4, 6, 1, 5, 7, 3], [6, 3, 1, 7, 8, 5, 9, 2, 4],
  [2, 6, 3, 7, 9, 4, 5, 8, 1], [7, 3, 8, 1, 5, 6, 4, 9, 2], [3, 9, 5, 1, 2, 7, 8, 6, 4],
  [5, 1, 2, 4, 8, 7, 9, 6, 3], [8, 7, 5, 9, 3, 6, 2, 4, 1], [6, 3, 9, 1, 4, 2, 7, 5, 8],
  [2, 4, 5, 9, 1, 8, 6, 3, 7], [1, 8, 6, 7, 5, 2, 4, 9, 3], [5, 3, 4, 7, 8, 6, 2, 1, 9],
  [2, 5, 8, 9, 6, 4, 3, 7, 1], [5, 8, 6, 3, 7, 1, 9, 2, 4], [1, 9, 6, 3, 8, 7, 2, 5, 4],
  [7, 5, 8, 3, 6, 1, 2, 4, 9], [7, 9, 4, 2, 1, 5, 3, 8, 6], [9, 3, 8, 4, 5, 1, 7, 2, 6],
  [2, 8, 3, 6, 5, 4, 7, 1, 9], [8, 4, 6, 3, 2, 5, 1, 9, 7], [5, 3, 9, 1, 7, 2, 6, 8, 4],
  [4, 3, 6, 1, 2, 8, 5, 7, 9], [7, 2, 3, 6, 4, 8, 5, 9, 1], [4, 6, 3, 5, 7, 8, 1, 9, 2],
  [4, 7, 8, 1, 9, 2, 6, 3, 5], [7, 2, 6, 5, 9, 8, 1, 3, 4], [1, 4, 8, 7, 3, 9, 6, 5, 2],
  [3, 8, 1, 6, 5, 2, 7, 9, 4], [1, 8, 5, 4, 9, 3, 6, 2, 7], [2, 1, 9, 5, 3, 7, 4, 8, 6],
  [2, 9, 4, 7, 8, 3, 5, 6, 1], [2, 9, 3, 4, 7, 8, 1, 6, 5], [8, 3, 2, 4, 7, 5, 1, 6, 9],
  [6, 4, 2, 1, 9, 3, 8, 7, 5], [7, 4, 5, 2, 9, 6, 8, 1, 3], [1, 7, 9, 8, 5, 4, 3, 2, 6],
  [1, 7, 4, 3, 8, 9, 6, 5, 2], [3, 9, 1, 8, 6, 2, 5, 4, 7], [8, 6, 9, 1, 7, 3, 2, 4, 5],
  [5, 8, 4, 3, 9, 1, 6, 7, 2], [9, 4, 8, 3, 2, 6, 5, 7, 1], [1, 2, 7, 5, 6, 8, 3, 9, 4],
  [6, 4, 5, 8, 9, 7, 3, 2, 1], [4, 6, 3, 9, 2, 1, 8, 5, 7], [5, 9, 8, 4, 3, 1, 6, 2, 7],
  [7, 5, 3, 9, 1, 8, 4, 2, 6], [9, 7, 4, 5, 3, 1, 8, 6, 2], [6, 7, 1, 8, 2, 9, 4, 3, 5],
  [9, 5, 4, 1, 2, 3, 7, 6, 8], [2, 8, 3, 5, 6, 1, 4, 7, 9], [1, 2, 8, 4, 6, 9, 3, 7, 5],
  [5, 3, 6, 1, 4, 8, 9, 2, 7], [6, 1, 7, 9, 3, 4, 5, 2, 8], [8, 3, 5, 2, 6, 4, 1, 9, 7],
  [1, 6, 9, 8, 5, 4, 3, 2, 7], [5, 1, 6, 9, 3, 2, 7, 4, 8], [9, 6, 7, 1, 8, 4, 3, 5, 2],
  [5, 6, 3, 1, 8, 2, 9, 7, 4], [4, 8, 5, 9, 7, 2, 3, 1, 6], [2, 3, 4, 8, 5, 9, 1, 7, 6],
  [1, 5, 4, 9, 2, 3, 7, 8, 6], [6, 5, 8, 9, 2, 4, 1, 7, 3], [1, 7, 9, 6, 3, 2, 8, 4, 5],
  [2, 9, 1, 8, 4, 5, 3, 7, 6], [2, 5, 1, 9, 8, 4, 7, 3, 6], [7, 4, 2, 6, 1, 8, 9, 5, 3],
  [4, 9, 1, 6, 5, 3, 8, 7, 2], [5, 4, 6, 1, 3, 7, 2, 9, 8], [1, 4, 2, 8, 6, 3, 9, 7, 5],
  [2, 1, 5, 6, 3, 4, 9, 7, 8], [7, 6, 8, 5, 9, 4, 3, 2, 1], [6, 9, 4, 5, 1, 7, 8, 2, 3],
  [6, 7, 2, 5, 9, 8, 1, 4, 3], [5, 6, 2, 1, 4, 8, 3, 9, 7], [8, 5, 3, 7, 9, 4, 6, 2, 1],
  [4, 9, 7, 6, 8, 5, 3, 2, 1], [6, 7, 4, 1, 5, 2, 8, 9, 3], [1, 2, 3, 8, 7, 5, 6, 9, 4],
  [9, 5, 8, 3, 1, 7, 6, 2, 4], [8, 2, 4, 5, 6, 7, 3, 9, 1],
];

const useCorsiGameState = create<CoriGameState>((set, get) => ({
  corsiBlocks: blocks,
  tableRow: getRandomNumWithoutRepeat(0, 499, []),
  usedSequences: [],
  currentRoundStage: CorsiRoundStage.DEFAULT,
  currentRound: 1,
  numberBlocksToHighLight: 2,
  errors: 0,
  highestRememberedSequence: 0,
  roundAnswers: [],
  isFinishedGameTrials: false,
  isGameOver: false,
  isSuccessRound: false,
  gameResult: {
    corsiGameScores: [],
    corsiMetadata: {
      firstPracticeFails: 0,
      timeSpentOnPracticeTrials: 0,
      corsiInstructionTime: 0,
    },
  },

  isPracticeMode: false,
  startGame: (isPracticeMode) => {
    set({
      isPracticeMode,
      currentRoundStage: CorsiRoundStage.ANIMATING,
    });
  },

  trialTime: {
    start: 0,
    firstClick: 0,
    lastClick: 0,
  },
  practiceTrialStartTime: 0,
  instructionsStartTime: 0,

  getCurrentSequence: () => (sequences[get().tableRow]),
  getLongestSequence: () => {
    let longest: Array<number> = [];
    sequences.forEach(seq => {
      if (seq.length > longest.length) {
        longest = seq;
      }
    });
    return longest;
  },
  roundStageForward: () => {
    switch (get().currentRoundStage) {
      case CorsiRoundStage.DEFAULT:
        set({
          currentRoundStage: CorsiRoundStage.ANIMATING,
          isFinishedGameTrials: false,
        });
        break;
      case CorsiRoundStage.ANIMATING:
        set({ currentRoundStage: CorsiRoundStage.GO });
        break;
      case CorsiRoundStage.GO:
        set({ currentRoundStage: CorsiRoundStage.USER_CHOICES });
        break;
      case CorsiRoundStage.USER_CHOICES:
        set({
          currentRoundStage: CorsiRoundStage.FEEDBACK,
        });
        break;
      case CorsiRoundStage.FEEDBACK:
        set({
          currentRoundStage: get().isFinishedGameTrials
            ? CorsiRoundStage.DEFAULT : CorsiRoundStage.ANIMATING,
        });
        break;
    }
  },
  testTrialsForward: () => {
    const {
      currentRound,
      checkAndSaveRoundData,
      roundStageForward,
      finishGame,
      setNextRound,
      stopPracticeTrialsTimer,
      roundAnswers,
      numberBlocksToHighLight,
      getCurrentSequence,
    } = get();

    const isCorrect = (!!roundAnswers.length) && (roundAnswers.length === numberBlocksToHighLight)
      && (roundAnswers.every((el, index) => (el === getCurrentSequence()[index])));

    // repeat first test trial if failed
    if (!isCorrect && currentRound === 1) {
      set({
        roundAnswers: [],
        gameResult: {
          ...get().gameResult,
          corsiMetadata: {
            ...get().gameResult.corsiMetadata,
            firstPracticeFails: get().gameResult.corsiMetadata.firstPracticeFails + 1,
          },
        },
      });
    } else {
      checkAndSaveRoundData(CorsiTrialsType.PRACTICE);
      if (currentRound < 2) {
        setNextRound();
      } else {
        stopPracticeTrialsTimer();
        finishGame(CorsiTrialsType.PRACTICE);
      }
    }
    roundStageForward();
  },
  mainTrialsForward: () => {
    const {
      checkAndSaveRoundData,
      roundStageForward,
      finishGame,
      setNextRound,
      repeatRound,
      numberBlocksToHighLight,
    } = get();

    checkAndSaveRoundData(CorsiTrialsType.MAIN);
    if (get().errors === 2 || numberBlocksToHighLight === 9) {
      finishGame(CorsiTrialsType.MAIN);
    } else if (get().errors === 1) {
      repeatRound();
    } else {
      setNextRound();
    }
    roundStageForward();
  },

  checkAndSaveRoundData: () => {
    const {
      roundAnswers,
      getCurrentSequence,
      gameResult,
      numberBlocksToHighLight,
      tableRow,
      errors,
      highestRememberedSequence,
      usedSequences,
      trialTime: { firstClick, lastClick, start },
    } = get();

    const isCorrect = (!!roundAnswers.length) && (roundAnswers.length === numberBlocksToHighLight)
      && (roundAnswers.every((el, index) => (el === getCurrentSequence()[index])));
    const clicksUntilFail = roundAnswers.findIndex((el, i) => (el !== getCurrentSequence()[i]));
    set({
      gameResult: {
        ...gameResult,
        corsiGameScores: [...gameResult.corsiGameScores, {
          highestCorsiSpan: isCorrect ? roundAnswers.length : highestRememberedSequence,
          numberOfItemsToRemember: numberBlocksToHighLight,
          statusOfTrial: isCorrect ? CorsiTrialStatus.SUCCESS : CorsiTrialStatus.FAIL,
          tableRow,
          fTime: firstClick - start < 0 ? 0 : firstClick - start,
          lTime: lastClick - start < 0 ? 0 : lastClick - start,
          clicks: roundAnswers.length,
          correct: clicksUntilFail < 0 ? roundAnswers.length : clicksUntilFail,
          practice: get().isPracticeMode,
        }],
      },
      isSuccessRound: isCorrect,
      errors: isCorrect ? 0 : errors + 1,
      highestRememberedSequence: isCorrect ? roundAnswers.length : highestRememberedSequence,
      roundAnswers: [],
      usedSequences: [...usedSequences, tableRow],
      tableRow: getRandomNumWithoutRepeat(0, 499, get().usedSequences),
      trialTime: {
        start: 0,
        firstClick: 0,
        lastClick: 0,
      },
    });
  },
  setAnswer: (answer: number) => {
    set({
      trialTime: {
        start: get().trialTime.start,
        firstClick: get().roundAnswers.length ? get().trialTime.firstClick : new Date().getTime(),
        lastClick: new Date().getTime(),
      },
      roundAnswers: [...get().roundAnswers, answer],
    });
  },

  repeatRound: () => {
    set({
      currentRound: get().currentRound + 1,
      numberBlocksToHighLight: get().numberBlocksToHighLight,
      isFinishedGameTrials: false,
    });
  },
  finishGame: (stage: CorsiTrialsType) => {
    set({
      currentRound: 1,
      numberBlocksToHighLight: 2,
      isFinishedGameTrials: true,
      isGameOver: stage === CorsiTrialsType.MAIN,
      errors: 0,
      highestRememberedSequence: 0,
    });
  },
  setNextRound: () => {
    set({
      currentRound: get().currentRound + 1,
      numberBlocksToHighLight: get().numberBlocksToHighLight + 1,
      isFinishedGameTrials: false,
    });
  },

  startPracticeTrialsTimer: () => {
    set({
      practiceTrialStartTime: new Date().getTime(),
    });
  },
  stopPracticeTrialsTimer: () => {
    set({
      gameResult: {
        ...get().gameResult,
        corsiMetadata: {
          ...get().gameResult.corsiMetadata,
          timeSpentOnPracticeTrials: new Date().getTime() - get().practiceTrialStartTime,
        },
      },
    });
  },
  startInstructionsTimer: () => {
    set({
      instructionsStartTime: new Date().getTime(),
    });
  },
  stopInstructionsTimer: () => {
    set({
      gameResult: {
        ...get().gameResult,
        corsiMetadata: {
          ...get().gameResult.corsiMetadata,
          corsiInstructionTime: new Date().getTime() - get().instructionsStartTime,
        },
      },
    });
  },
  startTrialTimer: () => {
    set({
      trialTime: {
        start: new Date().getTime(),
        firstClick: 0,
        lastClick: 0,
      },
    });
  },
}));

export default useCorsiGameState;
