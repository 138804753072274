import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { getRandomNumWithoutRepeat } from 'utils';
import {
  BretResult, BretRoundData,
  BretRoundStage,
  RiskBehaviour,
} from '../types';
import {
  DISPLAY_BOMB_TIME,
  HIDE_PARCEL_INTERVAL_TIME,
  MAIN_TRAILS_NUMBER,
  NUMBER_OF_ALL_PARCELS,
  TOTAL_ROUNDS_NUMBER,
} from './constants';

const reduceNums = (arr: number[]): number => (arr.reduce((prev, next) => prev + next, 0));

type BretState = {
  squares: Array<{ id: number }>,
  parcelWithBomb: number;
  didBombExplode: boolean,
  collectedParcels: number,
  allCollectedParcels: number[],
  bombLocations: number [],
  totalMoney: number[],
  didGameFinish: boolean,

  currentRound: number,
  currentStage: BretRoundStage,
  gameStageForward: () => void,

  startRound: () => void,
  stopRound: () => void,
  checkIfBombExplode: () => void,
  clearData: () => void,
  saveRoundData: () => void,
  calculateRiskBehaviour: (meanParcels: number) => RiskBehaviour,

  isPracticeMode: boolean,
  togglePracticeMode: (isPracticeMode: boolean) => void,

  roundResults: BretRoundData[],
  results: BretResult | null,
  saveFinalData: () => void,

  instructionsStartTime: number,
  startInstructionsTimer: () => void,
  stopInstructionsTimer: () => void,

  startGameTime: Date | null;
  setStartGameTime: () => void,
};

let intervalID: number;

const useBretState = create<BretState>()(devtools((set, get) => ({
  squares: [...Array(NUMBER_OF_ALL_PARCELS)].map((_, i: number) => ({ id: i })),
  collectedParcels: 0,
  parcelWithBomb: getRandomNumWithoutRepeat(3, 25, []),
  didBombExplode: false,
  didGameFinish: false,
  allCollectedParcels: [],
  bombLocations: [],
  totalMoney: [],

  currentRound: 1,
  currentStage: BretRoundStage.DEFAULT,
  gameStageForward: () => {
    switch (get().currentStage) {
      case BretRoundStage.DEFAULT:
        set({ currentStage: BretRoundStage.COLLECTING });
        break;
      case BretRoundStage.COLLECTING:
        if (get().isPracticeMode) {
          set({ currentStage: BretRoundStage.FEEDBACK });
        } else {
          set({ currentStage: BretRoundStage.BOMB_INFO });
          setTimeout(() => {
            get().gameStageForward();
          }, DISPLAY_BOMB_TIME);
        }
        break;
      case BretRoundStage.BOMB_INFO:
        set({ currentStage: BretRoundStage.FEEDBACK });
        break;
      case BretRoundStage.FEEDBACK:
        if (get().isPracticeMode) {
          set({ currentStage: BretRoundStage.DEFAULT, didGameFinish: true });
          break;
        }
        set({ currentStage: BretRoundStage.DEFAULT, didGameFinish: get().currentRound === 5 });
        if (get().currentRound !== TOTAL_ROUNDS_NUMBER) {
          get().clearData();
        } else {
          get().saveFinalData();
        }
    }
  },

  startRound: () => {
    get().gameStageForward();
    set({ collectedParcels: get().collectedParcels + 1 });
    intervalID = window.setInterval(() => {
      if (get().collectedParcels === NUMBER_OF_ALL_PARCELS) {
        get().stopRound();
        return;
      }
      set({ collectedParcels: get().collectedParcels + 1 });
    }, HIDE_PARCEL_INTERVAL_TIME);
  },
  stopRound: () => {
    clearInterval(intervalID);
    get().checkIfBombExplode();
    get().gameStageForward();
    if (!get().isPracticeMode) {
      get().saveRoundData();
    }
  },
  checkIfBombExplode: () => {
    if (get().collectedParcels >= get().parcelWithBomb) {
      set({ didBombExplode: true });
    }
  },
  calculateRiskBehaviour: (meanParcels) => {
    const parcelCount = NUMBER_OF_ALL_PARCELS / 2;
    if (meanParcels > parcelCount) {
      return RiskBehaviour.RISK_SEEKER;
    }
    if (meanParcels === parcelCount) {
      return RiskBehaviour.RISK_NEUTRAL;
    }
    return RiskBehaviour.RISK_AVERSE;
  },

  roundResults: [],
  saveRoundData: () => {
    const {
      totalMoney,
      allCollectedParcels,
      didBombExplode,
      collectedParcels,
      parcelWithBomb,
      bombLocations,
      isPracticeMode,
    } = get();
    set({
      roundResults: [...get().roundResults, {
        bombLocation: parcelWithBomb,
        didExplode: didBombExplode,
        parcelsCollected: collectedParcels,
        practice: isPracticeMode,
      }],
      totalMoney: [...totalMoney, didBombExplode ? 0 : +(collectedParcels).toFixed(1)],
      allCollectedParcels: [...allCollectedParcels, collectedParcels],
      bombLocations: [...bombLocations, parcelWithBomb],
    });
  },
  clearData: () => {
    set({
      didGameFinish: false,
      collectedParcels: 0,
      parcelWithBomb: getRandomNumWithoutRepeat(3, 25, get().bombLocations),
      didBombExplode: false,
      currentRound: get().currentRound + 1,
    });
  },

  isPracticeMode: false,
  togglePracticeMode: (isPracticeMode) => set({ isPracticeMode }),

  results: null,
  saveFinalData: () => {
    const {
      allCollectedParcels,
      startGameTime,
      totalMoney,
      calculateRiskBehaviour,
      instructionsStartTime,
    } = get();
    const meanParcels = reduceNums(allCollectedParcels) / MAIN_TRAILS_NUMBER;
    const date = startGameTime ?? new Date();
    set({
      results: {
        bretMetadata: {
          startDate: `${date.toISOString()}`,
          startTime: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          total: +(reduceNums(totalMoney)).toFixed(2),
          completed: true,
          meanExplosions: +(totalMoney.map((value) => value > 0)
            .filter((bool) => !bool).length / MAIN_TRAILS_NUMBER).toFixed(2),
          meanParcelsCollected: +(reduceNums(allCollectedParcels) / MAIN_TRAILS_NUMBER).toFixed(2),
          riskBehaviour: calculateRiskBehaviour(meanParcels),
          introductionTime: instructionsStartTime,
        },
        bretGameScores: get().roundResults,
      },
    });
  },

  instructionsStartTime: 0,
  startInstructionsTimer: () => {
    set({
      instructionsStartTime: new Date().getTime(),
    });
  },
  stopInstructionsTimer: () => {
    set({
      instructionsStartTime: new Date().getTime() - get().instructionsStartTime,
    });
  },

  startGameTime: null,
  setStartGameTime: () => {
    set({
      startGameTime: new Date(),
    });
  },
})));

export default useBretState;
