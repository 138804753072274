import classnames from 'classnames';

import s from './Button.module.scss';

type Props = {
  children: string;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
};

const Button: React.FC<Props> = ({
  children,
  onClick,
  className,
  style,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classnames(s.button, className)}
    style={style}
  >
    {children}
  </button>
);

export default Button;
