import { useMemo } from 'react';
import {
  GameFlow,
  Instructions,
  Intro,
  PlayGame,
} from 'components/game';
import { fillTranslationTextWithVariables, useTranslation } from 'hooks';
import Game from './CorsiGame';
import { useCorsiGameActions, useCorsiGameData } from './state';
import { CorsiResult } from './types';
import img from './img/playGameCorsi.svg';
import tutorialImages from './tutorialImages';
import { TranslationPage } from '../../types/enums/TranslationPage';
import Feedback from '../../components/game/Feedback';

const SampleGameFlow: React.FC = () => {
  const lang = useTranslation(TranslationPage.CORSI);
  const { startInstructionsTime, stopInstructionsTimer } = useCorsiGameActions();
  const { gameResult, longestCorsiSequence } = useCorsiGameData();
  const score = useMemo(() =>
    gameResult.corsiGameScores.reduce(
      (prev, score) => (prev < score.highestCorsiSpan ? score.highestCorsiSpan : prev),
      0,
    ),
  [gameResult]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            description={lang.intro.description}
            buttonLabel={lang.intro.btnText}
            superPowerName={lang.intro.superPowerName}
            superPower={lang.intro.superPower}
            gameTitle={lang.gameName}
          />
        ),
        () => (
          <Instructions
            name={lang.gameName}
            instructions={lang.instructions.text}
            onStart={startInstructionsTime}
            onEnd={stopInstructionsTimer}
            images={tutorialImages}
            texts={lang.tutorial}
            tutorial
          />
        ),
        () => <Game practiceMode />,
        () => (
          <PlayGame
            text={lang.playGame.text}
            imgSrc={img}
            gameName={lang.gameName}
            btnText={lang.playGame.btnText}
          />
        ),
        () => <Game />,
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { score, maxScore: longestCorsiSequence.length },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default SampleGameFlow;
export type Results = CorsiResult;
