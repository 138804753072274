import { useAssessmentState } from 'AppFlow/Summary/Assessment/state/index';
import { useShallow } from 'zustand/react/shallow';

export const useAssessmentStateActions = () => useAssessmentState(useShallow(s => ({
  createNewVisitation: s.createNewVisitation,
  setVisitedEntrepreneurship: s.setVisitedEntrepreneurship,
  addSearchQuery: s.addSearchQuery,
  incrementInteraction: s.incrementInteraction,
  addTimeSpent: s.addTimeSpent,
  setPdfSentToEmail: s.setPdfSentToEmail,
  setPdfDownloaded: s.setPdfDownloaded,
  getExistingVisitation: s.getExistingVisitation,
  clearSessionStorageVisitations: s.clearSessionStorageVisitations,
})));
