export class RiasecDto {
  realistic: number = 0;

  investigative: number = 0;

  artistic: number = 0;

  social: number = 0;

  enterprising: number = 0;

  conventional: number = 0;
}

export const RIASEC_MAX_SCORE = 25;

export const RIASEC_REALISTIC_ADD_MAP = [1, 7, 13, 19, 25];
export const RIASEC_INVESTIGATIVE_ADD_MAP = [2, 8, 14, 20, 26];
export const RIASEC_ARTISTIC_ADD_MAP = [3, 9, 15, 21, 27];
export const RIASEC_SOCIAL_ADD_MAP = [4, 10, 16, 22, 28];
export const RIASEC_ENTERPRISING_ADD_MAP = [5, 11, 17, 23, 29];
export const RIASEC_CONVENTIONAL_ADD_MAP = [6, 12, 18, 24, 30];
