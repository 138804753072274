import { useTranslation } from 'hooks';
import s from './MTPT.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';

const TooSlow = () => (
  <div className={s.tooSlowNotification}>
    <p className={s.text}>
      {useTranslation(TranslationPage.MTPT).tooSlow}
    </p>
  </div>
);

export default TooSlow;
