import s from './Timer.module.scss';

const format = (ms: number) => {
  const minutes = Math.floor(ms / 1000 / 60).toString().padStart(2, '0');
  const seconds = Math.floor((ms / 1000) % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};

const Timer: React.FC<{ children: number }> = ({ children }) => (
  <div className={s.timerWrapper}>
    <span className={s.timer}>{format(children)}</span>
  </div>
);

export default Timer;
