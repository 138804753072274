import { TrialName, TrialData } from './types';
import images from './images';

const trials: TrialData[] = [
  {
    name: TrialName.Ex1,
    practice: true,
    pattern: images.image_ex1,
    matches: [
      images.image_ex1A,
      images.image_ex1B,
      images.image_ex1C,
      images.image_ex1D,
      images.image_ex1E,
    ],
    correctMatch: 2, // C
  },
  {
    name: TrialName.Ex2,
    practice: true,
    pattern: images.image_ex2,
    matches: [
      images.image_ex2A,
      images.image_ex2B,
      images.image_ex2C,
      images.image_ex2D,
      images.image_ex2E,
    ],
    correctMatch: 0, // A
  },
  {
    name: TrialName.Trial_minus2,
    practice: false,
    pattern: images.image_minus2,
    matches: [
      images.image_minus2A,
      images.image_minus2B,
      images.image_minus2C,
      images.image_minus2D,
      images.image_minus2E,
    ],
    correctMatch: 1, // B
  },
  {
    name: TrialName.Trial_0,
    practice: false,
    pattern: images.image_0,
    matches: [
      images.image_0A,
      images.image_0B,
      images.image_0C,
      images.image_0D,
      images.image_0E,
    ],
    correctMatch: 4, // E
  },
  {
    name: TrialName.Trial_4,
    practice: false,
    pattern: images.image_4,
    matches: [
      images.image_4A,
      images.image_4B,
      images.image_4C,
      images.image_4D,
      images.image_4E,
    ],
    correctMatch: 3, // D
  },
  {
    name: TrialName.Trial_6,
    practice: false,
    pattern: images.image_6,
    matches: [
      images.image_6A,
      images.image_6B,
      images.image_6C,
      images.image_6D,
      images.image_6E,
    ],
    correctMatch: 1, // B
  },
  {
    name: TrialName.Trial_8,
    practice: false,
    pattern: images.image_8,
    matches: [
      images.image_8A,
      images.image_8B,
      images.image_8C,
      images.image_8D,
      images.image_8E,
    ],
    correctMatch: 3, // D
  },
  {
    name: TrialName.Trial_9,
    practice: false,
    pattern: images.image_9,
    matches: [
      images.image_9A,
      images.image_9B,
      images.image_9C,
      images.image_9D,
      images.image_9E,
    ],
    correctMatch: 2, // C
  },
  {
    name: TrialName.Trial_12,
    practice: false,
    pattern: images.image_12,
    matches: [
      images.image_12A,
      images.image_12B,
      images.image_12C,
      images.image_12D,
      images.image_12E,
    ],
    correctMatch: 0, // A
  },
  {
    name: TrialName.Trial_13,
    practice: false,
    pattern: images.image_13,
    matches: [
      images.image_13A,
      images.image_13B,
      images.image_13C,
      images.image_13D,
      images.image_13E,
    ],
    correctMatch: 1, // B
  },
  {
    name: TrialName.Trial_17,
    practice: false,
    pattern: images.image_17,
    matches: [
      images.image_17A,
      images.image_17B,
      images.image_17C,
      images.image_17D,
      images.image_17E,
    ],
    correctMatch: 1, // B
  },
  {
    name: TrialName.Trial_21,
    practice: false,
    pattern: images.image_21,
    matches: [
      images.image_21A,
      images.image_21B,
      images.image_21C,
      images.image_21D,
      images.image_21E,
    ],
    correctMatch: 0, // A
  },
];

export default trials;
