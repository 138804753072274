import { useAssessmentResults, useLanguage, useUsername } from 'appState';
import { useTranslation } from 'hooks';
import { PdfAssessmentContent } from 'types';
import { fractionToPercent, objectEntries } from 'utils';
import { TranslationPage } from '../../../types/enums/TranslationPage';

const usePdfAssessmentData = (): PdfAssessmentContent | null => {
  const lang = useTranslation(TranslationPage.ASSESMENT_PDF);
  const results = useAssessmentResults();
  const username = useUsername();
  const language = useLanguage();
  if (!results) return null;

  // Include top cognitive skills which are 70% and above.
  // If no score is 70% or above, include the cognitive skills
  // that are 45% and above
  const allCognitive = objectEntries(results.cognitive)
    .filter((t): t is [keyof typeof results.cognitive, number] => t[1] !== null)
    .sort(([, a], [, b]) => b - a);
  const cognitiveHigh = allCognitive.filter(([, score]) => score >= 0.7);
  const cognitiveLow = allCognitive.filter(([, score]) => score >= 0.45);
  const cognitive = cognitiveHigh.length ? cognitiveHigh : cognitiveLow;

  // Growth mindset: only if 50% or more:
  const growthMindset = (
    (results.behavior.growthMindset ?? 0) >= 0.5 ? ['growthMindset', 1] as const : []
  );

  // Persistence: only include if true
  const persistence = (results.cognitive.persistence ? ['persistence', 1] as const : []);

  // Emotional Intelligence: include strongest 2 traits.
  // Omit "empathy".
  const emotional = objectEntries(results.emotionalIntelligence)
    .filter((t): t is [keyof typeof results.emotionalIntelligence, number] => t[1] !== null)
    .filter((t): t is [Exclude<keyof typeof results.emotionalIntelligence, 'empathy'>, number] => (
      t[0] !== 'empathy'
    ))
    .sort(([, a], [, b]) => b - a)
    .slice(0, 2);

  // Personality Type: include 2 strongest traits
  const personality = objectEntries(results.personality)
    .filter((t): t is [keyof typeof results.personality, number] => t[1] !== null)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 2);

  const items = [
    ...cognitive,
    growthMindset,
    persistence,
    ...emotional,
    ...personality,
  ]
    .filter(([name, _]) => name)
    .map(([name, value]) => [
      lang[name].domain,
      `${lang[name].name}: ${fractionToPercent(value)}%`,
      lang[name].whatIsIt,
      lang[name].whyImportant,
    ] as [string, string | string[], string | string[], string | string[]]);

  return {
    items,
    legend: lang.legend as [string, string, string, string],
    username,
    date: `${lang.testDate}: ${(new Date(results.date)).toLocaleDateString(language)}`,
  };
};

export default usePdfAssessmentData;
