export enum TrialName {
  'Ex1' = 'Ex1',
  'Ex2' = 'Ex2',
  'Trial_minus2' = '-2',
  'Trial_0' = '0',
  'Trial_4' = '4',
  'Trial_6' = '6',
  'Trial_8' = '8',
  'Trial_9' = '9',
  'Trial_12' = '12',
  'Trial_13' = '13',
  'Trial_17' = '17',
  'Trial_21' = '21',
}

export type TrialData = {
  name: TrialName;
  pattern: string;
  matches: [string, string, string, string, string];
  correctMatch: number;
  practice: boolean;
};

export type TrialScore = {
  name: TrialName,
  practice: boolean;
  correct: boolean;
  timeout: boolean;
};

export type HMTResults = {
  hmtScores: TrialScore[];
  hmtMetadata: unknown;
};
