import { useEffect } from 'react';
import { useAppStateActions, useAssessmentVisibility } from 'appState';
import Assessment from 'AppFlow/Summary/Assessment/Assessment';
import GameResults from 'AppFlow/Summary/GameResults/GameResults';

const POST_ASSESSMENT_QUESTIONNAIRE_DELAY = 45_000;
const POST_NO_FEEDBACK_QUESTIONNAIRE_DELAY = 15_000;

const Summary: React.FC = () => {
  const { showFeedback } = useAssessmentVisibility();
  const { appFlowForward } = useAppStateActions();
  useEffect(() => {
    const ref = setTimeout(appFlowForward, showFeedback
      ? POST_ASSESSMENT_QUESTIONNAIRE_DELAY
      : POST_NO_FEEDBACK_QUESTIONNAIRE_DELAY);
    return () => clearTimeout(ref);
  }, [appFlowForward, showFeedback]);

  if (showFeedback) {
    return <Assessment />;
  }
  return <GameResults />;
};

export default Summary;
