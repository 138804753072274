import { useCallback, useMemo, useState } from 'react';
import { useLanguage, useAppState } from 'appState';
import { Language } from 'types/enums';
import USflag from './US-flag.svg';
import AMflag from './AM-flag.svg';
import ITflag from './IT-flag.svg';
import arrowDown from './arrow-down.svg';
import s from './LanguageSwitch.module.scss';

const LanguageSwitch: React.FC = () => {
  const language = useLanguage();
  const [isOnpen, setIsOpen] = useState<boolean>(false);
  const toggleSwitch = useCallback(() => setIsOpen(!isOnpen), [isOnpen]);
  const appState = useAppState();

  const switchLanguage = useCallback(
    (lang: Language) => {
      appState.setLanguage(lang);
    },
    [appState],
  );

  const flags = useMemo(
    () => ({
      [Language.EN]: USflag,
      [Language.AM]: AMflag,
      [Language.IT]: ITflag,
    }),
    [],
  );

  return (
    <>
      <div className={s.languageBtn} onClick={toggleSwitch}>
        <img src={flags[language]} alt="US flag" />
        <img src={arrowDown} alt="arrow down icon" />
        {isOnpen && (
          <ul className={s.languageOptions}>
            <button type="button" onClick={() => switchLanguage(Language.EN)}>
              <img src={flags[Language.EN]} alt="EN flag" />
            </button>
            <button type="button" onClick={() => switchLanguage(Language.AM)}>
              <img src={flags[Language.AM]} alt="AM flag" />
            </button>
            <button type="button" onClick={() => switchLanguage(Language.IT)}>
              <img src={flags[Language.IT]} alt="AM flag" />
            </button>
          </ul>
        )}
      </div>
    </>
  );
};

export default LanguageSwitch;
