import { fillTranslationTextWithVariables, useTranslation } from 'hooks';
import {
  GameFlow,
  GetReady,
  Instructions,
  Intro,
  PlayGame,
} from 'components/game';

import Game from './DigitSpanGame';
import { useDigitSpanGameActions, useDigitSpanGameData } from './state';
import { DigitSpanResultForSubmission } from './state/digitSpanState';
import { tutorialImagesA, tutorialImagesB } from './tutorialImages';
import playGameImg from './img/playGame.png';
import { TranslationPage } from '../../types/enums/TranslationPage';
import Feedback from '../../components/game/Feedback';

const SampleGameFlow:React.FC = () => {
  const lang = useTranslation(TranslationPage.DIGIT_SPAN);
  const {
    startIntroductionTimerForward,
    saveIntroductionTimerForward,
    startIntroductionTimerReversed,
    saveIntroductionTimerReversed,
  } = useDigitSpanGameActions();
  const { gameResult, maxRounds } = useDigitSpanGameData();

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            description={lang.intro.description}
            buttonLabel={lang.intro.btnText}
            superPowerName={lang.intro.superPowerName}
            superPower={lang.intro.superPower}
            gameTitle={lang.gameName}
          />
        ),
        () => (
          <Instructions
            name={`${lang.gameName} ${lang.directionTipForward}`}
            instructions={lang.instructions.textForward}
            onStart={startIntroductionTimerForward}
            onEnd={saveIntroductionTimerForward}
            images={tutorialImagesA}
            texts={lang.tutorialA}
            tutorial
          />
        ),
        () => <GetReady />,
        () => <Game practiceMode />,
        () => (
          <PlayGame
            gameName={lang.gameName}
            imgSrc={playGameImg}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => <Game />,
        () => (
          <Instructions
            name={`${lang.gameName} ${lang.directionTipReverse}`}
            instructions={lang.instructions.textReverse}
            onStart={startIntroductionTimerReversed}
            onEnd={saveIntroductionTimerReversed}
            images={tutorialImagesB}
            texts={lang.tutorialB}
            tutorial
          />
        ),
        () => <GetReady />,
        () => <Game practiceMode reversedMode />,
        () => (
          <PlayGame
            gameName={lang.gameName}
            imgSrc={playGameImg}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => <Game reversedMode />,
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                {
                  scoreForwards: gameResult.digitSpanMetadata.fML,
                  scoreBackwards: gameResult.digitSpanMetadata.bML,
                  maxScore: maxRounds,
                },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default SampleGameFlow;
export type Results = DigitSpanResultForSubmission;
