import { GameId } from 'types/enums';
import { INSIDER_BUILD } from 'config';
import { useAppState } from 'appState';

import games from '.';

const matcher = /^\/game\/([\w-]+)$/;

const isValidGameId = (id: string): id is GameId => Object.keys(games).includes(id);

const matchGameComponent = () => {
  const match = new URL(window.location.href).pathname.match(matcher);
  if (!match) {
    return null;
  }
  const gameId = match[1];
  if (isValidGameId(gameId)) {
    return games[gameId];
  }
  return null;
};

const routeToGame = () => {
  const GameComponent = matchGameComponent();

  if (!INSIDER_BUILD || !GameComponent) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useAppState.getState().toggleSingleGameMode(true);
  return <GameComponent />;
};

export default routeToGame;
