import classnames from 'classnames';

import s from './MTPT.module.scss';

type CursorProps = {
  x: number;
  y: number;
  isVisible: boolean;
  onPress?: () => void;
  tracingMode?: boolean;
};

const Cursor: React.FC<CursorProps> = ({
  x,
  y,
  isVisible,
  onPress,
  tracingMode,
}) => (
  <div
    className={classnames(
      s.cursor,
      tracingMode && s.tracingMode,
    )}
    style={{
      left: `${x}px`,
      top: `${y}px`,
      opacity: Number(isVisible),
    }}
    onMouseDown={onPress}
    onTouchStart={onPress}
  />
);

export default Cursor;
