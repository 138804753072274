export const getRandomNumWithinRange = (min: number, max: number): number => (
  Math.floor(Math.random() * (max - min + 1)) + min
);

export const getRandomNumWithoutRepeat = (min: number, max: number, arr: number[]): number => {
  const newNum = Math.floor(Math.random() * (max - min + 1) + min);
  if (arr.find((num) => num === newNum)) {
    return getRandomNumWithoutRepeat(min, max, arr);
  }
  return newNum;
};
