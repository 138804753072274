import { Heading, Layout } from 'components/assessment';
import useGameResults from 'AppFlow/Summary/GameResults/useGameResults';
import { useTranslation } from 'hooks';
import s from './GameResults.module.scss';
import { TranslationPage } from '../../../types/enums/TranslationPage';

const GameResults: React.FC = () => {
  const results = useGameResults();
  const lang = useTranslation(TranslationPage.GAME_RESULTS);

  return (
    <Layout>
      <Layout.Content>
        <Heading>{lang.gameResults}</Heading>
        {results?.ungroupedResults.length ? (
          <Layout.Content.Section>
            <div className={s.resultsWrapper}>
              { results?.ungroupedResults.map(({ content }) => content) }
            </div>
          </Layout.Content.Section>
        ) : null}
        {results?.emotionsQuizResults.length ? (
          <Layout.Content.Section>
            <div className={s.resultsWrapper}>
              <Heading secondary>{lang.emotionsQuiz}</Heading>
              { results?.emotionsQuizResults.map(({ content }) => content) }
            </div>
          </Layout.Content.Section>
        ) : null}
        {results?.personalityQuizResults.length ? (
          <Layout.Content.Section>
            <div className={s.resultsWrapper}>
              <Heading secondary>{lang.personalityQuiz}</Heading>
              { results?.personalityQuizResults.map(({ content }) => content) }
            </div>
          </Layout.Content.Section>
        ) : null}
        {results?.careersQuizResults.length ? (
          <Layout.Content.Section>
            <div className={s.resultsWrapper}>
              <Heading secondary>{lang.careersQuiz}</Heading>
              { results?.careersQuizResults.map(({ content }) => content) }
            </div>
          </Layout.Content.Section>
        ) : null}
      </Layout.Content>
    </Layout>
  );
};

export default GameResults;
