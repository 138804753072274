import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';

import s from './TextSection.module.scss';

type Props = {
  center?: boolean;
  children: string;
};

const TextSection: React.FC<Props> = ({ children, center }) => (
  <div className={classnames(s.text, center && s.center)}>
    <ReactMarkdown>
      {children}
    </ReactMarkdown>
  </div>
);

export default TextSection;
