import classnames from 'classnames';
import { Stage, Layer, Shape } from 'react-konva';

import { useVirtualViewportSize, useVirtualUnits, useViewportSize } from 'appState';

import { Point } from './types';
import s from './MTPT.module.scss';

function midPointBtw(p1: Point, p2: Point) {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  };
}

type TraceProps = {
  points: Point[],
  isFinished: boolean;
};

const Trace: React.FC<TraceProps> = ({ points, isFinished }) => {
  const vp = useVirtualViewportSize();
  const { width, height } = useViewportSize();
  const v = useVirtualUnits();

  if (points.length < 2) {
    return null;
  }

  return (
    <Stage
      width={width}
      height={height}
      className={classnames(s.trace, isFinished && s.isCompleted)}
    >
      <Layer>
        <Shape
          stroke="#E57A00"
          strokeWidth={v.w * 0.25}
          dash={[v.w * 0.25, v.w * 0.5]}
          dashEnabled={true}
          sceneFunc={(ctx, shape) => {
            let p1 = points[0];
            let p2 = points[1];
            ctx.moveTo(p2.x * vp.width, p2.y * vp.height);
            ctx.beginPath();

            // eslint-disable-next-line no-plusplus
            for (let i = 1, len = points.length; i < len; i++) {
              const midPoint = midPointBtw(p1, p2);
              ctx.quadraticCurveTo(
                p1.x * vp.width,
                p1.y * vp.height,
                midPoint.x * vp.width,
                midPoint.y * vp.height,
              );
              p1 = points[i];
              p2 = points[i + 1];
            }

            ctx.lineTo(p1.x * vp.width, p1.y * vp.height);
            shape.lineCap('round');
            shape.lineJoin('round');

            ctx.fillStrokeShape(shape);
          }}
        />
      </Layer>
    </Stage>
  );
};

export default Trace;
