import { useLanguage } from 'appState';
import { Language } from 'types/enums';
import amharic from 'language/amharic.json';
import english from 'language/english.json';
import italian from 'language/italian.json';
import { TranslationPage } from '../types/enums/TranslationPage';

export const useTranslation = <T extends TranslationPage>(
  translationPage: T,
): typeof english[T] => {
  const language = useLanguage();
  switch (language) {
    case Language.AM:
      return amharic[translationPage];
    case Language.IT:
      return italian[translationPage];
    case Language.EN:
    default:
      return english[translationPage];
  }
};

export const fillTranslationTextWithVariables = (
  text: string,
  variables: { [key: string]: string | number },
): string => {
  let newText = text;
  Object.keys(variables).forEach(key => {
    const valueToReplace = `\${${key}}`;
    newText = newText.replace(valueToReplace, variables[key].toString());
  });
  return newText;
};
