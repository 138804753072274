import useAppState from './useAppState';

const logout = useAppState.getState().logout;

export * from './selectors';

export {
  useAppState,
  logout,
};
