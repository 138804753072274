import { useEffect } from 'react';

import { useAppStateActions } from 'appState';
import {
  GameFlow,
  GetReady,
  Instructions,
  Intro,
  Summary,
  PlayGame,
} from 'components/game';
import { useTranslation } from 'hooks';
import { GameId } from 'types/enums';

import Game from './Game';
import useResultsState from './useResultsState';
import { tutorialImagesA, tutorialImagesB } from './tutorialImages';
import { GameStage, TMTResults } from './types';
import playGameImg from './img/playGame.png';
import { TranslationPage } from '../../types/enums/TranslationPage';
import Feedback from '../../components/game/Feedback';

const SampleGameFlow: React.FC = () => {
  const lang = useTranslation(TranslationPage.TMT);
  const { submitGameResults } = useAppStateActions();
  const saveResults = useResultsState(s => s.saveStageResults);
  const onIntroStart = useResultsState(s => s.setIntroStart);
  const onIntroEnd = useResultsState(s => s.setIntroEnd);
  const finalResults = useResultsState(s => s.finalResults);

  useEffect(() => {
    if (finalResults) {
      submitGameResults(GameId.TMT, finalResults);
    }
  }, [finalResults, submitGameResults]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.gameName}
            description={lang.intro.description}
            buttonLabel={lang.intro.btnText}
            superPowerName={lang.intro.superPowerName}
            superPower={lang.intro.superPower}
          />
        ),
        () => (
          <Instructions
            name={`${lang.gameName} ${lang.gameTitleExtraFirst}`}
            instructions={lang.partA.instructions}
            onStart={onIntroStart}
            onEnd={onIntroEnd}
            images={tutorialImagesA}
            texts={lang.tutorialA}
            tutorial
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            stage={GameStage.TrialRunA}
            onComplete={saveResults}
            maxDuration={120_000}
          />
        ),
        () => (
          <PlayGame
            gameName={`${lang.gameName} ${lang.gameTitleExtraFirst}`}
            imgSrc={playGameImg}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            stage={GameStage.MainRunA}
            onComplete={saveResults}
            maxDuration={120_000}
          />
        ),
        () => (
          <Instructions
            name={`${lang.gameName} ${lang.gameTitleExtraSecond}`}
            instructions={lang.partB.instructions}
            onStart={onIntroStart}
            onEnd={onIntroEnd}
            images={tutorialImagesB}
            texts={lang.tutorialB}
            tutorial
          />
        ),
        () => (
          <Game
            stage={GameStage.TrialRunB}
            onComplete={saveResults}
            maxDuration={300_000}
          />
        ),
        () => (
          <PlayGame
            gameName={`${lang.gameName} ${lang.gameTitleExtraSecond}`}
            imgSrc={playGameImg}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            stage={GameStage.MainRunB}
            onComplete={saveResults}
            maxDuration={300_000}
          />
        ),
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={lang.feedback.subTitle}
            description={lang.feedback.description}
          />
        ),
        () => <Summary gameName={lang.gameName} />,
      ]}
    </GameFlow>
  );
};

export default SampleGameFlow;
export type Results = TMTResults;
