import ReactMarkdown from 'react-markdown';

import { useTranslation } from 'hooks';
import { AspectRatioView, ButtonRect, LanguageSwitch } from 'components';
import styles from './FAQ.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';

const Instructions: React.FC<{
  onNext: () => void;
}> = ({
  onNext,
}) => {
  const lang = useTranslation(TranslationPage.FAQ);

  const renderFAQContent = () => lang.text.map((item, index) => (
    <div key={`faq-${index}`}>
      <h5 className={styles.Header}>{item.title}</h5>
      <ReactMarkdown>
        {item.description}
      </ReactMarkdown>
    </div>
  ));

  return (
    <AspectRatioView>
      <div className={styles.container}>
        <div className={styles.TopRow}>
          <LanguageSwitch />
          <div className={styles.Text}>
            <h4>{lang.mainTitle}</h4>
          </div>
        </div>
        <div className={styles.MidSection}>
          <div className={styles.FAQContainer}>
            {renderFAQContent()}
          </div>
        </div>
        <div className={styles.bottomRow}>
          <ButtonRect
            className={styles.buttonMoveToGame}
            text={lang.moveToGamesButton}
            onClick={onNext}
          />
        </div>
      </div>
    </AspectRatioView>
  );
};

export default Instructions;
