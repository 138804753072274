import { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

import { useTranslation } from 'hooks';
import { AspectRatioView, ButtonRect, GameModal } from 'components';

import { ButtonType } from 'components/ButtonRect/ButtonRect.types';
import { TrialData, TrialScore } from './types';
import s from './RMET.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';
import GameSkipper from 'components/game/GameSkipper/GameSkipper';

type Props = {
  trials: TrialData[];
  isPractice: boolean;
  onFinish: (scores: TrialScore[]) => void;
};

const Game: React.FC<Props> = ({ trials, isPractice, onFinish }) => {
  const lang = useTranslation(TranslationPage.RMET);
  const [scores, setScores] = useState<TrialScore[]>([]);
  const [trialStart, setTrialStart] = useState(0);
  const [isDictionaryVisible, setIsDictionaryVisible] = useState(false);
  const [result, setResult] = useState<boolean | null>(null);
  const [responseWord, setResponseWord] = useState(-1);
  const [answerSelected, setAnswerSelected] = useState<boolean>(false);

  const stage = Math.min(scores.length, trials.length - 1);
  const isNextBtnVisible = answerSelected && !isPractice;

  useEffect(() => {
    if (scores.length === trials.length) {
      onFinish(scores);
    }
  }, [scores, onFinish, trials.length]);

  useEffect(() => {
    setTrialStart(Date.now());
  }, [stage]);

  const {
    correct: correctWordIndex,
    gender: imageGender,
    image,
    words,
    trialNumber,
  } = trials[stage];

  const toggleDictionary = () => setIsDictionaryVisible(v => !v);
  useEffect(() => {
    setIsDictionaryVisible(false);
  }, [stage, setIsDictionaryVisible]);

  const onWordSelected = (index: number) => {
    setResponseWord(index);
    setResult(index === correctWordIndex);
    setAnswerSelected(true);
  };

  const commitScore = useCallback(() => {
    const score = {
      practice: isPractice,
      correct: !!result,
      imageGender,
      word1: trials[stage].words[0],
      word2: trials[stage].words[1],
      word3: trials[stage].words[2],
      word4: trials[stage].words[3],
      corrresp: trials[stage].correct + 1,
      response: responseWord + 1,
      latencyMs: Date.now() - trialStart,
    };
    setScores(s => [...s, score]);
    setResponseWord(-1);
    setAnswerSelected(false);
    setResult(null);
  }, [
    isPractice,
    result,
    imageGender,
    responseWord,
    stage,
    trialStart,
    trials,
  ]);

  const dictionary = words.map(word => lang.words[word as keyof typeof lang.words]);

  return (
    <AspectRatioView>
      <GameSkipper className={s.skipButton} />
      <div className={s.wrapper}>
        <span className={s.trialNumber}>
          {!isPractice && `${trialNumber}/${trials.length}`}
        </span>
        <div
          className={s.image}
          style={{ backgroundImage: `url("${image}")` }}
        />
        {dictionary.map(({ displayName: word }, index) => (
          <span
            onClick={() => onWordSelected(index)}
            className={classNames(
              s.selectableWord,
              s[`word-${index + 1}`],
              responseWord === index && s.active,
            )}
            key={word}
          >
            {word}
          </span>
        ))}
        <ul className={classNames(s.dictionary, isDictionaryVisible && s.open)}>
          {dictionary.map(({ displayName, example, synonym }) =>
            displayName && example && synonym && (
              <li className={s.dictionaryItem} key={displayName}>
                <span className={s.word}>{displayName}</span>
                <span className={s.synonym}>{synonym || 'synonym(s)'}</span>
                <span className={s.example}>
                  <ReactMarkdown>
                    {example || 'Example sentence.'}
                  </ReactMarkdown>
                </span>
              </li>
            ))}
        </ul>
        <ButtonRect
          className={s.dictionaryButton}
          onClick={toggleDictionary}
          text={isDictionaryVisible ? lang.close : lang.dictionary}
        />
        <ButtonRect
          isVisible={isNextBtnVisible}
          text={lang.next}
          type={ButtonType.PRIMARY}
          className={s.nextButton}
          onClick={commitScore}
        />
      </div>
      <GameModal isVisible={result !== null && isPractice}>
        {
          result === true && (
            <GameModal.Success
              header={lang.correct}
              onClick={commitScore}
              text={lang.textMessage}
            />
          )
        }
        {
          result === false && (
            <GameModal.Incorrect
              header={lang.incorrect}
              text={lang.textMessage}
              onClick={commitScore}
            />
          )
        }
      </GameModal>
    </AspectRatioView>
  );
};

export default Game;
