/* eslint-disable max-len */
import { Occupation } from 'types/enums';

const links = {
  [Occupation['General and Operations Managers']]: 'https://www.bls.gov/ooh/management/top-executives.htm#tab-2',
  [Occupation['Marketing Managers']]: 'https://www.bls.gov/ooh/management/advertising-promotions-and-marketing-managers.htm#tab-2',
  [Occupation['Sales Managers']]: 'https://www.bls.gov/ooh/management/sales-managers.htm#tab-2',
  [Occupation['Industrial Production Managers']]: 'https://www.bls.gov/ooh/management/industrial-production-managers.htm#tab-2',
  [Occupation['Human Resources Managers']]: 'https://www.bls.gov/ooh/management/human-resources-managers.htm#tab-2',
  [Occupation['Construction Managers']]: 'https://www.bls.gov/ooh/management/construction-managers.htm',
  [Occupation['Education and Childcare Administrators, Preschool and Daycare']]: 'https://www.bls.gov/ooh/management/preschool-and-childcare-center-directors.htm#tab-2',
  [Occupation['Food Service Managers']]: 'https://www.bls.gov/ooh/management/food-service-managers.htm#tab-2',
  [Occupation['Water Resource Specialists']]: 'https://www.mynextmove.org/profile/summary/11-9121.02',
  [Occupation['Postmasters and Mail Superintendents']]: 'https://www.bls.gov/oes/current/oes119131.htm',
  [Occupation['Property, Real Estate, and Community Association Managers']]: 'https://www.bls.gov/ooh/management/property-real-estate-and-community-association-managers.htm#tab-2',
  [Occupation['Social and Community Service Managers']]: 'https://www.bls.gov/ooh/management/social-and-community-service-managers.htm#tab-2',
  [Occupation['Buyers and Purchasing Agents']]: 'https://www.bls.gov/ooh/business-and-financial/purchasing-managers-buyers-and-purchasing-agents.htm#tab-2',
  [Occupation['Environmental Compliance Inspectors']]: 'https://www.onetonline.org/link/summary/13-1041.01',
  [Occupation['Human Resources Specialists']]: 'https://www.bls.gov/ooh/business-and-financial/human-resources-specialists.htm#tab-2',
  [Occupation['Farm Labor Contractors']]: 'https://www.onetonline.org/link/summary/13-1074.00#:~:text=Recruit%20and%20hire%20seasonal%20or,and%20provide%20meals%20for%20workers.',
  [Occupation['Meeting, Convention, and Event Planners']]: 'https://www.bls.gov/ooh/business-and-financial/meeting-convention-and-event-planners.htm#tab-2',
  [Occupation['Sustainability Specialists']]: 'https://www.onetonline.org/link/summary/13-1199.05',
  [Occupation['Online Merchants']]: 'https://www.onetonline.org/link/summary/13-1199.06',
  [Occupation['Personal Financial Advisors']]: 'https://www.bls.gov/ooh/business-and-financial/personal-financial-advisors.htm#tab-2',
  [Occupation['Loan Officers']]: 'https://www.bls.gov/ooh/business-and-financial/loan-officers.htm#tab-2',
  [Occupation['Actuaries']]: 'https://www.bls.gov/ooh/math/actuaries.htm#tab-2',
  [Occupation['Operations Research Analysts']]: 'https://www.bls.gov/ooh/math/operations-research-analysts.htm#tab-2',
  [Occupation['Statisticians']]: 'https://www.bls.gov/ooh/math/mathematicians-and-statisticians.htm#tab-2',
  [Occupation['Architects']]: 'https://www.bls.gov/ooh/architecture-and-engineering/architects.htm#tab-2',
  [Occupation['Surveyors']]: 'https://www.bls.gov/ooh/architecture-and-engineering/surveyors.htm#tab-2',
  [Occupation['Agricultural Engineers']]: 'https://www.bls.gov/ooh/architecture-and-engineering/agricultural-engineers.htm#tab-2',
  [Occupation['Chemical Engineers']]: 'https://www.bls.gov/ooh/architecture-and-engineering/chemical-engineers.htm#tab-2',
  [Occupation['Civil Engineers']]: 'https://www.bls.gov/ooh/architecture-and-engineering/civil-engineers.htm#tab-2',
  [Occupation['Transportation Engineers']]: 'https://www.onetonline.org/link/summary/17-2051.01#:~:text=Develop%20plans%20for%20surface%20transportation,or%20estimates%20for%20transportation%20facilities.',
  [Occupation['Electrical Engineers']]: 'https://www.bls.gov/ooh/architecture-and-engineering/electrical-and-electronics-engineers.htm#tab-2',
  [Occupation['Mechanical Engineers']]: 'https://www.bls.gov/ooh/architecture-and-engineering/mechanical-engineers.htm#tab-2',
  [Occupation['Automotive Engineers']]: 'https://www.onetonline.org/link/summary/17-2141.02',
  [Occupation['Wind Energy Engineers']]: 'https://www.onetonline.org/link/summary/17-2199.10',
  [Occupation['Solar Energy Systems Engineers']]: 'https://www.onetonline.org/link/summary/17-2199.11',
  [Occupation['Civil Engineering Technicians']]: 'https://www.onetonline.org/link/summary/17-3022.00',
  [Occupation['Environmental Engineering Technicians']]: 'https://www.onetonline.org/link/summary/17-3025.00',
  [Occupation['Industrial Engineering Technicians']]: 'https://www.bls.gov/ooh/architecture-and-engineering/industrial-engineering-technicians.htm#tab-2',
  [Occupation['Automotive Engineering Technicians']]: 'https://www.onetonline.org/link/summary/17-3027.01',
  [Occupation['Animal Scientists']]: 'https://www.bls.gov/oes/current/oes191011.htm',
  [Occupation['Food Scientists and Technologists']]: 'https://www.bls.gov/ooh/life-physical-and-social-science/agricultural-and-food-scientists.htm#tab-2',
  [Occupation['Soil and Plant Scientists']]: 'https://www.onetonline.org/link/summary/19-1013.00',
  [Occupation['Range Managers']]: 'https://www.onetonline.org/link/summary/19-1031.02',
  [Occupation['Park Naturalists']]: 'https://www.onetonline.org/link/summary/19-1031.03',
  [Occupation['Foresters']]: 'https://www.bls.gov/ooh/life-physical-and-social-science/conservation-scientists.htm#tab-2',
  [Occupation['Epidemiologists']]: 'https://www.bls.gov/ooh/life-physical-and-social-science/epidemiologists.htm#tab-2',
  [Occupation['Medical Scientists']]: 'https://www.bls.gov/ooh/life-physical-and-social-science/medical-scientists.htm#tab-2',
  [Occupation['Environmental Scientists and Specialists']]: 'https://www.bls.gov/ooh/life-physical-and-social-science/environmental-scientists-and-specialists.htm#tab-2',
  [Occupation['Geographers']]: 'https://www.bls.gov/ooh/life-physical-and-social-science/geographers.htm#tab-2',
  [Occupation['Transportation Planners']]: 'https://www.onetonline.org/link/summary/19-3099.01',
  [Occupation['Chemical Technicians']]: 'https://www.bls.gov/ooh/life-physical-and-social-science/chemical-technicians.htm#tab-2',
  [Occupation['Social Science Research Assistants']]: 'https://www.onetonline.org/link/summary/19-4061.00',
  [Occupation['Quality Control Analysts']]: 'https://www.bls.gov/ooh/production/quality-control-inspectors.htm#tab-2',
  [Occupation['Educational, Guidance, and Career Counselors']]: 'https://www.bls.gov/ooh/community-and-social-service/school-and-career-counselors.htm#tab-2',
  [Occupation['Child, Family, and School Social Workers']]: 'https://www.onetonline.org/link/summary/21-1021.00',
  [Occupation['Healthcare Social Workers']]: 'https://www.onetonline.org/link/summary/21-1022.00',
  [Occupation['Health Education Specialists']]: 'https://www.bls.gov/oes/current/oes211091.htm',
  [Occupation['Social and Human Service Assistants']]: 'https://www.bls.gov/ooh/community-and-social-service/social-and-human-service-assistants.htm#tab-2',
  [Occupation['Community Health Workers']]: 'https://www.bls.gov/ooh/community-and-social-service/health-educators.htm#tab-2',
  [Occupation['Lawyers']]: 'https://www.bls.gov/ooh/legal/lawyers.htm#tab-2',
  [Occupation['Paralegals and Legal Assistants']]: 'https://www.bls.gov/ooh/legal/paralegals-and-legal-assistants.htm#tab-2',
  [Occupation['Elementary School Teachers']]: 'https://www.bls.gov/ooh/education-training-and-library/kindergarten-and-elementary-school-teachers.htm#tab-2',
  [Occupation['Special Education Teachers']]: 'https://www.bls.gov/ooh/education-training-and-library/special-education-teachers.htm#tab-2',
  [Occupation['Adapted Physical Education Specialists']]: 'https://www.onetonline.org/link/summary/25-2059.01',
  [Occupation['Adult Educators']]: 'https://www.onetonline.org/link/summary/25-3011.00',
  [Occupation['Archivists']]: 'https://www.bls.gov/ooh/education-training-and-library/curators-museum-technicians-and-conservators.htm#tab-2',
  [Occupation['Museum Technicians and Conservators']]: 'https://www.bls.gov/ooh/education-training-and-library/curators-museum-technicians-and-conservators.htm#tab-2',
  [Occupation['Library Technicians']]: 'https://www.bls.gov/ooh/education-training-and-library/library-technicians-and-assistants.htm#tab-2',
  [Occupation['Craft Artists']]: 'https://www.bls.gov/ooh/arts-and-design/craft-and-fine-artists.htm#tab-2',
  [Occupation['Commercial and Industrial Designers']]: 'https://www.bls.gov/ooh/arts-and-design/industrial-designers.htm#tab-2',
  [Occupation['Fashion Designers']]: 'https://www.bls.gov/ooh/arts-and-design/fashion-designers.htm#tab-2',
  [Occupation['Floral Designers']]: 'https://www.bls.gov/ooh/arts-and-design/floral-designers.htm#tab-2',
  [Occupation['Graphic Designers']]: 'https://www.bls.gov/ooh/arts-and-design/graphic-designers.htm#tab-2',
  [Occupation['Merchandise Displayers']]: 'https://www.onetonline.org/link/summary/27-1026.00',
  [Occupation['Set and Exhibit Designers']]: 'https://www.onetonline.org/link/summary/27-1027.00',
  [Occupation['Actors']]: 'https://www.bls.gov/ooh/entertainment-and-sports/actors.htm#tab-2',
  [Occupation['Poets, Lyricists and Creative Writers']]: 'https://www.bls.gov/ooh/media-and-communication/writers-and-authors.htm#tab-2',
  [Occupation['Interpreters and Translators']]: 'https://www.bls.gov/ooh/media-and-communication/interpreters-and-translators.htm#tab-2',
  [Occupation['Audio and Video Technicians']]: 'https://www.bls.gov/oes/2017/may/oes274011.htm',
  [Occupation['Photographers']]: 'https://www.bls.gov/ooh/media-and-communication/photographers.htm#tab-2',
  [Occupation['Camera Operators, Television, Video, and Film']]: 'https://www.bls.gov/ooh/media-and-communication/film-and-video-editors-and-camera-operators.htm#tab-2',
  [Occupation['Film and Video Editors']]: 'https://www.bls.gov/ooh/media-and-communication/film-and-video-editors-and-camera-operators.htm#tab-2',
  [Occupation['Dentists']]: 'https://www.bls.gov/ooh/healthcare/dentists.htm#tab-2',
  [Occupation['Physician Assistants']]: 'https://www.bls.gov/ooh/healthcare/physician-assistants.htm#tab-2',
  [Occupation['Occupational Therapists']]: 'https://www.bls.gov/ooh/healthcare/occupational-therapists.htm#tab-2',
  [Occupation['Physical Therapists']]: 'https://www.bls.gov/ooh/healthcare/physical-therapists.htm#tab-2',
  [Occupation['Veterinarians']]: 'https://www.bls.gov/ooh/healthcare/veterinarians.htm#tab-2',
  [Occupation['Registered Nurses']]: 'https://www.bls.gov/ooh/healthcare/registered-nurses.htm#tab-2',
  [Occupation['Nurse Practitioners']]: 'https://www.bls.gov/ooh/healthcare/nurse-anesthetists-nurse-midwives-and-nurse-practitioners.htm#tab-2',
  [Occupation['Medical and Clinical Laboratory Technicians']]: 'https://www.bls.gov/ooh/healthcare/clinical-laboratory-technologists-and-technicians.htm#tab-2',
  [Occupation['Dietetic Technicians']]: 'https://www.onetonline.org/link/summary/29-2051.00',
  [Occupation['Pharmacy Technicians']]: 'https://www.bls.gov/ooh/healthcare/pharmacy-technicians.htm#tab-2',
  [Occupation['Psychiatric Technicians']]: 'https://www.bls.gov/ooh/healthcare/psychiatric-technicians-and-aides.htm#tab-2',
  [Occupation['Surgical Technologists']]: 'https://www.bls.gov/ooh/healthcare/surgical-technologists.htm#tab-2',
  [Occupation['Veterinary Technicians']]: 'https://www.bls.gov/ooh/healthcare/veterinary-technologists-and-technicians.htm#tab-2',
  [Occupation['Licensed Practical and Licensed Vocational Nurses']]: 'https://www.bls.gov/ooh/healthcare/licensed-practical-and-licensed-vocational-nurses.htm#tab-2',
  [Occupation['Midwives']]: 'https://www.onetonline.org/link/summary/29-9099.01',
  [Occupation['Occupational Therapy Assistants']]: 'https://www.onetonline.org/link/summary/31-2011.00',
  [Occupation['Physical Therapist Aides']]: 'https://www.bls.gov/ooh/healthcare/physical-therapist-assistants-and-aides.htm#tab-2',
  [Occupation['Massage Therapists']]: 'https://www.bls.gov/ooh/healthcare/massage-therapists.htm#tab-2',
  [Occupation['Dental Assistants']]: 'https://www.bls.gov/ooh/healthcare/dental-assistants.htm#tab-2',
  [Occupation['Medical Assistants']]: 'https://www.bls.gov/ooh/healthcare/medical-assistants.htm#tab-2',
  [Occupation['Medical Equipment Preparers']]: 'https://www.bls.gov/oes/2018/may/oes319093.htm',
  [Occupation['Pharmacy Aides']]: 'https://www.onetonline.org/link/summary/31-9095.00',
  [Occupation['Veterinary Assistants and Laboratory Animal Caretakers']]: 'https://www.bls.gov/ooh/healthcare/veterinary-assistants-and-laboratory-animal-caretakers.htm#tab-2',
  [Occupation['Speech-Language Pathology Assistants']]: 'https://www.onetonline.org/link/summary/31-9099.01',
  [Occupation['Forest Fire Inspectors and Prevention Specialists']]: 'https://www.onetonline.org/link/summary/33-2022.00',
  [Occupation['Fish and Game Wardens']]: 'https://www.onetonline.org/link/summary/33-3031.00',
  [Occupation['Transit and Railroad Police']]: 'https://www.onetonline.org/link/summary/33-3052.00',
  [Occupation['Animal Control Workers']]: 'https://www.onetonline.org/link/summary/33-9011.00',
  [Occupation['Chefs and Head Cooks']]: 'https://www.bls.gov/ooh/food-preparation-and-serving/chefs-and-head-cooks.htm#tab-2',
  [Occupation['Cooks, Private Household']]: 'https://www.bls.gov/ooh/food-preparation-and-serving/cooks.htm#tab-2',
  [Occupation['Food Preparation Workers']]: 'https://www.bls.gov/ooh/food-preparation-and-serving/food-preparation-workers.htm#tab-2',
  [Occupation['Waiters and Waitresses']]: 'https://www.bls.gov/ooh/food-preparation-and-serving/waiters-and-waitresses.htm#tab-2',
  [Occupation['Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop']]: 'https://www.bls.gov/ooh/food-preparation-and-serving/food-and-beverage-serving-and-related-workers.htm#tab-2',
  [Occupation['Landscaping and Groundskeeping Workers']]: 'https://www.bls.gov/ooh/building-and-grounds-cleaning/grounds-maintenance-workers.htm#tab-2',
  [Occupation['Animal Caretakers']]: 'https://www.bls.gov/ooh/personal-care-and-service/animal-care-and-service-workers.htm#tab-2',
  [Occupation['Barbers']]: 'https://www.bls.gov/ooh/personal-care-and-service/barbers-hairstylists-and-cosmetologists.htm#tab-2',
  [Occupation['Hairdressers, Hairstylists, and Cosmetologists']]: 'https://www.bls.gov/ooh/personal-care-and-service/barbers-hairstylists-and-cosmetologists.htm#tab-3',
  [Occupation['Makeup Artists, Theatrical and Performance']]: 'https://www.onetonline.org/link/summary/39-5091.00',
  [Occupation['Manicurists and Pedicurists']]: 'https://www.bls.gov/ooh/personal-care-and-service/manicurists-and-pedicurists.htm#tab-2',
  [Occupation['Skincare Specialists']]: 'https://www.bls.gov/ooh/personal-care-and-service/skincare-specialists.htm#tab-2',
  [Occupation['Tour Guides and Escorts']]: 'https://www.onetonline.org/link/summary/39-7011.00',
  [Occupation['Childcare Workers']]: 'https://www.bls.gov/ooh/personal-care-and-service/childcare-workers.htm#tab-2',
  [Occupation['Residential Advisors']]: 'https://www.onetonline.org/link/summary/39-9041.00',
  [Occupation['Cashiers']]: 'https://www.bls.gov/ooh/sales/cashiers.htm#tab-2',
  [Occupation['Retail Salespersons']]: 'https://www.bls.gov/ooh/sales/retail-sales-workers.htm#tab-2',
  [Occupation['Travel Agents']]: 'https://www.bls.gov/ooh/sales/travel-agents.htm#tab-2',
  [Occupation['Sales Representatives']]: 'https://www.onetonline.org/link/summary/41-4012.00',
  [Occupation['Demonstrators and Product Promoters']]: 'https://www.onetonline.org/link/summary/41-9011.00',
  [Occupation['Real Estate Sales Agents']]: 'https://www.bls.gov/ooh/sales/real-estate-brokers-and-sales-agents.htm#tab-2',
  [Occupation['Payroll and Timekeeping Clerks']]: 'https://www.bls.gov/ooh/office-and-administrative-support/financial-clerks.htm#tab-2',
  [Occupation['Procurement Clerks']]: 'https://www.bls.gov/ooh/office-and-administrative-support/financial-clerks.htm#tab-3',
  [Occupation['Tellers']]: 'https://www.bls.gov/ooh/office-and-administrative-support/tellers.htm#tab-2',
  [Occupation['Customer Service Representatives']]: 'https://www.bls.gov/ooh/office-and-administrative-support/customer-service-representatives.htm#tab-2',
  [Occupation['Library Assistants, Clerical']]: 'https://www.bls.gov/ooh/education-training-and-library/library-technicians-and-assistants.htm#tab-2',
  [Occupation['Human Resources Assistants']]: 'https://www.bls.gov/ooh/business-and-financial/human-resources-specialists.htm#tab-2',
  [Occupation['Receptionists and Information Clerks']]: 'https://www.bls.gov/ooh/office-and-administrative-support/receptionists.htm#tab-2',
  [Occupation['Cargo and Freight Agents']]: 'https://www.onetonline.org/link/summary/43-5011.00',
  [Occupation['Postal Service Mail Carriers']]: 'https://www.bls.gov/ooh/office-and-administrative-support/postal-service-workers.htm#tab-2',
  [Occupation['Secretaries and Administrative Assistants']]: 'https://www.bls.gov/ooh/office-and-administrative-support/secretaries-and-administrative-assistants.htm#tab-2',
  [Occupation['Data Entry Keyers']]: 'https://www.onetonline.org/link/summary/43-9021.00',
  [Occupation['Word Processors and Typists']]: 'https://www.onetonline.org/link/summary/43-9022.00',
  [Occupation['Statistical Assistants']]: 'https://www.onetonline.org/link/summary/43-9111.00',
  [Occupation['Animal Breeders']]: 'https://www.bls.gov/ooh/personal-care-and-service/animal-care-and-service-workers.htm#tab-2',
  [Occupation['Agricultural Equipment Operators']]: 'https://www.bls.gov/ooh/farming-fishing-and-forestry/agricultural-workers.htm#tab-2',
  [Occupation['Forest and Conservation Workers']]: 'https://www.bls.gov/ooh/farming-fishing-and-forestry/forest-and-conservation-workers.htm#tab-2',
  [Occupation['Logging Equipment Operators']]: 'https://www.bls.gov/ooh/farming-fishing-and-forestry/logging-workers.htm#tab-2',
  [Occupation['First-Line Supervisors of Construction Trades and Extraction Workers']]: 'https://www.onetonline.org/link/summary/47-1011.00',
  [Occupation['Boilermakers']]: 'https://www.bls.gov/ooh/construction-and-extraction/boilermakers.htm#tab-2',
  [Occupation['Stonemasons']]: 'https://www.bls.gov/ooh/construction-and-extraction/brickmasons-blockmasons-and-stonemasons.htm#tab-2',
  [Occupation['Tile and Stone Setters']]: 'https://www.bls.gov/ooh/construction-and-extraction/tile-and-marble-setters.htm#tab-2',
  [Occupation['Cement Masons and Concrete Finishers']]: 'https://www.bls.gov/ooh/construction-and-extraction/brickmasons-blockmasons-and-stonemasons.htm#tab-2',
  [Occupation['Operating Engineers and Other Construction Equipment Operators']]: 'https://www.bls.gov/ooh/construction-and-extraction/construction-equipment-operators.htm#tab-2',
  [Occupation['Drywall and Ceiling Tile Installers']]: 'https://www.bls.gov/ooh/construction-and-extraction/drywall-and-ceiling-tile-installers-and-tapers.htm#tab-2',
  [Occupation['Electricians']]: 'https://www.bls.gov/ooh/construction-and-extraction/electricians.htm#tab-2',
  [Occupation['Glaziers']]: 'https://www.bls.gov/ooh/construction-and-extraction/glaziers.htm#tab-2',
  [Occupation['Painters, Construction and Maintenance']]: 'https://www.bls.gov/ooh/construction-and-extraction/painters-construction-and-maintenance.htm#tab-2',
  [Occupation['Roofers']]: 'https://www.bls.gov/ooh/construction-and-extraction/roofers.htm#tab-2',
  [Occupation['Rail-Track Laying and Maintenance Equipment Operators']]: 'https://www.onetonline.org/link/summary/47-4061.00',
  [Occupation['Computer, Automated Teller, and Office Machine Repairers']]: 'https://www.bls.gov/ooh/about/data-for-occupations-not-covered-in-detail.htm',
  [Occupation['Electric Motor, Power Tool, and Related Repairers']]: 'https://www.bls.gov/ooh/installation-maintenance-and-repair/electrical-and-electronics-installers-and-repairers.htm#tab-2',
  [Occupation['Security and Fire Alarm Systems Installers']]: 'https://www.onetonline.org/link/summary/49-2098.00',
  [Occupation['Automotive Body and Related Repairers']]: 'https://www.bls.gov/ooh/installation-maintenance-and-repair/automotive-body-and-glass-repairers.htm#tab-2',
  [Occupation['Maintenance and Repair Workers, General']]: 'https://www.bls.gov/ooh/installation-maintenance-and-repair/general-maintenance-and-repair-workers.htm#tab-2',
  [Occupation['Commercial Divers']]: 'https://www.onetonline.org/link/summary/49-9092.00',
  [Occupation['Aircraft Structure, Surfaces, Rigging, and Systems Assemblers']]: 'https://www.bls.gov/ooh/production/assemblers-and-fabricators.htm#tab-2',
  [Occupation['Engine and Other Machine Assemblers']]: 'https://www.bls.gov/ooh/production/assemblers-and-fabricators.htm#tab-3',
  [Occupation['Bakers']]: 'https://www.bls.gov/ooh/production/bakers.htm#tab-2',
  [Occupation['Butchers and Meat Cutters']]: 'https://www.bls.gov/ooh/production/butchers-and-meat-cutters.htm#tab-2',
  [Occupation['Machinists']]: 'https://www.bls.gov/ooh/production/machinists-and-tool-and-die-makers.htm#tab-2',
  [Occupation['Tool and Die Makers']]: 'https://www.bls.gov/ooh/production/machinists-and-tool-and-die-makers.htm#tab-2',
  [Occupation['Printing Press Operators']]: 'https://www.onetonline.org/link/summary/51-5112.00',
  [Occupation['Shoe and Leather Workers and Repairers']]: 'https://www.onetonline.org/link/summary/51-6041.00',
  [Occupation['Tailors, Dressmakers, and Custom Sewers']]: 'https://www.onetonline.org/link/summary/51-6052.00',
  [Occupation['Patternmakers, Wood']]: 'https://www.onetonline.org/link/summary/51-7032.00',
  [Occupation['Water and Wastewater Treatment Plant and System Operators']]: 'https://www.bls.gov/ooh/production/water-and-wastewater-treatment-plant-and-system-operators.htm#tab-2',
  [Occupation['Chemical Plant and System Operators']]: 'https://www.onetonline.org/link/summary/51-8091.00',
  [Occupation['Petroleum Pump System Operators, Refinery Operators, and Gaugers']]: 'https://www.onetonline.org/link/summary/51-8093.00',
  [Occupation['Chemical Equipment Operators and Tenders']]: 'https://www.onetonline.org/link/summary/51-9011.00',
  [Occupation['Medical Appliance Technicians']]: 'https://www.bls.gov/ooh/production/dental-and-ophthalmic-laboratory-technicians-and-medical-appliance-technicians.htm#tab-2',
  [Occupation['Painting, Coating, and Decorating Workers']]: 'https://www.bls.gov/ooh/production/painting-and-coating-workers.htm#tab-2',
  [Occupation['Potters, Manufacturing']]: 'https://www.onetonline.org/link/summary/51-9195.05',
  [Occupation['Flight Attendants']]: 'https://www.bls.gov/ooh/transportation-and-material-moving/flight-attendants.htm#tab-2',
  [Occupation['Ambulance Drivers and Attendants']]: 'https://www.onetonline.org/link/summary/53-3011.00',
  [Occupation['Heavy and Tractor-Trailer Truck Drivers']]: 'https://www.bls.gov/ooh/transportation-and-material-moving/heavy-and-tractor-trailer-truck-drivers.htm#tab-2',
  [Occupation['Rail Yard Engineers, Dinkey Operators, and Hostlers']]: 'https://www.bls.gov/ooh/transportation-and-material-moving/railroad-occupations.htm#tab-2',
  [Occupation['Sailors and Marine Oilers']]: 'https://www.bls.gov/ooh/transportation-and-material-moving/water-transportation-occupations.htm#tab-2',
  [Occupation['Motorboat Operators']]: 'https://www.bls.gov/ooh/transportation-and-material-moving/water-transportation-occupations.htm#tab-2',
  [Occupation['Ship Engineers']]: 'https://www.bls.gov/ooh/architecture-and-engineering/marine-engineers-and-naval-architects.htm#tab-2',
};

export default links;
