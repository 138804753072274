import { useEffect, useState } from 'react';
// import { animated, useTransition } from '@react-spring/web';

import { useGameFlowStage } from 'appState';

type GameFlowProps = {
  children: Array<() => React.ReactElement>;
};

const GameFlow: React.FC<GameFlowProps> = ({ children: stages }) => {
  const currStage = useGameFlowStage();

  const [stage, setStage] = useState(0);
  useEffect(() => {
    setStage(prevStage => (prevStage < currStage ? currStage : prevStage));
  }, [currStage]);

  if (stage > stages.length - 1) {
    // eslint-disable-next-line no-console
    console.warn('Invalid game flow stage');
  }

  // const transitions = useTransition(stage, {
  //   from: stage => (
  //     stage === 0
  //       ? {
  //         position: 'fixed',
  //         width: '100%',
  //         minHeight: '100%',
  //         overflowX: 'hidden',
  //       } // first slide will be animated by the app flow
  //       : {
  //         position: 'fixed',
  //         width: '100%',
  //         minHeight: '100%',
  //         overflowX: 'hidden',
  //         transform: 'translate(100%,0)',
  //       }
  //   ),
  //   enter: { transform: 'translate(0%,0)' },
  //   leave: stage => (
  //     stage === stages.length - 1
  //       ? {} // final slide will be animated by the app flow
  //       : { transform: 'translate(-100%,0)' }
  //   ),
  //   config: {
  //     duration: 300,
  //   },
  // });

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        overflowX: 'hidden',
      }}
    >
      {stages[Math.min(stage, stages.length - 1)]()}
    </div>
  );
};

export default GameFlow;
