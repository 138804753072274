import { Button } from 'components/assessment';

import s from './Overview.module.scss';
import { useTranslation } from '../../../hooks';
import { TranslationPage } from '../../../types/enums/TranslationPage';
import { ReactNode } from 'react';

type Props = {
  username: string;
  testDate: string;
  heading: string;
  text: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  pdfHeading?: string;
  pdfDescription?: string[];
  showButtonIf?: boolean;
  children?: ReactNode;
};

const Overview: React.FC<Props> = ({
  username,
  testDate,
  heading,
  text,
  buttonLabel,
  onButtonClick,
  pdfHeading,
  pdfDescription,
  children: pdfButtons,
  showButtonIf = true,
}) => {
  const lang = useTranslation(TranslationPage.OVERVIEW);

  return (
    <section className={s.container}>
      <div className={s.infoCard}>
        <span className={s.infoEntry}>
          <span className={s.infoEntryKey}>{lang.name} </span>
          {username}
        </span>
        <span className={s.infoEntry}>
          <span className={s.infoEntryKey}>{lang.testDate} </span>
          {testDate}
        </span>
      </div>
      <h2 className={s.heading}>{heading}</h2>
      {/* eslint-disable-next-line max-len */}
      <p className={s.text}>{text}</p>
      {
        buttonLabel && showButtonIf && !!onButtonClick && (
          <div className={s.button}>
            <Button onClick={onButtonClick}>{buttonLabel}</Button>
          </div>
        )
      }
      {pdfHeading && pdfDescription && pdfButtons && (
        <>
          <div>
            <h2 className={s.pdfHeading}>{pdfHeading}</h2>
            {pdfDescription.map((description, index) =>
              <p key={`${description}-${index}`} className={s.pdfDescription}>{description}</p>
            )}
          </div>
          <div className={s.pdfButtons}>
            {pdfButtons}
          </div>
        </>
      )}
    </section>
  );
};
export default Overview;
