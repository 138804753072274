import { memo, useEffect, useRef } from 'react';
import { Shape } from 'react-konva';
import Konva from 'konva';
import { isEqual } from 'lodash';

import { useVirtualUnits } from 'appState';

import { Point } from './types';

function midPointBtw(p1: Point, p2: Point) {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  };
}

type ConnectorProps = {
  active?: boolean;
  wrong?: boolean;
  points: Point[];
};

const Connector: React.FC<ConnectorProps> = ({
  active: isActive,
  wrong: isWrong,
  points,
}) => {
  const v = useVirtualUnits();
  const connectorRef = useRef<Konva.Shape>(null);

  useEffect(() => {
    if (!isWrong || !connectorRef.current) return;
    const tween = new Konva.Tween({
      node: connectorRef.current,
      duration: 1,
      opacity: 0,
    });
    tween.play();
    return () => {
      tween.reset();
    };
  }, [points, isWrong, connectorRef]);

  if (points.length < 3) {
    return null;
  }

  const stroke = isActive ? '#333333' : isWrong ? '#FF6584' : '#a2a2a2';

  return (
    <>
      <Shape
        stroke={stroke}
        strokeWidth={v.w * 0.75}
        ref={connectorRef}
        sceneFunc={(ctx, shape) => {
          let p1 = points[0];
          let p2 = points[1];
          ctx.moveTo(p2.x * v.w, p2.y * v.w);
          ctx.beginPath();

          // eslint-disable-next-line no-plusplus
          for (let i = 1, len = points.length; i < len; i++) {
            const midPoint = midPointBtw(p1, p2);
            ctx.quadraticCurveTo(p1.x * v.w, p1.y * v.w, midPoint.x * v.w, midPoint.y * v.w);
            p1 = points[i];
            p2 = points[i + 1];
          }

          ctx.lineTo(p1.x * v.w, p1.y * v.w);
          shape.lineCap('round');
          shape.lineJoin('round');

          ctx.fillStrokeShape(shape);
        }}
      />
    </>
  );
};

export default memo(Connector, isEqual);
