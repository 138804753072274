import image_minus2 from './-2.png';
import image_minus2A from './-2A.jpg';
import image_minus2B from './-2B.jpg';
import image_minus2C from './-2C.jpg';
import image_minus2D from './-2D.jpg';
import image_minus2E from './-2E.jpg';

import image_0 from './0.png';
import image_0A from './0A.jpg';
import image_0B from './0B.jpg';
import image_0C from './0C.jpg';
import image_0D from './0D.jpg';
import image_0E from './0E.jpg';

import image_4 from './4.png';
import image_4A from './4A.jpg';
import image_4B from './4B.jpg';
import image_4C from './4C.jpg';
import image_4D from './4D.jpg';
import image_4E from './4E.jpg';

import image_6 from './6.png';
import image_6A from './6A.jpg';
import image_6B from './6B.jpg';
import image_6C from './6C.jpg';
import image_6D from './6D.jpg';
import image_6E from './6E.jpg';

import image_8 from './8.png';
import image_8A from './8A.jpg';
import image_8B from './8B.jpg';
import image_8C from './8C.jpg';
import image_8D from './8D.jpg';
import image_8E from './8E.jpg';

import image_9 from './9.png';
import image_9A from './9A.jpg';
import image_9B from './9B.jpg';
import image_9C from './9C.jpg';
import image_9D from './9D.jpg';
import image_9E from './9E.jpg';

import image_12 from './12.png';
import image_12A from './12A.jpg';
import image_12B from './12B.jpg';
import image_12C from './12C.jpg';
import image_12D from './12D.jpg';
import image_12E from './12E.jpg';

import image_13 from './13.png';
import image_13A from './13A.jpg';
import image_13B from './13B.jpg';
import image_13C from './13C.jpg';
import image_13D from './13D.jpg';
import image_13E from './13E.jpg';

import image_17 from './17.png';
import image_17A from './17A.jpg';
import image_17B from './17B.jpg';
import image_17C from './17C.jpg';
import image_17D from './17D.jpg';
import image_17E from './17E.jpg';

import image_21 from './21.png';
import image_21A from './21A.jpg';
import image_21B from './21B.jpg';
import image_21C from './21C.jpg';
import image_21D from './21D.jpg';
import image_21E from './21E.jpg';

import image_ex1 from './Ex1.png';
import image_ex1A from './Ex1A.jpg';
import image_ex1B from './Ex1B.jpg';
import image_ex1C from './Ex1C.jpg';
import image_ex1D from './Ex1D.jpg';
import image_ex1E from './Ex1E.jpg';

import image_ex2 from './Ex2.png';
import image_ex2A from './Ex2A.jpg';
import image_ex2B from './Ex2B.jpg';
import image_ex2C from './Ex2C.jpg';
import image_ex2D from './Ex2D.jpg';
import image_ex2E from './Ex2E.jpg';

const images = {
  image_minus2,
  image_minus2A,
  image_minus2B,
  image_minus2C,
  image_minus2D,
  image_minus2E,
  image_0,
  image_0A,
  image_0B,
  image_0C,
  image_0D,
  image_0E,
  image_4,
  image_4A,
  image_4B,
  image_4C,
  image_4D,
  image_4E,
  image_6,
  image_6A,
  image_6B,
  image_6C,
  image_6D,
  image_6E,
  image_8,
  image_8A,
  image_8B,
  image_8C,
  image_8D,
  image_8E,
  image_9,
  image_9A,
  image_9B,
  image_9C,
  image_9D,
  image_9E,
  image_12,
  image_12A,
  image_12B,
  image_12C,
  image_12D,
  image_12E,
  image_13,
  image_13A,
  image_13B,
  image_13C,
  image_13D,
  image_13E,
  image_17,
  image_17A,
  image_17B,
  image_17C,
  image_17D,
  image_17E,
  image_21,
  image_21A,
  image_21B,
  image_21C,
  image_21D,
  image_21E,
  image_ex1,
  image_ex1A,
  image_ex1B,
  image_ex1C,
  image_ex1D,
  image_ex1E,
  image_ex2,
  image_ex2A,
  image_ex2B,
  image_ex2C,
  image_ex2D,
  image_ex2E,
};

export default images;
