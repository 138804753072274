// Last Updated 20th November 2023 using:
// https://browsersl.ist/#q=%3E0.1%25%2C+not+dead%2C+not+op_mini+all
// and complimented by insecure browsers list of https://browser-update.org/browsers.html
// https://en.wikipedia.org/wiki/Android_version_history
// https://en.wikipedia.org/wiki/IOS_version_history

export const InsecureBelowVersionsList: {[key: string]: number} = {
  Android: 10,
  iOS: 14.8
}

export const browserDetectionConfig = {
  required: {
    f: 115,     // firefox
    o: 102,     // opera
    o_a: 73,    // opera android
    s: 9.1,     // safari
    c: 70,      // chrome
    ios: 12,    //safari ios
    samsung: 21 // samsung browser
  },
  insecure: true,
  nomessage: true,
}
