/* eslint-disable max-len */
import {
  useEffect,
  useRef,
  useState,
  createRef,
} from 'react';
import classnames from 'classnames';

import { useVirtualViewportSize } from 'appState';

import s from '../MTPT.module.scss';
import { Point } from '../types';

const checkDistance = (p1: Point, p2: Point) => (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2;

type ShapeProps = {
  cursor: Point;
  isFinished: boolean;
  hasError: boolean;
  isTracking: boolean;
  onError: () => void;
  onProgress: (progress: number) => void;
  setStartingPointRef: React.Dispatch<SVGEllipseElement | null>;
};

const Shape3: React.FC<ShapeProps> = ({
  cursor,
  isFinished,
  hasError,
  onError,
  onProgress,
  isTracking,
  setStartingPointRef,
}) => {
  const vp = useVirtualViewportSize();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const shapeRef = useRef<SVGPathElement | null>(null);
  const [progressShapes] = useState([
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
    createRef<SVGEllipseElement>(),
  ]);

  const [activatedProgressShapes] = useState(new Set<SVGEllipseElement>());
  if (!isTracking) {
    activatedProgressShapes.clear();
  }

  const [initialPoint, setInitialPoint] = useState<Point | null>(null);
  useEffect(() => {
    if (isTracking && !initialPoint) {
      setInitialPoint(cursor);
    } else if (!isTracking) {
      setInitialPoint(null);
    }
  }, [initialPoint, setInitialPoint, cursor, isTracking]);

  useEffect(() => {
    if (!svgRef.current || !shapeRef.current) {
      return;
    }
    const { left, top } = svgRef.current!.getBoundingClientRect();
    const cursorAbsolute = [
      cursor.x + left,
      cursor.y + top,
    ] as const;
    const elements = document.elementsFromPoint(...cursorAbsolute);

    const isInsideShape = elements.includes(shapeRef.current!);
    if (!isInsideShape) {
      onError();
    }

    // There are 9 progress shapes, each representing 10% of the trace progress. This means that
    // completing all of them makes up for 90% of the total progress.
    const progressShape = elements.find(e => progressShapes.map(s => s.current).includes(e as SVGEllipseElement));
    const containsChosenShape = activatedProgressShapes.has(progressShape as SVGEllipseElement);
    if (progressShape && !containsChosenShape) {
      activatedProgressShapes.add(progressShape as SVGEllipseElement);
      onProgress((activatedProgressShapes.size) / (progressShapes.length + 1));
    }

    // Once all intermediate progress shapes are activated, check if the current cursor position
    // is close enough to the initial position. If so, notify 100% progress.
    if (activatedProgressShapes.size === progressShapes.length && initialPoint) {
      const isFullTrace = checkDistance(cursor, initialPoint) <= window.innerWidth / 2;
      if (isFullTrace) {
        onProgress(1);
      }
    }
  }, [cursor, initialPoint, onError, onProgress, progressShapes, activatedProgressShapes, vp]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1778 1000"
      xmlSpace="preserve"
      id="svg821"
      width="1778"
      height="1000"
      ref={svgRef}
      className={classnames(
        s.svg,
        isFinished && s.isFinished,
        hasError && s.hasError,
      )}
    >
      <path
        ref={shapeRef}
        className={s.mainShape}
        d="m 1259.0045,743.2804 c -5.891,2.7471 -12.6333,3.905 -19.363,2.9606 L 931.08006,705.4201 764.01119,968.031 c -7.76053,12.2252 -22.15251,18.2742 -36.21567,15.5636 -14.15379,-2.6683 -25.12135,-13.8841 -27.73303,-28.0032 L 643.60088,649.4721 342.26348,571.8503 c -13.9481,-3.647 -24.27513,-15.3821 -25.94435,-29.6097 -1.80212,-14.2759 5.37896,-28.2169 18.0917,-35.0277 L 608.05544,358.86684 588.67012,48.25777 c -0.85965,-14.3844 7.11268,-27.81168 20.16351,-33.89738 13.05083,-6.08571 28.46124,-3.56197 38.92776,6.34267 L 873.33173,235.16647 1162.8668,120.89756 c 13.4312,-5.27002 28.5936,-1.8583 38.4619,8.65633 9.7776,10.5569 12.2198,25.96877 6.0056,38.90724 L 1073.0518,449.32624 1271.2599,689.348 c 9.1371,11.0763 10.6368,26.5966 3.6253,39.2449 -3.669,6.5657 -9.2646,11.6024 -15.8807,14.6875 z M 874.95647,603.76762 c 5.891,-2.747 12.63334,-3.9049 19.36298,-2.9605 L 1066.2604,616.47675 962.6347,470.80009 c -8.65997,-10.52637 -10.45598,-25.02583 -4.6165,-37.3482 l 81.7149,-150.03778 -173.31893,75.43462 c -12.66388,5.02256 -27.12572,2.16684 -36.98225,-7.13955 L 697.86352,222.07527 721.5589,413.45333 c 0.83571,13.6232 -6.31484,26.4466 -18.30253,32.91926 l -159.73355,71.2285 181.12233,41.14834 c 13.19301,3.4474 23.18805,14.234 25.6429,27.5435 l 18.59355,177.78428 91.27005,-147.33739 c 3.6929,-5.8045 8.91382,-10.2251 14.80482,-12.9722 z"
      />
      <ellipse
        ref={setStartingPointRef}
        className={s.progressShape}
        cx="960"
        cy="630"
        rx="29"
        ry="29"
      />
      <ellipse
        ref={progressShapes[0]}
        className={s.progressShape}
        cx="431.45374"
        cy="530.78217"
        rx="128.30118"
        ry="80.857986"
      />
      <ellipse
        ref={progressShapes[1]}
        className={s.progressShape}
        cx="655.52386"
        cy="389.70584"
        rx="92.957588"
        ry="95.257263"
      />
      <ellipse
        ref={progressShapes[2]}
        className={s.progressShape}
        cx="650.21448"
        cy="126.68357"
        rx="92.593056"
        ry="127.27484"
      />
      <ellipse
        ref={progressShapes[3]}
        className={s.progressShape}
        cx="862.04395"
        cy="284.25409"
        rx="77.785683"
        ry="97.767021"
      />
      <ellipse
        ref={progressShapes[4]}
        className={s.progressShape}
        cx="1117.4637"
        cy="203.99878"
        rx="130.22415"
        ry="123.48094"
      />
      <ellipse
        ref={progressShapes[5]}
        className={s.progressShape}
        cx="1038.9625"
        cy="447.00626"
        rx="98.362839"
        ry="80.857986"
      />
      <ellipse
        ref={progressShapes[6]}
        className={s.progressShape}
        cx="1159.1726"
        cy="675.36884"
        rx="131.73801"
        ry="102.20787"
      />
      <ellipse
        ref={progressShapes[7]}
        className={s.progressShape}
        cx="740.8963"
        cy="870.51788"
        rx="77.785683"
        ry="127.10259"
      />
      <ellipse
        ref={progressShapes[8]}
        className={s.progressShape}
        cx="677.198"
        cy="625.93805"
        rx="94.028351"
        ry="94.773514"
      />
    </svg>
  );
};

export default Shape3;
