import useCPTGameState from './CPTState';
import { useShallow } from 'zustand/react/shallow';

export const useCPTGameActions = () => useCPTGameState(useShallow(s => ({
  togglePracticeMode: s.togglePracticeMode,
  toggleExtendedMode: s.toggleExtendedMode,
  nextGameBlock: s.nextGameBlock,
  continueCurrentBlock: s.continueCurrentBlock,
  startNewGame: s.startNewGame,
  onLetterClick: s.onLetterClick,
  resetGameStage: s.resetGameStage,
  startIntroTimer: s.startIntroTimer,
  endIntroTimer: s.endIntroTimer,
  saveGameResult: s.saveGameResult,
})));

export const useCPTGameData = () => useCPTGameState(useShallow(s => ({
  gameStage: s.gameStage,
  lettersBlock: s.lettersBlock,
  letterToDisplay: s.letterToDisplay,
  displayGameModal: s.displayGameModal,
  gameModalToDisplay: s.gameModalToDisplay,
  gameFinished: s.gameFinished,
  gameResult: s.gameResult,
  finalScore: s.finalScore,
  progress: s.progress,
})));
