import s from './ProgressBar.module.scss';

type ProgressBarProps = {
  progress: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => (
  <div className={s.progressBar}>
    <div
      className={s.progress}
      style={{ width: `${progress * 100}%` }}
    />
  </div>
);

export default ProgressBar;
