import { useEffect, useState } from 'react';

export const useIsLargeScreen = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(
    window.matchMedia('(min-width: 1000px)').matches
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLargeScreen(window.matchMedia('(min-width: 1000px)').matches);
    }, false);
    return () => {
      window.removeEventListener("resize", () => {
        setIsLargeScreen(window.matchMedia('(min-width: 1000px)').matches);
      }, false);
    }
  }, []);

  return isLargeScreen;
};
