// import { useViewportSize, useVirtualViewportSize } from 'appState';

import s from './AspectRatioView.module.scss';
import { ReactNode } from 'react';

type GameViewProps = {
  background?: string;
  children?: ReactNode;
};

const AspectRatioView: React.FC<GameViewProps> = ({ background, children }) => {
  // const viewport = useViewportSize();
  // const virtualViewport = useVirtualViewportSize();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div
        className={s.outerContainer}
        style={{
          background,
          // width: `${viewport.width}px`,
          // height: `${viewport.height}px`,
        }}
      >
        <div
          className={s.innerContainer}
          style={{
            width: '100%', //`${virtualViewport.width}px`,
            height: '100%', //`${virtualViewport.height}px`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AspectRatioView;
