import imagePractice from './images/practice.jpg';
import image01 from './images/01.jpg';
import image02 from './images/02.jpg';
import image03 from './images/03.jpg';
import image04 from './images/04.jpg';
import image05 from './images/05.jpg';
import image06 from './images/06.jpg';
import image07 from './images/07.jpg';
import image08 from './images/08.jpg';
import image09 from './images/09.jpg';
import image10 from './images/10.jpg';
import image11 from './images/11.jpg';
import image12 from './images/12.jpg';
import image13 from './images/13.jpg';
import image14 from './images/14.jpg';
import image15 from './images/15.jpg';
import image16 from './images/16.jpg';
import image17 from './images/17.jpg';
import image18 from './images/18.jpg';
import image19 from './images/19.jpg';
import image20 from './images/20.jpg';
import image21 from './images/21.jpg';
import image22 from './images/22.jpg';
import image23 from './images/23.jpg';
import image24 from './images/24.jpg';
import image25 from './images/25.jpg';
import image26 from './images/26.jpg';
import image27 from './images/27.jpg';
import image28 from './images/28.jpg';

import { TrialData } from './types';

export const practiceTrials: TrialData[] = [
  {
    gender: 'M',
    words: [
      'jealous',
      'scared',
      'relaxed',
      'hateful',
    ],
    correct: 1,
    image: imagePractice,
  },
];

export const mainTrials: TrialData[] = [
  { // #1
    trialNumber: 1,
    gender: 'F',
    words: [
      'hateful',
      'surprised',
      'kind',
      'cross',
    ],
    correct: 2,
    image: image01,
  },
  { // #2
    trialNumber: 2,
    gender: 'F',
    words: [
      'unkind',
      'cross',
      'surprised',
      'sad',
    ],
    correct: 3,
    image: image02,
  },
  { // #3
    trialNumber: 3,
    gender: 'M',
    words: [
      'friendly',
      'sad',
      'surprised',
      'worried',
    ],
    correct: 0,
    image: image03,
  },
  { // #4
    trialNumber: 4,
    gender: 'M',
    words: [
      'relaxed',
      'upset',
      'surprised',
      'excited',
    ],
    correct: 1,
    image: image04,
  },
  { // #5
    trialNumber: 5,
    gender: 'M',
    words: [
      'feeling sorry',
      'making somebody do something',
      'joking',
      'relaxed',
    ],
    correct: 2,
    image: image05,
  },
  { // #6
    trialNumber: 6,
    gender: 'M',
    words: [
      'hateful',
      'unkind',
      'worried',
      'bored',
    ],
    correct: 2,
    image: image06,
  },
  { // #7
    trialNumber: 7,
    gender: 'M',
    words: [
      'feeling sorry',
      'bored',
      'interested',
      'joking',
    ],
    correct: 2,
    image: image07,
  },
  { // #8
    trialNumber: 8,
    gender: 'M',
    words: [
      'remembering',
      'happy',
      'friendly',
      'angry',
    ],
    correct: 0,
    image: image08,
  },
  { // #9
    trialNumber: 9,
    gender: 'F',
    words: [
      'displeased',
      'hateful',
      'surprised',
      'thinking about something',
    ],
    correct: 3,
    image: image09,
  },
  { // #10
    trialNumber: 10,
    gender: 'M',
    words: [
      'kind',
      'shy',
      'not believing',
      'sad',
    ],
    correct: 2,
    image: image10,
  },
  { // #11
    trialNumber: 11,
    gender: 'M',
    words: [
      'bossy',
      'hopeful',
      'angry',
      'disgusted',
    ],
    correct: 1,
    image: image11,
  },
  { // #12
    trialNumber: 12,
    gender: 'M',
    words: [
      'confused',
      'joking',
      'sad',
      'serious',
    ],
    correct: 3,
    image: image12,
  },
  { // #13
    trialNumber: 13,
    gender: 'F',
    words: [
      'thinking about something',
      'upset',
      'excited',
      'happy',
    ],
    correct: 0,
    image: image13,
  },
  { // #14
    trialNumber: 14,
    gender: 'M',
    words: [
      'happy',
      'thinking about something',
      'excited',
      'kind',
    ],
    correct: 1,
    image: image14,
  },
  { // #15
    trialNumber: 15,
    gender: 'F',
    words: [
      'not believing',
      'friendly',
      'playful',
      'relaxed',
    ],
    correct: 0,
    image: image15,
  },
  { // #16
    trialNumber: 16,
    gender: 'F',
    words: [
      'made up her mind',
      'joking',
      'surprised',
      'bored',
    ],
    correct: 0,
    image: image16,
  },
  { // #17
    trialNumber: 17,
    gender: 'F',
    words: [
      'angry',
      'friendly',
      'unkind',
      'worried',
    ],
    correct: 3,
    image: image17,
  },
  { // #18
    trialNumber: 18,
    gender: 'M',
    words: [
      'thinking about something sad',
      'angry',
      'bossy',
      'friendly',
    ],
    correct: 0,
    image: image18,
  },
  { // #19
    trialNumber: 19,
    gender: 'F',
    words: [
      'angry',
      'daydreaming',
      'sad',
      'interested',
    ],
    correct: 3,
    image: image19,
  },
  { // #20
    trialNumber: 20,
    gender: 'M',
    words: [
      'kind',
      'surprised',
      'displeased',
      'excited',
    ],
    correct: 2,
    image: image20,
  },
  { // #21
    trialNumber: 21,
    gender: 'F',
    words: [
      'interested',
      'joking',
      'relaxed',
      'happy',
    ],
    correct: 0,
    image: image21,
  },
  { // #22
    trialNumber: 22,
    gender: 'F',
    words: [
      'playful',
      'kind',
      'surprised',
      'thinking about something',
    ],
    correct: 3,
    image: image22,
  },
  { // #23
    trialNumber: 23,
    gender: 'F',
    words: [
      'surprised',
      'sure about something',
      'joking',
      'happy',
    ],
    correct: 1,
    image: image23,
  },
  { // #24
    trialNumber: 24,
    gender: 'M',
    words: [
      'serious',
      'ashamed',
      'confused',
      'surprised',
    ],
    correct: 0,
    image: image24,
  },
  { // #25
    trialNumber: 25,
    gender: 'M',
    words: [
      'shy',
      'guilty',
      'daydreaming',
      'worried',
    ],
    correct: 3,
    image: image25,
  },
  { // #26
    trialNumber: 26,
    gender: 'F',
    words: [
      'joking',
      'relaxed',
      'nervous',
      'feeling sorry',
    ],
    correct: 2,
    image: image26,
  },
  { // #27
    trialNumber: 27,
    gender: 'M',
    words: [
      'ashamed',
      'excited',
      'not believing',
      'pleased',
    ],
    correct: 2,
    image: image27,
  },
  { // #28
    trialNumber: 28,
    gender: 'M',
    words: [
      'disgusted',
      'hateful',
      'happy',
      'bored',
    ],
    correct: 2,
    image: image28,
  },
];
