import classnames from 'classnames';

import s from './SubHeading.module.scss';
import { ReactNode } from 'react';

const SubHeading: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <h2 className={classnames(s.sub_heading)}>
    {children}
  </h2>
);

export default SubHeading;
