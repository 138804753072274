import { forwardRef, ReactNode } from 'react';
import s from './Layout.module.scss';

type Props = {
  children: ReactNode;
  id?: string;
  showIf?: boolean;
};

const ObservableSection = forwardRef<HTMLDivElement, Props>((
  { children, id, showIf: visible = true },
  ref,
) => (
  visible ? (
    <div
      className={s.section}
      {...id ? { id: `section-${id}` } : {}}
      ref={ref}
    >
      {children}
    </div>
  ) : null
));

export default ObservableSection;
