export enum GameId {
  TMT = 'tmt',
  CORSI = 'corsi',
  DigitSpan = 'digit-span',
  CPT = 'cpt',
  MTPT = 'mtpt',
  BRET = 'bret',
  RMET = 'rmet',
  HMT = 'hmt',
  BigFive = 'big-five-quiz',
  EI = 'ei-quiz',
  RIASEC = 'riasec-quiz',
  PP = 'pp-quiz',
  GM = 'growth-mindset-quiz',
}
