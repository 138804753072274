import classnames from 'classnames';

import s from './Tag.module.scss';

type Props = {
  children: string;
  className?: string;
  style?: React.CSSProperties;
};

const Tag: React.FC<Props> = ({ children, className, style }) => (
  <div
    className={classnames(s.tag, className)}
    style={style}
  >
    {children}
  </div>
);

export default Tag;
