import classnames from 'classnames';

import { Tag } from 'components/assessment';

import s from './CareerInterestCard.module.scss';
import { useTranslation } from '../../../hooks';
import { TranslationPage } from '../../../types/enums/TranslationPage';

type Props = {
  percentage: number;
};

const TRESHOLD_HIGH = 70;
const TRESHOLD_MEDIUM = 20;

const StrengthLabel: React.FC<Props> = ({ percentage }) => {
  const lang = useTranslation(TranslationPage.CAREER_INTEREST_CARD);

  const style = percentage >= TRESHOLD_HIGH
    ? s.strongest
    : percentage >= TRESHOLD_MEDIUM
      ? s.medium
      : s.low;

  const label = percentage >= TRESHOLD_HIGH
    ? lang.strongInterest
    : percentage >= TRESHOLD_MEDIUM
      ? lang.mediumInterest
      : lang.lowInterest;

  return (
    <Tag className={classnames(s.strengthTag, style)}>
      {label}
    </Tag>
  );
};

export default StrengthLabel;
