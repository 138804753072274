/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import s from './LearningCenter.module.scss';
import { useAssessmentStateActions } from '../../../AppFlow/Summary/Assessment/state';

const parseTitle = (rawTitle: string) => {
  const title = rawTitle.replace(/([^[]+)(.*)$/, '$1');
  const website = rawTitle.replace(/([^[]+)(\[(.+)\])?/, '$3');
  return { title, website };
};

type ResourceGroup = {
  heading?: string;
  items: Array<{
    url: string;
    title: string;
  }>;
};

type Props = {
  resources: ResourceGroup[];
  interactionType: string;
};

const LearningCenter: React.FC<Props> = ({ resources: groups, interactionType }) => {
  const { incrementInteraction } = useAssessmentStateActions();

  return (
    <section className={s.wrapper}>
      {groups.map(({ heading, items }, i) => (
        <Fragment key={`${heading}-${i}`}>
          {heading && <h3 className={s.groupName}>{heading}</h3>}
          <ul className={s.items}>
            {items.map(({ title, url }, i) => (
              <li className={s.item} key={`${url}-${i}`}>
                <a
                  href={url}
                  className={s.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => incrementInteraction(interactionType, title)}
                >
                  {parseTitle(title).title}
                </a>
                {parseTitle(title).website && (
                  <span className={s.linkWebsite}> [{parseTitle(title).website}]</span>
                )}
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </section>
  );
};

export default LearningCenter;
