/* eslint-disable max-len */
const useLinks = () => ({
  entrepreneurship: [
    {
      heading: 'Running Your Own Business',
      items: [
        { // #47
          title: 'Guide for Beginners [Coursera/The University of Barcelona]',
          url: 'https://www.coursera.org/learn/entrepreneur-guide-beginners',
        },
        { // #48
          title: 'How to Start your own Business Specialization [Coursera/Michigan State University]',
          url: 'https://www.coursera.org/specializations/start-your-own-business',
        },
        { // #49
          title: 'Entrepreneurship Specialization [Coursera/Wharton]',
          url: 'https://www.coursera.org/specializations/wharton-entrepreneurship',
        },
        { // #50
          title: 'Growing your Business [Coursera/University of Virginia]',
          url: 'https://www.coursera.org/specializations/entrepreneurship-growing-your-business',
        },
      ],
    },
    {
      heading: 'Financial',
      items: [
        { // #51
          title: 'Financial Analysis for Startups [Coursera/Yonsei University]',
          url: 'https://www.coursera.org/learn/financial-ratios',
        },
        { // #52
          title: 'Finance for Startups [Coursera/Korea Advanced Institute of Science and Technology]',
          url: 'https://www.coursera.org/learn/finance-for-startups',
        },
      ],
    },
    {
      heading: 'Small Business Marketng',
      items: [
        { // #53
          title: 'Marketing Strategy Specialization [Coursera/IE Business School]',
          url: 'https://www.coursera.org/specializations/marketing-strategy',
        },
        { // #54
          title: 'Small Business Marketing using Facebook [Coursera]',
          url: 'https://www.coursera.org/projects/facebook-small-business-marketing',
        },
        { // #55
          title: 'South Africa startup services',
          url: 'https://www.smtax.co.za/startup',
        },
      ],
    },
    {
      heading: 'Social Media Marketing',
      items: [
        { // #56
          title: 'Social Media Marketing Professional Certificate',
          url: 'https://www.facebook.com/business/learn/social-media-marketing-certificate-coursera',
        },
      ],
    },
    {
      heading: 'Growth Mindset',
      items: [
        { // #57
          title: 'Growth vs. Fixed Mindset [Coursera]',
          url: 'https://www.coursera.org/lecture/emerging-technologies-lifelong-learning/growth-vs-fixed-mindset-YulSB',
        },
        { // #58
          title: 'Growth Mindset [Alison]',
          url: 'https://alison.com/course/growth-mindsets-for-teachers-and-learners',
        },
      ],
    },
    {
      heading: 'Programming',
      items: [
        { // #59
          title: 'R Programming [Coursera/Johns Hopkins University]',
          url: 'https://www.coursera.org/learn/r-programming?specialization=jhu-data-science',
        },
      ],
    },
    {
      heading: 'Data Analysis in Excel',
      items: [
        { // #60
          title: 'Mastering Data Analysis in Excel [Coursera/Duke University]',
          url: 'https://www.coursera.org/learn/analytics-excel?specialization=excel-mysql',
        },
      ],
    },
    {
      heading: 'Mentoring Platform for Entrepreneurs',
      items: [
        { // #61
          title: 'Sky’s the Limit Platform',
          url: 'https://www.skysthelimit.org/accenture',
        },
      ],
    },
  ],
  networksAndInnovations: [
    {
      heading: 'Networks and Innovations',
      items: [
        { // #1
          title: 'Linkedin',
          url: 'https://www.linkedin.com/',
        },
        { // #2
          title: 'Ennomotive',
          url: 'https://www.ennomotive.com/',
        },
        { // #3
          title: 'Viima',
          url: 'https://www.viima.com/',
        },
        { // #4
          title: 'Youth Agency Marketplace',
          url: 'https://www.fondationbotnar.org/project/the-african-youth-digital-innovation-platform/',
        },
        { // #5
          title: 'Innovation Bridge Portal',
          url: 'https://www.innovationbridge.info/ibportal/',
        },
      ],
    },
    {
      heading: 'Some Funding Avenues',
      items: [
        { // #1
          title: 'NEPAD',
          url: 'https://nepad.org/skillsportalforyouth/overview',
        },
        { // #2
          title: 'Skills Africa',
          url: 'https://skillsafrica.org',
        },
      ],
    },
  ],
  career: [
    {
      heading: 'Visual-Spatial and Working Memory',
      items: [
        { // #2
          title: 'Art of Memory [Toolkit]',
          url: 'https://artofmemory.com/files/ebooklet/Learn_the_Art_of_Memory.pdf',
        },
        { // #3
          title: 'Art of Memory Training',
          url: 'https://artofmemory.com/training/',
        },
        { // #4
          title: 'Elevate Brain-Game',
          url: 'https://elevateapp.com/',
        },
        { // #5
          title: 'Lumosity Brain Training',
          url: 'https://www.lumosity.com/en/',
        },
        { // #6
          title: 'Mindshift: Break Through Obstacles to Learning and Discover Your Hidden Potential [Coursera]',
          url: 'https://www.coursera.org/learn/mindshift#about',
        },
        { // #7
          title: 'Learning How to Learn: Powerful mental tools to help you master tough subjects [Coursera]',
          url: 'https://www.coursera.org/learn/learning-how-to-learn#about',
        },
        { // #8
          title: 'Happy Neuron visual-spatial games',
          url: 'http://www.happy-neuron.com/brain-games/visual-spatial',
        },
      ],
    },
    {
      heading: 'Adaptability',
      items: [
        { // #9
          title: 'Luminosity attention games',
          url: 'https://www.lumosity.com/en/brain-games/attention-games/',
        },
        { // #10
          title: 'Sudoku games',
          url: 'https://www.websudoku.com/',
        },
        { // #11
          title: 'Coursera Biohacking your brain’s health: cognitive training',
          url: 'https://www.coursera.org/lecture/biohacking-your-brains-health/cognitive-training-UbdXA',
        },
        { // #12
          title: 'Brain HQ: cognitive training',
          url: 'https://www.brainhq.com/',
        },
        { // #13
          title: 'Lumosity Brain Training',
          url: 'https://www.lumosity.com/en/',
        },
      ],
    },
    {
      heading: 'Persistence',
      items: [
        { // #14
          title: 'Monitor and manage your goals: ClickUp',
          url: 'https://clickup.com/',
        },
        { // #15
          title: 'Keep on track with your long-term goals with EverNote',
          url: 'https://evernote.com/',
        },
        { // #16
          title: 'Improve Memory brain games',
          url: 'https://www.improvememory.org/brain-games/memory-games/',
        },
        { // #17
          title: 'Elevate Brain-Game',
          url: 'https://elevateapp.com/',
        },
        { // # 18
          title: 'Lumosity Brain Training',
          url: 'https://www.lumosity.com/en/',
        },
        { // #19
          title: 'Happy Neuron Memory Games',
          url: 'http://www.happy-neuron.com/brain-games/visual-spatial#memory',
        },
        { // #20
          title: 'Happy Neuron Attention Games',
          url: 'http://www.happy-neuron.com/brain-games/visual-spatial#attention',
        },
      ],
    },
    {
      heading: 'Problem Solving',
      items: [
        { // #21
          title: 'Queendom Puzzles',
          url: 'https://www.queendom.com/tests/testscontrol.htm?t=3',
        },
        { // #22
          title: 'Jigsaw Planet',
          url: 'https://www.jigsawplanet.com/',
        },
        { // #23
          title: 'Happy Neuron Reasoning Games',
          url: 'http://www.happy-neuron.com/brain-games/visual-spatial#executive-functions',
        },
      ],
    },
    {
      heading: 'Speed',
      items: [
        { // #24
          title: 'Elevate Brain-Game',
          url: 'https://elevateapp.com/',
        },
        { // #25
          title: 'Processing Speed Training',
          url: 'https://www.i3mindware.com/iq-training/gs-training',
        },
        { // #26
          title: 'Lumosity Brain Training',
          url: 'https://www.lumosity.com/en/',
        },
      ],
    },
    {
      heading: 'Risk Propensity',
      items: [
        { // #27
          title: 'Critical Thinking [Coursera]',
          url: 'https://www.coursera.org/search?query=critical%20thinking&amp;',
        },
        { // #28
          title: 'Critical Thinking [Alison]',
          url: 'https://alison.com/courses?query=critic',
        },
      ],
    },
    {
      heading: 'Emotion Recognition',
      items: [
        { // #29
          title: 'Emotional Intelligence Course List [Alison]',
          url: 'https://alison.com/courses?query=emotional%20intelligence',
        },
        { // #30
          title: 'Self-Awareness [Udemy]',
          url: 'https://www.udemy.com/course/emotional-intelligence-certification-self-awareness/',
        },
        { // #31
          title: 'Empathy and Social Awareness [Udemy]',
          url: 'https://www.udemy.com/course/empathy-social-awareness/',
        },
      ],
    },
    {
      heading: 'Emotional Intelligence',
      items: [
        { // #32
          title: 'Self-Awareness [Udemy]',
          url: 'https://www.udemy.com/course/emotional-intelligence-certification-self-awareness/',
        },
        { // #33
          title: 'Self-Management [Udemy]',
          url: 'https://www.udemy.com/course/emotional-intelligence-self-management/',
        },
        { // #34
          title: 'Empathy and Social Awareness [Udemy]',
          url: 'https://www.udemy.com/course/empathy-social-awareness/',
        },
        { // #35
          title: 'Relationship Management [Udemy]',
          url: 'https://www.udemy.com/course/relationship-management/',
        },
        { // #36
          title: 'Conflict Management [Coursera]',
          url: 'https://www.coursera.org/specializations/conflict-management',
        },
        { // #37
          title: 'Negotiation Skills [Coursera]',
          url: 'https://www.coursera.org/learn/negotiation-skills',
        },
        { // #38
          title: 'Emotional Intelligence Course List [Alison]',
          url: 'https://alison.com/courses?query=emotional%20intelligence',
        },
        { // #39
          title: 'Compassionate Integrity Training [UNESCO]',
          url: 'https://mgiep.unesco.org/cit',
        },
        { // #40
          title: 'Self Confidence Course List [Alison]',
          url: 'https://alison.com/courses?query=self%20confidence',
        },
      ],
    },
    {
      heading: 'Personality',
      items: [
        { // #41
          title: 'Openess to Experience: Creativity [Coursera]',
          url: 'https://www.coursera.org/search?query=creativity',
        },
        { // #42
          title: 'Openess to Experience: Innovation [Coursera]',
          url: 'https://www.coursera.org/search?query=innovation',
        },
        { // #43
          title: 'Emotional Intelligence Course List [Alison]',
          url: 'https://alison.com/courses?query=emotional%20intelligence',
        },
        { // #44
          title: 'Extraversion: Leadership [edX]',
          url: 'https://www.edx.org/search?q=leadership',
        },
        { // #45
          title: 'Agreeableness: Teamwork and Collaboration [Coursera]',
          url: 'https://www.coursera.org/search?query=teamwork%20and%20collaboration',
        },
      ],
    },
    {
      heading: 'Career Interests',
      items: [
        { // #46
          title: 'Strategic Career Self-Management [Coursera]',
          url: 'https://www.coursera.org/learn/strategic-career-self-management',
        },
      ],
    },
  ],
  careerOptions: [
    { // #62
      title: 'CV builder website',
      url: 'https://www.careers.govt.nz/tools/cv-builder',
    },
    { // #63
      title: 'Skillup resume helper',
      url: 'https://www.skillup.org/update-your-resume-for-each-job-you-apply-for/',
    },
    { // #64
      title: 'Prepare for an interview',
      url: 'https://www.careers.govt.nz/job-hunting/interviews/',
    },
    { // #65
      title: 'Prepare for an online interview',
      url: 'https://www.skillup.org/how-to-shine-through-your-computer-screen/',
    },
    { // #67
      title: 'South Africa: Pathways from School to Work',
      url: 'https://youth.dgmt.co.za/pathways-from-school-to-work/',
    },
  ],
});

export default useLinks;
