import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classnames from 'classnames';
import styles from './Carousel.module.scss';
import { CarouselComposition, CarouselProps } from './Carousel.types';
import SlideImage from './SlideImage/SlideImage';
import SlideText from './SlideImage/SlideText';

const prevArrow = (
  clickHandler: () => void,
  hasPrev: boolean,
): React.ReactNode => (
  <div onClick={clickHandler} className={classnames(styles.arrowPrev, hasPrev && styles.active)} />
);

const nextArrow = (
  clickHandler: () => void,
  hasNext: boolean,
): React.ReactNode => (
  <div onClick={clickHandler} className={classnames(styles.arrowNext, hasNext && styles.active)} />
);

const Carousel: React.FC<CarouselProps> & CarouselComposition = ({
  children,
  className,
}) => (
  <div
    className={classnames(styles.container, className)}
  >
    <ReactResponsiveCarousel
      showThumbs={false}
      renderArrowPrev={prevArrow}
      renderArrowNext={nextArrow}
    >
      { children }
    </ReactResponsiveCarousel>
  </div>
);

Carousel.SlideImage = SlideImage;
Carousel.SlideText = SlideText;

export default Carousel;
