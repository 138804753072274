import { useCallback, useState } from 'react';
import classnames from 'classnames';

import s from './PdfButton.module.scss';

const OUTCOME_DISPLAY_DURATION = 3000;

type Props = {
  action: () => Promise<unknown>;
  label: string;
  labelSuccess?: string;
  labelError?: string;
  icon: 'pdf' | 'email';
};

const PdfButton: React.FC<Props> = ({
  action,
  label: labelDefault,
  labelSuccess,
  labelError,
  icon,
}) => {
  const label = {
    default: labelDefault,
    success: labelSuccess || labelDefault,
    error: labelError || labelDefault,
  };
  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const displayLabel = success ? label.success : error ? label.error : label.default;
  const disabled = pending || success || error;

  const onClick = useCallback(() => {
    if (pending || success || error) {
      return;
    }
    setPending(true);

    let timeoutRef: number;
    action()
      .then(() => setSuccess(true))
      .catch(() => setError(true))
      .finally(() => {
        setPending(false);
        timeoutRef = window.setTimeout(() => {
          setSuccess(false);
          setError(false);
        }, OUTCOME_DISPLAY_DURATION);
      });
    return () => clearTimeout(timeoutRef);
  }, [action, pending, setPending, success, setSuccess, error, setError]);

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={classnames(s.button, s[icon])}
    >
      {displayLabel}
    </button>
  );
};

export default PdfButton;
