import { Occupation } from 'types/enums';

const greenOccupations: Array<Occupation> = [
  Occupation['General and Operations Managers'],
  Occupation['Industrial Production Managers'],
  Occupation['Human Resources Specialists'],
  Occupation['Architects'],
  Occupation['Wind Energy Engineers'],
  Occupation['Solar Energy Systems Engineers'],
  Occupation['Civil Engineering Technicians'],
  Occupation['Environmental Engineering Technicians'],
  Occupation['Industrial Engineering Technicians'],
  Occupation['Animal Scientists'],
  Occupation['Food Scientists and Technologists'],
  Occupation['Soil and Plant Scientists'],
  Occupation['Range Managers'],
  Occupation['Park Naturalists'],
  Occupation['Foresters'],
  Occupation['Epidemiologists'],
  Occupation['Medical Scientists'],
  Occupation['Chemical Technicians'],
  Occupation['Quality Control Analysts'],
  Occupation['Health Education Specialists'],
  Occupation['Commercial and Industrial Designers'],
  Occupation['Floral Designers'],
  Occupation['Graphic Designers'],
  Occupation['Merchandise Displayers'],
  Occupation['Set and Exhibit Designers'],
  Occupation['Veterinarians'],
  Occupation['Forest Fire Inspectors and Prevention Specialists'],
  Occupation['Fish and Game Wardens'],
  Occupation['Forest and Conservation Workers'],
  Occupation['Logging Equipment Operators'],
  Occupation['Tile and Stone Setters'],
  Occupation['Automotive Body and Related Repairers'],
  Occupation['Commercial Divers'],
  Occupation['Machinists'],
  Occupation['Water and Wastewater Treatment Plant and System Operators'],
];

export default greenOccupations;
