import { useEffect, useRef } from 'react';
import Konva from 'konva';
import { Group, Circle, Text } from 'react-konva';

import { useVirtualUnits } from 'appState';
import { Item, Point } from './types';
import useGameState from './useGameState';

const fillColor = {
  primary: '#fff',
  secondary: 'rgba(255,255,255,0.5)',
};

const strokeColor = {
  OK: '#a2a2a2',
  WRONG: '#FF6584',
  NEUTRAL: '#333333',
};

const isPointInCircle = (p: Point, c: Point, radius: number) =>
  (p.x - c.x) ** 2 + (p.y - c.y) ** 2 < radius ** 2;

type ItemShapeProps = Item;

const ItemShape: React.FC<ItemShapeProps> = ({
  index,
  label,
  pos,
  isCompleted,
  isMarkedWrong,
}) => {
  const radius = 3;

  const v = useVirtualUnits();

  const startDrawing = useGameState(s => s.startDrawing);
  const isLastCompletedItem = index === useGameState(s => s.getLastCompletedItem)().index;
  const onDrawStart = isLastCompletedItem
    ? {
      onMouseDown: () => startDrawing({ isTouchMode: false }),
      onTouchStart: () => startDrawing({ isTouchMode: true }),
    } : {};

  const didConnect = useGameState(s => s.didConnect);
  const isDrawingMode = useGameState(s => s.isDrawingMode);
  const connectorHead = useGameState(s => s.getActiveConnectorHead());
  useEffect(() => {
    if (!connectorHead || !isDrawingMode) {
      return;
    }
    const collisionDetected = isPointInCircle(connectorHead, pos, radius);
    if (collisionDetected) {
      didConnect(index);
    }
  }, [connectorHead, isDrawingMode, didConnect, index, pos]);

  const colorVariant = isCompleted ? 'OK' : isMarkedWrong ? 'WRONG' : 'NEUTRAL';
  const stroke = strokeColor[colorVariant];

  const fill = isCompleted && !isLastCompletedItem
    ? fillColor.secondary
    : fillColor.primary;

  const circleRef = useRef<Konva.Circle>(null);
  useEffect(() => {
    if (!isLastCompletedItem || isDrawingMode) {
      return;
    }
    const period = 250;

    const animation = new Konva.Animation(frame => {
      if (!frame) return;
      const opacity = Math.abs(Math.sin(frame.time / period) / 2) + 0.5;
      circleRef.current?.opacity(opacity);
    }, circleRef.current?.getLayer());
    animation.start();
    return () => {
      animation.stop();
    };
  }, [isLastCompletedItem, isDrawingMode]);

  return (
    <Group
      x={((pos.x - radius) * v.w)}
      y={((pos.y - radius) * v.w)}
    >
      <Circle
        radius={radius * v.w}
        x={radius * v.w}
        y={radius * v.w}
        strokeWidth={v.w / 4}
        stroke={stroke}
        shadowColor={fillColor.primary}
        fill={fill}
        ref={circleRef}
        {...onDrawStart}
      />
      <Text
        width={radius * 2 * v.w}
        height={radius * 2 * v.w}
        fontSize={v.w * 2.5}
        text={label}
        fill={stroke}
        align="center"
        verticalAlign="middle"
        listening={false}
      />
    </Group>
  );
};

export default ItemShape;
