import { JobSector } from 'types/enums';

import {
  validateNonEmpty,
  // validateNumber,
} from '../helpers';
import { FormError, MaybeEmptyString } from '../types';

import { FormValues } from './FormValues';

const makeValidator = (messages: Record<FormError, string>) =>
  (values: MaybeEmptyString<FormValues>) => {
    const errors = {
      reportUsefullness:
        validateNonEmpty(values.reportUsefullness),
      jobStrengthsAndWeaknesses:
        validateNonEmpty(values.reportUsefullness),
      helpJobSearch:
        validateNonEmpty(values.reportUsefullness),
      helpGettingJob:
        validateNonEmpty(values.reportUsefullness),
      // planToIncreaseJobSearch:
      //   validateNonEmpty(values.planToIncreaseJobSearch),
      // targetSector:
      //   validateNonEmpty(values.targetSector),
      // otherTargetSector: values.targetSector === JobSector.Other
      //   ? validateNonEmpty(values.otherTargetSector) : null,
      // howManyHoursWillSearchPerWeek:
      //   validateNonEmpty(values.howManyHoursWillSearchPerWeek),
      // howManyApplicationsNextMonth:
      //   validateNonEmpty(values.howManyApplicationsNextMonth),
      // willSkillsAttachHelpGetInterview:
      //   validateNonEmpty(values.planToIncreaseJobSearch),
      // plansToAttachSkillsReport:
      //   validateNonEmpty(values.expectsToFindJobInSixMonths),
      // wageOfSimilarEmployeesAfter:
      //   validateNumber(values.wageOfSimilarEmployeesAfter),
      // lowestAcceptablePayAfter:
      //   validateNumber(values.lowestAcceptablePayAfter),
      likelyToRecommendSkillcraft:
        validateNonEmpty(values.likelyToRecommendSkillcraft),
    };
    return Object.fromEntries(
      Object.entries(errors)
        .filter(([, value]) => value !== null)
        .map(([field, errorType]) => [field, messages[errorType as FormError]]),
    );
  };

export default makeValidator;
