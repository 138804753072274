import { useEffect, useState } from 'react';

import { useAppStateActions } from 'appState';
import s from './LoginByReferral.module.scss';

const useUrlData = () => {
  const url = new URL(window.location.href);
  return {
    pilotGroupSlug: url.pathname.split('/').filter(Boolean)[0],
    userId: url.searchParams.get('id'),
    userIdHash: url.searchParams.get('h'),
  };
};

const LogInByReferral: React.FC = () => {
  const [showContent, setShowContent] = useState(false);
  const { userId, userIdHash, pilotGroupSlug } = useUrlData();
  const { signIn } = useAppStateActions();

  useEffect(() => {
    if (!userId || !userIdHash) {
      setShowContent(true);
      return;
    }
    signIn({
      identifier: userId,
      identifierHash: userIdHash,
      authByReferral: true,
      identifierType: 'gepiId',
      pilotGroup: pilotGroupSlug,
    });
  }, [userId, userIdHash, pilotGroupSlug, signIn]);

  if (showContent) {
    return (
      <div className={s.container}>
        <p className={s.description}>
          This pilot group requires a referral link to log in.
          <br/><br/>
          If you have a referral link, please check if it is correct
          and refresh the app to try signing in again.
          <br/><br/>
          If the issue persists, please contact your provider.
        </p>
      </div>
    );
  }
  return null;
};

export default LogInByReferral;
