import { useIsLargeScreen } from 'hooks';
import { useAppState } from 'appState';
import { Section, Tab } from './enums';

export type UseSectionSelection = {
  onSectionSelected: (section: Section) => void;
  activeTab: Tab;
};

const useSectionSelection = (): UseSectionSelection => {
  const isDesktop = useIsLargeScreen();
  const {
    setAssessmentScrollPosition,
    setActiveAssessmentTab,
    activeAssessmentTab,
  } = useAppState();

  const onSectionSelected = (section: Section) => {
    const isCareerSection = [
      Section.CareerOverview,
      Section.CareerSkillStrenghts,
      Section.CareerInterests,
      Section.CareerOptions,
      Section.CareerLearningCenter,
    ].includes(section);
    setActiveAssessmentTab(isCareerSection ? Tab.Career : Tab.Entrepreneurship);

    setTimeout(() => {
      const element = document.getElementById(`section-${section}`);
      const offset = isDesktop ? 127 : 90;
      if (element !== null) {
        setAssessmentScrollPosition(element.offsetTop - offset);
      }
    });
  };

  return { onSectionSelected, activeTab: activeAssessmentTab };
};

export default useSectionSelection;
