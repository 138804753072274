import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  InputAdornment,
  TextField,
} from '@mui/material';

import { useAppStateActions } from 'appState';
import { AspectRatioView, ButtonRect } from 'components';
import PrivacyModal from './PrivacyModal';
import {
  isValidEmail, isValidName, isValidPhoneNumber,
} from '../../utils/regex';
import s from './Login.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';
import { useTranslation } from '../../hooks';

enum InputName {
  USERNAME = 'username',
  IDENTIFIER = 'identifier',
  PHONE_NUMBER = 'phoneNumber',
  CONSENT = 'consent',
}

const LogIn: React.FC = () => {
  const { signIn } = useAppStateActions();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [user, setUserData] = useState({
    identifier: '', phoneNumber: '', username: '', consent: false,
  });
  const [inputsErrors, setInputErrors] = useState({
    identifier: '', phoneNumber: '', username: '', consent: false,
  });
  const [isOpenModal, setOpenModal] = useState(false);
  const lang = useTranslation(TranslationPage.LOG_IN);
  // For this moment english is a default language for SA
  // const isInSouthAfrica = useLanguage() === 'en';
  const defaultError = lang.requiredFields;

  const inputs = useMemo(() => [
    {
      name: InputName.IDENTIFIER,
      errorMsg: lang.emailErrorMsg,
      checkValidityFn: isValidEmail,
      label: lang.email,
      type: 'identifier',
    },
    {
      name: InputName.PHONE_NUMBER,
      errorMsg: lang.phoneNumberErrorMsg,
      checkValidityFn: isValidPhoneNumber,
      label: lang.phoneNumber,
      type: 'tel',
    },
    {
      name: InputName.USERNAME,
      errorMsg: lang.nameErrorMsg,
      checkValidityFn: isValidName,
      label: lang.name,
      type: 'text',
    }], [lang]);

  useEffect(() => {
    const closeModal = (): void => {
      setOpenModal(false);
    };

    window.addEventListener('popstate', closeModal);
    return () => window.removeEventListener('popstate', closeModal);
  }, []);

  const getErrorMessage = (inputName: string, value: string): string => {
    let errorMessage = '';
    if (!value.length) {
      return defaultError;
    }
    const currentInput = inputs.find((input) => input.name === inputName);
    if (currentInput && !currentInput.checkValidityFn(value)) {
      errorMessage = currentInput.errorMsg;
    }
    return errorMessage;
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (isOpenModal) {
      return;
    }    
    setIsFormSubmitted(isValidForm());    
    const error = getErrorMessage(name, value);
    setUserData({ ...user, [name]: value });
    setInputErrors({ ...inputsErrors, [name]: error });
  };

  const isValidForm = (): boolean => {
    const {
      identifier,
      username,
      phoneNumber,
    } = user;
    // const canAuthWithRsaId = isInSouthAfrica
    //   ? isValidEmailOrRsaId(identifier)
    //   : isValidEmail(identifier);
    return (isValidEmail(identifier) && isValidName(username)
      && isValidPhoneNumber(phoneNumber));
  };

  const handleSubmit = (): void => {
    const {
      phoneNumber,
      username,
      identifier,
      consent,
    } = user;

    if (isValidForm()) {
      const pilotGroup = new URL(window.location.href).pathname.split('/').filter(Boolean)[0];
      setIsFormSubmitted(false);
      signIn({
        identifier,
        identifierType: 'email',
        phoneNumber,
        username,
        pilotGroup,
      });
    } else {
      setInputErrors({
        identifier: getErrorMessage('identifier', identifier),
        phoneNumber: getErrorMessage('phoneNumber', phoneNumber),
        username: getErrorMessage('username', username),
        consent: consent ? !consent : true,
      });
    }
  };

  return (
    <AspectRatioView>
      <div className={s.container}>
        <div className={s.loginImg} />
        <form
          className={classNames(s.loginForm)}
          noValidate
          onSubmit={handleSubmit}
        >
          <div className={s.row}>
            <h4>{lang.welcome}</h4>
            <div className={s.loginIcon} />
          </div>
          <div className={s.formInputs}>
            {inputs.map(({ name, label, type }) => (
              <TextField
                key={name}
                name={name}
                label={label}
                color="primary"
                variant="outlined"
                onChange={handleChangeInput}
                required
                value={user[name]}
                helperText={inputsErrors[name]}
                error={!!inputsErrors[name]}
                className={classNames(s.field)}
                type={type}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className={classNames(s.icon, {
                        [s.visible]: !!user[name],
                        [s.success]: !inputsErrors[name],
                      })}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            ))}
            <p className={s.reminderText}>{lang.detailsReminder}</p>
          </div>
          <ButtonRect
            onClick={handleSubmit}
            text={lang.login}
            className={classNames(s.loginBtn, { [s.disabled]: !isFormSubmitted })}
          />
          <span className={s.requiredInfo}>*{lang.requiredFields}</span>
        </form>
        <PrivacyModal onClick={() => setOpenModal(false)} open={isOpenModal} />
      </div>
    </AspectRatioView>
  );
};

export default LogIn;
