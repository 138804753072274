import { useAppStateActions } from 'appState';
import { AspectRatioView, ButtonRect, LanguageSwitch } from 'components';
import { useTranslation } from 'hooks';

import image from 'assets/images/correct.svg';
import s from './Summary.module.scss';
import { TranslationPage } from '../../../types/enums/TranslationPage';

type SummaryProps = {
  gameName: string,
  result?: string | number,
};

const Summary: React.FC<SummaryProps> = ({
  gameName,
  result,
}) => {
  const { appFlowForward } = useAppStateActions();
  const lang = useTranslation(TranslationPage.SUMMARY);

  return (
    <AspectRatioView>
      <div className={s.container}>
        <div className={s.row}>
          <LanguageSwitch />
          <div>
            <h4 className={s.gameName}>{gameName}</h4>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.resultWrapper}>
            <img src={image} className={s.image} alt="" />
            <p className={s.gameOver}>{lang.gameOver}</p>
            {result && <span className={s.result}>{result}</span>}
          </div>
        </div>
        <ButtonRect onClick={appFlowForward} text={lang.nextButton} className={s.btn} />
      </div>
    </AspectRatioView>
  );
};

export default Summary;
