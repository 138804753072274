import { create } from 'zustand';
import {
  DIGIT_CYCLE_INTERVAL_TIME,
  DIGIT_CYCLE_START_DELAY,
  MAX_ROUNDS_PRACTICE,
  MAX_ROUNDS_TRIAL,
  ROUND_MIN_DIGITS,
  ROUND_START_MIN_DIGITS,
} from './digitSpanState.const';

export enum DigitSpanStage {
  BEGINNING,
  SHOW_DIGITS,
  PAUSE,
  REPEAT_DIGITS,
  FINISHED,
}

export enum DigitSpanGameModal {
  ANSWER_WRONG,
  ANSWER_RIGHT,
}

type DigitSpanRoundData = {
  assessType: number;
  practice: number;
  n: number;
  sequence: Array<number>;
  correctSolution: Array<number>;
  proposedSolution: Array<number>;
  acc: number;
};

type DigitSpanResult = {
  digitSpanScores: Array<DigitSpanRoundData>,
  digitSpanMetadata: {
    f_countTrials: number;
    f_con_errorcount: number;
    b_countTrials: number;
    b_con_errorcount: number;
    TE_ML: number;
    TE_TT: number;
    ML: number;
    MS: number;
    scriptElapsedTime: number;
    fTE_ML: number;
    fTE_TT: number;
    fML: number;
    fMS: number;
    bTE_ML: number;
    bTE_TT: number;
    bML: number;
    bMS: number;
    f_countPracticeTrials: number;
    b_countPracticeTrials: number;
    f_timeSpentOnIntroductionPage: number;
    b_timeSpentOnIntroductionPage: number;
    firstExtendedPracticeFails: number;
  },
};

export type DigitSpanResultForSubmission = {
  digitSpanScores: Array<
  Omit<DigitSpanRoundData, 'sequence' | 'correctSolution' | 'proposedSolution'> & {
    sequence: string;
    correctSolution: string;
    proposedSolution: string;
  }
  >,
  digitSpanMetadata: DigitSpanResult['digitSpanMetadata'];
};

type DigitSpanGameState = {
  currentRound: number,
  maxRounds: number,
  currentTrial: number,
  firstExtendedPracticeFails: number,

  digitsSet: Array<number>,
  digitsInCurrentRound: number,

  sequence: Array<number>,
  correctSequence: Array<number>,
  digitCycleInterval: number,
  digitToDisplay: number,

  trialRuns: number,
  roundFlowStage: DigitSpanStage,

  displayGameModal: boolean,
  gameModalToDisplay: DigitSpanGameModal,

  isPracticeMode: boolean,
  togglePracticeMode: (isPracticeMode: boolean) => void,
  shouldRetryPractice: (isInitPractice: boolean) => boolean,

  isForwardGame: boolean,
  setDirectionReversed: (reversed: boolean) => void,

  roundAnswer: Array<number>,
  setAnswer: (answer: number) => void,
  removeLastFromAnswer: () => void,

  startNewGame: () => void,
  startShowDigits: () => void,
  startGuessing: () => void,
  startPause: () => void,
  submitAnswer: () => void,
  finish: () => void,
  nextRound: () => void,

  resetDigitSpanFlow: () => void;

  gameStartTime: number;
  consecutiveErrors: number;
  lastGuessLen: number;
  gameResult: DigitSpanResult;
  startGameTimer: () => void;
  saveRoundResult: () => void;
  saveGameResult: () => void;

  timerIntroductionForward: { start: number; end: number; },
  timerIntroductionReversed: { start: number; end: number; },
  startIntroductionTimerForward: () => void,
  saveIntroductionTimerForward: () => void,
  startIntroductionTimerReversed: () => void,
  saveIntroductionTimerReversed: () => void,

  progress: number;
  updateProgress: () => void;
};

const useDigitSpanGameState = create<DigitSpanGameState>((set, get) => ({
  currentRound: 1,
  maxRounds: MAX_ROUNDS_TRIAL,
  currentTrial: 1,
  firstExtendedPracticeFails: 0,

  countDownText: 'Get Ready',
  countDownInterval: 0,

  digitsSet: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
  digitsInCurrentRound: ROUND_START_MIN_DIGITS,

  sequence: [],
  correctSequence: [],
  digitCycleInterval: 0,
  digitToDisplay: 0,
  trialRuns: 0,
  roundFlowStage: DigitSpanStage.BEGINNING,

  displayGameModal: false,
  gameModalToDisplay: DigitSpanGameModal.ANSWER_RIGHT,

  isPracticeMode: false,
  progress: 0,

  updateProgress: () => {
    const currentBlockProgress = get().currentRound;
    const totalBlocks = get().isPracticeMode ? MAX_ROUNDS_PRACTICE : MAX_ROUNDS_TRIAL;
    const progress = currentBlockProgress / totalBlocks;
    set({ progress });
  },

  togglePracticeMode: (isPracticeMode) => {
    set({
      isPracticeMode,
      maxRounds: isPracticeMode ? MAX_ROUNDS_PRACTICE : MAX_ROUNDS_TRIAL,
    });
  },

  shouldRetryPractice: (isInitPractice: boolean) => {
    const {
      isForwardGame,
      correctSequence,
      roundAnswer,
      currentRound,
      isPracticeMode,
    } = get();

    return (
      correctSequence.toString() !== roundAnswer.toString()
      && isPracticeMode
      && !isForwardGame
      && currentRound === 1
      && !isInitPractice
    );
  },

  isForwardGame: true,
  setDirectionReversed: (reversed: boolean) => {
    set({
      isForwardGame: !reversed,
    });
  },

  roundAnswer: [],
  setAnswer: (answer: number) => {
    if (get().roundAnswer.length >= 50) {
      return;
    }
    set({
      roundAnswer: [...get().roundAnswer, answer],
    });
  },
  removeLastFromAnswer: () => {
    set({
      roundAnswer: get().roundAnswer.slice(0, -1),
    });
  },

  startNewGame: () => {
    set({
      currentRound: 1,
      digitsInCurrentRound: ROUND_START_MIN_DIGITS,
      maxRounds: get().isPracticeMode ? MAX_ROUNDS_PRACTICE : MAX_ROUNDS_TRIAL,
    });
    get().startShowDigits();
    get().updateProgress();
  },
  startShowDigits: () => {
    set({
      roundFlowStage: DigitSpanStage.SHOW_DIGITS,
      digitToDisplay: 0,
    });
    const numbersToSelect = get().digitsInCurrentRound;
    const { digitsSet } = get();
    const newSolution = get().shouldRetryPractice(get().firstExtendedPracticeFails === 0)
      ? get().sequence
      : [...Array(numbersToSelect)]
        .map(() => digitsSet[Math.floor(Math.random() * digitsSet.length)]);

    setTimeout(() => {
      set({
        sequence: newSolution,
        correctSequence: get().isForwardGame ? newSolution : [...newSolution].reverse(),
        digitToDisplay: get().digitToDisplay + 1,
        digitCycleInterval: window.setInterval(() => {
          if (get().digitToDisplay >= get().sequence.length) {
            clearInterval(get().digitCycleInterval);
            get().startPause();
          } else {
            set({
              digitToDisplay: get().digitToDisplay + 1,
            });
          }
        }, DIGIT_CYCLE_INTERVAL_TIME),
      });
    }, DIGIT_CYCLE_START_DELAY);
  },
  startPause: () => {
    set({
      roundFlowStage: DigitSpanStage.PAUSE,
    });
    setTimeout(() => {
      get().startGuessing();
    }, 500);
  },
  startGuessing: () => {
    set({
      roundAnswer: [],
      roundFlowStage: DigitSpanStage.REPEAT_DIGITS,
    });
  },
  submitAnswer: () => {
    get().saveRoundResult();
  },
  finish: () => {
    set({
      roundFlowStage: DigitSpanStage.FINISHED,
    });
    get().saveGameResult();
  },
  nextRound: () => {
    const {
      currentRound,
      isPracticeMode,
      currentTrial,
      maxRounds,
      firstExtendedPracticeFails,
      finish,
      startShowDigits,
      shouldRetryPractice,
    } = get();

    if (shouldRetryPractice(false)) {
      set({
        displayGameModal: false,
        firstExtendedPracticeFails: firstExtendedPracticeFails + 1,
      });
    } else {
      set({
        displayGameModal: false,
        currentRound: currentRound + 1,
        currentTrial: !isPracticeMode ? currentTrial + 1 : currentTrial,
      });
    }
    if (currentRound >= maxRounds) {
      finish();
      return;
    }
    startShowDigits();
    get().updateProgress();
  },

  resetDigitSpanFlow: () => {
    set({
      roundFlowStage: DigitSpanStage.BEGINNING,
    });
  },

  gameStartTime: 0,
  consecutiveErrors: 0,
  lastGuessLen: 0,
  gameResult: {
    digitSpanScores: [],
    digitSpanMetadata: {
      f_countTrials: 0,
      f_con_errorcount: 0,
      b_countTrials: 0,
      b_con_errorcount: 0,
      TE_ML: -1,
      TE_TT: -1,
      ML: 0,
      MS: 0,
      scriptElapsedTime: 0,
      fTE_ML: -1,
      fTE_TT: -1,
      fML: 0,
      fMS: 0,
      bTE_ML: -1,
      bTE_TT: -1,
      bML: 0,
      bMS: 0,
      f_countPracticeTrials: 0,
      b_countPracticeTrials: 0,
      f_timeSpentOnIntroductionPage: 0,
      b_timeSpentOnIntroductionPage: 0,
      firstExtendedPracticeFails: 0,
    },
  },
  startGameTimer: () => {
    set({
      gameStartTime: get().gameStartTime === 0 ? new Date().getTime() : get().gameStartTime,
    });
  },
  saveRoundResult: () => {
    const {
      isForwardGame,
      sequence,
      correctSequence,
      roundAnswer,
      gameResult,
      consecutiveErrors,
      currentTrial,
      currentRound,
      firstExtendedPracticeFails,
      digitsInCurrentRound,
      isPracticeMode,
      gameStartTime,
      lastGuessLen,
      nextRound,
      shouldRetryPractice,
    } = get();
    const isCorrect = correctSequence.toString() === roundAnswer.toString();
    const consecutiveErrorsToSave = !isPracticeMode && !isCorrect ? consecutiveErrors + 1 : 0;
    set({
      consecutiveErrors: consecutiveErrorsToSave,
      lastGuessLen: isCorrect ? digitsInCurrentRound : lastGuessLen,
    });
    const roundData: DigitSpanRoundData = {
      assessType: !isForwardGame ? 1 : 0,
      practice: isPracticeMode ? 1 : 0,
      n: digitsInCurrentRound,
      sequence,
      correctSolution: correctSequence,
      proposedSolution: roundAnswer,
      acc: isCorrect ? 1 : 0,
    };

    // eslint-disable-next-line max-len
    const saveNewErrorCountForward = gameResult.digitSpanMetadata.f_con_errorcount < consecutiveErrorsToSave
      && isForwardGame
      && !isPracticeMode;
    // eslint-disable-next-line max-len
    const saveNewErrorCountBackward = gameResult.digitSpanMetadata.b_con_errorcount < consecutiveErrorsToSave
      && !isForwardGame
      && !isPracticeMode;
    const saveTwoErrorMaximumLength = gameResult.digitSpanMetadata.TE_ML === -1
      && consecutiveErrorsToSave === 2;
    const saveForwardTwoErrorMinimalLength = isForwardGame
      && gameResult.digitSpanMetadata.fTE_ML === -1
      && consecutiveErrorsToSave === 2;
    const saveBackwardTwoErrorMinimalLength = !isForwardGame
      && gameResult.digitSpanMetadata.bTE_ML === -1
      && consecutiveErrorsToSave === 2;
    const saveForwardMaximumLength = isForwardGame
      && gameResult.digitSpanMetadata.fML < digitsInCurrentRound
      && isCorrect;
    const saveBackwardMaximumLength = !isForwardGame
      && gameResult.digitSpanMetadata.bML < digitsInCurrentRound
      && isCorrect;
    const saveForwardCountPracticeTrials = isForwardGame && isPracticeMode;
    const saveBackwardCountPracticeTrials = !isForwardGame && isPracticeMode;

    set({
      gameResult: {
        ...gameResult,
        digitSpanScores: gameResult.digitSpanScores.length
          ? shouldRetryPractice(false)
            ? gameResult.digitSpanScores
            : [...gameResult.digitSpanScores, roundData]
          : [roundData],
        digitSpanMetadata: {
          ...gameResult.digitSpanMetadata,
          f_countTrials: isForwardGame && !isPracticeMode
            ? gameResult.digitSpanMetadata.f_countTrials + 1
            : gameResult.digitSpanMetadata.f_countTrials,
          f_con_errorcount: saveNewErrorCountForward
            ? consecutiveErrorsToSave
            : gameResult.digitSpanMetadata.f_con_errorcount,
          b_countTrials: !isForwardGame && !isPracticeMode
            ? gameResult.digitSpanMetadata.b_countTrials + 1
            : gameResult.digitSpanMetadata.b_countTrials,
          b_con_errorcount: saveNewErrorCountBackward
            ? consecutiveErrorsToSave
            : gameResult.digitSpanMetadata.b_con_errorcount,
          firstExtendedPracticeFails,
          TE_TT: saveTwoErrorMaximumLength ? currentTrial : gameResult.digitSpanMetadata.TE_TT,
          TE_ML: saveTwoErrorMaximumLength ? lastGuessLen : gameResult.digitSpanMetadata.TE_ML,
          ML: gameResult.digitSpanMetadata.ML < digitsInCurrentRound
            ? digitsInCurrentRound
            : gameResult.digitSpanMetadata.ML,
          scriptElapsedTime: new Date().getTime() - gameStartTime,
          fTE_TT: saveForwardTwoErrorMinimalLength
            ? currentRound
            : gameResult.digitSpanMetadata.fTE_TT,
          fTE_ML: saveForwardTwoErrorMinimalLength
            ? lastGuessLen
            : gameResult.digitSpanMetadata.fTE_ML,
          fML: saveForwardMaximumLength
            ? digitsInCurrentRound
            : gameResult.digitSpanMetadata.fML,
          bTE_TT: saveBackwardTwoErrorMinimalLength
            ? currentRound
            : gameResult.digitSpanMetadata.bTE_TT,
          bTE_ML: saveBackwardTwoErrorMinimalLength
            ? lastGuessLen
            : gameResult.digitSpanMetadata.bTE_ML,
          bML: saveBackwardMaximumLength ? digitsInCurrentRound : gameResult.digitSpanMetadata.bML,
          f_countPracticeTrials: saveForwardCountPracticeTrials
            ? currentRound
            : gameResult.digitSpanMetadata.f_countPracticeTrials,
          b_countPracticeTrials: saveBackwardCountPracticeTrials
            ? currentRound
            : gameResult.digitSpanMetadata.b_countPracticeTrials,
        },
      },
      displayGameModal: isPracticeMode,
      gameModalToDisplay: isCorrect
        ? DigitSpanGameModal.ANSWER_RIGHT
        : DigitSpanGameModal.ANSWER_WRONG,
    });

    if (!isPracticeMode) {
      if (isCorrect) {
        set({
          digitsInCurrentRound: digitsInCurrentRound + 1,
        });
      } else if (digitsInCurrentRound > ROUND_MIN_DIGITS) {
        set({
          digitsInCurrentRound: digitsInCurrentRound - 1,
        });
      }
      nextRound();
    }
  },
  saveGameResult: () => {
    const {
      isForwardGame,
      isPracticeMode,
      gameResult,
      timerIntroductionForward,
      timerIntroductionReversed,
    } = get();
    const introductionTimerF = timerIntroductionForward.end - timerIntroductionForward.start;
    const introductionTimerB = timerIntroductionReversed.end - timerIntroductionReversed.start;
    let msToSave = gameResult.digitSpanMetadata.MS;
    let forwardMSToSave = gameResult.digitSpanMetadata.fMS;
    let backwardMSToSave = gameResult.digitSpanMetadata.bMS;
    if (isForwardGame && !isPracticeMode) {
      const trialsLengthArrayForward = gameResult.digitSpanScores.filter(round => (
        round.assessType === 0
      )).map(round => (
        round.n
      ));
      const forwardMSRoundsSum = trialsLengthArrayForward.reduce((accumulator, roundLen) => (
        accumulator + roundLen
      ), 0);

      forwardMSToSave = forwardMSRoundsSum / trialsLengthArrayForward.length;
    }
    if (!isForwardGame && !isPracticeMode) {
      const trialsLengthArrayBackward = gameResult.digitSpanScores.filter(round => (
        round.assessType === 1
      )).map(round => (
        round.n
      ));
      const backwardMsRoundsSum = trialsLengthArrayBackward.reduce((accumulator, roundLen) => (
        accumulator + roundLen
      ), 0);
      backwardMSToSave = backwardMsRoundsSum / trialsLengthArrayBackward.length;

      const trialsLengthArray = gameResult.digitSpanScores.map(round => (
        round.n
      ));
      const msRoundsSum = trialsLengthArray.reduce((accumulator, roundLen) => (
        accumulator + roundLen
      ), 0);
      msToSave = msRoundsSum / trialsLengthArray.length;
    }

    set({
      gameResult: {
        ...gameResult,
        digitSpanMetadata: {
          ...gameResult.digitSpanMetadata,
          MS: msToSave,
          fMS: forwardMSToSave,
          bMS: backwardMSToSave,
          f_timeSpentOnIntroductionPage: introductionTimerF,
          b_timeSpentOnIntroductionPage: introductionTimerB,
        },
      },
    });
  },

  timerIntroductionForward: { start: 0, end: 0 },
  timerIntroductionReversed: { start: 0, end: 0 },
  startIntroductionTimerForward: () => {
    set({
      timerIntroductionForward: {
        ...get().timerIntroductionForward,
        start: new Date().getTime(),
      },
    });
  },
  saveIntroductionTimerForward: () => {
    set({
      timerIntroductionForward: {
        ...get().timerIntroductionForward,
        end: new Date().getTime(),
      },
    });
  },
  startIntroductionTimerReversed: () => {
    set({
      timerIntroductionReversed: {
        ...get().timerIntroductionReversed,
        start: new Date().getTime(),
      },
    });
  },
  saveIntroductionTimerReversed: () => {
    set({
      timerIntroductionReversed: {
        ...get().timerIntroductionReversed,
        end: new Date().getTime(),
      },
    });
  },
}));

export default useDigitSpanGameState;
