import { QuizzResults } from '../types';

export class QuizScoreCalculator {
  static addQuestionAnswers(questionMap: number[], results: QuizzResults) {
    let sum = 0;
    if (results && results.length) {
      questionMap.forEach(qNumber => {
        if (results[qNumber - 1] && results[qNumber - 1].answer) {
          sum += results[qNumber - 1].answer;
        }
      });
    }
    return sum;
  }

  static substractQuestionAnswers(questionMap: number[], results: QuizzResults) {
    let sum = 0;
    if (results && results.length) {
      questionMap.forEach(qNumber => {
        if (results[qNumber - 1] && results[qNumber - 1].answer) {
          sum -= results[qNumber - 1].answer;
        }
      });
    }
    return sum;
  }

  static reverseAnswerScale(questionMap: number[], results: QuizzResults): QuizzResults {
    const resultsCopy = [...results];
    if (results && results.length) {
      questionMap.forEach(qNumber => {
        if (results[qNumber - 1]) {
          resultsCopy[qNumber - 1].answer = Math.abs(results[qNumber - 1].answer - 6);
        }
      });
    }
    return resultsCopy;
  }
}
