import { useEffect, useState } from 'react';
// import { animated, useTransition } from '@react-spring/web';

import { AspectRatioView } from 'components';
import { QuizzResults } from 'types';
import { chunkArray } from 'utils';

import ItemSet from './ItemSet';
import ProgressBar from './ProgressBar';

type QuizzProps = {
  questions: string[];
  images?: string[];
  setSize: number;
  scoreLabels: string[];
  onComplete: (scores: QuizzResults) => void;
};

const Quizz: React.FC<QuizzProps> = ({
  questions,
  images,
  onComplete,
  scoreLabels,
  setSize,
}) => {
  const [scores, setScores] = useState<number[]>([]);

  const progress = scores.length / questions.length;

  const [currentSet, setCurrentSet] = useState(0);
  const itemSets = chunkArray(questions, setSize);
  const imageSets = chunkArray(images ?? [], setSize);

  const addScores = (scores: number[]) => setScores(s => s.concat(scores));
  const removeScores = () => setScores(s => s.slice(0, -setSize));

  useEffect(() => {
    setCurrentSet(
      Math.min(itemSets.length - 1, Math.floor(scores.length / setSize)),
    );
  }, [scores, questions, itemSets, setSize]);

  useEffect(() => {
    if (questions.length === scores.length) {
      onComplete(scores.map(
        (answer, i) => ({ questionNumber: i + 1, answer }),
      ));
    }
  }, [questions, scores, onComplete]);

  // const transitions = useTransition(currentSet, {
  //   from: stage => (
  //     stage === 0
  //       ? {
  //         position: 'absolute',
  //         width: '100%',
  //         height: '100%',
  //       } // first slide will be animated by the game flow
  //       : {
  //         position: 'absolute',
  //         width: '100%',
  //         height: '100%',
  //         transform: 'translate(100%,0)',
  //       }
  //   ),
  //   enter: { transform: 'translate(0%,0)' },
  //   leave: item => (
  //     item === questions.length - 1
  //       ? {} // final slide will be animated by the game flow
  //       : {
  //         transform: 'translate(-100%,0)',
  //         opacity: 0.5,
  //       }
  //   ),
  //   config: {
  //     duration: 300,
  //   },
  // });

  return (
    <AspectRatioView>
      <ProgressBar progress={progress} />
      <div style={{
        width: '100%',
        height: '100%',
      }}>
        <ItemSet
          questions={itemSets[currentSet]}
          images={imageSets[currentSet]}
          onAnswers={addScores}
          scoreLabels={scoreLabels}
          isFirstSet={currentSet === 0}
          onBack={removeScores}
        />
      </div>
    </AspectRatioView>
  );
};

export default Quizz;
