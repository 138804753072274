import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import AppFlow from 'AppFlow';
import { About, PrivacyPolicy } from 'pages';
import { InstallPrompt, Viewport } from 'components';
import routeToGame from 'games/routeToGame';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OldVersionPrompt from './components/OldVersionPrompt/OldVersionPrompt';

const Main = () => {

  return (
    <Viewport>
      {
        routeToGame() ?? <AppFlow />
      }
    </Viewport>
  );
}

const App = () => {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <InstallPrompt />
      <OldVersionPrompt />
      <BrowserRouter>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/*" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}


export default App;
