import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import zip from 'lodash/zip';

import { useAppStateActions } from 'appState';
import { useTranslation } from 'hooks';
import {
  AspectRatioView,
  ButtonRect,
  Carousel,
  LanguageSwitch,
  Portal,
} from 'components';
import styles from './Instructions.module.scss';
import { TranslationPage } from '../../../types/enums/TranslationPage';

type InstructionsProps = {
  name: string;
  instructions: string;
  nextButtonLabel?: string;
  tutorial?: boolean;
  images?: string[];
  texts?: string[];
  onStart?: () => void;
  onEnd?: () => void;
};

const Instructions: React.FC<InstructionsProps> = ({
  name,
  instructions,
  nextButtonLabel,
  tutorial,
  images,
  texts,
  onStart,
  onEnd,
}) => {
  const { gameFlowForward } = useAppStateActions();
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const lang = useTranslation(TranslationPage.INSTRUCTIONS);

  const contentItems = zip(
    (texts ?? []).map(content => ({ type: 'text' as const, content})),
    (images ?? []).map(content => ({ type: 'image' as const, content})),
  ).flat().filter(Boolean);

  useEffect(() => {
    onStart?.()
    return () => {
      onEnd?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderImageInstructions = () => (
    <Portal>
      <div className={styles.carouselContainer}>
        <Carousel className={styles.carousel}>
          {
            (contentItems).map((item, index) => {
              if (item!.type === 'image') {
                return <Carousel.SlideImage content={item!.content} key={index} />;
              }
              if (item!.type === 'text') {
                return <Carousel.SlideText content={item!.content} key={index} />;
              }
              return <div />;
            })
          }
        </Carousel>
        <ButtonRect
          text={lang.exitImageTutorial}
          onClick={() => {
            setIsCarouselOpen(false);
          }}
        />
      </div>
    </Portal>
  );

  return (
    <AspectRatioView>
      <div className={styles.container}>
        {
          isCarouselOpen && renderImageInstructions()
        }
        <div className={styles.TopRow}>
          <LanguageSwitch />
          <div className={styles.Text}>
            <h4>{name}</h4>
          </div>
        </div>
        <div className={styles.MidSection}>
          <div className={styles.InstructionsContainer}>
            <h5 className={styles.Header}>{lang.instructions}:</h5>
            <ReactMarkdown>
              {instructions}
            </ReactMarkdown>
          </div>
        </div>
        <div className={styles.bottomRow}>
          {tutorial && (
            <ButtonRect
              className={styles.buttonImages}
              text={lang.showImageTutorial}
              onClick={() => {
                setIsCarouselOpen(true);
              }}
            />
          )}
          <ButtonRect
            className={styles.buttonPractice}
            text={nextButtonLabel || lang.nextButtonDefault}
            onClick={gameFlowForward}
          />
        </div>
      </div>
    </AspectRatioView>
  );
};

export default Instructions;
