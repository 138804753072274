export const TARGET_LETTER = 'X';
export const TARGET_LETTER_EXTENSION = 'A';
export const LETTERS_CHANGE_INTERVAL = 550;
export const LETTER_REST_TIME = 230;
export const PAUSE_TIME = 230;
export const LETTERS_PER_BLOCK = 31;
export const TARGETS_PER_BLOCK = 8;
export const TARGETS_PER_BLOCK_EXTENDED = 6;
export const FAKE_TARGETS_PER_BLOCK_EXTENDED = 2;
export const DISTRACTORS = [
  ['A', 'Y'],
  ['B', 'X'],
  ['B', 'Y'],
];
export const MAX_BLOCKS_PRACTICE = 1;
export const MAX_BLOCKS = 7;
