import img1 from './01.svg';
import img2 from './02.svg';
import img3 from './03.svg';
import img4 from './04.svg';
import img5 from './05.svg';
import img6 from './06.svg';
import img7 from './07.svg';
import img8 from './08.svg';
import img9 from './09.svg';
import img10 from './10.svg';
import img11 from './11.svg';
import img12 from './12.svg';
import img13 from './13.svg';
import img14 from './14.svg';
import img15 from './15.svg';
import img16 from './16.svg';
import img17 from './17.svg';
import img18 from './18.svg';
import img19 from './19.svg';
import img20 from './20.svg';
import img21 from './21.svg';
import img22 from './22.svg';
import img23 from './23.svg';
import img24 from './24.svg';
import img25 from './25.svg';
import img26 from './26.svg';
import img27 from './27.svg';
import img28 from './28.svg';
import img29 from './29.svg';
import img30 from './30.svg';

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
];

export default images;
