import useBretState from './bretState';
import { useShallow } from 'zustand/react/shallow';

export const useBretGameActions = () => useBretState(useShallow(s => ({
  startRound: s.startRound,
  stopRound: s.stopRound,
  togglePracticeMode: s.togglePracticeMode,
  gameStageForward: s.gameStageForward,
  startInstructionsTimer: s.startInstructionsTimer,
  stopInstructionsTimer: s.stopInstructionsTimer,
  setStartGameTime: s.setStartGameTime,
  clearData: s.clearData,
})));

export const useBretData = () => useBretState(useShallow(s => ({
  squares: s.squares,
  collectedParcels: s.collectedParcels,
  totalMoney: s.totalMoney,
  didGameFinish: s.didGameFinish,
  currentStage: s.currentStage,
  didBombExplode: s.didBombExplode,
  parcelWithBomb: s.parcelWithBomb,
  isPracticeMode: s.isPracticeMode,
  finalResults: s.results,
})));
