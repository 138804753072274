// import { JobSector, YesNoPlanTo } from 'types/enums';

import {
  validateNonEmpty,
  // validateNumber,
} from '../helpers';
import { FormError, MaybeEmptyString } from '../types';

import { FormValues } from './FormValues';

const makeValidator = (messages: Record<FormError, string>) =>
  (values: MaybeEmptyString<FormValues>) => {
    const errors = {
      reportUsefullness:
        validateNonEmpty(values.reportUsefullness),
      jobStrengthsAndWeaknesses:
        validateNonEmpty(values.reportUsefullness),
      // helpJobSearch:
      //   validateNonEmpty(values.reportUsefullness),
      // helpGettingJob:
      //   validateNonEmpty(values.reportUsefullness),
      // jobOpportunitiesKnowWaysToFindJob:
      //   validateNonEmpty(values.jobOpportunitiesKnowWaysToFindJob),
      // jobOpportunitiesHaveGoodIdeaOfJobMarket:
      //   validateNonEmpty(values.jobOpportunitiesHaveGoodIdeaOfJobMarket),
      // jobOpportunitiesKnowWhatKindOFJobWant:
      //   validateNonEmpty(values.jobOpportunitiesKnowWhatKindOFJobWant),
      // jobOpportunitiesIsGoodAtSearchingJobs:
      //   validateNonEmpty(values.jobOpportunitiesIsGoodAtSearchingJobs),
      // planToIncreaseJobSearch:
      //   validateNonEmpty(values.planToIncreaseJobSearch),
      // targetSector:
      //   validateNonEmpty(values.targetSector),
      // otherTargetSector: values.targetSector === JobSector.Other
      //   ? validateNonEmpty(values.otherTargetSector) : null,
      // howManyHoursWillSearchPerWeek:
      //   validateNonEmpty(values.howManyHoursWillSearchPerWeek),
      // howManyApplicationsNextMonth:
      //   validateNonEmpty(values.howManyApplicationsNextMonth),
      // willSkillsAttachHelpGetInterview:
      //   validateNonEmpty(values.planToIncreaseJobSearch),
      // plansToAttachSkillsReport:
      //   validateNonEmpty(values.plansToAttachSkillsReport),
      // expectsToFindJobInSixMonths:
      //   validateNonEmpty(values.expectsToFindJobInSixMonths),
      // wageOfSimilarEmployeesAfter:
      //   validateNumber(values.wageOfSimilarEmployeesAfter),
      // lowestAcceptablePayAfter:
      //   validateNumber(values.lowestAcceptablePayAfter),
      // accessedLearningLinks:
      //   validateNonEmpty(values.accessedLearningLinks),
      // accessedLearningLinksWhyNot:
      //   values.accessedLearningLinks !== YesNoPlanTo.Yes
      //   && values.accessedLearningLinks !== YesNoPlanTo.PlanTo
      //     ? validateNonEmpty(values.accessedLearningLinksWhyNot)
      //     : null,
      // howManyHoursToUpskillPerWeek:
      //   values.accessedLearningLinks !== YesNoPlanTo.No
      //     ? validateNonEmpty(values.howManyHoursToUpskillPerWeek)
      //     : null,
      // helpGetInterview:
      //   validateNonEmpty(values.helpGetInterview),
      // helpGetJobInDesiredSector:
      //   validateNonEmpty(values.helpGetJobInDesiredSector),
      inspiredToDevelopFurther:
        validateNonEmpty(values.likelyToRecommendSkillcraft),
      likelyToRecommendSkillcraft:
        validateNonEmpty(values.likelyToRecommendSkillcraft),
    };
    return Object.fromEntries(
      Object.entries(errors)
        .filter(([, value]) => value !== null)
        .map(([field, errorType]) => [field, messages[errorType as FormError]]),
    );
  };

export default makeValidator;
