import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  GameFlow,
  Instructions,
  Intro,
  PlayGame,
  GetReady,
} from 'components/game';
import { fillTranslationTextWithVariables, useTranslation } from 'hooks';
import { useAppStateActions } from 'appState';
import { GameId } from 'types/enums';

import Trial from './Trial';
import trialData from './trialData';
import { HMTResults, TrialScore } from './types';
import playGameImg from './img/playGame.png';
import tutorialImages from './tutorialImages';
import { TranslationPage } from '../../types/enums/TranslationPage';
import Feedback from '../../components/game/Feedback';

const HMT: React.FC = () => {
  const lang = useTranslation(TranslationPage.HMT);
  const {
    gameFlowForward, submitGameResults, startInstructionsTimer, stopInstructionsTimer,
  } = useAppStateActions();

  const [scores, setScores] = useState<TrialScore[]>([]);

  const correct = useMemo(() => scores.filter(f => !f.practice && f.correct).length, [scores]);
  const maxScore = useMemo(() => scores.filter(f => !f.practice).length, [scores]);

  const onTrialEnd = useCallback((score: TrialScore) => {
    setScores(s => s.concat(score));
    gameFlowForward();
  }, [gameFlowForward, setScores]);

  useEffect(() => {
    if (scores.length === trialData.length) {
      const results: HMTResults = {
        hmtScores: scores,
        hmtMetadata: {},
      };
      submitGameResults(GameId.HMT, results);
    }
  }, [scores, submitGameResults]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.gameTitle}
            description={lang.description}
            superPowerName={lang.superPowerName}
            superPower={lang.superPower}
            buttonLabel={lang.introButtonLabel}
          />
        ),
        () => (
          <Instructions
            name={lang.gameTitle}
            instructions={lang.instructionsTwo}
            nextButtonLabel={lang.buttonBegin}
            onStart={startInstructionsTimer}
            onEnd={stopInstructionsTimer}
            images={tutorialImages}
            texts={lang.tutorial}
            tutorial
          />
        ),
        () => (
          <Trial
            data={trialData[0]}
            explanation={lang.explanations[0]}
            onFinish={onTrialEnd}
            showTimer
          />
        ),
        () => (
          <Trial
            data={trialData[1]}
            explanation={lang.explanations[1]}
            onFinish={onTrialEnd}
            showTimer
          />
        ),
        () => (
          <PlayGame
            gameName={lang.gameTitle}
            imgSrc={playGameImg}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        ...trialData.slice(2).map(data => () => (
          <Trial
            data={data}
            onFinish={onTrialEnd}
            showTimer
          />
        )),
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { score: correct, maxScore },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default HMT;
export type Results = HMTResults;
