export enum TranslationPage {
  QUESTIONNAIRE_WRAPPER = 'QuestionnaireWrapper',
  CHART_ITEM_PERSONALITY = 'ChartItemPersonality',
  CAREER_INTEREST_CARD = 'CareerInterestCard',
  SKILL_STRENGTH_CARD = 'SkillStrengthCard',
  GAMES_COMPLETED_MODAL = 'GamesCompletedModal',
  HORIZONTAL_ORIENTATION_PROMPT = 'HorizontalOrientationPrompt',
  CAREER_OPTION_CARD = 'CareerOptionCard',
  CHART_ITEM = 'ChartItem',
  TAB_TOGGLE = 'TabToggle',
  OVERVIEW = 'Overview',
  CHILDREN = 'Children',
  INSTRUCTIONS = 'Instructions',
  GAME_RESULTS = 'GameResults',
  INSTALL_PROMPT = 'InstallPrompt',
  MESSAGE = 'Message',
  PLAY_GAME = 'PlayGame',
  GET_READY = 'GetReady',
  COLLECT_EXTRA_DATA = 'CollectExtraData',
  UPDATE_EXTRA_DATA = 'UpdateExtraData',
  AFTER_LANDING_PAGE = 'AfterLandingPage',
  SUMMARY = 'Summary',
  GAMES_COMPLETED = 'GamesCompleted',
  QUIZZ = 'Quizz',
  Questionnaire = 'Questionnaire',
  PROGRESS_MAP = 'ProgressMap',
  LOGGING_IN = 'LoggingIn',
  DIGIT_SPAN = 'DigitSpan',
  WELCOME = 'Welcome',
  FAQ = 'FAQ',
  BIG_FIVE = 'BigFive',
  LOG_IN = 'LogIn',
  RIASEC = 'RIASEC',
  CORSI = 'Corsi',
  RMET = 'RMET',
  MTPT = 'MTPT',
  TMT = 'TMT',
  HMT = 'HMT',
  CPT = 'CPT',
  PP = 'PP',
  GM = 'GM',
  EI = 'EI',
  BRET = 'BRET',
  GAMES_INFO = 'GamesInfo',
  PAGES = 'pages',
  ASSESMENT = 'Assesment',
  ASSESMENT_PDF = 'AssesmentPdf',
  GAME_SKIPPER = 'GameSkipper',
  OLD_VERSION_PROMPT = 'OldVersionPrompt',
}
