import classNames from 'classnames';

import { ButtonRect } from 'components';
import { ButtonType } from 'components/ButtonRect/ButtonRect.types';
import s from './Login.module.scss';

type PrivacyModalProps = {
  onClick: () => void,
  open: boolean,
};

const PrivacyModal: React.FC<PrivacyModalProps> = ({ onClick, open }) => (
  <div className={classNames(s.consentModal, open && s.open)}>
    <ButtonRect onClick={onClick} text="" type={ButtonType.CLOSE} className={s.closeBtn} />
    <div className={s.consentBox}>
      <h4>Storing and processing personal data</h4>
      <div className={s.text}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu neque nibh.
          Suspendisse et libero ut risus vestibulum accumsan non non orci.
          Aenean auctor, erat vel consectetur tristique, quam libero dictum libero,
          sit amet malesuada tellus risus eget quam. Nam lectus lectus, vehicula a risus
          sit amet, sodales molestie tellus. Duis cursus accumsan ligula, nec congue dui dapibus et.
          Quisque nec ipsum mi. Aenean quis sodales erat, ut mattis ex.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu neque nibh.
          Suspendisse et libero ut risus vestibulum accumsan non non orci.
          Aenean auctor, erat vel consectetur tristique, quam libero dictum libero,
          sit amet malesuada tellus risus eget quam. Nam lectus lectus, vehicula a risus sit amet,
          sodales molestie tellus. Duis cursus accumsan ligula, nec congue dui dapibus et.
          Quisque nec ipsum mi. Aenean quis sodales erat, ut mattis ex.
        </p>
        <p>
          Duis viverra finibus nisl id sagittis. Nullam quis egestas elit. Suspendisse potenti.
          Cras hendrerit magna et nunc laoreet, vel fermentum neque tincidunt.
          Ut sit amet nibh eget neque commodo aliquet ut ac leo. Ut tempus eget ligula vel tempor.
          Vestibulum sodales purus non arcu sagittis ultricies. Quisque cursus dapibus dolor,
          non molestie turpis rutrum quis. Quisque eu magna at tellus mollis vestibulum.
          Donec diam augue, iaculis in purus cursus, porttitor rutrum ex.
        </p>
      </div>
    </div>
  </div>

);

export default PrivacyModal;
