export type ButtonRectProps = {
  className?: string;
  onClick(): void;
  text: string;
  isVisible?: boolean,
  type?: ButtonType,
};

export enum ButtonType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SUBMIT = 'submit',
  CANCEL = 'cancel',
  PLAY = 'play',
  STOP = 'stop',
  QUIT = 'quit',
  LOGOUT = 'logout',
  FULLSCREEN = 'fullscreen',
  ASSESSMENT = 'assessment',
  CLOSE = 'close',
}
