import s from './Layout.module.scss';
import { ReactNode } from 'react';

type Props = {
  id?: string;
  showIf?: boolean;
  children?: ReactNode;
};

const Section: React.FC<Props> = ({ children, id, showIf: visible = true }) => (
  visible ? (
    <div
      className={s.section}
      {...id ? { id: `section-${id}` } : {}}
    >
      {children}
    </div>
  ) : null
);

export default Section;
