import { useState } from 'react';
import classnames from 'classnames';

import s from './ScoreSelector.module.scss';

type ScoreSelectorProps = {
  onSelect: (score: number) => void;
  scoreLabels: string[];
};

const ScoreSelector: React.FC<ScoreSelectorProps> = ({ onSelect, scoreLabels }) => {
  const [score, setScore] = useState<number>(3);
  const [isTouched, setIsTouched] = useState(false);

  const onChange = (score: number) => {
    setScore(score);
    setIsTouched(true);
  };

  const onReleased = () => {
    onSelect(score);
    setIsTouched(true);
  };

  return (
    <>
      <div className={s.scoreSelector}>
        <input
          className={s.range}
          type="range"
          min={1}
          max={5}
          step={1}
          value={score}
          onChange={e => onChange(+e.target.value)}
          onTouchEnd={onReleased}
          onMouseUp={onReleased}
        />
      </div>
      <div className={s.labels}>
        {scoreLabels.map((label, index) => (
          <div
            key={label}
            className={classnames(
              s.label,
              isTouched && index === score - 1 && s.selected,
            )}
          >
            {label}
          </div>
        ))}
      </div>
    </>
  );
};

export default ScoreSelector;
