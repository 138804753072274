import { ReactElement, useEffect } from 'react';
import { createPortal } from 'react-dom';

type PortalType = {
  children?: ReactElement[] | ReactElement;
};

const Portal: React.FC<PortalType> = ({ children }) => {
  const mount = document.getElementById('portal-root');
  const el = document.createElement('div');

  useEffect(() => {
    mount?.appendChild(el);
    el.style.pointerEvents = 'initial';
    el.style.width = '100%';
    el.style.height = '100%';
    el.style.background = 'transparent';
    return () => {
      mount?.removeChild(el);
    };
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
