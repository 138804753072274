/* eslint-disable max-len */
export enum Occupation {
  'General and Operations Managers' = 'General and Operations Managers',
  'Marketing Managers' = 'Marketing Managers',
  'Sales Managers' = 'Sales Managers',
  'Industrial Production Managers' = 'Industrial Production Managers',
  'Human Resources Managers' = 'Human Resources Managers',
  'Construction Managers' = 'Construction Managers',
  'Education and Childcare Administrators, Preschool and Daycare' = 'Education and Childcare Administrators, Preschool and Daycare',
  'Food Service Managers' = 'Food Service Managers',
  'Water Resource Specialists' = 'Water Resource Specialists',
  'Postmasters and Mail Superintendents' = 'Postmasters and Mail Superintendents',
  'Property, Real Estate, and Community Association Managers' = 'Property, Real Estate, and Community Association Managers',
  'Social and Community Service Managers' = 'Social and Community Service Managers',
  'Buyers and Purchasing Agents' = 'Buyers and Purchasing Agents',
  'Environmental Compliance Inspectors' = 'Environmental Compliance Inspectors',
  'Human Resources Specialists' = 'Human Resources Specialists',
  'Farm Labor Contractors' = 'Farm Labor Contractors',
  'Meeting, Convention, and Event Planners' = 'Meeting, Convention, and Event Planners',
  'Sustainability Specialists' = 'Sustainability Specialists',
  'Online Merchants' = 'Online Merchants',
  'Personal Financial Advisors' = 'Personal Financial Advisors',
  'Loan Officers' = 'Loan Officers',
  'Actuaries' = 'Actuaries',
  'Operations Research Analysts' = 'Operations Research Analysts',
  'Statisticians' = 'Statisticians',
  'Architects' = 'Architects',
  'Surveyors' = 'Surveyors',
  'Agricultural Engineers' = 'Agricultural Engineers',
  'Chemical Engineers' = 'Chemical Engineers',
  'Civil Engineers' = 'Civil Engineers',
  'Transportation Engineers' = 'Transportation Engineers',
  'Electrical Engineers' = 'Electrical Engineers',
  'Mechanical Engineers' = 'Mechanical Engineers',
  'Automotive Engineers' = 'Automotive Engineers',
  'Wind Energy Engineers' = 'Wind Energy Engineers',
  'Solar Energy Systems Engineers' = 'Solar Energy Systems Engineers',
  'Civil Engineering Technicians' = 'Civil Engineering Technicians',
  'Environmental Engineering Technicians' = 'Environmental Engineering Technicians',
  'Industrial Engineering Technicians' = 'Industrial Engineering Technicians',
  'Automotive Engineering Technicians' = 'Automotive Engineering Technicians',
  'Animal Scientists' = 'Animal Scientists',
  'Food Scientists and Technologists' = 'Food Scientists and Technologists',
  'Soil and Plant Scientists' = 'Soil and Plant Scientists',
  'Range Managers' = 'Range Managers',
  'Park Naturalists' = 'Park Naturalists',
  'Foresters' = 'Foresters',
  'Epidemiologists' = 'Epidemiologists',
  'Medical Scientists' = 'Medical Scientists',
  'Environmental Scientists and Specialists' = 'Environmental Scientists and Specialists',
  'Geographers' = 'Geographers',
  'Transportation Planners' = 'Transportation Planners',
  'Chemical Technicians' = 'Chemical Technicians',
  'Social Science Research Assistants' = 'Social Science Research Assistants',
  'Quality Control Analysts' = 'Quality Control Analysts',
  'Educational, Guidance, and Career Counselors' = 'Educational, Guidance, and Career Counselors',
  'Child, Family, and School Social Workers' = 'Child, Family, and School Social Workers',
  'Healthcare Social Workers' = 'Healthcare Social Workers',
  'Health Education Specialists' = 'Health Education Specialists',
  'Social and Human Service Assistants' = 'Social and Human Service Assistants',
  'Community Health Workers' = 'Community Health Workers',
  'Lawyers' = 'Lawyers',
  'Paralegals and Legal Assistants' = 'Paralegals and Legal Assistants',
  'Elementary School Teachers' = 'Elementary School Teachers',
  'Special Education Teachers' = 'Special Education Teachers',
  'Adapted Physical Education Specialists' = 'Adapted Physical Education Specialists',
  'Adult Educators' = 'Adult Educators',
  'Archivists' = 'Archivists',
  'Museum Technicians and Conservators' = 'Museum Technicians and Conservators',
  'Library Technicians' = 'Library Technicians',
  'Craft Artists' = 'Craft Artists',
  'Commercial and Industrial Designers' = 'Commercial and Industrial Designers',
  'Fashion Designers' = 'Fashion Designers',
  'Floral Designers' = 'Floral Designers',
  'Graphic Designers' = 'Graphic Designers',
  'Merchandise Displayers' = 'Merchandise Displayers',
  'Set and Exhibit Designers' = 'Set and Exhibit Designers',
  'Actors' = 'Actors',
  'Poets, Lyricists and Creative Writers' = 'Poets, Lyricists and Creative Writers',
  'Interpreters and Translators' = 'Interpreters and Translators',
  'Audio and Video Technicians' = 'Audio and Video Technicians',
  'Photographers' = 'Photographers',
  'Camera Operators, Television, Video, and Film' = 'Camera Operators, Television, Video, and Film',
  'Film and Video Editors' = 'Film and Video Editors',
  'Dentists' = 'Dentists',
  'Physician Assistants' = 'Physician Assistants',
  'Occupational Therapists' = 'Occupational Therapists',
  'Physical Therapists' = 'Physical Therapists',
  'Veterinarians' = 'Veterinarians',
  'Registered Nurses' = 'Registered Nurses',
  'Nurse Practitioners' = 'Nurse Practitioners',
  'Medical and Clinical Laboratory Technicians' = 'Medical and Clinical Laboratory Technicians',
  'Dietetic Technicians' = 'Dietetic Technicians',
  'Pharmacy Technicians' = 'Pharmacy Technicians',
  'Psychiatric Technicians' = 'Psychiatric Technicians',
  'Surgical Technologists' = 'Surgical Technologists',
  'Veterinary Technicians' = 'Veterinary Technicians',
  'Licensed Practical and Licensed Vocational Nurses' = 'Licensed Practical and Licensed Vocational Nurses',
  'Midwives' = 'Midwives',
  'Occupational Therapy Assistants' = 'Occupational Therapy Assistants',
  'Physical Therapist Aides' = 'Physical Therapist Aides',
  'Massage Therapists' = 'Massage Therapists',
  'Dental Assistants' = 'Dental Assistants',
  'Medical Assistants' = 'Medical Assistants',
  'Medical Equipment Preparers' = 'Medical Equipment Preparers',
  'Pharmacy Aides' = 'Pharmacy Aides',
  'Veterinary Assistants and Laboratory Animal Caretakers' = 'Veterinary Assistants and Laboratory Animal Caretakers',
  'Speech-Language Pathology Assistants' = 'Speech-Language Pathology Assistants',
  'Forest Fire Inspectors and Prevention Specialists' = 'Forest Fire Inspectors and Prevention Specialists',
  'Fish and Game Wardens' = 'Fish and Game Wardens',
  'Transit and Railroad Police' = 'Transit and Railroad Police',
  'Animal Control Workers' = 'Animal Control Workers',
  'Chefs and Head Cooks' = 'Chefs and Head Cooks',
  'Cooks, Private Household' = 'Cooks, Private Household',
  'Food Preparation Workers' = 'Food Preparation Workers',
  'Waiters and Waitresses' = 'Waiters and Waitresses',
  'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop' = 'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop',
  'Landscaping and Groundskeeping Workers' = 'Landscaping and Groundskeeping Workers',
  'Animal Caretakers' = 'Animal Caretakers',
  'Barbers' = 'Barbers',
  'Hairdressers, Hairstylists, and Cosmetologists' = 'Hairdressers, Hairstylists, and Cosmetologists',
  'Makeup Artists, Theatrical and Performance' = 'Makeup Artists, Theatrical and Performance',
  'Manicurists and Pedicurists' = 'Manicurists and Pedicurists',
  'Skincare Specialists' = 'Skincare Specialists',
  'Tour Guides and Escorts' = 'Tour Guides and Escorts',
  'Childcare Workers' = 'Childcare Workers',
  'Residential Advisors' = 'Residential Advisors',
  'Cashiers' = 'Cashiers',
  'Retail Salespersons' = 'Retail Salespersons',
  'Travel Agents' = 'Travel Agents',
  'Sales Representatives' = 'Sales Representatives',
  'Demonstrators and Product Promoters' = 'Demonstrators and Product Promoters',
  'Real Estate Sales Agents' = 'Real Estate Sales Agents',
  'Payroll and Timekeeping Clerks' = 'Payroll and Timekeeping Clerks',
  'Procurement Clerks' = 'Procurement Clerks',
  'Tellers' = 'Tellers',
  'Customer Service Representatives' = 'Customer Service Representatives',
  'Library Assistants, Clerical' = 'Library Assistants, Clerical',
  'Human Resources Assistants' = 'Human Resources Assistants',
  'Receptionists and Information Clerks' = 'Receptionists and Information Clerks',
  'Cargo and Freight Agents' = 'Cargo and Freight Agents',
  'Postal Service Mail Carriers' = 'Postal Service Mail Carriers',
  'Secretaries and Administrative Assistants' = 'Secretaries and Administrative Assistants',
  'Data Entry Keyers' = 'Data Entry Keyers',
  'Word Processors and Typists' = 'Word Processors and Typists',
  'Statistical Assistants' = 'Statistical Assistants',
  'Animal Breeders' = 'Animal Breeders',
  'Agricultural Equipment Operators' = 'Agricultural Equipment Operators',
  'Forest and Conservation Workers' = 'Forest and Conservation Workers',
  'Logging Equipment Operators' = 'Logging Equipment Operators',
  'First-Line Supervisors of Construction Trades and Extraction Workers' = 'First-Line Supervisors of Construction Trades and Extraction Workers',
  'Boilermakers' = 'Boilermakers',
  'Stonemasons' = 'Stonemasons',
  'Tile and Stone Setters' = 'Tile and Stone Setters',
  'Cement Masons and Concrete Finishers' = 'Cement Masons and Concrete Finishers',
  'Operating Engineers and Other Construction Equipment Operators' = 'Operating Engineers and Other Construction Equipment Operators',
  'Drywall and Ceiling Tile Installers' = 'Drywall and Ceiling Tile Installers',
  'Electricians' = 'Electricians',
  'Glaziers' = 'Glaziers',
  'Painters, Construction and Maintenance' = 'Painters, Construction and Maintenance',
  'Roofers' = 'Roofers',
  'Rail-Track Laying and Maintenance Equipment Operators' = 'Rail-Track Laying and Maintenance Equipment Operators',
  'Computer, Automated Teller, and Office Machine Repairers' = 'Computer, Automated Teller, and Office Machine Repairers',
  'Electric Motor, Power Tool, and Related Repairers' = 'Electric Motor, Power Tool, and Related Repairers',
  'Security and Fire Alarm Systems Installers' = 'Security and Fire Alarm Systems Installers',
  'Automotive Body and Related Repairers' = 'Automotive Body and Related Repairers',
  'Maintenance and Repair Workers, General' = 'Maintenance and Repair Workers, General',
  'Commercial Divers' = 'Commercial Divers',
  'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers' = 'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers',
  'Engine and Other Machine Assemblers' = 'Engine and Other Machine Assemblers',
  'Bakers' = 'Bakers',
  'Butchers and Meat Cutters' = 'Butchers and Meat Cutters',
  'Machinists' = 'Machinists',
  'Tool and Die Makers' = 'Tool and Die Makers',
  'Printing Press Operators' = 'Printing Press Operators',
  'Shoe and Leather Workers and Repairers' = 'Shoe and Leather Workers and Repairers',
  'Tailors, Dressmakers, and Custom Sewers' = 'Tailors, Dressmakers, and Custom Sewers',
  'Patternmakers, Wood' = 'Patternmakers, Wood',
  'Water and Wastewater Treatment Plant and System Operators' = 'Water and Wastewater Treatment Plant and System Operators',
  'Chemical Plant and System Operators' = 'Chemical Plant and System Operators',
  'Petroleum Pump System Operators, Refinery Operators, and Gaugers' = 'Petroleum Pump System Operators, Refinery Operators, and Gaugers',
  'Chemical Equipment Operators and Tenders' = 'Chemical Equipment Operators and Tenders',
  'Medical Appliance Technicians' = 'Medical Appliance Technicians',
  'Painting, Coating, and Decorating Workers' = 'Painting, Coating, and Decorating Workers',
  'Potters, Manufacturing' = 'Potters, Manufacturing',
  'Flight Attendants' = 'Flight Attendants',
  'Ambulance Drivers and Attendants' = 'Ambulance Drivers and Attendants',
  'Heavy and Tractor-Trailer Truck Drivers' = 'Heavy and Tractor-Trailer Truck Drivers',
  'Rail Yard Engineers, Dinkey Operators, and Hostlers' = 'Rail Yard Engineers, Dinkey Operators, and Hostlers',
  'Sailors and Marine Oilers' = 'Sailors and Marine Oilers',
  'Motorboat Operators' = 'Motorboat Operators',
  'Ship Engineers' = 'Ship Engineers',
}
