function luhnAlgorithm(rsa: string): boolean {
  let checkDigit = 0;
  let addValue = 0;
  for (let i = 0; i < rsa.length; i += 1) {
    if (i % 2 === 0) {
      const result = parseInt(rsa[rsa.length - i - 1].toString(), 10) * 2;
      if (result > 9) {
        addValue += result - 9;
      } else {
        addValue += result;
      }
    } else {
      addValue += parseInt(rsa[rsa.length - i - 1].toString(), 10);
    }
  }

  if (addValue % 10 === 0) {
    checkDigit = 0;
  } else {
    checkDigit = 10 - (addValue % 10);
  }
  return checkDigit !== 0;
}

export { luhnAlgorithm };
