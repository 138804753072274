import useCorsiGameState from './corsiState';
import { useShallow } from 'zustand/react/shallow';

export const useCorsiGameActions = () => useCorsiGameState(useShallow(s => ({
  roundStageForward: s.roundStageForward,
  testTrialsForward: s.testTrialsForward,
  mainTrialsForward: s.mainTrialsForward,
  setAnswer: s.setAnswer,
  startPracticeTrialsTimer: s.startPracticeTrialsTimer,
  startTrialTimer: s.startTrialTimer,
  startInstructionsTime: s.startInstructionsTimer,
  stopInstructionsTimer: s.stopInstructionsTimer,
  startGame: s.startGame,
})));

export const useCorsiGameData = () => useCorsiGameState(useShallow(s => ({
  corsiBlocks: s.corsiBlocks,
  sequence: s.getCurrentSequence(),
  stage: s.currentRoundStage,
  numberBlocksToHighlight: s.numberBlocksToHighLight,
  isFinishedGameTrials: s.isFinishedGameTrials,
  isSuccessRound: s.isSuccessRound,
  gameResult: s.gameResult,
  isGameOver: s.isGameOver,
  isPracticeMode: s.isPracticeMode,
  longestCorsiSpan: Math.max(...s.gameResult.corsiGameScores
    .filter((result) => !result.practice)
    .map(result => result.highestCorsiSpan)),
  longestCorsiSequence: s.getLongestSequence(),
  currentRound: s.currentRound,
})));
