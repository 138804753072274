import { DeviceInfo } from 'types';

export enum GameStage {
  trialRunA = 'trial run A',
  trialRunB = 'trial run B',
  mainRun = 'main run',
}

export type Point = {
  x: number;
  y: number;
};

export type SvgProps = {
  isDrawing: boolean;
  isFinished: boolean;
  hasError: boolean;
  onAllHitboxesChecked: () => void;
  onOutOfBounds: () => void;
};

export type MTPTStageResult = {
  nrTimesOffLine: number;
  nrTimesTooSlow: number;
  totalErrors: number;
  roundTime: number;
  maxRoundTime: number;
  maxCompletionPercent: number;
  didQuit: boolean;
  isPractice: boolean;
};

export type MTPTResults = {
  mtptScores: MTPTStageResult[];
  mtptMetadata: {
    timeSpentOnIntroPage: number;
    device: DeviceInfo;
  };
};
