import {
  createTheme,
  ThemeProvider,
} from '@mui/material';

import { useVirtualUnits } from 'appState';

import s from './QuestionnaireWrapper.module.scss';
import { ReactNode } from 'react';

const QuestionnaireWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const v = useVirtualUnits();
  const theme = createTheme({
    typography: {
      fontSize: 250 / v.w,
    },
  });

  return (
    <div className={s.wrapper}>
      <div className={s.innerWrapper}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </div>
    </div>
  );
};

export default QuestionnaireWrapper;
