import { useAppStateActions } from 'appState';
import { AspectRatioView, ButtonRect, LanguageSwitch } from 'components';

import s from './PlayGame.module.scss';

type PlayGameProps = {
  gameName: string,
  imgSrc?: string,
  text: { p1: string, p2?: string };
  btnText: string,
};

const PlayGame: React.FC<PlayGameProps> = ({
  gameName,
  imgSrc,
  text,
  btnText,
}) => {
  const { gameFlowForward } = useAppStateActions();

  return (
    <AspectRatioView>
      <div className={s.container}>
        <div className={s.row}>
          <LanguageSwitch />
          <h4 className={s.gameName}>{gameName}</h4>
        </div>
        <div className={s.row}>
          <div className={s.text}>
            <p>{text.p1}</p>
            <p>{text.p2}</p>
          </div>
          {imgSrc && <img src={imgSrc} className={s.playGameImg} alt="" />}
        </div>
        <ButtonRect text={btnText} onClick={gameFlowForward} className={s.btn} />
      </div>
    </AspectRatioView>
  );
};

export default PlayGame;
