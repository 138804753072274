import { DeviceInfo } from 'types';

const tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
// eslint-disable-next-line max-len
const mobileRegex = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

const getDeviceType = () => {
  if (tabletRegex.test(navigator.userAgent)) {
    return 'mobile';
  }
  if (mobileRegex.test(navigator.userAgent)) {
    return 'mobile';
  }
  return 'desktop';
};

export const getDeviceInfo = (): DeviceInfo => ({
  resolution: `${window.screen.width}x${window.screen.height}`,
  browser: navigator.userAgent,
  type: getDeviceType(),
});
