import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  GameFlow,
  GetReady,
  Instructions,
  Intro,
  PlayGame,
} from 'components/game';
import { useAppStateActions } from 'appState';
import { fillTranslationTextWithVariables, useTranslation } from 'hooks';
import { GameId } from 'types/enums';

import Game from './Game';
import { mainTrials, practiceTrials } from './trials';
import { RMETResults, TrialScore } from './types';
import playGameImg from './img/playGame.png';
import { TranslationPage } from '../../types/enums/TranslationPage';
import Feedback from '../../components/game/Feedback';
import { calcRmetScore } from '../../utils/scoresCalc';

const TOTAL_STAGES = practiceTrials.length + mainTrials.length;

const RMET: React.FC = () => {
  const lang = useTranslation(TranslationPage.RMET);
  const { gameFlowForward, submitGameResults } = useAppStateActions();

  const [introStart, setIntroStart] = useState(-1);
  const [introEnd, setIntroEnd] = useState(-1);
  const onIntroStart = useCallback(
    () => setIntroStart(Date.now()),
    [setIntroStart],
  );
  const onIntroEnd = useCallback(
    () => setIntroEnd(Date.now()),
    [setIntroEnd],
  );

  const [scores, setScores] = useState<TrialScore[]>([]);

  const onFinish = useCallback((scores: TrialScore[]) => {
    setScores(prevScores => [...prevScores, ...scores]);
    gameFlowForward();
  }, [gameFlowForward, setScores]);

  const score = useMemo(
    () => calcRmetScore(scores),
    [scores],
  );

  useEffect(() => {
    if (scores.length === TOTAL_STAGES) {
      const results: RMETResults = {
        rmetScores: scores,
        rmetMetadata: {
          timeSpentOnInstructions: introEnd - introStart,
        },
      };
      submitGameResults(GameId.RMET, results);
    }
  }, [scores, submitGameResults, introStart, introEnd]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.gameTitle}
            description={lang.description}
            superPowerName={lang.superPowerName}
            superPower={lang.superPower}
            buttonLabel={lang.introButtonLabel}
          />
        ),
        () => (
          <Instructions
            name={lang.gameTitle}
            instructions={lang.instructions}
            onStart={onIntroStart}
            onEnd={onIntroEnd}
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            isPractice={true}
            trials={practiceTrials}
            onFinish={onFinish}
          />
        ),
        () => (
          <PlayGame
            gameName={lang.gameTitle}
            imgSrc={playGameImg}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            isPractice={false}
            trials={mainTrials}
            onFinish={onFinish}
          />
        ),
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { score },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default RMET;
export type Results = RMETResults;
