import { luhnAlgorithm } from './luhnAlghorithm';

const isValidCitizenship = (rsaId: string): boolean => RegExp('^.{10}[0-1]').test(rsaId);

const isValidRsaId = (rsaId: string): boolean =>
  RegExp('^[0-9]{13}$').test(rsaId) && isValidCitizenship(rsaId) && luhnAlgorithm(rsaId);

const isValidName = (name: string): boolean => {
  const forbiddenSigns = '0-9_!¡?÷?¿©/\\+=@#$%ˆ&*(){}|~<>;:[\\]';
  return RegExp(`[^ ${forbiddenSigns}]+(\\s+[^${forbiddenSigns}]+)*$`).test(name.trimEnd());
};

const isValidPhoneNumber = (phoneNumber: string): boolean =>
  (RegExp('^[+]?[0-9]{8,13}$').test(phoneNumber));

const isValidEmailOrRsaId = (identifier: string): boolean =>
  (RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,}$', 'i')
    .test(identifier)) || isValidRsaId(identifier);

const isValidEmail = (identifier: string): boolean =>
  (RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,}$', 'i').test(identifier));

const standardInput = (input: string): boolean =>
  (RegExp('^[a-zA-Z0-9 .-]*$', 'i').test(input));

export {
  isValidRsaId,
  isValidName,
  isValidPhoneNumber,
  isValidEmailOrRsaId,
  isValidEmail,
  standardInput
};
