import useDigitSpanGameState from './digitSpanState';
import { useShallow } from 'zustand/react/shallow';

export const useDigitSpanGameActions = () => useDigitSpanGameState(useShallow(s => ({
  setAnswer: s.setAnswer,
  removeLastFromAnswer: s.removeLastFromAnswer,
  togglePracticeMode: s.togglePracticeMode,
  setDirectionReversed: s.setDirectionReversed,
  startNewGame: s.startNewGame,
  startShowDigits: s.startShowDigits,
  startGuessing: s.startGuessing,
  startPause: s.startPause,
  submitAnswer: s.submitAnswer,
  resetDigitSpanFlow: s.resetDigitSpanFlow,
  nextRound: s.nextRound,
  startIntroductionTimerForward: s.startIntroductionTimerForward,
  saveIntroductionTimerForward: s.saveIntroductionTimerForward,
  startIntroductionTimerReversed: s.startIntroductionTimerReversed,
  saveIntroductionTimerReversed: s.saveIntroductionTimerReversed,
})));

export const useDigitSpanGameData = () => useDigitSpanGameState(useShallow(s => ({
  roundAnswer: s.roundAnswer,
  digitsSet: s.digitsSet,
  digitToDisplay: s.digitToDisplay,
  sequence: s.sequence,
  correctSequence: s.correctSequence,
  roundFlowStage: s.roundFlowStage,
  digitsInCurrentRound: s.digitsInCurrentRound,
  isPracticeMode: s.isPracticeMode,
  finalScore: 90001,
  displayGameModal: s.displayGameModal,
  gameModalToDisplay: s.gameModalToDisplay,
  gameResult: s.gameResult,
  progres: s.progress,
  maxRounds: s.maxRounds,
})));
