import { InputAdornment, MenuItem, TextField } from "@mui/material";
import classNames from "classnames";
import { ChangeEvent, useEffect, useMemo, useState } from "react";

import { useAppStateActions } from "appState";
import { AspectRatioView, ButtonRect } from "components";
import { useTranslation } from "../../hooks";
import { TranslationPage } from "../../types/enums/TranslationPage";
import { EmploymentStatusUpdated } from "../../types/enums/Questionnaire";
import { standardInput } from "../../utils/regex";
import s from "./UpdateExtraData.module.scss";

enum InputName {
  EMPLOYMENT_STATUS = "employmentStatus",
  CURRENT_EMPLOYMENT = "currentEmployment",
  JOB_TITLE = "jobTitle",  
  RSA_ID = "rsaId",
}

enum InputLabel {
  EMPLOYMENT_STATUS = "Employment Status",
  CURRENT_EMPLOYMENT = "Company Name",
  JOB_TITLE = "Job Title"
}

const UpdateExtraData: React.FC = () => {
  const { updateExtraData, currentEmploymentData, employmentStatusData, jobTitleData } = useAppStateActions();
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [currentEmployment, setCurrentEmployment] = useState('');
  const [isOpenModal, setOpenModal] = useState(false);
  const lang = useTranslation(TranslationPage.UPDATE_EXTRA_DATA);
  const defaultError = lang.errorMsg;

  const [inputsErrors, setInputErrors] = useState({
    [InputName.EMPLOYMENT_STATUS]: "",
    [InputName.CURRENT_EMPLOYMENT]: "",
    [InputName.JOB_TITLE]: "",
  });

  const inputs = useMemo(
    () => [
      {
        name: InputName.EMPLOYMENT_STATUS,
        errorMsg: lang.errorMsgEmployementStatus,
        checkValidityFn: standardInput,
        label: InputLabel.EMPLOYMENT_STATUS,
        type: "select",
      },
      {
        name: InputName.CURRENT_EMPLOYMENT,
        errorMsg: lang.errorMsgCurrentEmployment,
        checkValidityFn: standardInput,
        label: InputLabel.CURRENT_EMPLOYMENT,
        type: "text",
      },
      {
        name: InputName.JOB_TITLE,
        errorMsg: lang.errorMsgJobTitle,
        checkValidityFn: standardInput,
        label: InputLabel.JOB_TITLE,
        type: "text",
      },
    ],
    [lang]
  );

  const dropdownOptions = [
    { value: EmploymentStatusUpdated.ContractEmployment, label: EmploymentStatusUpdated.ContractEmployment },
    { value: EmploymentStatusUpdated.SalaryEmployment, label: EmploymentStatusUpdated.SalaryEmployment },
    { value: EmploymentStatusUpdated.SelfEmployed, label: EmploymentStatusUpdated.SelfEmployed },
    { value: EmploymentStatusUpdated.Unemployed, label: EmploymentStatusUpdated.Unemployed },
  ];

  useEffect(() => {
    const closeModal = (): void => {
      setOpenModal(false);
    };
    window.addEventListener("popstate", closeModal);
    return () => window.removeEventListener("popstate", closeModal);
  }, []);

  useEffect(() => {
    jobTitleData && setJobTitle(jobTitleData);
    employmentStatusData && setEmploymentStatus(employmentStatusData);
    currentEmploymentData && setCurrentEmployment(currentEmploymentData);
  }, [currentEmploymentData, employmentStatusData, jobTitleData]);



  const getErrorMessage = (inputName: string, value: string): string => {
    let errorMessage = "";
    if (!value.length) {
      return defaultError;
    }
    const currentInput = inputs.find((input) => input.name === inputName);
    // console.log("currentInput> >>>", currentInput);
    if (currentInput && !currentInput.checkValidityFn(value)) {
      errorMessage = currentInput.errorMsg;
    }
    return errorMessage;
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    // console.log("updateEmployementStatus> ", value);
    setEmploymentStatus(value);

    if (value === EmploymentStatusUpdated.Unemployed) {
      setJobTitle('');
      setCurrentEmployment('');
    }
  };
  
  const updateCurrentEmployment = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const error = getErrorMessage(InputName.CURRENT_EMPLOYMENT, value);
    setInputErrors({ ...inputsErrors, [InputName.CURRENT_EMPLOYMENT]: error });
    setCurrentEmployment(value);
  };

  const updateJobTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const error = getErrorMessage(InputName.JOB_TITLE, value);
    setInputErrors({ ...inputsErrors, [InputName.JOB_TITLE]: error });
    setJobTitle(value);
  };
  
  const isValidForm = (): boolean => {
    return ((employmentStatus != EmploymentStatusUpdated.Unemployed ) && (!currentEmployment || !jobTitle) || employmentStatus.length == 0) ? false: true;
  };
  
  const handleSubmit = (): void => {

    if (isValidForm()) {
      updateExtraData({
        employmentStatus,
        currentEmployment,
        jobTitle});
    } else {
      // setInputErrors({
      //   rsaId: getErrorMessage("rsaId", rsaId),
      // });
    }
  };

  return (
    <AspectRatioView>
      <div className={s.container}>
        <div className={s.loginImg} />
        <form
          className={classNames(s.loginForm)}
          noValidate
          onSubmit={handleSubmit}
        >
          <div className={s.row}>
            <h4>{lang.heading}</h4>
            <div className={s.loginIcon} />
          </div>
          <div className={s.formInputs}>
         
         
            <TextField
              select
              label={InputLabel.EMPLOYMENT_STATUS}
              value={employmentStatus} 
              key={InputName.EMPLOYMENT_STATUS}
              onChange={handleStatusChange}
              required
              variant="outlined"
              className={classNames(s.field)}
            >
              {dropdownOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            
            {employmentStatus !== EmploymentStatusUpdated.Unemployed && (
              <>
              <TextField
                key={InputName.CURRENT_EMPLOYMENT}
                name={InputName.CURRENT_EMPLOYMENT}
                label={InputLabel.CURRENT_EMPLOYMENT}
                value={currentEmployment}
                onChange={updateCurrentEmployment}
                required
                variant="outlined"
                className={classNames(s.field)}
                helperText={inputsErrors[InputName.CURRENT_EMPLOYMENT]}
                error={!!inputsErrors[InputName.CURRENT_EMPLOYMENT]}
                
              />
              <TextField
                key={InputName.JOB_TITLE}
                name={InputName.JOB_TITLE}
                label={InputLabel.JOB_TITLE}
                value={jobTitle}
                onChange={updateJobTitle}
                required
                variant="outlined"
                className={classNames(s.field)}
                helperText={inputsErrors[InputName.JOB_TITLE]}
                error={!!inputsErrors[InputName.JOB_TITLE]}
              />
              </>
            )}

          </div>

          <ButtonRect
            onClick={handleSubmit}
            text={lang.proceed}
            className={classNames(s.loginBtn, { [s.disabled]: !isValidForm() })}
          />
        </form>
      </div>
    </AspectRatioView>
  );
};

export default UpdateExtraData;
