import { useAppStateActions } from 'appState';
import { FAQ } from 'components';

const PostLoginInstructions: React.FC = () => {
  const { appFlowForward } = useAppStateActions();
  return (
    <FAQ onNext={appFlowForward} />
  );
};

export default PostLoginInstructions;
