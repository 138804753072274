import classnames from 'classnames';
import { SlideImageProps } from './SlidImage.types';
import styles from './SlideImage.module.scss';

const SlideImage: React.FC<SlideImageProps> = ({
  className,
  content: img,
}) => (
  <div className={classnames(styles.container, className)}>
    <img className={styles.image} src={img} alt="text" />
  </div>
);

export default SlideImage;
