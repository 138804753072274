import { create } from 'zustand';
import { TMTResults, StageResults } from './types';

type ResultsState = {
  stageResults: StageResults[];
  introStartTimestamps: number[],
  introEndTimeStamps: number[],
  introductionTimes: number[],
  finalResults: TMTResults | null;
  setIntroStart: () => void;
  setIntroEnd: () => void;
  saveIntroductionTime: (time: number) => void;
  saveStageResults: (results: StageResults) => void;
  calculateFinalResults: () => void;
};

const useResultsState = create<ResultsState>((set, get) => ({
  stageResults: [],
  introStartTimestamps: [],
  introEndTimeStamps: [],
  introductionTimes: [-1, -1],
  finalResults: null,

  setIntroStart: () => set({
    introStartTimestamps: get().introStartTimestamps.concat(Date.now()),
  }),

  setIntroEnd: () => set({
    introEndTimeStamps: get().introEndTimeStamps.concat(Date.now()),
  }),

  saveIntroductionTime: (time) => set({
    introductionTimes: get().introductionTimes.concat(time),
  }),

  saveStageResults: (stage) => {
    set({ stageResults: get().stageResults.concat(stage) });
    if (get().stageResults.length === 4) {
      get().calculateFinalResults();
    }
  },

  calculateFinalResults: () => {
    if (get().stageResults.length !== 4) {
      return null;
    }

    const introStartTimestamps = get().introStartTimestamps;
    const introEndTimeStamps = get().introEndTimeStamps;
    const introductionTimes = [
      Math.round((introEndTimeStamps[0] - introStartTimestamps[0])),
      Math.round((introEndTimeStamps[1] - introStartTimestamps[1])),
    ];

    const stages = get().stageResults;
    if (stages.length < 4) {
      return null;
    }

    const finalResults = {
      tmtMetadata: {
        practiceAErrors: stages[0].errors,
        practiceATime: stages[0].time,
        practiceBErrors: stages[2].errors,
        practiceBTime: stages[2].time,
        trailAErrors: stages[1].errors,
        trailATime: stages[1].time,
        trailBErrors: stages[3].errors,
        trailBTime: stages[3].time,
        combinedErrors: stages.map(s => s.errors).reduce((a, b) => a + b, 0),
        combinedTrailtime: stages.map(s => s.time).reduce((a, b) => a + b, 0),
        partAIntroductionTime: introductionTimes[0],
        partBIntroductionTime: introductionTimes[1],
        practiceAFingerLifts: stages[0].fingerLifts - stages[0].errors,
        trailAFingerLifts: stages[1].fingerLifts - stages[1].errors,
        practiceBFingerLifts: stages[2].fingerLifts - stages[2].errors,
        trailBFingerLifts: stages[3].fingerLifts - stages[3].errors,
        practiceASelections: stages[0].selections.map(pair => pair.join(',')).join(';'),
        trailASelections: stages[1].selections.map(pair => pair.join(',')).join(';'),
        practiceBSelections: stages[2].selections.map(pair => pair.join(',')).join(';'),
        trailBSelections: stages[3].selections.map(pair => pair.join(',')).join(';'),
      },
    };

    set({ finalResults });
  },
}));

export default useResultsState;
