import { useCallback, useMemo, useState } from 'react';

import { fillTranslationTextWithVariables, useTranslation } from 'hooks';
import { getDeviceInfo, objectEntries } from 'utils';
import { GameId } from 'types/enums';
import { QuizzResults } from 'types';
import { useAppStateActions } from 'appState';
import {
  GameFlow,
  Instructions,
  Intro,
  Quizz,
} from 'components/game';

import images from './images';
import { RIASECResults } from './types';
import { TranslationPage } from '../../types/enums/TranslationPage';
import Feedback from '../../components/game/Feedback';
import { calcRiasecScores } from '../../utils/scoresCalc';
import { RiasecDto } from '../../utils/riasec';

const RIASECGameFlow: React.FC = () => {
  const { gameFlowForward, submitGameResults } = useAppStateActions();
  const lang = useTranslation(TranslationPage.RIASEC);
  const cardsLang = useTranslation(TranslationPage.ASSESMENT);
  const [careerTypes, setCareerTypes] = useState<RiasecDto>();

  const onComplete = useCallback((scores: QuizzResults) => {
    const results: RIASECResults = {
      riasecTestScores: scores,
      riasecTestMetadata: {
        device: getDeviceInfo(),
      },
    };
    submitGameResults(GameId.RIASEC, results);
    setCareerTypes(calcRiasecScores(results.riasecTestScores));
    gameFlowForward();
  }, [submitGameResults, gameFlowForward]);

  const topCareerType = useMemo(() => {
    if (!careerTypes) {
      return '';
    }

    const occupationalInterests = objectEntries(careerTypes)
      .sort(([, a], [, b]) => b - a)
      .map(([id, _]) => (cardsLang.cards.occupationInterests[
        id as keyof typeof cardsLang.cards.occupationInterests
      ].name));

    return occupationalInterests[0];
  }, [careerTypes, cardsLang]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.title}
            description={lang.description}
            buttonLabel={lang.buttonLabels.intro}
            superPowerName={lang.superPowerName}
            superPower={lang.superPower}
          />
        ),
        () => (
          <Instructions
            name={lang.title}
            instructions={lang.instructions}
            nextButtonLabel={lang.buttonLabels.instructions}
          />
        ),
        () => (
          <Quizz
            questions={lang.questions}
            images={images}
            setSize={1}
            scoreLabels={lang.scoreLabels}
            onComplete={onComplete}
          />
        ),
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                {
                  careerTypes: topCareerType || '',
                },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default RIASECGameFlow;
export type Results = RIASECResults;
