import image1 from './slide1.png';
import image2 from './slide2.png';
import image3 from './slide3.png';
import image4 from './slide4.png';

export const tutorialImagesA = [
  image1,
  image2,
  image3,
];

export const tutorialImagesB = [
  image4,
];
