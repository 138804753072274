import { CSSProperties, ReactChild, useEffect } from 'react';
import classNames from 'classnames';

import s from './Toast.module.scss';
import Message from './Message/Message';

type ToastProps = {
  duration?: number,
  onEnd?: () => void,
  isVisible: boolean,
  children: ReactChild | ReactChild[],
  classname?: string,
  style?: CSSProperties,
};

type ToastComposition = {
  Message: typeof Message,
};

const Toast: React.FC<ToastProps> & ToastComposition = (props) => {
  const {
    duration,
    onEnd,
    children,
    classname,
    isVisible,
    style,
  } = props;

  useEffect(() => {
    let time: number;
    if (isVisible && onEnd && duration) {
      time = window.setTimeout(onEnd, duration);
    }
    return () => clearTimeout(time);
  }, [duration, onEnd, isVisible]);

  return (
    <div
      className={classNames(s.notificationContainer, classname, { [s.visible]: isVisible })}
      style={style}
    >
      {children}
    </div>
  );
};

Toast.Message = Message;

export default Toast;
