import React from 'react';
import classNames from 'classnames';
import successImg from 'assets/images/successModalImg.svg';
import { ButtonRect } from 'components';

import { DefaultModalProps } from './types';
import s from '../GameModal.module.scss';
import { useTranslation } from '../../../hooks';
import { TranslationPage } from '../../../types/enums/TranslationPage';

const Success: React.FC<DefaultModalProps> = ({ header, text, onClick }) => {
  const lang = useTranslation(TranslationPage.CHILDREN);

  return (
    <>
      <div className={s.modal}>
        <div>
          <div className={classNames(s.feedbackIcon, s.success)} />
          <h6>{ header ?? 'Success!' }</h6>
          <p>{ text ?? 'Click ‘Next’ to start another trial' }</p>
        </div>
        <img className={s.modalImg} src={successImg} alt="Success illustration" />
      </div>
      <ButtonRect
        text={lang.next}
        onClick={onClick}
        isVisible={true}
        className={s.cornerBtn}
      />
    </>
  );
};

export default Success;
