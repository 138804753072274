import { create } from 'zustand';
import api from 'api';
import {
  InteractionKeys, Interactions, TimeSpentKeys, Visitation,
} from 'types/Visitation';

type AssessmentState = {
  errors: unknown[],
  registerError: (error: unknown) => void;

  currentVisitation: Visitation | null;
  createNewVisitation: () => void;
  getExistingVisitation: () => void;
  updateCurrentVisitation: (content: Partial<Visitation>) => void;
  setVisitedEntrepreneurship: () => void;
  addSearchQuery: (query: string) => void;
  incrementInteraction: (visitationKey: string, interactionKey: string) => void;
  addTimeSpent: (
    key: 'mainFeedbackTimeSpent' | 'entrepreneurshipTimeSpent' | 'learningCornerTimeSpent',
    value: number
  ) => void;
  setPdfSentToEmail: (email: string) => void;
  setPdfDownloaded: () => void;
  clearSessionStorageVisitations: () => void;
};

const createAssessmentState = () => create<AssessmentState>(
  (set, get) => ({
    errors: [],
    registerError: (error) => {
      const serializedError = error instanceof Error
        ? { name: error.name, message: error.message }
        : error;
      set({ errors: get().errors.concat(serializedError) });
    },

    currentVisitation: null,
    createNewVisitation: () => {
      const { registerError } = get();
      api.createNewVisitation()
        .then(newVisitation => {
          set({ currentVisitation: newVisitation });
          sessionStorage.setItem('assessmentEvents', JSON.stringify({
            currentVisitation: { ...newVisitation },
          }));
        })
        .catch(e => registerError(e));
    },
    getExistingVisitation: () => {
      const root = sessionStorage.getItem('assessmentEvents');
      let rootValue;
      if (root) {
        rootValue = JSON.parse(root);
      }
      if (rootValue.currentVisitation) {
        set({ currentVisitation: { ...rootValue.currentVisitation } });
      }
    },
    updateCurrentVisitation: (content) => {
      const { currentVisitation, registerError } = get();
      if (currentVisitation) {
        api.updateVisitation(currentVisitation.id, content)
          .then(updatedValues => {
            // Since we're sending patch for times in separate function that modifies
            // only specific time spent field on response we don't want to other
            // fields updates to roll them back in worse case scenario
            const updatedVisitation = {
              ...currentVisitation,
              ...updatedValues,
              mainFeedbackTimeSpent: currentVisitation.mainFeedbackTimeSpent,
              entrepreneurshipTimeSpent: currentVisitation.entrepreneurshipTimeSpent,
            };
            set({ currentVisitation: updatedVisitation });
            sessionStorage.setItem('assessmentEvents', JSON.stringify({
              currentVisitation: { ...updatedVisitation },
            }));
          })
          .catch(e => registerError(e));
      }
    },
    addSearchQuery: (query) => {
      const { currentVisitation, updateCurrentVisitation } = get();
      if (currentVisitation) {
        const prevSearchesVector = currentVisitation.searchesVector;
        const contentToUpdate = { searchesVector: [...prevSearchesVector, query] };
        updateCurrentVisitation(contentToUpdate);
      }
    },
    incrementInteraction: (visitationKey, interactionKey) => {
      const { currentVisitation, updateCurrentVisitation } = get();
      if (currentVisitation) {
        const interactions = currentVisitation[visitationKey as keyof InteractionKeys];
        const prevValue = interactions[interactionKey as keyof Interactions];
        const newValue = prevValue ? prevValue + 1 : 1;
        const contentToUpdate = {
          [visitationKey]: {
            ...interactions,
            [interactionKey]: newValue,
          },
        };
        updateCurrentVisitation(contentToUpdate);
      }
    },
    addTimeSpent: (key, value) => {
      const { currentVisitation, registerError } = get();
      if (currentVisitation) {
        const prevValue = currentVisitation[key as keyof TimeSpentKeys];
        const newValue = prevValue + value;
        const contentToUpdate = { [key]: newValue };
        if (currentVisitation) {
          api.updateVisitation(currentVisitation.id, contentToUpdate)
            .then(updatedValues => {
              const updatedVisitation = {
                ...currentVisitation,
                [key]: updatedValues[key],
              };
              set({ currentVisitation: updatedVisitation });
              sessionStorage.setItem('assessmentEvents', JSON.stringify({
                currentVisitation: { ...updatedVisitation },
              }));
            })
            .catch(e => registerError(e));
        }
      }
    },
    setVisitedEntrepreneurship: () => {
      const { currentVisitation, updateCurrentVisitation } = get();
      if (currentVisitation) {
        const contentToUpdate = {
          ...currentVisitation,
          visitedEntrepreneurship: true,
        };
        updateCurrentVisitation(contentToUpdate);
      }
    },
    setPdfSentToEmail: (email) => {
      const { currentVisitation, updateCurrentVisitation } = get();
      if (currentVisitation) {
        const contentToUpdate = {
          ...currentVisitation,
          pdfSentToEmail: true,
          pdfSentTo: email,
        };
        updateCurrentVisitation(contentToUpdate);
      }
    },
    setPdfDownloaded: () => {
      const { currentVisitation, updateCurrentVisitation } = get();
      if (currentVisitation) {
        const contentToUpdate = {
          ...currentVisitation,
          pdfDownloaded: true,
        };
        updateCurrentVisitation(contentToUpdate);
      }
    },
    clearSessionStorageVisitations: () => {
      set({ currentVisitation: null });
      sessionStorage.removeItem('assessmentEvents');
    },
  }),
);

const useAssessmentState = createAssessmentState();

export {
  createAssessmentState,
};

export default useAssessmentState;
