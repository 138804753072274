import { useCallback, useState } from 'react';

import { fillTranslationTextWithVariables, useTranslation } from 'hooks';
import { getDeviceInfo } from 'utils';
import { GameId } from 'types/enums';
import { QuizzResults } from 'types';
import { useAppStateActions } from 'appState';
import {
  GameFlow,
  Instructions,
  Intro,
  Quizz,
} from 'components/game';
import { EIResults } from './types';
import { TranslationPage } from '../../types/enums/TranslationPage';
import Feedback from '../../components/game/Feedback';
import { calcEmotionalIntelligenceScore } from '../../utils/scoresCalc';

const EIGameFlow: React.FC = () => {
  const { gameFlowForward, submitGameResults } = useAppStateActions();
  const lang = useTranslation(TranslationPage.EI);
  const [gameScore, setGameScore] = useState<number>(0);

  const onComplete = useCallback((scores: QuizzResults) => {
    const results: EIResults = {
      emotionalTestScores: scores,
      emotionalTestMetadata: {
        device: getDeviceInfo(),
      },
    };
    submitGameResults(GameId.EI, results);
    setGameScore(Math.round(
      calcEmotionalIntelligenceScore(results.emotionalTestScores) * 10000)/100
    );
    gameFlowForward();
  }, [submitGameResults, gameFlowForward]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.title}
            description={lang.description}
            buttonLabel={lang.buttonLabels.intro}
            superPowerName={lang.superPowerName}
            superPower={lang.superPower}
          />
        ),
        () => (
          <Instructions
            name={lang.title}
            instructions={lang.instructions}
            nextButtonLabel={lang.buttonLabels.instructions}
          />
        ),
        () => (
          <Quizz
            questions={lang.questions}
            setSize={2}
            scoreLabels={lang.scoreLabels}
            onComplete={onComplete}
          />
        ),

        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { score: gameScore },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default EIGameFlow;
export type Results = EIResults;
