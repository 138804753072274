import { TrialScore as RmetScore } from '../games/RMET/types';
import { QuizzResults } from '../types';
import { QuizScoreCalculator } from './quizScoreCalculator';
import {
  BIG_FIVE_AGREEABLENESS_ADD_MAP,
  BIG_FIVE_AGREEABLENESS_REVERSE_SCALE_MAP,
  BIG_FIVE_CONSCIENTIOUSNESS_ADD_MAP,
  BIG_FIVE_CONSCIENTIOUSNESS_REVERSE_SCALE_MAP,
  BIG_FIVE_EMOTIONAL_STABILITY_ADD_MAP,
  BIG_FIVE_EMOTIONAL_STABILITY_REVERSE_SCALE_MAP,
  BIG_FIVE_EXTRAVERSION_ADD_MAP,
  BIG_FIVE_EXTRAVERSION_REVERSE_SCALE_MAP,
  BIG_FIVE_MAX_SCORE,
  BIG_FIVE_OPEN_MINDEDNESS_ADD_MAP,
  BIG_FIVE_OPEN_MINDEDNESS_REVERSE_SCALE_MAP,
  BigFiveDto,
} from './bigFive';
import {
  RIASEC_ARTISTIC_ADD_MAP,
  RIASEC_CONVENTIONAL_ADD_MAP,
  RIASEC_ENTERPRISING_ADD_MAP,
  RIASEC_INVESTIGATIVE_ADD_MAP,
  RIASEC_MAX_SCORE,
  RIASEC_REALISTIC_ADD_MAP,
  RIASEC_SOCIAL_ADD_MAP,
  RiasecDto,
} from './riasec';
import { EMOTIONAL_INT_MAX_SCORE, EMOTIONAL_INTELLIGENCE_ADD_MAP } from './EI';

export function calcRmetScore(rmetScores: RmetScore[]): string | number {
  const correct = rmetScores.filter(s => !s.practice).filter(s => s.correct).length;
  const total = rmetScores.filter(s => !s.practice).length;
  // perform some rounding and limiting of precision point len.
  return +parseFloat(((correct / total) * 100).toFixed(2));
}

export function calcEmotionalIntelligenceScore(scores: QuizzResults): number {
  return (
    (QuizScoreCalculator.addQuestionAnswers(EMOTIONAL_INTELLIGENCE_ADD_MAP, scores) -
      EMOTIONAL_INTELLIGENCE_ADD_MAP.length)
    / (EMOTIONAL_INT_MAX_SCORE - EMOTIONAL_INTELLIGENCE_ADD_MAP.length)
  );
}

export const extractBigFiveExtreme = (data: BigFiveDto) => {
  const min = Math.min(...Object.values(data));
  const max = Math.max(...Object.values(data));

  return Object.fromEntries(Object.entries(data).filter(([_, v]) => v === max || v === min));
};

export function calcBigFiveGameResults(scores: QuizzResults): BigFiveDto {
  QuizScoreCalculator.reverseAnswerScale(BIG_FIVE_EXTRAVERSION_REVERSE_SCALE_MAP, scores);
  QuizScoreCalculator.reverseAnswerScale(BIG_FIVE_AGREEABLENESS_REVERSE_SCALE_MAP, scores);
  QuizScoreCalculator.reverseAnswerScale(BIG_FIVE_CONSCIENTIOUSNESS_REVERSE_SCALE_MAP, scores);
  QuizScoreCalculator.reverseAnswerScale(BIG_FIVE_EMOTIONAL_STABILITY_REVERSE_SCALE_MAP, scores);
  QuizScoreCalculator.reverseAnswerScale(BIG_FIVE_OPEN_MINDEDNESS_REVERSE_SCALE_MAP, scores);

  let bigFiveDto = new BigFiveDto();

  bigFiveDto.extraversion = QuizScoreCalculator.addQuestionAnswers(
    BIG_FIVE_EXTRAVERSION_ADD_MAP,
    scores,
  );
  bigFiveDto.agreeableness = QuizScoreCalculator.addQuestionAnswers(
    BIG_FIVE_AGREEABLENESS_ADD_MAP,
    scores,
  );
  bigFiveDto.conscientiousness = QuizScoreCalculator.addQuestionAnswers(
    BIG_FIVE_CONSCIENTIOUSNESS_ADD_MAP,
    scores,
  );
  bigFiveDto.emotionalStability = QuizScoreCalculator.addQuestionAnswers(
    BIG_FIVE_EMOTIONAL_STABILITY_ADD_MAP,
    scores,
  );
  bigFiveDto.openness = QuizScoreCalculator.addQuestionAnswers(
    BIG_FIVE_OPEN_MINDEDNESS_ADD_MAP,
    scores,
  );

  Object.keys(bigFiveDto).forEach((metric: string) => {
    // @ts-ignore
    bigFiveDto[metric] = (bigFiveDto[metric] - 6) / (BIG_FIVE_MAX_SCORE - 6);
  });

  bigFiveDto = extractBigFiveExtreme(bigFiveDto) as BigFiveDto; //* we grab only highest extremes to show and remove rest

  return bigFiveDto;
}

export const PROACTIVE_PERSONALITY_MAX_SCORE = 20;
export const PROACTIVE_PERSONALITY_ADD_MAP = [1, 2, 3, 4];

export function calcProactivePersonalityScore(scores: QuizzResults) {
  return (
    (QuizScoreCalculator.addQuestionAnswers(PROACTIVE_PERSONALITY_ADD_MAP, scores) -
      PROACTIVE_PERSONALITY_ADD_MAP.length)
    / (PROACTIVE_PERSONALITY_MAX_SCORE - PROACTIVE_PERSONALITY_ADD_MAP.length)
  );
}

export const GROWTH_MINDSET_MAX_SCORE = 20;
export const GROWTH_MINDSET_REVERSE_SCALE_MAP = [4, 3, 2, 1];
export const GROWTH_MINDSET_ADD_MAP = [1, 2, 3, 4];

export function calcGrowthMindsetScore(scores: QuizzResults): number {
  QuizScoreCalculator.reverseAnswerScale(GROWTH_MINDSET_REVERSE_SCALE_MAP, scores);
  return (
    (QuizScoreCalculator.addQuestionAnswers(GROWTH_MINDSET_ADD_MAP, scores) -
      GROWTH_MINDSET_ADD_MAP.length)
    / (GROWTH_MINDSET_MAX_SCORE - GROWTH_MINDSET_ADD_MAP.length)
  );
}

export function calcRiasecScores(scores: QuizzResults): RiasecDto {
  const riasecDto = new RiasecDto();

  riasecDto.realistic = QuizScoreCalculator.addQuestionAnswers(
    RIASEC_REALISTIC_ADD_MAP,
    scores,
  );
  riasecDto.investigative = QuizScoreCalculator.addQuestionAnswers(
    RIASEC_INVESTIGATIVE_ADD_MAP,
    scores,
  );
  riasecDto.artistic = QuizScoreCalculator.addQuestionAnswers(
    RIASEC_ARTISTIC_ADD_MAP,
    scores,
  );
  riasecDto.social = QuizScoreCalculator.addQuestionAnswers(
    RIASEC_SOCIAL_ADD_MAP,
    scores,
  );
  riasecDto.enterprising = QuizScoreCalculator.addQuestionAnswers(
    RIASEC_ENTERPRISING_ADD_MAP,
    scores,
  );
  riasecDto.conventional = QuizScoreCalculator.addQuestionAnswers(
    RIASEC_CONVENTIONAL_ADD_MAP,
    scores,
  );

  Object.keys(riasecDto).forEach(metric => {
    // @ts-ignore
    riasecDto[metric] = (riasecDto[metric] - 5) / (RIASEC_MAX_SCORE - 5);
  });

  return riasecDto;
}
