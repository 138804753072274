import React from 'react';

import modalImg from 'assets/images/gameModalImg.svg';
import { ButtonRect } from 'components';

import { DefaultModalProps } from './types';
import s from '../GameModal.module.scss';

const Go: React.FC<DefaultModalProps> = ({ text, onClick }) => (
  <>
    <div className={s.modal}>
      <div>
        <h6>Your turn</h6>
        <p>{text}</p>
      </div>
      <img className={s.modalImg} src={modalImg} alt="Start practice trial illustration" />
    </div>
    <ButtonRect
      text="Go"
      onClick={onClick}
      isVisible={true}
      className={s.cornerBtn}
    />
  </>
);

export default Go;
