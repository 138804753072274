/* eslint-disable max-len */
import { GameStage } from './types';

const itemLabels = {
  [GameStage.TrialRunA]: ['1', '2', '3', '4', '5'],
  [GameStage.MainRunA]: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'],
  [GameStage.TrialRunB]: ['1', 'A', '2', 'B', '3', 'C', '4', 'D'],
  [GameStage.MainRunB]: ['1', 'A', '2', 'B', '3', 'C', '4', 'D', '5', 'E', '6', 'F', '7', 'G', '8', 'H'],
};

const constraints = {
  [GameStage.TrialRunA]: {
    xMin: 36,
    xMax: 64,
    yMin: 16,
    yMax: 40,
    minDistance: 10,
  },
  [GameStage.MainRunA]: {
    xMin: 12,
    xMax: 88,
    yMin: 10,
    yMax: 45,
    minDistance: 10,
  },
  [GameStage.TrialRunB]: {
    xMin: 26,
    xMax: 74,
    yMin: 16,
    yMax: 40,
    minDistance: 10,
  },
  [GameStage.MainRunB]: {
    xMin: 12,
    xMax: 88,
    yMin: 10,
    yMax: 45,
    minDistance: 10,
  },
};

const lazyBrushRadius = {
  touch: 1,
  nonTouch: 1,
};

export {
  itemLabels,
  constraints,
  lazyBrushRadius,
};
