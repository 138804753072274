export class BigFiveDto {
  openness: number = 0;

  conscientiousness: number = 0;

  extraversion: number = 0;

  agreeableness: number = 0;

  emotionalStability: number = 0;
}

export const BIG_FIVE_MAX_SCORE = 30;

export const BIG_FIVE_EXTRAVERSION_REVERSE_SCALE_MAP = [1, 21, 26];
export const BIG_FIVE_EXTRAVERSION_ADD_MAP = [1, 6, 11, 16, 21, 26];

export const BIG_FIVE_AGREEABLENESS_REVERSE_SCALE_MAP = [7, 17, 27];
export const BIG_FIVE_AGREEABLENESS_ADD_MAP = [2, 7, 12, 17, 22, 27];

export const BIG_FIVE_CONSCIENTIOUSNESS_REVERSE_SCALE_MAP = [3, 8, 28];
export const BIG_FIVE_CONSCIENTIOUSNESS_ADD_MAP = [3, 8, 13, 18, 23, 28];

export const BIG_FIVE_EMOTIONAL_STABILITY_REVERSE_SCALE_MAP = [4, 9, 29];
export const BIG_FIVE_EMOTIONAL_STABILITY_ADD_MAP = [4, 9, 14, 19, 24, 29];

export const BIG_FIVE_OPEN_MINDEDNESS_REVERSE_SCALE_MAP = [10, 20, 30];
export const BIG_FIVE_OPEN_MINDEDNESS_ADD_MAP = [5, 10, 15, 20, 25, 30];
