import { useEffect, useState } from 'react';

import ButtonRect from 'components/ButtonRect/ButtonRect';
import { ButtonType } from 'components/ButtonRect/ButtonRect.types';

import QuizzItem from './QuizzItem';
import { useTranslation } from '../../../hooks';
import { TranslationPage } from '../../../types/enums/TranslationPage';
import GameSkipper from '../GameSkipper/GameSkipper';
import s from './ItemSet.module.scss';

type ItemSetProps = {
  questions: string[];
  images?: string[];
  onAnswers: (answers: number[]) => void;
  scoreLabels: string[];
  isFirstSet: boolean;
  onBack: () => void;
};

const ItemSet: React.FC<ItemSetProps> = ({
  questions,
  images = [],
  onAnswers,
  scoreLabels,
  isFirstSet,
  onBack,
}) => {
  //* Set fill to 3 to get neutral as a default answer
  const [answers, setAnswers] = useState<Array<number | null>>(Array(questions.length).fill(3));
  const setAnswer = (index: number, newAnswer: number) => setAnswers(
    answers => answers.map((oldAnswer, i) => (i === index ? newAnswer : oldAnswer)),
  );
  const lang = useTranslation(TranslationPage.QUIZZ);

  useEffect(() => {
    setAnswers(Array(questions.length).fill(3));
  }, [questions, setAnswers]);
  return (
    <>
      {questions.map((question, index) => (
        <QuizzItem
          key={question}
          question={question}
          image={images?.[index] || ""}
          onAnswer={answer => setAnswer(index, answer)}
          scoreLabels={scoreLabels}
        />
      ))}
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 5rem' }}>
        <ButtonRect
          isVisible={!isFirstSet}
          type={ButtonType.QUIT}
          text={lang.back}
          onClick={onBack}
        />
        <GameSkipper className={s.skipButton} />
        <ButtonRect
          isVisible={true}
          type={ButtonType.SUBMIT}
          text={lang.next}
          onClick={() => onAnswers(answers as number[])}
        />
      </div>
    </>
  );
};

export default ItemSet;
