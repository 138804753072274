/* eslint-disable react/no-children-prop */
import App from './App';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import SentryConfig from 'sentry.config';

SentryConfig.init();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

serviceWorkerRegistration.register();
