import s from './TextItem.module.scss';

type Props = {
  name: string;
  value: React.ReactText;
};

const TextItem: React.FC<Props> = ({ name, value }) => (
  <div className={s.item}>
    <h3 className={s.name}>
      {name}
    </h3>
    <p className={s.value}>
      {value}
    </p>
  </div>
);

export default TextItem;
