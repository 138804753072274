export enum GameStage {
  TrialRunA,
  MainRunA,
  TrialRunB,
  MainRunB,
}

export type Point = {
  x: number;
  y: number;
};

export type RandomConstraints = {
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  minDistance: number,
};

export type Item = {
  index: number;
  label: string;
  pos: Point;
  isCompleted: boolean;
  isMarkedWrong: boolean;
};

export type StageResults = {
  time: number;
  errors: number;
  fingerLifts: number;
  selections: [string, string][],
};

export type TMTResults = {
  tmtMetadata: {
    practiceAErrors: number;
    practiceATime: number;
    practiceBErrors: number;
    practiceBTime: number;
    trailAErrors: number;
    trailATime: number;
    trailBErrors: number;
    trailBTime: number;
    combinedErrors: number;
    combinedTrailtime: number;
    partAIntroductionTime: number;
    partBIntroductionTime: number;
    practiceAFingerLifts: number;
    trailAFingerLifts: number;
    practiceBFingerLifts: number;
    trailBFingerLifts: number;
    practiceASelections: string;
    trailASelections: string;
    practiceBSelections: string;
    trailBSelections: string;
  };
};
