export enum BretRoundStage {
  DEFAULT = 'default',
  COLLECTING = 'collecting',
  BOMB_INFO = 'bomb_info',
  FEEDBACK = 'feedback',
}

export enum RiskBehaviour {
  RISK_AVERSE = 'risk averse',
  RISK_NEUTRAL = 'risk neutral',
  RISK_SEEKER = 'risk seeker',
}

export type BretRoundData = {
  bombLocation: number,
  parcelsCollected: number,
  didExplode: boolean,
  practice: boolean,
};

export type BretMetadata = {
  startDate: string,
  startTime: string,
  total: number,
  completed: boolean,
  meanExplosions: number,
  meanParcelsCollected: number,
  riskBehaviour: RiskBehaviour,
  introductionTime: number,
};

export type BretResult = {
  bretGameScores: BretRoundData[],
  bretMetadata: BretMetadata,
};
